$bg-color: #ffe03c;


@keyframes pulsing {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.15);
    }

    100% {
        transform: scale(1);
    }
}

.circle {
    position: relative;
    // top: -5px;
    top: -10px;

    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: $bg-color;

    transform: scale(0);
    &.entering {
        transition: transform 1s ease-in-out 500ms;
        transform: scale(1);
    }
    &.entered {
        transform: scale(1);
    }
    &.exiting {
        transition: transform 1s ease-in-out;
        transform: scale(0);
    }
    &.exited {
        transform: scale(0);
    }

    &.pulsing {
        animation: pulsing 1.9s infinite;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 30px;
        height: 30px;
        background: $bg-color;
    }

    &.left {
        left: -5px;
        &::before {
            left: 0;
        }
    }

    &.right {
        right: -5px;
        &::before {
            right: 0;
        }
    }

    @media (min-width: 360px) {
        width: 72px;
        height: 72px;
        top: -12px;
    }

    @media (min-width: 414px) {
        width: 74px;
        height: 74px;
        top: -10px;

        &.big {
            width: 77px;
            height: 77px;
        }

        &.right {
            right: -1px;
        }
    }
}
