@import '~styles/variables/index';
@import '~styles/variables/header-height-vars';


$links-container-padding-left_320: 24px;
$links-container-padding-left_375: 28px;

$links-container-padding_320: 20px 40px 20px $links-container-padding-left_320;
$links-container-padding_375: 24px 40px 24px $links-container-padding-left_375;
$links-container-padding_393: 28px 40px 28px $links-container-padding-left_375;
$links-container-padding_424: 32px 40px 32px $links-container-padding-left_375;

$appearing-filler-timout: 100ms;
$disappearing-filler-timout: 700ms;


.mobile-nav__root {
    box-sizing: border-box;

    *, &::after, *::before {
        box-sizing: border-box;
    }
}

.mobile-nav__root {
    @include mobile-props((
        position: fixed,
        z-index: 100,
        left: 0,
        bottom: 0,
        right: 0,
        top: (
            0: $header-height-320,
            375: 60px,
        ),
        overflow-y: scroll,

        flex-direction: column,
        width: 100%,
        background: var(--color__coconut),
    ));

    &.entering,
    &.entered {
        @include mobile-props((
            opacity: 1,
        ));
    }
    &.entering {
        @include mobile-props((
            transition: opacity 300ms,
        ));
    }

    &.exiting,
    &.exited {
        @include mobile-props((
            opacity: 0,
        ));

    }
    &.exiting {
        @include mobile-props((
            transition: opacity 300ms,
        ));
    }

    &.index {
        @include mobile-props((
            top: (
                0: 43px,
                375: 50px,
            ),
            padding-top: 0,
        ));
    }
}

.mobile-nav__top-container {
    @include mobile_320 {
        border-top: solid 1px var(--color__onion);
        border-bottom: solid 1px var(--color__onion);
    }
}

.mobile-nav__links-container {
    @include mobile_320 {
        padding: $links-container-padding_320;

        &.main {
            border-bottom: solid 1px var(--color__onion);
        }
        &.ordinary {
            padding-top: 16px;
            padding-bottom: 4px;
        }
    }

    @include mobile_375 {
        padding: $links-container-padding_375;

        &.ordinary {
            padding-top: 20px;
            padding-bottom: 4px;
        }
    }

    @include mobile_393 {
        padding: $links-container-padding_393;

        &.ordinary {
            padding-top: 24px;
            padding-bottom: 4px;
        }
        &.bottom {
            padding-top: 28px;
        }
    }

    @include mobile_424 {
        padding: $links-container-padding_424;

        &.ordinary {
            padding-top: 28px;
            padding-bottom: 4px;
        }
        &.bottom {
            padding-top: 32px;
        }
    }
}

.mobile-nav__filler {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid var(--color__onion);
    background: var(--color__coconut);

    visibility: hidden;
    opacity: 0;
    transition:
        opacity $disappearing-filler-timout,
        visibility 0ms $disappearing-filler-timout;

    &.visible {
        visibility: visible;

        opacity: 1;
        transition: opacity $appearing-filler-timout;
    }
}

.mobile-nav__region-button {
    @include mobile-props((
        padding: (
            0: 8px $links-container-padding-left_320 8px 16px,
            375: 9px $links-container-padding-left_375 8px 16px,
            414: 10px $links-container-padding-left_375 10px 16px,
        ),
        position: fixed,
        z-index: 1000,
        right: 0,
        top: 0,
    ));
    &.exiting,
    &.exited {
        opacity: 0;
        visibility: hidden;
    }
}

.mobile-nav-tablet-limiter {
    @include mobile-props((
        max-width: 444px,
        margin: (
            444: 0 auto,
        ),

    ));
}
