@import "~global/styles/cool-media-queries/index.global.scss";

.deliveryZoneStreetFormRoot {
    @include all-props((
        padding: (
            0: 0 20px 12px 20px,
            375: 0 20px 16px 20px,
            1024: 0 20px 16px 20px,
        ),
    ));
}

.deliveryZoneStreetFormInputWrapper {

}

.deliveryZoneStreetFormSelectWrapper {
    @include mobile-props((
        margin-bottom: (
            0: 6px,
            375: 8px,
        )
    ));
    @include desktop-props((
        margin-bottom: 8px,
    ))
}
