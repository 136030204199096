@import "~global/styles/cool-media-queries/index.global.scss";

.deliveryZoneMapRoot {
    @include all-props((
        width: 100%,
        height: (
            0: 206px,
            375: 240px,
            1024: 240px,
        ),
        position: relative,
    ));
}

.deliveryZoneMap {
    @include all-props((
        width: 100%,
        height: 100%,
    ));
}

.deliveryZoneMapPin {
    @include all-props((
        position: absolute,
        top: (
            0: calc(50% - 21.5px),
            1024: calc(50% - 21.5px),
        ),
        left: (
            0: calc(50% - 70px),
            1024: calc(50% - 70px),
        )
    ));
}

.deliveryZoneMapGeoButton {
    position: absolute;
    @include all-props((
        right: 3%,
        bottom: (
            0: 15%,
            1024: 15%,
        ),
    ));
}

.deliveryZoneMapGeoButtonIcon {
    @include all-props((
        height: (
            0: 32px,
            375: 40px,
            1024: 40px,
        ),
        width: (
            0: 32px,
            375: 40px,
            1024: 40px,
        ),
    ));
}
