.overlay-tooltip__targeted-highlight {
    display: flex;
    justify-content: flex-end;
}

.overlay-tooltip__targeted-highlight-content {
    position: absolute;
}

.filters-overlay-content {
    margin: 92px 20px 0 44px;

    @media (min-width: 360px) {
        margin: 100px 20px 0 38px;
    }

    @media (min-width: 414px) {
        margin: 122px 20px 0 42px;
    }
}
