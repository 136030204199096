@import '~styles/variables/index';
@import './components/MenuFilters/menu-filters-vars';


$filters-button-width-1024: $v3-res1024-gird-gap;

$filters-width-1366: 312px;
$filters-width-1440: 317px;
$filters-width-1600: 351px;
$filters-width-1920: 420px;


.filters-button {
    background: var(--color__coconut);
    border: solid 1px $concrete;
    border-left: none;
    box-sizing: border-box;
    position: fixed;
    top: 250px;
    left: 0;
    z-index: 6;
    padding-top: 0;
    height: 158px;

    transition: all 300ms ease 500ms; // close animation

    &.open {
        top: 0;
        bottom: 0;
        height: 100%;
        padding-top: 250px;
        transition: all 300ms ease; // open animation
    }
    &:hover {
        cursor: pointer;
    }

    @media (min-width: 1024px) {
        top: 322px;
        height: 174px;
        width: $filters-button-width-1024;

        &.open {
            padding-top: 322px;
        }
    }
}

.filters-button-text {
    font-family: $gilroy-medium;
    font-size: 12px;
    letter-spacing: -0.13px;
    color: var(--color__cuttlefish);
    opacity: 0.47;
    transform: rotate(-90deg);
    text-transform: uppercase;
    margin-top: 84px;
    margin-left: 1px;

    @media (min-width: 1024px) {
        margin-top: 88px;
    }
}

.filters-wrap {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    bottom: 0;

    @media (min-width: 1280px) {
        width: 285px;
        top: 146px; // при изменение поменять в Filter.js
    }

    @media (min-width: 1366px) {
        width: $filters-width-1366;
        top: 150px; // при изменение поменять в Filter.js
    }

    @media (min-width: 1440px) {
        width: $filters-width-1440;
    }

    @media (min-width: 1600px) {
        width: $filters-width-1600;
    }

    @media (min-width: 1920px) {
        width: $filters-width-1920;
        top: 171px;
    }
}

.filters-wrap--tablet {
    z-index: 5; // перекрыть блюр блюд при закрытии
    width: 293px;
    left: -293px;
    background: var(--color__coconut);
    padding-top: 128px;
    box-shadow: 0 14px 26px 0 rgba(223, 223, 223, 0.25);
    transition: left 600ms ease; // close animation
    &.open {
        transition: left 600ms ease 200ms; // open animation
    }

    @media (min-width: 1024px) {
        padding-top: 146px;
        &.open {
            left: $filters-button-width-1024;
        }
    }
}

.discount {
    padding-top: 16px;
    padding-right: 20px;

    @media (min-width: 1024px) {
        padding-bottom: $reset-button-wrap-height-1024 + $reset-button-wrap-padding-vertical-1024;
        padding-left: $v3-res1024-gird-gap;
    }
    @media (min-width: 1280px) {
        padding-bottom: $reset-button-wrap-height-1280 + $reset-button-wrap-padding-vertical-1280;
        padding-left: $v3-res1280-gird-gap;
    }
    @media (min-width: 1366px) {
        padding-top: 20px;

        padding-bottom: $reset-button-wrap-height-1366 + $reset-button-wrap-padding-vertical-1366;
        padding-left: $v3-res1366-gird-gap;
    }
    @media (min-width: 1440px) {
        padding-left: $v3-res1440-gird-gap;
    }
    @media (min-width: 1600px) {
        padding-bottom: $reset-button-wrap-height-1600 + $reset-button-wrap-padding-vertical-1600;
        padding-left: $v3-res1600-gird-gap;
    }
    @media (min-width: 1920px) {
        padding-bottom: $reset-button-wrap-height-1920 + $reset-button-wrap-padding-vertical-1920;
        padding-left: $v3-res1920-gird-gap;
    }
}

.filters {
    box-sizing: border-box;
    width: 0;
    min-width: 0;
    margin-right: $v3-res960-gird-gap * 2;

    background: var(--color__coconut);
    border-right: 1px solid $concrete;
    box-shadow: 0 14px 26px 0 rgba(223, 223, 223, 0.25);

    @media (min-width: 1024px) {
        margin-right: $v3-res1024-gird-gap * 2;
    }

    @media (min-width: 1280px) {
        background: var(--color__coconut);
        box-shadow: 0 14px 26px 0 rgba(223, 223, 223, 0.25);
        min-width: 285px;
        width: 285px;
        margin-right: $v3-res1280-gird-gap;
    }

    @media (min-width: 1366px) {
        min-width: $filters-width-1366;
        width: $filters-width-1366;
        margin-right: $v3-res1366-gird-gap;
    }

    @media (min-width: 1440px) {
        min-width: $filters-width-1440;
        width: $filters-width-1440;
        margin-right: $v3-res1440-gird-gap;
    }

    @media (min-width: 1600px) {
        min-width: $filters-width-1600;
        width: $filters-width-1600;
        margin-right: $v3-res1600-gird-gap;
    }

    @media (min-width: 1920px) {
        min-width: $filters-width-1920;
        width: $filters-width-1920;
        margin-right: $v3-res1920-gird-gap;
    }
}
