@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

.auth-enter-phone {
    @include all-props((
        display: flex,
        flex-direction: column,
        justify-content: center,
        align-items: center,
        opacity: 1,
        transition: opacity 200ms,
        width: 100%,
        box-sizing: border-box,
    ));

    &.entering {
        opacity: 0;
    }

    &.entered {
        display: none;
    }
}

.auth-enter-phone__form {
    @include all-props((
        box-sizing: border-box,
        width: 100%,
        padding: (
            0: 0 34px,
            375: 0 40px,
            1024: 0 0px,
        ),
    ));

    &.single-step-checkout {
        padding: 0,
    }
}

.auth-enter-phone-header {
    @include all-props((
        text-align: center,
        margin-bottom: (
            0: 8px,
            375: 10px,
            1024: 10px,
        ),
    ));
}

.auth-enter-phone-input {
    @include all-props((
        width: 100%,
        margin-bottom: (
            0: 8px,
            375: 9px,
            1024: 9px,
        ),
    ));
}

.auth-enter-phone-button {
    @include all-props((
        width: 100%,
        margin-bottom: (
            0: 8px,
            375: 9px,
            1024: 9px,
        ),
    ));
}
.auth-enter-phone-button__text {
    @include all-props((
        font-family: $gilroy,
        font-weight: var(--font-weight__bold),
        font-size: (
            0: 17px,
            375: 18px,
            1024: 18px,
        ),
    ));
}

.auth-enter-phone-policy {
    @include all-props((
        text-align: center,
        font-family: $gilroy,
        font-weight: var(--font-weight__regular),
        letter-spacing: (
            0: -0.25px,
            375: -0.2px,
            1024: -0.2px,
        ),
        font-size: (
            0: 12px,
            375: 13px,
            1024: 13px,
        ),
        color: var(--color__lichi),
        line-height: (
            0: 14px,
            375: 17px,
            1024: 17px,
        ),
        width: (
            0: 252px,
            375: 310px,
            1024: 310px,
        ),
        margin: 0 auto,
    ));
}
.auth-enter-phone-policy__link {
    text-decoration: underline;
}
