@import '~styles/variables/index';
@import "~global/styles/cool-media-queries/index.global.scss";

.lowres--minus {
    @include mobile-props((
        display: (
            0: inline,
            360: none,
        )
    ));
}
.lowres--plus {
    @include mobile-props((
        display: (
            0: none,
            360: inline,
        )
    ));
}

.basket-trial-description-container {
    @include mobile-props((
        background-color: #fff,
        border-bottom: 1px solid #F1F1F1,
        width: 100%,
        margin: 0 auto,
        box-sizing: border-box,

        padding: (
            0: calc($header-height_320 + 40px) 16px 20px,
            360: calc($header-height_360 + 40px) 16px 20px,
            375: calc($header-height_375 + 36px) 20px 24px,
            414: calc($header-height_414 + 41px) 20px 24px,
        )
    ));
}

.basket-trial-description__title {
    @include mobile-props((
        letter-spacing: (
            0: -0.7px,
            414: -1.5px
        ),
        padding-bottom: (
            0: 4px,
            375: 7px,
        )
    ));

}

.basket-trial-description__item {
    @include mobile-props((
        line-height: (
            0: 115%,
        ),
        letter-spacing: (
            0: -0.5px,
            360: -0.5px,
            375: -0.5px,
            414: -0.5px,
        ),
        font-size: (
            0: 17px,
            375: 18px,
            414: 19px,
        ),
    ))
}

.basket-trial-description__item__crossed {
    @include mobile-props((
        font-weight: 600,
        text-decoration: line-through,
    ));
}


