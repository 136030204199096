@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

.dish-ad-lable-root {
    @include all-props((
        position: absolute,
        bottom: 8px,
        right: 8px,
    ));

    &.detail {
        @include all-props((
            position: absolute,
            bottom: 8px,
            right: 24px,
        ));
    }
};

.dish-ad-lable-bg {
    // @include all-props((
    //     border-radius: 4px,
    //     padding-top: 2px,
    //     padding-bottom: 2px,
    //     padding-left: 6px,
    //     padding-right: 6px,
    // ));

    @include all-props((
        border-radius: 4px,
    ));


   @include responsive-mobile-props(375px, (
        padding-top: 2px,
        padding-bottom: 2px,
        padding-left: 6px,
        padding-right: 6px,
    ));

    @include responsive-desktop-props(1680px, (
        padding-top: 2px,
        padding-bottom: 2px,
        padding-left: 6px,
        padding-right: 6px,
    ));

    &.dark {
        background-color: rgb(255, 255, 255, 0.3);
    }
    &.light {
        background-color: rgb(255, 255, 255, 0.5);
    }
}

/*

   @include responsive-mobile-props(375px, (
        padding-top: 2px,
        padding-bottom: 2px,
        padding-left: 6px,
        padding-right: 6px,
    ));

    @include responsive-desktop-props(1680px, (
        padding-top: 2px,
        padding-bottom: 2px,
        padding-left: 6px,
        padding-right: 6px,
    ));

*/

.dish-ad-lable-signature {
    @include all-props((
        font-weight: 400,
        font-family: $gilroy,
        color: #9C9C9C,
        // font-size: 10px,
        // line-height: 11px,
    ));

    @include responsive-mobile-props(375px, (
        font-size: 10px,
        line-height: 11px,
    ));

    @include responsive-desktop-props(1680px, (
        font-size: 10px,
        line-height: 11px,
    ));

    &.dark {
        color: var(--color__coconut);
    }
    &.light {
        color: var(--color__hering);
    }
}

/*

    @include responsive-mobile-props(375px, (
        font-size: 10px,
        line-height: 11px,
    ));

    @include responsive-desktop-props(1680px, (
        font-size: 10px,
        line-height: 11px,
    ));

*/
