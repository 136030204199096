@import "~global/styles/cool-media-queries/index.global.scss";

.textShieldRoot {
    @include all-props((
        height: (
            0: 20px,
            375: 22px,
            1024: 20px,
            1366: 22px,
            1920: 23px,
        ),
        z-index: 1,
        position: absolute,
        left: (
            0: 10px,
            375: 12px,
            1024: 10px,
            1366: 12px,
        ),
        bottom: (
            0: 10px,
            375: 12px,
            1024: 10px,
            1366: 12px,
        ),
    ));

    & .s {
        @include all-props((
            display: (
                0: block,
                375: none,
                1024: block,
                1366: none,
            ),
        ));
    };

    & .m {
        @include all-props((
            display: (
                0: none,
                375: block,
                1024: none,
                1366: block,
                1920: none,
            ),
        ));
    };

    & .l {
        @include all-props((
            display: (
                0: none,
                1024: none,
                1920: block,
            ),
        ))
    };
}


.textShieldImg {
    @include all-props((
        height: (
            0: 20px,
            375: 22px,
            1024: 20px,
            1366: 22px,
            1920: 23px,
        ),
    ));
}

