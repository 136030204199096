@import '~styles/variables/index';

.select {
    display: flex;
}

.select-button {
    display: flex;
    // justify-content: space-between;
    box-sizing: border-box;
    height: 42px;
    width: 100%;
    @include mobile-props((
        border-radius: (
            0: var(--sizeBorderRadiusXXS),
        ),
    ));
    // box-shadow: 0 4px 10px 0 rgba(215, 215, 215, 0.24);
    background: var(--color__coconut);
    border: solid 1px $concrete;
    padding: 0;
    overflow: hidden;
}

.select-value {
    padding: 0;
    width: 100%;
    padding-left: 20px;
}

.select-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 42px;
    height: 42px;
    border: none;
    padding: 0;
    background: transparent;
    border-left: solid 1px $wild-sand;
}

.select-toggle-icon {
    transition: all 150ms ease;
    transform: rotate(0);

    svg {
        width: 17px;
        height: 11px;
    }
}

.select-period {
    color: var(--color__cuttlefish);
    display: flex;
    align-items: center;
    height: 100%;
}

.select-day {
    font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
    font-size: 13px;
    letter-spacing: -0.1px;
    margin-right: 2px;
}

.select-month {
    font-family: $gilroy-semibold;
    font-size: 13px;
    letter-spacing: -0.1px;
    margin-right: 3px;
}

.select-separator {
    font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
    font-size: 14px;
    letter-spacing: -0.1px;
    margin-right: 3px;
}

@media (min-width: 360px) {

    .select-button {
        height: 48px;
    }
    .select-value {
        padding-left: 22px;
    }
    .select-toggle {
        min-width: 48px;
        height: 48px;
    }
    .select-toggle-icon {
        svg {
            width: 19px;
            height: 12px;
        }
    }
    .select-day {
        font-size: 15px;
    }
    .select-month {
        font-size: 15px;
    }
    .select-separator {
        font-size: 14px;
    }
}
