$font-path: '../../assets/fonts';
$font-name: 'NeueHaasGrotDisp-55Roman';

$src: url("#{$font-path}/#{$font-name}.woff") format('woff');

@font-face {
  font-family: $font-name;
  src:  $src;
  font-display: swap;
}
