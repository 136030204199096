@import '~styles/variables/index';
@import '~styles/modules/ruble';

.price {
    @extend %ruble;
    color: $mine-shaft;
    font-size: inherit;
}

.basket__mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color__onion);
    border-bottom: solid 1px $concrete;
    padding: 0 5px;
    min-height: 36px;
    z-index: 4;
    position: relative;
    box-shadow: 0 2px 25px 0 rgba(107, 107, 107, 0.09);
}

.basket__mobile-header-back {
    transform: rotate(180deg);
    background: var(--color__onion);
    border: none;
    padding: 0;
    height: 36px;
    min-height: 36px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.basket__mobile-header-title {
    font-family: $gilroy-semibold;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0px;
    color: $silver;
    text-transform: uppercase;
}

.basket__mobile-header-temp {
    min-width: 46px;
}

.dish-details__mobile-header-back {
    background: transparent;
    border: none;
    padding: 0;
    width: 50%;
    min-height: 36px;
    border: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    left: 0;
    padding-left: 15px;
    svg {
        width: 12px;
        height: 11px;
    }
}

.dish-details-container {
    background: var(--color__coconut);
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 3000;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 452px;
    box-sizing: border-box;
    height: 100%;
}

.dish-details-scroll {
    margin-bottom: -64px; // height .dish-details__content--price
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

.dish-details__card-container {
    background: var(--color__onion);
    padding: 14px 12px 0;
    // z-index: 2;
}

.dish-details__dish-card {
    position: relative;
    border-radius: 6px;
    background: var(--color__coconut);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 12px 8px rgba(1, 1, 1, 0.04);
    width: 100%;
    z-index: 2;

    &:hover {
        .dish-details__image {
            transform: scale(1.03);
        }
    }

    &::before {
        content: '';
        position: absolute;
        left: 8%;
        bottom: 7px;
        width: 84%;
        height: 84%;
        box-shadow: 0 2px 31px 0 rgba(207, 207, 207, 0.5);
    }
}

.dish-details__image--container {
    overflow: hidden;
    background: var(--color__coconut);
    position: relative;
    // TODO: в корзине должны быть скруглены углы
    // border-top-left-radius: 6px;
    // border-top-right-radius: 6px;
}

.dish-details__image {
    width: 100%;
    height: 176px;
    transition: transform 600ms linear;
    transform: scale(1);
}

.dish-details__wrap {
    position: relative;
    background: var(--color__coconut);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.dish-details__content {
    & + & {
        border-top: 1px solid $wild-sand;
    }
}

.dish-details__content--title {
    padding: 13px 15px 14px 12px;
}

.dish-details__content--price {
    padding: 16px 13px 19px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dish-details__title-text,
.dish-details__caption-text {
    font-family: $gilroy-semibold;
    font-size: 11px;
    line-height: 1;
    letter-spacing: 0;
    color: var(--color__cuttlefish);
}

.dish-details__basket-info-text {
    font-family: $gilroy-medium;
    font-size: 9px;
    letter-spacing: -0.2px;
    color: $scorpion;
    line-height: 1;
    text-transform: uppercase;
}

.dish-details__basket-portions {
    display: flex;
    line-height: 1;
    align-items: baseline;
}

.dish-details__basket-portions-number,
.dish-details__basket-portions-price {
    font-family: $gilroy-medium;
    font-size: 13px;
    letter-spacing: -0.2px;
    color: var(--color__cuttlefish);

    .num {
        font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
    }
}

.dish-details__basket-portions-text {
    font-family: $gilroy-bold;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.2px;
    color: $mine-shaft;
}

.dish-details__basket-portions-x {
    font-family: $gilroy-medium;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.2px;
    color: $silver-chalice;
}

.dish-details__total {
    font-family: $gilroy-medium;
    font-size: 17px;
    letter-spacing: -0.2px;
    color: var(--color__cuttlefish);
    position: relative;
    top: -1px;

    .num {
        font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
    }
}

.dish-details__info-container {
    @include all-props((
        margin-bottom: (
            0: 90px,
            1024: 90px,
        )
    ));
    // position: relative;
    top: -59px; // height .dish-details__content--price
    padding-top: 84px;
    // height: 100%; // сумма высоты внутренних блоков + браузерная панель
    // z-index: -1; // for animation

    background: var(--color__coconut);
    // box-shadow:
    //     0 1px 9px 8px rgba(1, 1, 1, 0.03),
    //     0 2px 5px 0 rgba(223, 223, 223, 0.5);

    @include desktop-props((
        box-shadow: none,
    ));
}

.dish-details__cooktime-portion {
    display: flex;
    padding: 0 11px 0 12px;
    border-top: solid 1px $wild-sand;
}

.dish-details__cooktime {
    padding: 20px 0px 18px 13px;
    border-right: solid 1px $wild-sand;
}
.dish-details__portion {
    padding: 21px 0px 18px 12px;
    border-left: solid 1px transparent;
}

.dish-details__cooktime,
.dish-details__portion {
    width: 50%;
    // display: flex;
    box-sizing: border-box;
}

.dish-details__text--container {
    display: flex;
    align-items: center;
}

.dish-details__cooktime-icon {
    margin-right: 7px;
    width: 23px;
    height: 23px;
    svg {
        width: 23px;
        height: 23px;
    }
}

.dish-details__portion-icon {
    margin-right: 9px;
    width: 26px;
    height: 22px;
    svg {
        width: 26px;
        height: 22px;
    }
}

.dish-details__cooktime-text,
.dish-details__portion-text {
    font-family: $gilroy-medium;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: -0.1px;
    color: var(--color__cuttlefish);
}

.dish-details__cooktime-value-container,
.dish-details__portion-value-container {
    display: flex;
    align-items: flex-end;
    margin-top: 6px;
}
.dish-details__cooktime-value-container {
    margin-left: 3px;
}
.dish-details__portion-value-container {
    margin-left: 4px;
}

.dish-details__cooktime-value,
.dish-details__portion-value {
    font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
    font-size: 17px;
    letter-spacing: -0.1px;
    color: var(--color__cuttlefish);
    line-height: 1;
}

.dish-details__cooktime-unit,
.dish-details__portion-unit {
    font-family: $gilroy-bold;
    font-size: 9px;
    letter-spacing: 0.1px;
    color: var(--color__cuttlefish);
    line-height: 1;
    margin-bottom: 2px;
}

.dish-details__cooking {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    background: var(--color__onion);
    border: solid 1px $wild-sand;
    padding: 9px 10px 8px 10px;
    margin: 0 11px 0 12px;
}

.dish-details__cooking-icon {
    margin-right: 7px;
    width: 16px;
    height: 16px;
    svg {
        width: 16px;
        height: 16px;
    }
}

.dish-details__cooking-text {
    font-family: $gilroy-semibold;
    font-size: 11px;
    color: var(--color__cuttlefish);
    letter-spacing: -0.2px;
}

.dish-details__composition {
    padding: 20px 14px 26px 14px;
    margin: 0 11px 0 12px;
}

.dish-details__composition-title {
    font-family: $gilroy-semibold;
    font-size: 12px;
    color: $mine-shaft;
    letter-spacing: -0.3px;
    margin-bottom: 5px;

    a {
        margin-top: 10px;
        font: inherit;
        color: inherit;
        text-decoration: underline;
    }
}

.dish-details__composition-text {
    font-family: $gilroy;
    font-size: 10px;
    line-height: 12px;
    color: $gray;

    width: 270px;
    &:first-letter {
        text-transform: capitalize;
    }
}

.dish-details__nutrition {
    padding: 25px 26px 0 25px;
    border-top: solid 1px $wild-sand;
}

.dish-details__nutrition-title {
    font-family: $gilroy-semibold;
    font-size: 12px;
    color: $mine-shaft;
    letter-spacing: -0.1px;
    margin-bottom: 3px;
}

.dish-details__nutrition-item {
    display: flex;
}

.dish-details__nutrition-item-title {
    font-family: $gilroy;
    font-size: 12px;
    line-height: 19px;
    color: var(--color__cuttlefish);
}

.dish-details__nutrition-value-container {
    display: flex;
    align-items: flex-end;
    position: relative;
    top: -2px;
}

.dish-details__nutrition-item-value {
    font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
    font-size: 12px;
    color: var(--color__cuttlefish);
}

.dish-details__nutrition-item-unit {
    font-family: $gilroy-semibold;
    font-size: 11px;
    color: var(--color__cuttlefish);
}

.dish-details__nutrition-item-space {
    margin: 0 15px 0 5px;
    width: 100%;
    height: 13px;
    background-image: linear-gradient(to right, $silver, 40%, rgba(255, 255, 255, 0) 20%);
    background-position: bottom;
    background-size: 3px 1px;
    background-repeat: repeat-x;
}

.dish-details__info-block.exited,
.dish-details__info-block.entering {
    opacity: 0;
    transform: translate3d(0, 0, 0);
}

.dish-details__info-block.entered {
    animation: fadeInUp 600ms;
}

@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
}

@media (min-width: 360px) {
    .basket__mobile-header {
        min-height: 45px;
    }
    .basket__mobile-header-back {
        height: 46px;
        min-height: 46px;
    }
    .dish-details__mobile-header-back {
        min-height: 46px;
        svg {
            width: 14px;
            height: 13px;
        }
    }
    .dish-details__image {
        height: 277px;
    }
    .dish-details__content--title {
        padding: 15px 15px 18px 18px;
    }
    .dish-details__content--price {
        padding: 17px 14px 20px 19px;
    }
    .dish-details__title-text,
    .dish-details__caption-text {
        font-family: $gilroy-semibold;
        font-size: 14px;
        line-height: 1;
        letter-spacing: -0.2px;
    }
    .dish-details__title-text {
        color: var(--color__cuttlefish);
    }
    .dish-details__caption-text {
        color: $mine-shaft;
    }
    .dish-details__basket-info-text {
        font-size: 10px;
    }
    .dish-details__basket-portions-number,
    .dish-details__basket-portions-price {
        font-size: 14px;
    }
    .dish-details__basket-portions-text {
        font-size: 12px;
    }
    .dish-details__basket-portions-x {
        font-size: 12px;
    }
    .dish-details__total {
        font-size: 21px;
    }
    .dish-details__info-container {
        top: -64px; // height .dish-details__content--price
        padding-top: 86px;
    }
    .dish-details__cooktime {
        padding: 21px 0px 22px 16px;
    }
    .dish-details__portion {
        padding: 21px 16px 22px 19px;
    }
    .dish-details__cooktime-icon {
        margin-right: 5px;
        width: 27px;
        height: 27px;
        svg {
            width: 27px;
            height: 27px;
        }
    }
    .dish-details__portion-icon {
        margin-right: 7px;
        width: 30px;
        height: 25px;
        svg {
            width: 30px;
            height: 25px;
        }
    }
    .dish-details__cooktime-text,
    .dish-details__portion-text {
        font-size: 12px;
        line-height: 12px;
    }
    .dish-details__cooktime-value,
    .dish-details__portion-value {
        font-size: 21px;
    }
    .dish-details__cooktime-value-container,
    .dish-details__portion-value-container {
        margin-left: 0px;
    }
    .dish-details__cooktime-value-container {
        margin-top: 5px;
    }
    .dish-details__portion-value-container {
        margin-top: 6px;
    }
    .dish-details__cooking {
        padding: 9px 15px;
    }
    .dish-details__cooking-icon {
        width: 18px;
        height: 18px;
        svg {
            width: 18px;
            height: 18px;
        }
    }
    .dish-details__cooking-text {
        font-size: 12px;
    }
    .dish-details__composition {
        padding: 20px 17px 24px 17px;
    }
    .dish-details__composition-title {
        font-size: 13px;
        margin-bottom: 2px;
    }
    .dish-details__composition-text {
        font-size: 12px;
        line-height: 14px;
        width: 315px;
    }
    .dish-details__nutrition {
        padding: 23px 28px 0 29px;
    }
    .dish-details__nutrition-title {
        font-size: 13px;
        margin-bottom: 1px;
    }
    .dish-details__nutrition-item-title {
        font-size: 13px;
        line-height: 19px;
    }
    .dish-details__nutrition-value-container {
        margin-bottom: 1px;
    }
    .dish-details__nutrition-item-value {
        font-size: 13px;
    }
    .dish-details__nutrition-item-unit {
        font-size: 12px;
    }
    .dish-details__nutrition-item-space {
        margin: 0 15px 0 12px;
        height: 14px;
    }
}
