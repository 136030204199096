@import '~styles/variables/index';
@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";


.basket__dish-item {
    @include mobile-props((
        display: flex,
        position: relative,

        margin-bottom: (
            0:   16px,
            375: 24px,
        ),
        height: (
            0:   72px,
            375: 80px,
            414: 84px,
        ),
    ));

    &.is-last-item {
        @include mobile-props((
            margin-bottom: (
                0:   20px,
                375: 24px,
            ),
        ));
    }

    // exiting & exited - style of dish item deleting animation
    &.exiting,
    &.exited {
        @include mobile-props((
            margin-bottom: 0px,
            height: 0px,
            opacity: 0,
            transform: scale(0.9),
        ));
    }
    &.exiting {
        @include mobile-props((
            transition: (
                transform 600ms,
                opacity 450ms 150ms,
                height 700ms 600ms,
                margin-bottom 700ms 600ms,
            ),
            transition-timing-function: cubic-bezier(0.25 0.1 0.25 1),
        ));
    }
}

.basket__dish-item__image--container {
    @include mobile-props((
        object-fit: cover,
        overflow: hidden,
        display: flex,
        flex: none,

        margin-right: (
            0:   16px,
            375: 18px,
            414: 20px,
        ),
        height: 100%,
        width: (
            0:   96px,
            375: 108px,
            414: 114px,
        ),
        min-width: (
            0:   96px,
            375: 108px,
            414: 114px,
        ),
        border-radius: (
            0: var(--sizeBorderRadiusXS),
            375: var(--sizeBorderRadiusM),
        ),

        -webkit-mask-image: -webkit-radial-gradient(white, black), // Safari https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
    ));

    &:hover {
        .basket__dish-item__image {
            @include mobile-props((
                transform: scale(1.05),
            ));
        }
    }
}

.basket__dish-item__image {
    transition: all 300ms ease;
}

.basket__dish-item__info--container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    @media (min-width: 414px) {
        justify-content: flex-start;
    }
}

.basket__dish-item__row {
    @include mobile-props((
        display: flex,
        width: 100%,
    ));

    &.first {
        @include mobile-props((
            justify-content: space-between,
            align-items: flex-start,
            margin-bottom: 0,
        ));
    }
    &.second {
        @include mobile-props((
            align-items: baseline,
            max-height: (
                0:   17px,
                360: 20px,
                414: 21px,
            ),
        ));
    }
}

.basket__dish-item__title-container {
    width: 100%;
    text-align: left;
}

.basket__dish-item__multiplier {
    @include mobile-props((
        font-family: $n-haas-tx-std,
        font-weight: 500,
        font-size: (
            0: 13px,
            375: 14px,
            414: 15px,
        ),
        letter-spacing: -0.34px,
        color: var(--color__cuttlefish),
        padding-left: 1px,
        margin-top: (
            0: 2px,
            375: 2px,
            414: 0px,
        ),
    ));
}

.basket__dish-cooking-time {
    @include mobile-props((
        font-family: $gilroy,
        font-weight: 700,
        color: var(--color__cuttlefish),

        font-size: (
            0: 16px,
            375: 17px,
            414: 18px,
        ),

        letter-spacing: (
            0: -0.4px,
            375: -0.4px,
            414: -0.44px,
        )
    ));

    .num {
        @include mobile-props((
            font-family: $n-haas-tx-std,
            font-weight: 700,
            color: var(--color__cuttlefish),

            font-size: (
                0: 14px,
                375: 15px,
                414: 16px,
            ),

            letter-spacing: (
                0: -0.3px,
                375: -0.3px,
                414: -0.34px,
            )
        ));
    }

    .num-spacing {
        @include mobile-props((
            font-family: $n-haas-tx-std,
            font-weight: 700,
            color: var(--color__cuttlefish),

            font-size: (
                0: 16px,
                375: 16px,
                414: 16px,
            ),

            letter-spacing: (
                0: -1.24px,
                375: -1.24px,
                414: -1.24px,
            )
        ));
    }
}
