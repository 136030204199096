@import "~global/styles/cool-media-queries/index.global.scss";
@import '~styles/variables/index';


.bonusesPopupRoot {
    @include desktop-props((
        display: none,
    ));

    font-family: Gilroy;
    color: #7483D2;
    position: relative;
    z-index: 1;
    height: 100%;

    overflow-y: scroll;
    @include mobile-props((
        -webkit-overflow-scrolling: touch,
    ));
    &::-webkit-scrollbar { // chrome, safari, opera
        @include mobile-props((
            display: none,
        ));
    }
    &::-webkit-scrollbar-thumb {
        display: none;
    }
}

.bonusesPopupWrapper {
    position: absolute;
    background-color: #D3D8F1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include all-props((
        box-sizing: border-box,
        padding: (
            0: 16px,
            375: 20px,
        ),
        border-radius: (
            0: 16px,
            375: 30px,
        ),
        width: (
            0: 288px,
            375: 335px,
        ),
    ));
}


.bonusesCloseBtnWrapper {
    @include all-props((
        width: (
            0: 22px,
            375: 28px,
        ),
        height: (
            0: 22px,
            375: 28px,
        ),
        position: absolute,
        right: (
            0: 16px,
            375: 20px,
        ),
        top: (
            0: 13px,
            375: 20px,
        ),

    ));
}
.bonusesCloseBtn {
    @include all-props((
        height: inherit,
        width: inherit,
        -webkit-user-select: none, /* Safari */
        -ms-user-select: none, /* IE 10 and IE 11 */
        user-select: none, /* Standard syntax */
    ));
}

.bonusesLogoAria {
    @include all-props((
        height: (
            0: 168px,
            375: 196px,
        ),
        display: flex,
        justify-content: center,
        align-items: center,
    ));

}

.bonusesLogoWrapper {
    @include mobile-props((
        width: (
            0: 108px,
            375: 126px,
        ),
        transform: (
            0: translate(0, 5px),
        ),
    ));
}
.bonusesLogo {
    @include all-props((
        display: block,
        width: inherit,
    ))
}

.bonusesInfo {
    @include all-props((
        margin-bottom: (
            0: 16px,
            375: 20px,
        ),
    ));
}

.bonusesInfo {
    @include all-props((
        padding: (
            0: 16px,
            375: 20px,
        ),
        border-radius: (
            0: 16px,
            375: 16px,
        ),
        background-color: #fff,
    ))
}

.bonusesInfoTitle {
    @include all-props((
        color: #7483D2,
        font-size: 22px,
        font-weight: 800,
        font-style: normal,
        line-height: 23px,
        letter-spacing: -0.6px,
        margin-bottom: (
            0: 8px,
        ),
    ))
}

.bonusesInfoDescList {
    @include all-props((
        padding: (
            0: 0px 0px 0px 11px,
            375: 0px 0px 0px 13px,
        ),
        margin: 0px,

        // margin-left: (
        //     0: 14px,
        //     375: 17px,
        // ),
    ));

}

.bonusesInfoDescList li {
    @include all-props((
        list-style-type: none,
        font-family: $gilroy,
        font-size: (
            0: 16px,
            375: 17px,
        ),
        font-weight: 500,
        font-style: normal,
        line-height: (
            0: 17px,
            375: 19.5px,
        ),
        letter-spacing: -0.381px,
        margin-bottom: (
            0: 3px,
            375: 4px,
        ),
        position: relative,
    ));

    &:nth-last-child(-n + 1) {
        margin-bottom: 0px;
    };

    &::before {
        content: '•';
        position: absolute;
        @include all-props((
            font-size: (
                0: 21px,
            ),
            padding-right: 5px,
            transform: (
                0: translate(-12px, 1px),
                375: translate(-14px, 0px),
            ),
            display: inline-block,
        ));
    }
}

.bonusesApplyButton {
    display: block;
    text-align: center;
    @include all-props((
        padding: (
            0: 13px 10px,
            375: 15px 10px,
        ),
        border-radius: (
            0: 11px,
            375: 12px,
        ),
        background-color: #fff,
    ));
}

.bonusesApplyButton button {
    @include all-props((
        font-family: $gilroy,
        font-size: (
            0: 17px,
            375: 18px,
        ),
        font-weight: 700,
        font-style: normal,
        line-height: 22px,
        color: #4F4F4F,
    ))
}
