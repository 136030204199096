@import '~styles/variables/index';

.romanticDetailsRoot {
    @include mobile-props((
        padding: (
            0: 0 16px,
            375: 0 20px,
        ),
        color: var(--color__pink),
    ))
}

.romanticDishTitle {
    @include mobile-props((
        font-size: (
            0: 17px,
            375: 18px,
        ),
        font-weight: 500px,
        font-family: $gilroy,
        color: var(--color__cuttlefish),
        margin-bottom: (
            0: 14px,
            375: 18px,
        )
    ))
}

.romanticSetList {
    font-style: normal;
    font-weight: 400;
    color: var(--color__pink);

    @include all-props((
        font-family: $gilroy,
        height: auto,
    ));
    @include mobile-props((
        margin-bottom: (
            0: 50px,
            375: 60px,
        ),
    ));
}

.mainCard {
    @include mobile-props((
        margin-bottom: (
            0: 31px,
            375: 40px,
        )
    ));
}

.mainCardTitle {
    @include mobile-props((
        font-size: (
            0: 30px,
            375: 36px,
        ),
        line-height: (
            0: 26.55px,
            375: 31.86px,
        ),
        font-weight: 600,
        margin-bottom: (
            0: 8px,
            375: 10px,
        ),
        letter-spacing: -1px,
    ));
}

.descriptionCard {
    overflow: hidden;
    background-color: var(--color__light_pink);
    @include mobile-props((
        display: (
            0: none,
        ),
    ));
}

.descriptionCardImage {
    width: 100%;
    @include mobile-props((
        display: block,
        aspect-ratio: 1,
        border-radius: (
            0: 20px,
            375: 30px,
        ),
    ));
    @include all-props((
        object-fit: cover,
        object-position: center,
    ));
}

.cardContent {
    list-style-type: disc !important;
    width: 50%;
}

.cardContent li:before {
    background-color: var(--color__pink);
}

.cardsList {
    @include all-props((
        display: flex,
        flex-direction: column,
    ));
    @include mobile-props((
        gap: (
            0: 30px,
            375: 40px,
        )
    ));
}

.secondaryCard {
    @include all-props((
        width: 100%,
    ));
}

.cardTitle {
    @include mobile-props((
        font-size: (
            0: 19px,
            375: 22px,
        ),
        line-height: (
            0: 19.76px,
            375: 22.88px,
        ),
        margin-bottom: (
            0: 8px,
            375: 10px,
        ),
        letter-spacing: (
            0: -0.5px,
            375: -0.5px,
        ),
        font-style: normal,
        font-weight: 700,
    ));
}

.secondaryCardImage {
    width: 100%;
    aspect-ratio: 2/1;
    object-fit: cover;
    object-position: center;
    @include mobile-props((
        margin-bottom: 10px,
        border-radius: (
            0: 20px,
            375: 30px,
        )
    ))
}

.description, .descriptionList li {
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.5px;
    @include mobile-props((
        font-size: (
            0: 16px,
            375: 19px,
        ),
        line-height: (
            0: 16.64px,
            375: 19.76px,
        ),
        letter-spacing: -0.5px
    ));
}

.descriptionList {
    list-style-type: disc !important;
    @include mobile-props((
        display: flex,
        flex-direction: column,
        gap: 3px,
        padding-left: (
            0: 11px,
            375: 12px,
        ),
    ));
}

.descriptionList li:before {
    background-color: var(--color__pink);
}

.romanticMenuList {
    font-style: normal;
    font-weight: 400;
    color: var(--color__pink);

    @include all-props((
        font-family: $gilroy,
        height: auto,
    ));
    @include mobile-props((
        margin-bottom: (
            0: 60px,
            375: 60px,
        ),
    ));
}

.mainCartTitle {
    @include all-props((
        letter-spacing: -1px,
        color: var(--color__pink),
    ));
    @include mobile-props((
        padding-left: (
            0: 10px,
            375: 12px,
        ),
        font-size: (
            0: 30px,
            375: 36px,
        ),
        line-height: (
            0: 28.5px,
            375: 34.2px,
        ),
        font-weight: 600,
        margin-bottom: 10px,
    ));
}

.cardsList {
    @include all-props((
        display: grid,
        grid-template-columns: 1fr,
    ));
    @include mobile-props((
        gap: (
            0: 50px,
            375: 60px,
        )
    ));
}

.menuCard {
    @include all-props((
        width: 100%,
        overflow: hidden,
        border: 1px solid #FCEBEF,
    ));
    @include mobile-props((
        border-radius: (
            0: 8px,
            375: 14px,
        )
    ));
}

.menuCardImage {
    width: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
    @include mobile-props((
        aspect-ratio: (
            0: calc(296/213),
            375: calc(369/263)
        ),
    ));
}

.dishInfo {
    @include mobile-props((
        padding: (
            0: 19px,
            375: 14px 19px 19px,
        ),
    ));
}

.dishTitle {
    font-style: normal;
    font-weight: 800;

    @include mobile-props((
        font-size: (
            0: 17px,
            375: 21px,
        ),
        line-height: (
            0: 17px,
            375: 21px,
        ),
        letter-spacing: -0.5px,
        margin-bottom: (
            0: 8px,
            375: 10px,
        )
    ));
}

.dishDesc {
    @include mobile-props((
        font-size: (
            320: 14px,
            375: 17px,
        ),
        line-height: (
            320: 13.7px,
            375: 16.6px,
        ),
        letter-spacing: 0.44px
    ));
}

.menuWrapper {
    @include mobile-props((
        max-width: 378px,
        margin: 0 auto,
    ));
}
