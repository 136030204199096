@import '../fonts/index';

/* Conventions

    Desktop
    maket font -> use font

    ExtraBold -> Bold
    Bold -> Semibold
    Semibold -> Medium
    Medium -> Regular
    Regular -> Light

*/

$gilroy: 'Gilroy';
$geometria: 'Geometria';
$proxima-nova: 'ProximaNova';
$haas-dsstd: 'NHaasGroteskDSStd';
$haas-dspro: 'HaasGrotDisp'; // HaasGrotDisp and HaasGrotDispPro
$haas-txstd: 'NHaasGroteskTXStd';
$haas-txpro: 'NHaasGroteskTXPro';
$inter: 'Inter';


// DEPRECATED:
$gilroy-ultralight: 'Gilroy-UltraLight'; // 200
$gilroy-light: 'Gilroy-Light'; // 300
$gilroy-light-italic: 'Gilroy-LightItalic';
$gilroy-ultra-light-italic: 'Gilroy-UltraLightItalic';
// $gilroy: 'Gilroy'; // 400
$gilroy-italic: 'Gilroy-Italic';
$gilroy-medium: 'Gilroy-Medium'; // 500
$gilroy-semibold: 'Gilroy-SemiBold'; // 600
$gilroy-bold: 'Gilroy-Bold'; // 700
$gilroy-extrabold: 'Gilroy-ExtraBold'; // 800
$gilroy-black: 'Gilroy-Black'; // 900

$gilroy-200: $gilroy-ultralight;
$gilroy-300: $gilroy-light;
$gilroy-400: $gilroy;
$gilroy-500: $gilroy-medium;
$gilroy-600: $gilroy-semibold;
$gilroy-700: $gilroy-bold;
$gilroy-800: $gilroy-extrabold;
$gilroy-900: $gilroy-black;

$Neue-Haas-Grotesk-Display-Pro-65-Medium: 'Neue-Haas-Grotesk-Display-Pro-65-Medium';

$NHaasGroteskTXStd-55Rg: 'NHaasGroteskTXStd-55Rg';
$NHaasGroteskTXStd-65Md: 'NHaasGroteskTXStd-65Md';
$NHaasGroteskTXStd-75Bd: 'NHaasGroteskTXStd-75Bd';
$NHaasGroteskDSStd-55Rg: 'NHaasGroteskDSStd-55Rg';
$NHaasGroteskDSStd-45Lt: 'NHaasGroteskDSStd-45Lt';
$NHaasGroteskDSStd-65Md: 'NHaasGroteskDSStd-65Md';
$NHaasGroteskTXPro-75Bd: 'NHaasGroteskTXPro-75Bd';
$NHaasGroteskTXPro-55Rg: 'NHaasGroteskTXPro-55Rg';
$NHaasGroteskDisp-45Light: 'NeueHaasGrotDisp-45Light';
$NHaasGroteskDisp-55Roman: 'NeueHaasGrotDisp-55Roman';
$NHaasGroteskDisp-75Bold: 'NeueHaasGrotDisp-75Bold';

$geometria-extrabold: 'Geometria-ExtraBold';

$rouble: 'rouble';
$ptsans-ruble: 'PTSans-Ruble';

