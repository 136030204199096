@import '~styles/variables/index';

.selectCityRoot {
    @include mobile-props((
        text-align: start,
    ));
}

.selectCityTitle {
    @include all-props((
        padding: (
            0: 20px 16px 10px 16px,
            375: 20px 20px 6px 20px,
        ),

        color: #424242,
        font-family: $gilroy,
        font-size: (
            0: 21px,
            375: 24px,
        ),
        font-style: normal,
        font-weight: 800,
        line-height: (
            0: 22px,
            375: 25px,
        ),
        letter-spacing: -0.6px,
    ));
}

.selectCityItems {
    @include all-props((
        padding: (
            0: 0px 16px,
            375: 0px 20px,
        ),
    ));
}

.selectCityItemButton {
    @include mobile-props((
        display: flex,
        justify-content: space-between,
        align-items: center,
        width: 100%,
        padding: (
            0: 12px 0px,
        ),
        border-bottom: 1px solid #F1F1F1,
    ));


    &:nth-last-child(-n + 1) {
        border: none;
    }
}

.selectCityItemText {
    @include mobile-props((
        color: var(--color__cuttlefish),
        font-family: $gilroy,
        font-size: (
            0: 17px,
            375: 18px,
        ),
        font-style: normal,
        font-weight: 500,
        line-height: 18px,
        letter-spacing: -0.2px,
        text-align: start,

    ));

    &:global(.selected) {
        font-weight: 700;
    }
}

.ingredientType {
    color: var(--color__hering);
    padding-right: 6px;
}

.selectCitySubmit {
    @include all-props((
        padding: (
            0: 12px 16px 0 16px,
            375: 12px 20px 0 20px,
        ),
    ));
}
