@import '~styles/variables/index';


.overlay__root {
    @include all-props((
        position: fixed,
        z-index: 2000,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,

        height: 100%,
        width: 100%,
    ));

    &.exited {
        @include all-props((
            pointer-events: none,
        ));
    }
}

.overlay__close-layer {
    // reset default button style
    @include all-props((
        border: none,
        outline: none,
        margin: 0,
        padding: 0,
        width: auto,
        overflow: visible,
        background: transparent,
        text-align: unset,
    ));

    @include all-props((
        position: absolute,
        z-index: 1,
        top: 0,

        width: (
            0: 100%,
            444: 0,
            1024: 100%
        ),
        height: (
            0: 100%,
            444: 0,
            1024: 100%
        ),
    ));
}

// взято из бывшего dialog.context
.overlay__blur-layer {
    $initialWhite: rgba(255, 255, 255, 0);
    $blurredWhite: rgba(255, 255, 255, 0.3);

    @include all-props((
        position: absolute,
        z-index: 1,
        top: 0,

        width: 100%,
        height: 100%,

        background-color: var(--color__coconut),
        backdrop-filter: blur(4px),
    ));

    &.exited {
        @include all-props((
            visibility: hidden,
            backdrop-filter: blur(0px),
            background-color: $initialWhite,
        ));
    }
    &.entering {
        @include all-props((
            transition: (
                backdrop-filter 500ms,
                background-color 500ms
            ),
            backdrop-filter: blur(4px),
            background-color: $blurredWhite,
        ));
    }
    &.entered {
        @include all-props((
            backdrop-filter: blur(4px),
            background-color: $blurredWhite,
        ));
    }
    &.exiting {
        @include all-props((
            transition: (
                backdrop-filter 500ms,
                background-color 500ms
            ),
            backdrop-filter: blur(0px),
            background-color: $initialWhite,
        ));
    }
}

.overlay__white-layer {
    @include all-props((
        position: fixed,
        top: 0,
        left: 0,
        width: 150vw,
        height: 150vh,
        background-color: (
            0: var(--color__coconut),
            444: var(--color__onion),
            1024: var(--color__coconut),
        ),
    ));
}

.overlay__animation-layer {
    @include all-props((
        position: absolute,
        top: 0,

        width: 100%,
        height: 100%,
    ));

    &.exited {
        @include all-props((
            pointer-events: none,
        ));
    }
}

// перенесено из бывшего dialog.context
.animation-dialog-bottom {
    &.exited > div {
        @include all-props((
            transform: (
                0:    translateY(100%),
                1024:  translateY(100vh),
            ),
        ));
    }
    &.entering > div {
        @include all-props((
            transition: transform 500ms,
            transform: translateY(0%),
        ));
    }
    &.entered > div {
        @include all-props((
            transform: translateY(0%),
        ));
    }
    &.exiting > div {
        @include all-props((
            transition: transform 500ms,
            transform: (
                0:    translateY(100%),
                1024:  translateY(100vh),
            ),
        ));
    }
}

.animation-top {
    &.exited > div,
    &.exiting > div,
    &.entering > div {
        @include all-props((
            transform: translateY(-100%),
            transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1),
        ));
    }
    &.entered > div {
        @include all-props((
            transform: translateY(0%),
            transition: all 500ms cubic-bezier(.12,.87,.04,1.04),
        ));
    }
}

$animation-bottom-time: 400ms;

.animation-bottom {
    &.exited > div {
        @include all-props((
            transform: translateY(100%),
            transition: transform $animation-bottom-time cubic-bezier(0.25, 0.1, 0.25, 1),
        ));
    }
    &.entering > div {
        @include all-props((
            transform: translateY(0),
            transition: transform $animation-bottom-time ease-in-out,
        ));
    }
    &.entered > div {
        @include all-props((
            transform: translateY(0%),
            transition: transform $animation-bottom-time cubic-bezier(0.25, 0.1, 0.25, 1),
        ));
    }
    &.exiting > div {
        @include all-props((
            transform: translateY(100%),
            transition: transform $animation-bottom-time ease-in-out,
        ));
    }

    // костыль для кнопки закрытия DishDetailsWithCustom
    &.exited > button {
        @include all-props((
            opacity: 0,
            transition: opacity $animation-bottom-time cubic-bezier(0.25, 0.1, 0.25, 1),
        ));
    }
    &.entering > button {
        @include all-props((
            opacity: 0,
            transition: opacity $animation-bottom-time ease-in-out,
        ));
    }
    &.entered > button {
        @include all-props((
            opacity: 1,
            transition: opacity $animation-bottom-time cubic-bezier(0.25, 0.1, 0.25, 1),
        ));
    }
    &.exiting > button {
        @include all-props((
            opacity: 0,
            transition: opacity 0 ease-in-out,
        ));
    }
}

.animation-right {
    &.exited > div {
        @include all-props((
            transform: translateX(100%),
            // transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1),
        ));
    }
    &.entering > div {
        @include all-props((
            transform: translateX(100%), // TODO: связано с багом в наших оверлеях
            transition: transform 600ms ease-out,
        ));
    }
    &.entered > div {
        @include all-props((
            transform: notranslateX(0%),
            transition: transform 600ms ease-out,
        ));
    }
    &.exiting > div {
        @include all-props((
            transform: translateX(100%),
            transition: transform 600ms ease-out,
        ));
    }
}
