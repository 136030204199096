@function resize_to_vw($media, $value) {
    $full_width: 100vw;
    $result: calc(calc($full_width * $value) / $media);
    @return $result;
}


@function resize_to_px($source_media, $dest_media, $source_value) {
    $result: calc(calc($source_value * $dest_media) / $source_media);
    @return $result;
}


// TODO: test it!
@mixin responsive-mobile-props($layout-width, $args-map) {
    $min-width: 0px;
    $max-width: 1024px;
    // $static-max-width: 424px;
    $static-max-width: 564px;

    @each $prop, $value in $args-map {
        @media (min-width: $min-width) and (max-width: calc($max-width - 1px)) {
            #{$prop}: resize_to_vw($layout-width, $value);
        }

        @media (min-width: $static-max-width) and (max-width: calc($max-width - 1px)) {
            #{$prop}: resize_to_px($layout-width, $static-max-width, $value);
        }
    }

    @media (min-width: $min-width) and (max-width: calc($max-width - 1px)) {
        @content;
    }
}



@mixin responsive-desktop-props($layout-width, $args-map) {
    $min-width: 1024px;
    $max-width: 1920px;

    @each $prop, $value in $args-map {
        @media (min-width: $min-width) and (max-width: calc($max-width - 1px)) {
            #{$prop}: resize_to_vw($layout-width, $value);
        }

        @media (min-width: $max-width) {
            #{$prop}: resize_to_px($layout-width, $max-width, $value);
        }
    }

    @media (min-width: $min-width) {
        @content;
    }
}

// SAME RESPONSIVE MIXINS BUT WITH MIN MAX ARGUMENTS
@mixin responsive-mobile-props-limited($layout-width, $args-map, $static-max-width: 564px, $min-width: 0px, $max-width: 1024px) {
    @each $prop, $value in $args-map {
        @media (min-width: $min-width) and (max-width: calc($max-width - 1px)) {
            #{$prop}: resize_to_vw($layout-width, $value);
        }

        @media (min-width: $static-max-width) and (max-width: calc($max-width - 1px)) {
            #{$prop}: resize_to_px($layout-width, $static-max-width, $value);
        }
    }

    @media (min-width: $min-width) and (max-width: calc($max-width - 1px)) {
        @content;
    }
}
// Довольно костыльный миксин. Нужен, чтобы из 100vh вычислять responsive значение из-за сложности работы с calc() в миксинах. В дальнейшем 100vh можно заменить на аргумент
@mixin responsive-subtract-value($layout-width, $args-map) {
    $min-width: 1024px;
    $max-width: 1920px;

    @each $prop, $value in $args-map {
        @media (min-width: $min-width) and (max-width: calc($max-width - 1px)) {
            #{$prop}: calc(100vh - resize_to_vw($layout-width, $value));
        }

        @media (min-width: $max-width) {
            #{$prop}: calc(100vh - resize_to_px($layout-width, $max-width, $value));
        }
    }

    @media (min-width: $min-width) {
        @content;
    }
}
