@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

/* KitInputContainer -------------------------------------------------------- */
.uiKitInputContainer {
    position: relative;
    @include all-props((
        width: 100%,
        box-sizing: border-box,
        overflow: hidden,
        display: flex,
        justify-content: space-between,
        border: 1.5px solid var(--color__grey-salt),
        text-align: left,
        white-space: nowrap,
    ));
    @include mobile-props((
        border-radius: var(--sizeBorderRadiusXS),
    ));
    @include desktop-props((
        border-radius: 8px,
    ));

    @media (-webkit-max-device-pixel-ratio: 1.25) {
        border-width: 1px;
    }
}

/* ЦВЕТА */
.uiKitInputContainer {
    &:global(.default) {
        border-color: #EDEDED
    };

    &:global(.validation) {
        // border-color: #F1F1F1,
        border-color: #FFDB19;
    };

    &:global(.active) {
        border-color: #FFDB19;
    };

    &:global(.error) {
        border-color: #FF4C00;
    };

    &:global(.confirmed) {
        border-color: var(--color__grey-salt),
    };

    &:global(.disabled) {
        border-color: #EDEDED,
    };
}

/* ВЫСОТА */
.uiKitInputContainer {
    &:global(.large) {
        @include mobile-props((
            height: (
                0: 50px,
                375: 54px,
            ),
        ));
        // @include desktop-props((
        //     height: (
        //         1024: 54px,
        //         // 1366: 54px,
        //     ),
        // ));

        @include responsive-desktop-props(1536px, (
            height: 54px,
        ));
    };

    &:global(.medium) {
        @include mobile-props((
            height: 50px,
        ));
        @include desktop-props((
            height: 50px,
        ));
    }

    &:global(.small) {
        @include desktop-props((
            height: (
                1024: 44px,
                1366: 50px,
                1600: 54px,
            ),
        ));
    }
}

/* КОГДА ЧТО-ТО ВНУТРИ В ФОКУСЕ */
.uiKitInputContainer {
    &:focus-within {
        border-color: #FFDB19;

        & .uiKitInputLabelExternalModifier {
            color: var(--color__hering),
        }

        & :global(.submit) {
            background-color: #FFDB19;
            svg path {
                fill: #9A660A;
            }
        }
    }
}

/* KitInputTextArea  -------------------------------------------------------- */
.uiKitInputWrapper {
    @include all-props((
        position: relative,
        box-sizing: inherit,
        text-align: infinite,
        display: flex,
        flex-direction: column,
        justify-content: center,
        height: 100%,
        width: 100%,

        background-color: #FFFFFF,
        border-top-left-radius: inherit,
        border-bottom-left-radius: inherit,
        outline: none,
    ));

    @include mobile-props((
        padding: 6px 0px 3px 12px,
    ));

    // @include desktop-props((
    //     padding: (
    //         1024: 9px 0px 7px 14px,
    //     ),
    // ));

    @include responsive-desktop-props(1536px, (
        padding-top: 9px,
        padding-bottom: 9px,
        // padding-right: 0px,
        padding-left: 12px,
    ));
}

.uiKitInputItem {
    @include all-props((
        position: relative,
        z-index: 1,
        display: flex,
        align-items: center,
        justify-content: flex-start,
        padding: 0,
        width: 100%,
        background-color: transparent,
        border-color: transparent,
        font-family: $gilroy,
        text-align: infinite,
    ));
    @include mobile-props((
        font-weight: 500,
        line-height: 20px,
        letter-spacing: -0.2px,
    ));
    @include desktop-props((
        font-weight: 500,
        // font-size: (
        //     1024: 18px,
        //     1366: 17px,
        //     1600: 18px,
        // ),
        // line-height: (
        //     1024: 20px,
        //     1366: 18px,
        //     1600: 20px,
        // ),
        letter-spacing: -0.2px,
    ));

    @include responsive-desktop-props(1536px, (
        font-size: 18px,
        line-height: 20px,
    ));

    // NOTE: Удаляет нативные радиусы в iOS Safari, их было не видно, но они были
    // -webkit-appearance: none; находится в normalize.scss
    -webkit-border-radius: 0px;
    border-radius: 0px;
    &:disabled {
        // NOTE: iOS Safari недостаточно иметь просто prop color, нужно капитально уточнить
        -webkit-text-fill-color: #4F4F4F;
        color: #4F4F4F;
        opacity: 1;
    }


    /* ЦВЕТА */
    color: var(--color__pepper);
    -webkit-text-fill-color: var(--color__pepper);

    & &:global(.colors-light) {
        color: var(--color__hering);
    }

    &:global(.colors-medium) {
        color: var(--color__coal);
    }

    &:global(.disabled) {
        -webkit-text-fill-color: #DBDBDB;
        color: #DBDBDB;
        opacity: 1;
    }

    /* ВЫСОТА */
    &:global(.large) {
        @include mobile-props((
            font-size: (
                0: 17px,
                375: 18px,
            ),
        ));

        &.uiKitInputItemNumber {
            @include mobile-props((
                font-size: (
                    0: 16px,
                    375: 17px,
                ),
            ));

            @include responsive-desktop-props(1536px, (
                font-size: 17px,
                line-height: 20px,
            ));

            // NOTE: У
        }
    }
    &:global(.medium) {
        @include mobile-props((
            font-size: 18px,
        ));
    }

    /* АНИМАЦИЯ */
    transition: height 0.2s;
    &:focus {
        border: none;
        outline: none;
    }

    &:global(.trasition-default) {
        height: 1px;
    }

    &:global(.trasition-active) {
        height: auto;
    }
}

.uiKitInputShadow {
    @include all-props((
        position: absolute,
        z-index: 0,
        pointer-events: none,

        -webkit-text-fill-color: var(--color__hering),
        color: var(--color__hering),
        bottom: (
            0: 24%,
            1024: 17%,
        ),
    ));

    &:global(.large) {
        @include mobile-props((
            top: (
                0: 38%,
                // 375: 21px,
            ),
        ));
    }
}

.uiKitInputLabel {
    @include all-props((
        position: relative,
        z-index: 1,
        cursor: pointer,
        color: var(--color__hering),
        font-family: $gilroy,
        user-select: none,
        text-align: start,
        letter-spacing: -0.2px,
        font-weight: var(--font-weight__medium),
    ));
    @include mobile-props((
        line-height: 20px,
        height: 22px,
    ));

    @include responsive-desktop-props(1536px, (
        font-size: 18px,
        line-height: 16px,
        height: 14px,
    ));
}

.uiKitInputLabel {
    &:global(.error) {
        color: #FF4C00;
    }

    &:global(.disabled) {
        color: #DBDBDB
    }
}

.uiKitInputLabel {
    &:global(.large) {
        @include mobile-props((
            font-size: (
                0: 17px,
                375: 18px,
            ),
        ));

        @include responsive-desktop-props(1536px, (
            font-size: 17px,
            line-height: 18px,
        ));
    }
    &:global(.medium) {
        @include mobile-props((
            font-size: (
                0:   18px,
                375: 17px,
            ),
        ));

        @include responsive-desktop-props(1536px, (
            font-size: 17px,
            line-height: 18px,
        ));
    }
    &:global(.small) {
         @include desktop-props((
            font-size: (
                1024: 14px,
                1366: 16px,
                1600: 17px,
            ),
        ));
    }
}

.uiKitInputLabel {
    transform: unset;
    &:global(.animation-active) {
        transition: padding 0.2s, height 0.2s, font-size 0.2s, transition 0.2s;
    }
    &:global(.trasition-default) {
        @include mobile-props((
            padding-bottom: 4px,
            transform: (
                0:   translateY(-1px),
                375: translateY(-2px),
            ),
        ));
        @include desktop-props((
            padding-bottom: 16px,
        ));

    }

    &:global(.trasition-active) {
        @include all-props((
            cursor: default,
        ));
        @include mobile-props((
            padding-bottom: (
                0: 4px,
                375: 5px,
            ),
            height: 10px,

            line-height: 12px,
            font-size: 12px,
        ));
        @include desktop-props((
            padding-bottom: (
                1024: 8px,
            ),
            letter-spacing: -0.2px,
        ));

        @include responsive-desktop-props(1536px, (
            font-size: 12px,
            line-height: 12px,
        ));
    }

    &:global(.empty) {
        @include mobile-props((
            height: 1px,
            line-height: 1px,
            padding-bottom: 0px,
            margin-bottom: -5px,
        ));
        @include desktop-props((
            height: 1px,
            line-height: 1px,
            padding-bottom: 0px,
            margin-bottom: -2px,
        ));
    }
}

/* KitInputButton ----------------------------------------------------------- */

// Обертка в родителе для смены позиционирования
.uiKitInputButtonStateWrapper {
    height: 100%;
    width: auto;
}

.uiKitInputButtonWrapper {
    position: relative;
    z-index: 0;
    height: 100%;
    width: auto;
    background-color: #FFFFFF,
}

.uiKitInputButtonRoot {
    @include all-props((
        box-sizing: border-box,
        display: flex,
        justify-content: center,
        align-items: center,
        background-color: transparent,
        // background-color: red,
        outline: none,
        height: 100%,
        width: (
            0: 48px,
            1024: 48px,
        ),
    ));
}

/* АНИМАЦИЯ */
:global {
    @keyframes mymove {
        from {
            transform:rotate(0deg)
        }
        to {
            transform:rotate(360deg)
        }
    }
}

/* ЦВЕТА */
.uiKitInputButtonRoot {
    &:global(.submit) {
        background-color: var(--color__grey-salt);
        svg path{
            fill: #d1d1d1;
        }
        cursor: pointer;
    }

    &:global(.clear) {
        cursor: pointer;
    }

    &:global(.empty) {
        @include mobile-props((
            width: 14px,
        ));

        @include desktop-props((
            width: (
                1024: 14px,
            ),
        ));
    }
}

/* ВЫСОТА */
.uiKitInputButtonRoot {
    &:global(.large) {
        @include desktop-props((
            padding: (
                1024: 15px 16px,
                1366: 16px 17px,
                1600: 17px 18px,
            ),
        ));
    }
}

.uiKitInputButtonIconClasses {
    position: absolute;
    pointer-events: none;

    &:global(.large) {
        @include mobile-props((
            width: (
                0: 18px,
                375: 20px,
                1024: 20px,
            ),
            height: (
                0: 18px,
                375: 20px,
                1024: 20px,
            ),
        ));

        @include all-props((
            top: (
                0: calc(50% - 9px),
                375: calc(50% - 10px),
                1024: calc(50% - 9px),
                1366: calc(50% - 10px),
            ),
            right: (
                0: calc(50% - 10px),
                375: calc(50% - 11px),
                1024: calc(50% - 10px),
                1366: calc(50% - 11px),
            )
        ));

        @include responsive-desktop-props(1536px, (
            width: 20px,
            height: 20px,
        ));
    }

    &:global(.medium) {
        $icon-sizes: (
            1024: 14px,
            1366: 18px,
            1600: 20px,
        );
        @include mobile-props((
            width: 20px,
            height: 20px,
        ));
        @include desktop-props((
            width: $icon-sizes,
            height: $icon-sizes,
        ));
        @include all-props((
            top: (
                0: calc(50% - 10px),
                1024: calc(50% - 7px),
                1366: calc(50% - 9px),
                1600: calc(50% - 10px),
            ),
            right: (
                0: calc(50% - 10px),
                1024: calc(50% - 7px),
                1366: calc(50% - 9px),
                1600: calc(50% - 10px),
            )
        ));
    }

    &:global(.small) {
        $icon-sizes: (
            1024: 14px,
            1366: 18px,
            1600: 20px,
        );
        @include mobile-props((
            width: 20px,
            height: 20px,
        ));
        @include desktop-props((
            width: $icon-sizes,
            height: $icon-sizes,
        ));
        @include all-props((
            top: (
                0: calc(50% - 10px),
                1024: calc(50% - 7px),
                1366: calc(50% - 9px),
                1600: calc(50% - 10px),
            ),
            right: (
                0: calc(50% - 10px),
                1024: calc(50% - 7px),
                1366: calc(50% - 9px),
                1600: calc(50% - 10px),
            )
        ));
    }

    &:global(.validation) {
        svg {
            animation: mymove 0.8s infinite;
        }
    }
}
