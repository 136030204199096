@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";
@import '~styles/variables/index';


.portions-button-group-container {
    @include all-props((
        position: sticky,
        bottom: 0,
        padding: (
            0: 12px 16px,
            375: 12px 20px,
            1024: 12px 20px,
        ),
        background-color: var(--color__coconut),
        box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.05),
    ));
}

.portions-button-group {
    @include all-props((
        position: relative,
        width: 100%,
        height: (
            0: 48px,
            375: 52px,
            1024: 52px,
        ),
        border-radius: (
            0: 11px, // стили не из ui-токенов, но из ui-кита
            375: var(--sizeBorderRadiusL),
            1024: var(--sizeBorderRadiusL),
        ),
        background-color: var(--color__egg),
    ));

    &.disabled {
        background-color: var(--color__salt);
    }
}

.portions-content {
    @include all-props((
        display: flex,
        justify-content: center,
        align-items: center,
        gap: (
            0: 4px,
            375: 5px,
            1024: 5px,
        ),
        height: 100%,
        margin: (
            0: 0 18px,
            375: 0 22px,
            1024: 0 22px,
        ),

        opacity: 1,
        transition: opacity 200ms ease-in-out,
    ));

    &.in-cart {
        @include all-props((
            justify-content: space-between,
        ));
    }

    &.is-updating {
        @include all-props((
            opacity: 0,
        ));
    }
}

.portions-button-group__button-price-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.portions-button-group__button-count-price {
    display: flex;
    align-items: center;
    justify-content: center;
}

.portions-button-group__price {
    @include all-props((
        font-family: $haas-dsstd,
        font-weight: 500,
        letter-spacing: inherit,
        font-size: (
            0: 17px,
            375: 18px,
            1024: 18px,
        ),
        line-height: 22px,

        color: var(--color__choco)
    ));

    &.disabled {
        color: var(--color__lichi);
    }
}

.portions-button-group__price-rub {
    @include all-props((
        font-family: $gilroy,
        font-weight: 600,
    ));
}

.portions-button-group__cross {
    @include all-props((
        display: inline-block,
        width: 7px,
        height: 7px,
        margin: 0 2px,
    ));
}

.portions-button-group__count {
    @include all-props((
        font-family: $haas-dsstd,
        font-weight: 500,
        letter-spacing: inherit,
        font-size: (
            0: 17px,
            375: 18px,
            1024: 18px,
        ),
        line-height: 22px,

        color: var(--color__kurkuma)
    ));


    &.disabled {
        color: var(--color__lichi);
    }
}

.portions-button-group__button-icon-container {
    @include all-props((
        width: (
            0: 11px,
            375: 12px,
            1024: 12px,
        ),
        height: (
            0: 11px,
            375: 12px,
            1024: 12px,
        ),
        display: flex,
    ));
}

.portions-button-group__button-icon {
    @include all-props((
        width: 100%,
        object-fit: contain,
    ));
}

.portions-button-group__controls {
    @include all-props((
        position: absolute,
        left: 0,
        top: 0,
        width: 100%,
        height: 100%,
        display: flex,
        align-items: stretch,
        gap: 10px,
    ));
}

.portions-button-group__control {
    @include all-props((
        width: 100%,
        opacity: 0,
        outline: none,
        border: none,
    ));
}
