@import "~global/styles/cool-media-queries/index.global.scss";

.toggle-switch-root {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    user-select: none;
    text-align: left;
    @include all-props((
        width: (
            0: 34px,
            375: 42px,
            1024: 42px,
        ),
    ));
}

.toggle-switch-checkbox {
    display: none;
}

.toggle-switch-label {
    display: block;
    overflow: hidden;
    border-radius: 50px;

    @include desktop-props((
        cursor: pointer,
    ));
}

.toggle-switch-background {
    display: block;
    transition: background-color 0.2s;
    background-color: #EDEDED;
    @include all-props((
        height:(
            0: 18px,
            375: 22px,
            1024: 22px,
        ),
        line-height: (
            0: 18px,
            375: 22px,
            1024: 22px,
        ),
    ));
}

.toggle-switch-handler {
    display: block;
    @include all-props((
        width: (
            0: 13px,
            375: 16px,
            1024: 16px,
        ),
        height: (
            0: 13px,
            375: 16px,
            1024: 16px,
        ),
    ));
    margin: 2.5px;
    border-radius: 20px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    @include all-props((
        right: (
            0: 15px,
            375: 20px,
            1024: 20px,
        ),
    ));
    transition: right 0.2s;
}


.toggle-switch-checkbox:checked + .toggle-switch-label{
    .toggle-switch-background {
        margin-left: 0;
        background-color: #4FBC0D;
    }
    .toggle-switch-handler {
        right: 1px;
    }
}

.toggle-switch-checkbox:disabled + .toggle-switch-label {
    cursor: default;
    .toggle-switch-background {
        // margin-left: 0;
        background-color: #EDEDED;
    }
    .toggle-switch-handler {
        // right: 0px;
    }
}

