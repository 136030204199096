.achievementsBodyAnimation {
    position: relative;
    top: 0;
    transition: top 500ms;
}

.achievementsBodyAnimationOpen {
    top: -200px;
    overflow: hidden;
}

.achievementsPopupTransition {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1108;

    transition: transform 500ms;

    &.exited {
        transform: translateY(100%);
        opacity: 0;
        pointer-events: none;
    }
    &.entering {
        transform: translateY(0%);
    }
    &.entered {
        transform: translateY(0%);
    }
    &.exiting {
        transform: translateY(100%);
    }
}

.achievementsPopupBackdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1101;
    background-color: #000;

    transition: opacity 500ms;

    &.exited {
        opacity: 0;
        pointer-events: none;
    }
    &.entering {
        opacity: 0.4;
    }
    &.entered {
        opacity: 0.4;
    }
    &.exiting {
        opacity: 0;
    }
}
