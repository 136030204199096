@import '~styles/variables/index';

$hover-transition: all 150ms;

.portions {
    display: flex;
    padding: 8px 0 9px 23px;
    background: var(--color__coconut);

    transition: $hover-transition;

    &.interactive {
        &:hover,
        &.active {
            background: $barley-white;
        }
    }

    &.light:not(.active):not(:hover) {
        .portions__count,
        .portions__units,
        .portions__size {
            color: $gray;
        }
    }

    @media (min-width: 1024px) {
        padding: 10px 0 9px 18px;
    }
    @media (min-width: 1366px) {
        padding: 12px 0 9px 21px;
    }
    @media (min-width: 1440px) {
        padding: 15px 0 9px 24px;
        &.placement-details {
            padding: 12px 0 9px 21px;
        }
    }
    @media (min-width: 1600px) {
        padding: 15px 0 9px 26px;
        &.placement-details {
            padding: 12px 0 9px 21px;
        }
    }
    @media (min-width: 1920px) {
        padding: 20px 0 9px 29px;
        &.placement-details {
            padding: 12px 0 9px 21px;
        }
    }
}

.portions__count,
.portions__units,
.portions__size {
    $hover-transition: all 150ms;
}

.portions__count {
    color: var(--color__cuttlefish);
    font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
    font-size: 20px;
    letter-spacing: -0.2px;
    line-height: 20px;
    margin-right: 4px;
}
.portions__units-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.portions__units {
    color: $scorpion;
    font-family: $gilroy-bold;
    font-size: 9px;
    letter-spacing: -0.1px;
}
.portions__size {
    color: var(--color__hering);
    font-family: $gilroy-medium;
    font-size: 8px;
    margin-bottom: 1px;
    letter-spacing: -0.1px;

    span:first-child {
        margin-right: 2px;
    }
}

.portions.large {
    padding: 12px 21px;

    .portions__count {
        color: $mine-shaft;
        font-size: 25px;
        line-height: 25px;
    }

    .portions__units {
        font-family: $gilroy-medium;
        letter-spacing: 0.3px;
    }

    .portions__size {
        margin-top: 3px;
    }
}

.portions.small {
    padding: 11px 0 14px 18px;
    align-items: baseline;

    .portions__count {
        font-family: $NHaasGroteskTXStd-65Md;
        margin-right: 3px;
        font-size: 13px;
        letter-spacing: -0.2px;
        line-height: 1;
        color: var(--color__cuttlefish);
    }

    .portions__units {
        font-size: 12px;
        font-family: $gilroy-semibold;
        letter-spacing: -0.4px;
        color: var(--color__cuttlefish);
    }
}

.portions.basket {
    padding: 6px 0 9px 12px;
    .portions__count {
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -.2px;
        margin-top: 1px;
        margin-right: 3px;
    }
    .portions__units {
        font-family: $gilroy-bold;
        font-size: 8px;
        letter-spacing: -0.1px;
        color: $scorpion;
        margin-top: 0;
    }
    .portions__size {
        font-family: $gilroy;
        font-size: 7px;
        color: $boulder;
        letter-spacing: -0.2px;
        margin-top: 2px;
        span {
            font-family: $gilroy;
            font-size: 8px;
        }
    }
}

@media (min-width: 360px) {
    .portions__units-container {
        justify-content: initial;
    }
    .portions__count {
        font-size: 24px;
        line-height: 24px;
    }
    .portions__units {
        font-size: 10px;
    }
    .portions__size {
        font-size: 9px;
        margin-top: 2px;
        margin-bottom: 0;
    }
    .portions.basket {
        padding: 9px 0 9px 15px;
        .portions__count {
            font-size: 20px;
            margin-right: 4px;
        }
        .portions__units {
            font-size: 9px;
        }
        .portions__size {
            font-size: 8px;
            margin-top: 1px;
        }
    }
    .portions.small {
        padding: 13px 0 14px 24px;
        .portions__count {
            font-size: 14px;
            letter-spacing: -0.3px;
        }
        .portions__units {
            font-size: 14px;
            letter-spacing: -0.5px;
        }
    }
}

@media (min-width: 375px) {
    .portions.basket {
        padding: 9px 0 9px 18px;
    }
}

@media (min-width: 414px) {
    .portions.small {
        padding: 16px 0 14px 24px;
        .portions__count {
            font-size: 15px;
        }
        .portions__units {
            font-size: 15px;
        }
    }
}

@media (min-width: 424px) {
    .portions.small {
        .portions__count {
            font-size: 16px;
        }
    }
}

@media (min-width: 1024px) {
    .portions__count {
        font-size: 23px;
        color: var(--color__cuttlefish);
        margin-right: 5px;
    }
    .portions__units {
        font-family: $gilroy-medium;
        color: var(--color__cuttlefish);
    }
    .portions__size {
        font-size: 10px;
        color: $silver;
        letter-spacing: -0.2px;
        margin-top: 1px;
        span {
            font-size: 10px;
            font-family: $gilroy;
        }
    }
    .portions.basket {
        padding: 12px 0 9px 20px;
        .portions__count {
            font-size: 22px;
            color: $scorpion;
            margin-right: 5px;
            margin-top: 3px;
        }
        .portions__units {
            font-family: $gilroy-medium;
            font-size: 10px;
            color: var(--color__cuttlefish);
        }
        .portions__size {
            font-size: 10px;
            color: $silver;
            letter-spacing: -0.2px;
            margin-top: 1px;
            span {
                font-size: 10px;
                font-family: $gilroy;
            }
        }
    }
}

@media (min-width: 1600px) {
    .portions__count {
        margin-right: 7px;
        font-size: 25px;
    }
    .portions__units {
        font-size: 11px;
    }
    .portions__size {
        font-size: 11px;
        span {
            font-size: 11px;
        }
    }
    .portions.placement-details {
        .portions__count {
            font-size: 23px;
            color: var(--color__cuttlefish);
            margin-right: 5px;
        }
        .portions__units {
            font-size: 10px;
        }
        .portions__size {
            font-size: 10px;
            span {
                font-size: 10px;
            }
        }
    }
}

@media (min-width: 1920px) {
    .portions__count {
        margin-right: 9px;
        font-size: 30px;
        letter-spacing: -0.12px;
    }
    .portions__units {
        font-size: 15px;
        letter-spacing: -0.17px;
    }
    .portions__size {
        font-size: 15px;
        letter-spacing: -0.27px;
        span {
            font-size: 15px;
        }
    }

    .portions__units-container {
        position: relative;
        top: -3px;
    }

    .portions.placement-details {
        .portions__count {
            font-size: 23px;
            color: var(--color__cuttlefish);
            margin-right: 5px;
        }
        .portions__units {
            font-size: 10px;
        }
        .portions__size {
            font-size: 10px;
            span {
                font-size: 10px;
            }
        }
    }
}
