@import '~styles/variables/index';


.city-tag__icon {
    @include mobile-props((
        width: (
            0: 16px,
            375: 20px,
        ),
        height: (
            0: 16px,
            375: 20px,
        ),
        margin-right: (
            0: 4px,
            375: 8px,
        ),
    ));
}

.tag__root {
    @include mobile-props((
        box-sizing: border-box,
        position: relative,
        display: flex,
        justify-content: center,
        align-items: center,
        width: 100%,
        white-space: nowrap,
        border-radius: (
            0: var(--sizeBorderRadiusXXXS),
            375: var(--sizeBorderRadiusXXS),
        ),
        background-color: var(--color__coconut),
    ));

    &.primary {
        @include mobile-props((
            height: (
                0:   32px,
                375: 38px,
            ),
            padding: (
                0:   0 15px,
                375: 0 18px,
            ),
            box-shadow: 0 2px 7px rgba(0, 0, 0, 0.02), // var(--color__coconut)
            transition: box-shadow 100ms,
        ));
        &.is-selected {
            @include mobile-props((
                box-shadow: none,
            ));
        }
    }

    &.grey-back {
        @include mobile-props((
            height: (
                0:   35px,
                375: 40px,
            ),
            padding: (
                0:   0 14px,
                375: 0 16px,
            ),
        ));
    }

    &.city-select-tag {
        @include mobile-props((
            padding: (
                0: 0 9px,
                375: 0 14px 0 10px,
            ),
        ));
    }
}

.tag__boundary {
    &.primary {
        @include mobile-props((
            position: absolute,
            height: 100%,
            width: 100%,
            border-radius: (
                0: var(--sizeBorderRadiusXXXS),
                375: var(--sizeBorderRadiusXXS),
            ),
            border: 1px solid var(--color__salt),
        ));
    }
    &.grey-back {}
}

.tag__checkbox {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.tag__label {
    @include mobile-props((
        position: relative,
        z-index: 0,

        font-family: $gilroy,

        transition-property: (font-weight, letter-spacing),
    ));

    &.primary {
        @include mobile-props((
            color: var(--color__coal),
            font-weight: 500,
            font-size: (
                0:   14px,
                375: 17px,
            ),
            line-height: 18px,
            letter-spacing: -0.3px,
        ));

        &.is-selected {
            @include mobile-props((
                letter-spacing: -0.4px,
                color: var(--color__choco),
            ));
        }

        &.disabled {
            @include mobile-props((
                color: var(--color__fish),
            ));
            &.is-selected {
                @include mobile-props((
                    color: $disabled-label,
                ));
            }
        }
    }

    &.grey-back {
        @keyframes labelSelecting {
            0% {
                transform: translateY(0);
                animation-timing-function: ease-out;
            }
            54% {
                // transform: translateY(-31%);
                transform: translateY(-11%);
                animation-timing-function: ease-out;
            }
            63% {
                // transform: translateY(-31%);
                transform: translateY(-11%);
                animation-timing-function: ease-out;
            }
            100% {
                transform: translateY(0px);
                animation-timing-function: ease-out;
            }
        }

        @keyframes labelUnselecting {
            0% {
                transform: translateY(0);
                animation-timing-function: ease-out;
            }
            36% {
                // transform: translateY(-31%);
                transform: translateY(-11%);
                animation-timing-function: ease-out;
            }
            45% {
                // transform: translateY(-31%);
                transform: translateY(-11%);
                animation-timing-function: ease-out;
            }
            100% {
                transform: translateY(0);
                animation-timing-function: ease-out;
            }
        }

        @include mobile-props((
            color: var(--color__coal),
            font-weight: 600,
            font-size: (
                0:   16px,
                375: 17px,
            ),
            line-height: 16px,
            letter-spacing: -0.2px,
        ));
        &.disabled {
            @include mobile-props((
                color: var(--color__oyster),
            ));
        }

        &.is-selected {
            // animation: labelSelecting 550ms;
            // animation-fill-mode: forwards;
            color: var(--color__choco);
        }
        &.was-selected {
            // animation: labelUnselecting 550ms;
            // animation-fill-mode: forwards;
        }
    }

    &.capitalized {
        @include mobile-props((
            text-transform: capitalize,
        ));
    }

    &.delivery-date-tag {
        @include mobile-props((
            color: var(--color__cuttlefish),
            font-weight: 700,
            font-size: (
                0:   15px,
                375: 18px,
            ),
            letter-spacing: -0.2px,
        ));

        :global(.num) {
            @include mobile-props((
                font-family: $haas-dspro,
                font-weight: 600,
                margin: (
                    0:   0 -1px 0 1px,
                    375: 0 -2px 0 1px,
                ),
            ));
        }
    }

    &.city-select-tag {
        @include mobile-props((
            color: var(--color__cuttlefish),
            font-weight: 700,
            font-size: (
                0:   15px,
                375: 18px,
            ),
            letter-spacing: -0.2px,
        ));
    }

    & .num {
        @include mobile-props((
            margin-left: (
                0:   4px,
                375: 6px,
            ),

            font-weight: inherit,
        ));
    }
}

.tag__label-container {
    @include mobile-props((
        align-items: center,
        display: flex,
    ));
}



.tag__label-container.delivery-date-tag {
    @include mobile-props((
        position: relative,
        margin-right: (
            0:   12px,
            360: 14px,
            414: 16px,
        ),
    ));

    & .tag__arrow-container {
        @include mobile-props((
            position: absolute,
            top: 0,
            right: 0,
            transform: (
                0:   translateY(6px),
                414: translateY(5px),
            ),
        ));
    }
}

.tag__expander {
    @include mobile-props((
        position: absolute,
        margin: auto,
        height: 100%,
        width: 100%,
        border-radius: (
            0:   var(--sizeBorderRadiusXXXS),
            375: var(--sizeBorderRadiusXXS),
        ),
    ));

    &.primary {
        @keyframes expanderFilling {
            0% {
                transform: scale(0);
                animation-timing-function: ease-out;
            }
            54% {
                transform: scale(1.125);
                animation-timing-function: ease-out;
            }
            63% {
                transform: scale(1.125);
                animation-timing-function: ease-out;
            }
            100% {
                transform: scale(1);
                animation-timing-function: ease-out;
            }
        }

        @keyframes expanderCollapsing {
            0% {
                transform: scale(1);
                animation-timing-function: ease-out;
            }
            36% {
                transform: scale(1.125);
                animation-timing-function: ease-out;
            }
            45% {
                transform: scale(1.125);
                animation-timing-function: ease-out;
            }
            100% {
                transform: scale(0);
                animation-timing-function: ease-out;
            }
        }

        @include mobile-props((
            transform: scale(0),
            transition: transform 550ms ease-out,
            border: 1px solid transparent,
            background-color:  var(--color__melon),
            box-shadow: 0 2px 7px rgba(0, 0, 0, 0.02), // var(--color__coconut)
        ));

        &.is-selected {
            animation: expanderFilling 550ms;
            animation-fill-mode: forwards;
            border: 1px solid  var(--color__melon),
        }
        &.was-selected {
            animation: expanderCollapsing 550ms;
            animation-fill-mode: forwards;
            border: 1px solid var(--color__salt),
        }
    }

    &.grey-back {
        @keyframes expanderFilling {
            0% {
                transform: scale(1);
                background-color:  var(--color__coconut);
                animation-timing-function: ease-out;
            }
            54% {
                transform: scale(1.125);
                background-color:  var(--color__egg);
                animation-timing-function: ease-out;
            }
            63% {
                transform: scale(1.125);
                background-color:  var(--color__egg);
                animation-timing-function: ease-out;
            }
            100% {
                transform: scale(1);
                background-color:  var(--color__egg);
                animation-timing-function: ease-out;
            }
        }
        @keyframes expanderCollapsing {
            0% {
                transform: scale(1);
                background-color:  var(--color__egg);
                animation-timing-function: ease-out;
            }
            36% {
                transform: scale(1.125);
                background-color:  var(--color__coconut);
                animation-timing-function: ease-out;
            }
            45% {
                transform: scale(1.125);
                background-color:  var(--color__coconut);
                animation-timing-function: ease-out;
            }
            100% {
                transform: scale(1);
                background-color:  var(--color__coconut);
                animation-timing-function: ease-out;
            }
        }

        @include mobile-props((
            background-color: var(--color__coconut),
            transition: transform opacity 550ms ease-out,
        ));

        &.is-selected {
            background-color: var(--color__egg);
            animation: expanderFilling 550ms;
            animation-fill-mode: forwards;
        }
        &.was-selected {
            animation: expanderCollapsing 550ms;
            animation-fill-mode: forwards;
        }
    }

    &.disabled {
        @include mobile-props((
            border-color: $disabled-tag-expander,
            background-color: $disabled-tag-expander,
        ));
    }
}
