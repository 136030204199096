@import '~styles/variables/index';

.menu-filter-option {
    padding-top: 0;
    padding-bottom: 6px;

    @media (min-width: 1280px) {
        padding-top: 0;
        padding-bottom: 7px;
    }

    @media (min-width: 1366px) {
        padding-bottom: 6px;
    }
}

.menu-filter-option__button {
    display: flex;
    align-items: center;

    padding: 0;
    border: none;
    background: transparent;

    font-family: $gilroy;
    font-size: 16px;
    letter-spacing: -0.28px;
    color: var(--color__cuttlefish);

    &.disabled {
        color: $alto;
        cursor: default;
    }

    @media (min-width: 1366px) {
        font-size: 17px;
        letter-spacing: -0.3px;
    }

    @media (min-width: 1600px) {
        font-size: 18px;
        letter-spacing: -0.32px;
    }

    @media (min-width: 1920px) {
        font-size: 20px;
        letter-spacing: -0.35px;
    }
}

.menu-filter-option__title {
    margin: 0 9px;
    font: inherit;
    color: inherit;

    max-width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (min-width: 1366px) {
        margin: 0 9px 0 17px;
    }

    @media (min-width: 1600px) {
        max-width: 100%;
        overflow: visible;
        margin: 0 12px;
    }
}

.menu-filter-option__count {
    font: inherit;
    font-family: $NHaasGroteskDisp-55Roman;
    color: inherit;
}
