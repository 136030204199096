$font-path: '../../assets/fonts';

@font-face {
    font-family: 'Geometria';
    src: url("#{$font-path}/Geometria.woff") format('woff');
    font-display: swap;
    font-weight: 400;
}
@font-face {
    font-family: 'Geometria';
    src: url("#{$font-path}/Geometria-MediumItalic.woff") format('woff');
    font-display: swap;
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Geometria';
    src:
        url("#{$font-path}/Geometria-Bold.woff") format('woff');
    font-display: swap;
    font-weight: 700;
}
@font-face {
    font-family: 'Geometria';
    src:
        url("#{$font-path}/Geometria-BoldItalic.woff") format('woff');
    font-display: swap;
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Geometria';
    src:
        url("#{$font-path}/Geometria-ExtraBold.woff") format('woff');
    font-display: swap;
    font-weight: 800;
}
