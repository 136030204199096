@import './fonts';
@import './colors';
@import './ui-colors';

html, body {
    height: 100vh;

    // Эти параметры приводят к появлению пустой области в корзине при уменьшении адресной строки в IOS
    // height: -webkit-fill-available;
    // height: fill-available;
}

body {
    padding: 0;
    margin: 0;
    // overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

h1, h2, h3, h4, p, a {
    margin: 0;
    line-height: 1;
    font-family: $gilroy-light;
    font-weight: normal;
}

span {
    margin: 0;
    line-height: inherit;
    font-weight: inherit;
    font-family: inherit;
}

a, a:visited, a:active {
    text-decoration: none;
}

button {
    outline: none;
    color: var(--color__cuttlefish);
    align-items: stretch;
    cursor: pointer;
    margin: 0;
    padding: 0;
    overflow: visible;
    background: transparent;
    border: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0.1);

    &::-moz-focus-inner {
        border: 0;
    }
}

input {
    padding: 0;
    border-width: 0;
}

// :global
// #root {
//     // display: flex;
//     // min-height: 100vh;
//     // flex-direction: column;
// }

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0.1);
}
