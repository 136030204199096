@import '~styles/variables/index';


.comment-form {
    @include mobile_320 {
        visibility: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        top: 20%;
        left: 22%;
        height: 23%;
        width: 24%;

        position: fixed;
        z-index: 0;
        height: auto;
        border-radius: 4px;
        border: 1px solid #f1f1f1;
        opacity: 0;
        background: #ffffff;
        padding: 78px 0 13px;
        transform: translateZ(1px);

        overflow: hidden;
    }
    @include mobile_360 {
        padding: 89px 0 14px;
    }
    @include mobile_375 {
        padding: 84px 0 14px;
    }
    @include mobile_414 {
        padding: 95px 0 14px;
    }

    @include desktop_960 {
        box-sizing: border-box;
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        overflow: hidden;
        background: #ffffff;
        padding: 166px 40px 0;
    }
    @include desktop_1024 {
        padding: 180px 40px 0;
    }
    @include desktop_1366 {
        padding: 188px 40px 0;
    }
    @include desktop_1680 {
        padding: 200px 40px 0;
    }
    @include desktop_1920 {
        padding: 300px 40px 0;
    }
}

.comment-container {
    @include mobile_320 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        opacity: 0;

        // TODO: need set height without ios soft keyboard
        &.ios {
            height: 100%;
        }
    }
    @include desktop_960 {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        opacity: 1;
    }
}

.comment-form__title {
    @include desktop_960 {
        margin: 0 0 16px;
        white-space: pre-line;

        font-family: $gilroy-bold;
        font-size: 23px;
        line-height: 1.09;
        letter-spacing: -0.45px;
        text-align: center;
        color: #424242;
    }
    @include desktop_1600 {
        font-size: 25px;
        line-height: 1;
        letter-spacing: -0.49px;
    }
    @include desktop_1920 {
        font-size: 26px;
        line-height: 1.04;
        letter-spacing: -0.51px;
    }
}

.comment-area {
    @include mobile_320 {
        padding: 0 55px 0 27px;
        height: calc(100% - 64px); // $save-comment-button__height_320
        width: 100%;
        border: none;
        resize: none;
        outline: none;
        background: transparent;

        font-family: $gilroy-medium;
        font-size: 13px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.31;
        letter-spacing: -0.28px;
        color: rgba(64, 64, 64, 0.93); // #404040 + opacity: 0.93

        &::placeholder {
            color: rgba(186, 186, 186, 0.93); // #bababa + opacity: 0.93
        };
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @include mobile_360 {
        height: calc(100% - 74px); // $save-comment-button__height_360

        font-size: 15px;
        line-height: 1.27;
        letter-spacing: -0.32px;
    }
    @include mobile_375 {
        height: calc(100% - 80px); // $save-comment-button__height_375

        font-size: 16px;
        line-height: 1.19;
        letter-spacing: -0.44px;
    }

    @include desktop_960 {
        box-sizing: border-box;
        width: 100%;
        height: 164px;
        padding: 20px 60px 20px 28px;
        margin-bottom: 16px;
        border-radius: 4px;
        border: solid 1px #f1f1f1;
        resize: none;
        outline: none;

        font-family: $gilroy;
        font-size: 15px;
        line-height: 1.2;
        letter-spacing: -0.41px;
        color: rgba(64, 64, 64, 0.93); // color: #404040 + opacity: 0.93

        &:focus {
            border: solid 1px #e8e8e8;
        }
        &::placeholder {
            font-family: $gilroy-light;
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: -0.12px;
            color: #b0b0b0;
        };
    }
    @include desktop_1280 {
        font-size: 16px;
        line-height: 1.19;
        letter-spacing: -0.44px;

        &::placeholder {
            font-size: 15px;
            line-height: 1.27;
            letter-spacing: -0.13px;
        };
    }
    @include desktop_1600 {
        font-size: 17px;
        line-height: 1.12;
        letter-spacing: -0.47px;

        &::placeholder {
            font-size: 16px;
            line-height: 1.19;
            letter-spacing: -0.14px;
        };
    }
    @include desktop_1680 {
        padding: 24px 60px 24px 32px;
    }
}

.save-comment-button {
    transition: opacity 400ms;
    @include mobile-props((
        position: fixed,
        bottom: 14px,
        z-index: 7,
        display: flex,
        justify-content: center,
        align-items: center,
        cursor: pointer,
        width: (
            0: 288px,
            360: 328px,
            375: 344px,
            414: 382px,
            )
        ));

    &.invisible {
        @include mobile-props((
            opacity: 0,
        ));
    }

    @include desktop-props ((
        width: 100%,
        font-size: 16px,
        letter-spacing: -0.16px,
    ))
}

.spinner-block {
    @include mobile_320 {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 20px;
    }
    @include desktop_960 {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 24px;
        top: 20px;
    }
    @include desktop_1680 {
        top: 24px;
    }
}
