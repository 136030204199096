@import '~styles/variables/index';


@keyframes blink {
    from { transform: scale(0); }    // 0ms
    29% { transform: scale(1.05); }  // 260ms
    33% { transform: scale(1); }     // 300ms
    67% { transform: scale(1); }     // 600ms
    89% { transform: scale(0); }     // 800ms
    to { transform: scale(0); }      // 900ms
}

.loading__root {
    @include all-props((
        display: flex,
        justify-content: center,
        align-items: center,
        height: 100%,
        width: 100%,
    ));
}

.loading__dot {
    @include all-props((
        border-radius: 50%,
        background-color: var(--color__choco),
        transform: scale(0),

        height: 7px,
        width: 7px,
    ));

    & + & {
        margin-left: 7px;
    }

    &.disabled {
        background-color: var(--color__oyster);
    }
    &:nth-child(1) {
        animation: blink 900ms infinite;
    }
    &:nth-child(2) {
        animation: blink 900ms 100ms infinite;
    }
    &:nth-child(3) {
        animation: blink 900ms 200ms infinite;
    }
}
