@import '~styles/variables/index';
@import '~styles/modules/ruble';
@import './variables.scss';

.sold-out {
    @include desktop-props((
        opacity: 0.45,
    ));
}

.dish-card__wrapper {
    transform: translateZ(0);
    position: relative;
    margin-bottom: 66px;
    width: 100%;

    &.exiting {
        z-index: 3;
    }

    @media (min-width: 1024px) {
        display: flex;
        width: calc(50% - #{$v3-res960-gird-gap});
        margin-right: $v3-res960-gird-gap;
        margin-bottom: $v3-res960-dish-gap-bottom;
        transform: none;
    }

    @media (min-width: 1024px) {
        width: calc(50% - #{$v3-res1024-gird-gap});
        margin-bottom: $v3-res1024-dish-gap-bottom;
        margin-right: $v3-res1024-gird-gap;
    }

    @media (min-width: 1280px) {
        width: calc(50% - #{$v3-res1280-gird-gap});
        margin-right: $v3-res1280-gird-gap;
        margin-bottom: $v3-res1280-dish-gap-bottom;
    }

    @media (min-width: 1366px) {
        width: calc(50% - #{$v3-res1366-gird-gap});
        margin-right: $v3-res1366-gird-gap;
        margin-bottom: $v3-res1366-dish-gap-bottom;
    }

    @media (min-width: 1440px) {
        width: calc(50% - #{$v3-res1440-gird-gap});
        margin-right: $v3-res1440-gird-gap;
        margin-bottom: $v3-res1440-dish-gap-bottom;
    }

    @media (min-width: 1600px) {
        width: calc(50% - #{$v3-res1600-gird-gap});
        margin-right: $v3-res1600-gird-gap;
        margin-bottom: $v3-res1600-dish-gap-bottom;
    }

    @media (min-width: 1920px) {
        width: calc(50% - #{$v3-res1920-gird-gap});
        margin-right: $v3-res1920-gird-gap;
        margin-bottom: $v3-res1920-dish-gap-bottom;
    }
}

@keyframes unblurring {
    0% {
        filter: blur(10px);
        transform: scale(0.8);
    }
    90% {
        filter: blur(10px);
    }
    100% {
        filter: blur(0px);
        transform: scale(1);
    }
}

:global(.dish-card-desktop-link) {
    width: 100%;
}


.dish-card {
    overflow: hidden;
    position: relative;
    list-style-type: none;
    width: 100%;
    background: var(--color__coconut);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: solid 1px var(--color__onion);
    border-bottom: solid 1px var(--color__onion);

    &:hover {
        .dish-card__image {
            transform: scale(1.03);
        }
    }

    &::before {
        content: '';
        position: absolute;
        left: 8%;
        bottom: 7px;
        width: 84%;
        height: 84%;
        box-shadow: 0 2px 31px 0 rgba(207, 207, 207, 0.5);
    }

    &.filter-changed-animation {
        animation: unblurring 400ms;
        animation-timing-function: ease-in-out;
    }

    &.is-animated {
        z-index: 3;
        border: none;
        box-shadow: none;
    }

    &.sold-out {
        pointer-events: none;
        opacity: 0.55;
    }

    @media (min-width: 1024px) {
        &.sold-out {
            pointer-events: none;
            opacity: 0.45;
        }
    }
}

.dish-card__image--container {
    overflow: hidden;
    background: var(--color__coconut);
    position: relative;
}

.dish-card__image {
    position: relative;
    width: 100%;
    transition: transform 600ms linear;
    transform: scale(1);

    @media (min-width: 1024px) {
        height: $dish-image-heigth-1024;
    }
    @media (min-width: 1280px) {
        height: $dish-image-heigth-1280;
    }
    @media (min-width: 1366px) {
        height: $dish-image-heigth-1366;
    }
    @media (min-width: 1440px) {
        height: $dish-image-heigth-1440;
    }
    @media (min-width: 1600px) {
        height: $dish-image-heigth-1600;
    }
    @media (min-width: 1920px) {
        height: $dish-image-heigth-1920;
    }
}

.dish-card__image-stub {
    position: absolute;
    width: 100%;
    height: 100%;
}

.dish-card__image-wrap {
    position: relative;
    width: 100%;
    height: 100%;
}

.dish-card__wrap {
    position: relative;
    background: var(--color__coconut);
    border-radius: 6px;
}

.dish-card__content--title {
    @include desktop-props((
        display: flex,
        box-sizing: border-box,
        justify-content: space-between,
        width: 100%,
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0.1),
        padding: (
            1024: 20px 10px 0 20px,
            1366: 14px 12px 0 20px,
            1440: 20px 14px 0 23px,
            1600: 20px 17px 0 23px,
            1920: 28px 17px 0 28px,
        )
    ));

    &.with-custom {
        @include desktop-props((
            padding: (
                1024: 10px 10px 0 20px,
                1366: 14px 12px 0 20px,
                1440: 20px 14px 0 23px,
                1600: 20px 17px 0 23px,
                1920: 28px 17px 0 28px,
            )
        ));
    }
}

.dish-card__content--price {
    @media (min-width: 480px) {
        border-top: 1px solid var(--color__onion);
        padding: 12px 16px 16px 21px;
    }
    @media (min-width: 1024px) {
        border-top: none;
        padding: 8px 10px 8px 20px;
    }
    @media (min-width: 1366px) {
        padding: 18px 12px 10px 20px;
    }
    @media (min-width: 1440px) {
        padding: 14px 14px 12px 23px;
    }
    @media (min-width: 1600px) {
        padding: 21px 17px 12px 23px;
    }
}

.dish-card-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    margin: 0;
    min-height: 18px;
    overflow: hidden;
    &.with-custom {
        flex-direction: column;
        justify-content: flex-end;
        text-overflow: ellipsis;
        padding-right: 10px,
    }
}

.dish-card-title-wrap {
    width: 100%;
    text-align: center;
}

.dish-card-title__text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    // @media (min-width: 1024px) {
    //     max-width: initial;
    //     padding: 0;
    //     color: var(--color__cuttlefish);

    //     font-family: $gilroy;
    //     font-size: 14px;
    //     letter-spacing: -0.14px;
    //     line-height: 16px;
    // }
    @media (min-width: 1024px) {
        font-size: 15px;
        line-height: 18px;
    }
    @media (min-width: 1280px) {
        line-height: 19px;
    }
    @media (min-width: 1366px) {
        line-height: 18px;
    }
    @media (min-width: 1600px) {
        font-size: 17px;
        line-height: 19px;
    }
    @media (min-width: 1920px) {
        font-size: 20px;
        line-height: 22px;
        letter-spacing: -0.2px;
    }

    &.dish-card-title__text-bolder {
        font-family: $gilroy-semibold;
    }
}

.dish-card__footer {
    display: flex;
    justify-content: space-between;
}

.dish-card__price {
    margin: 0;

    @media (min-width: 1024px) {
        padding-top: 6px;
        font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
        font-size: 23px;
        letter-spacing: -0.09px;
        color: var(--color__cuttlefish);

        .price {
            position: relative;
            top: -1px;
            margin-left: 1px;
            font-size: 20px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    @media (min-width: 1024px) {
        font-size: 24px;
        .price {
            margin-left: 5px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
    @media (min-width: 1280px) {
        font-size: 24px;
    }
    @media (min-width: 1366px) {
        font-size: 25px;
    }
    @media (min-width: 1440px) {
        padding-top: 10px;
        .price {
            font-size: 22px;
        }
    }
    @media (min-width: 1600px) {
        padding-top: 8px;
        font-size: 27px;
        letter-spacing: -0.1px;
        .price {
            font-size: 24px;
        }
    }
    @media (min-width: 1920px) {
        padding-top: 8px;
        font-size: 31px;
        letter-spacing: -0.12px;
        .price {
            font-size: 27px;
        }
    }

    &.dish-card__price--text {
        font-family: $gilroy-semibold;

        a {
            font-family: inherit;
            text-decoration: underline;
        }
    }
}

.dish-card__price-text {
    @media (min-width: 1024px) {
        margin-top: -2px;
        font-family: $gilroy;
        font-size: 10px;
        color: $silver;
        letter-spacing: -0.2px;
    }
    @media (min-width: 1280px) {
        font-size: 11px;
        letter-spacing: -0.1px;
    }
    @media (min-width: 1366px) {
        margin-top: -1px;
    }
    @media (min-width: 1440px) {
        letter-spacing: 0.02px;
    }
    @media (min-width: 1600px) {
        margin-top: 0;
        font-size: 12px;
        letter-spacing: 0.03px;
    }
    @media (min-width: 1920px) {
        font-size: 15px;
    }
}

.price {
    @extend %ruble;
    color: var(--color__cuttlefish);
    font-size: inherit;
    font-size: 18px;
}

.dish-card__count-container {
    display: flex;
    align-items: center;
}

.dish-card__count-wrapper {
    padding-right: 8px;
}

.dish-card__basket {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0;
    opacity: 1;
    transition: all 300ms ease;
    transform: scale(1);

    svg {
        position: relative;
        width: 24px;
        height: 21px;
    }

    &:hover {
        transform: scale(1.05);
        svg {
            animation: slide-basket linear 300ms running;
        }
    }

    @media (min-width: 1024px) {
        box-sizing: border-box;
        width: $basket-button-size-960;
        height: $basket-button-size-960;
        background: $sunglow;
        border-radius: 4px;
        border: solid 1px $candlelight;
        box-shadow: 0 5px 12px 0 rgba(226, 226, 226, 0.78);

        svg {
            height: 23px;
            width: 24px;
            margin-top: 1px;
        }
    }
    @media (min-width: 1024px) {
        width: $basket-button-size-1024;
        height: $basket-button-size-1024;
    }
    @media (min-width: 1366px) {
        width: $basket-button-size-1366;
        height: $basket-button-size-1366;
    }
    @media (min-width: 1440px) {
        width: $basket-button-size-1440;
        height: $basket-button-size-1440;
        svg {
            height: 25px;
            width: 28px;
        }
    }
    @media (min-width: 1600px) {
        width: $basket-button-size-1600;
        height: $basket-button-size-1600;
        svg {
            height: 28px;
            width: 31px;
        }
    }
    @media (min-width: 1920px) {
        width: $basket-button-size-1920;
        height: $basket-button-size-1920;
        svg {
            height: 35px;
            width: 38px;
        }
    }
}

.dish-card__sold-out-label {
    height: 40px;
    width: 129px;
    border-radius: 5px;

    line-height: 40px;
    text-align: center;
    font-family: $gilroy-semibold;
    font-size: 14px;
    letter-spacing: -0.47px;

    /*
        colors calculatet by https://www.viget.com/articles/equating-color-and-transparency/
        with opacity 0.55
    */
    background: #f4f4f4;
    color: #b3b3b3;

    @media (min-width: 1024px) {
        margin-top: 0;
    }

    @media (min-width: 1024px) {
        width: 200px;
        height: 47px;

        background: #e2e2e2;
        color: #929292;

        font-family: $gilroy-medium;
        font-size: 14px;
        letter-spacing: -0.47px;
    }
}


@keyframes slide-basket {
    0% {
        top: 0;
    }
    50% {
        top: -5px;
    }
    100% {
        top: 0;
    }
}

@media (min-width: 1024px) {
    .dish-card {
        overflow: visible;
        width: 100%;
        box-shadow: 1px 5px 17px 0 rgba(0, 0, 0, 0.03);
        border-radius: 6px;
        border: none;
        background: var(--color__coconut);

        &:first-child {
            margin-top: 0;
        }

        &.is-animated {
            position: fixed;
            width: calc(50% - #{$v3-res960-gird-gap});
        }
    }

    .dish-card__image--container {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    .dish-card__wrap {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .dish-card__count-wrapper {
        padding-right: 10px;
    }
}

@media (min-width: 1920px) {
    .dish-card__count-wrapper {
        padding-right: 13px;
    }
}

.spa-march-dish-label-wrapper {
    position: absolute;

    @include all-props((
        left: (
            0: 3.5%,
            1024: 4.5%,
        ),
        bottom: (
            0: 3.5%,
            1024: 6.5%,
        )
    ));

    & img {
        @include responsive-mobile-props(375px, (
            height: 21px,
        ));
        @include responsive-desktop-props(1680px, (
            height: 24px,
        ));
    }
}
