@import "~global/styles/cool-media-queries/index.global.scss";

.deliveryZonePageRoot {
    @include all-props((
        display: flex,
        flex-direction: column,
        // height: 100%,
        background-color: #ececec,
        position: relative,
        z-index: 1,
        // overflow: scroll,
        max-width: 470px,
        padding-top: (
            0: 18px,
            375: 22px,
            1024: 22px,
        ),
    ));
}
