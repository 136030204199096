@import "~global/styles/cool-media-queries/index.global.scss";
@import '~global/styles/fonts/index.scss';

.basket-button-skeleton {
    @include all-props((
        height: (
            0: 48px,
            375: 52px,
        ),
        max-height: (
            0: 48px,
            375: 52px,
        ),
        border-radius: (
            0: 5px,
            375: 9px,
        ),
    ));
}

.open-basket-button-price {
    @include mobile-props((
        margin-left: (
            0: 5px,
            375: 5px,
            414: 5px,
        )
    ));
}

.num-with--discount {
    @include mobile-props((
        text-decoration: line-through,
    ));
}

/* DESKTOP */

:global {
.pch-float-footer__wrapper {
    @include desktop-props((
        height: (
            1024: 70px,
            1366: 76px,
        ),
        box-shadow: 0px 2px 54px rgba(0, 0, 0, 0.04),
    ));
    background: #fff;
    // position: fixed;
    // bottom: 0;
    // z-index: 3;
    width: 100%;
}

.pch-float-footer {
    @include desktop-props((
        height: 100%,
        margin: 0 auto,
        width: (
            1024: 736px,
            1366: 982px,
            1440: 1036px,
            1680: 1208px,
            1920: 1380px,
        ),
    ));
}

.pch-float-footer__buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    cursor: pointer;
    // @include desktop-props((
    //     margin-right: (
    //         1366: 29px,
    //     ),
    // ));
}

.pch-float-footer-icon-caontainer {
    @include desktop-props((
        padding-right: (
            1024: 12px,
            1366: 10px,
        ),
    ));

    .contact-link {
        @include desktop-props((
            border: none,
        ));
    }

    .contact-link__icon {
        @include desktop-props((
            border-radius: 10px,
        ));
    }
}

.pch-float-footer__button-container {
    @include desktop-props((
        width: (
            1024: 320px,
        )
    ));
    cursor: pointer;
}

};

.center-content-wrapper {
    @include all-props((
        display: flex,
        align-items: center,
    ))
}

