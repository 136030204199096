@import "~global/styles/cool-media-queries/index.global.scss";
@import '~global/styles/fonts/index.scss';

.step-payment-recipe-row-root {
    @include all-props((
        padding: (
            0: 0 16px,
            375: 0 20px,
            1024: 0 20px,
        ),
        margin-top: (
            0: 20px,
            375: 24px,
            1024: 24px,
        ),
    ));
}

.step-payment-recipe-row {
    @include all-props((
        align-items: center,
        background-color: #FFF,
        display: flex,
        justify-content: space-between,
        padding: (
            0: 14px 17px 14px 18px,
            375: 17px 16px 16px 22px,
            1024: 17px 16px 16px 22px,
        ),
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.07),

        border-radius: (
            0: var(--sizeBorderRadiusXL),
            375: var(--sizeBorderRadiusXXL),
            1024: var(--sizeBorderRadiusXXL),
        ),
    ));

}

.recipe-content {
    @include all-props((
        display: flex,
    ));
}

.recipe-content__icon {
    @include all-props((
        width: (
            0: 57px,
            375: 68px,
            1024: 68px,
        ),
        // background-color: red,
        position: relative,
    ));

    img {
        @include all-props((
            position: absolute,
            left: (
                0: -7px,
                375: -10px,
                1024: -10px,
            ),
            bottom: (
                0: -12px,
                375: -15px,
                1024: -15px,
            ),
            width: (
                0: 67px,
                375: 79px,
                1024: 79px,
            ),
        ));
    }
}

/* COMMON */
.recipe-content__text {
    @include all-props((
        font-size: (
            0: 15px,
            375: 17px,
            1024: 17px,
        ),
        font-family: $gilroy,
        font-weight: (
            0: 500,
            1024: 400,
        ),
        letter-spacing: -0.2px,
        line-height: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
    ));
}

/* COLOR */
.recipe-content__text {
    &.enabled {
        color: var(--color__pepper);
    }
    &.disabled {
        color: var(--color__coal);
    }
}
