@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";


.achievementsPopup {
    background-color: #fff;
    height: 100%;
    overflow-y: scroll;
}

.achievementsPopupWrap {
    @include mobile-props((
        width: 100%,
        margin: 0 auto,
        max-width: 564px,
    ));
}

/* POPUP HEADER */
.achievementsPopupHeader {
    @include mobile-props((
        padding: (
            0: 10px 16px,
            375: 15px 20px,
        ),
        // NOTE: No kit color
        background-color: #FF6B00,
        display: flex,
        justify-content: space-between,
        position: relative,
    ));
}

.achievementsPopupHeaderLogo {
    @include mobile-props((
        width: (
            0: 189px,
            375: 222px,
        ),
    ));
}

.achievementsPopupCloseContainer {
    @include mobile-props((
        position: fixed,
        top: (
            0: 14px,
            375: 20px,
        ),
        right: (
            0: 16px,
            375: 20px,
        ),
        width: (
            0: 22px,
            375: 28px,
        ),
        height: (
            0: 22px,
            375: 28px,
        ),
    ));
}

.achievementsPopupClose {
    width: 100%;
    height: 100%;
}

/* POPUP BANNER */
.achievementsPopupBannerRoot {
    background-color: #FF6B00;
}

.achievementsPopupStatus {
    @include mobile-props((
        padding: (
            0: 16px,
            375: 16px 20px 20px,
        ),
        background-size: contain,
        background-repeat: no-repeat,
        background-position-y: -2%,
    ));

    &.legend {
        background-image: url('../img/legend.png');
    }
    &.taster {
        background-image: url('../img/taster.png');
    }
    &.cinnabon {
        background-image: url('../img/cinnabon.png');
    }
    &.critic {
        background-image: url('../img/critic.png');
    }
    &.connoisseur {
        background-image: url('../img/connoisseur.png');
    }
    &.grandchild {
        background-image: url('../img/grandchild.png');
    }
    &.time_lord {
        background-image: url('../img/time_lord.png');
    }
    &.holiday {
        background-image: url('../img/holiday.png');
    }
    &.friends {
        background-image: url('../img/friends.png');
    }
    &.experience {
        background-image: url('../img/experience.png');
    }
}

.achievementsPopupPrefix {
    @include responsive-mobile-props(375px, (
        font-size: 14px,
        line-height: 16px,
    ));

    @include mobile-props((
        color: #FFF,
        font-family: $gilroy,
        font-weight: 600,
        letter-spacing: -0.2px,
        text-transform: uppercase,
    ));
}

.achievementsPopupStatusText {
    @include responsive-mobile-props(375px, (
        font-size: 40px,
        line-height: 37px,
    ));

    @include mobile-props((
        color: #FFF,
        font-family: $gilroy,
        font-weight: 700,
        letter-spacing: -1px,
        width: 30%,
        margin-top: -1px,
    ));

    &.legend {
        @include responsive-mobile-props(375px, (
            margin-bottom: 208px,
        ));
    }
    &.taster {
        @include responsive-mobile-props(375px, (
            margin-bottom: 283px,
        ));
    }
    &.cinnabon {
        @include mobile-props((
            width: 85%,
        ));
        @include responsive-mobile-props(375px, (
            margin-bottom: 283px,
        ));
    }
    &.critic {
        @include responsive-mobile-props(375px, (
            margin-bottom: 283px,
        ));
    }
    &.connoisseur {
        @include responsive-mobile-props(375px, (
            margin-bottom: 246px,
        ));
    }
    &.grandchild {
        @include responsive-mobile-props(375px, (
            margin-bottom: 283px,
        ));
    }
    &.time_lord {
        @include responsive-mobile-props(375px, (
            margin-bottom: 283px,
        ));
    }
    &.holiday {
        @include responsive-mobile-props(375px, (
            margin-bottom: 283px,
        ));
    }
    &.friends {
        @include responsive-mobile-props(375px, (
            margin-bottom: 283px,
        ));
        @include mobile-props((
            width: 100%,
        ));
    }
    &.experience {
        @include responsive-mobile-props(375px, (
            margin-bottom: 283px,
        ));
    }
}

.achievementsPopupStatusDescription {
    @include mobile-props((
        color: var(--color__onion),
        font-family: $gilroy,
        font-size: (
            0: 16px,
            375: 18px,
        ),
        font-weight: 500,
        line-height: (
            0: 17px,
            375: 19px,
        ),
        letter-spacing: -0.2px,
        margin-bottom: (
            0: 16px,
            375: 20px,
        ),
    ));
}

.achievementsPopupStatusShare {}


/* POPUP STATS */
.achievementsPopupStats {
    @include mobile-props((
        box-sizing: border-box,
        padding: (
            0: 32px 16px 16px 16px,
            375: 40px 20px 20px 20px,
        ),
    ));
}
.achievementsPopupStatsContent {}
.achievementsPopupStatsTitle {
    @include mobile-props((
        color: var(--color__cuttlefish),
        font-family: $gilroy,
        font-size: (
            0: 21px,
            375: 24px,
        ),
        font-weight: 700,
        line-height: (
            0: 22px,
            375: 25px,
        ),
        letter-spacing: (
            0: -0.6px,
            375: -0.381px,
        ),
        padding-bottom: 8px,
    ));
}
.achievementsPopupStatsMetrics {
    @include mobile-props((
        display: flex,
        flex-direction: column,
        gap: (
            0: 12px,
            375: 11px,
        ),
    ));
}
.achievementsPopupStatsMetric {
    @include mobile-props((
        display: flex,
        align-items: baseline,
        gap: 4px,
    ));
}

.achievementsPopupStatsMetricText {
    @include mobile-props((
        color: var(--color__coal),
        font-family: $gilroy,
        font-size: (
            0: 15px,
            375: 17px,
        ),

        font-style: normal,
        font-weight: 500,
        line-height: (
            0: 15px,
            375: 18px,
        ),
        letter-spacing: (
            0: -0.2px,
            375: -0.381px,
        ),
    ));
}
.achievementsPopupStatsDivider {
    @include mobile-props((
        height: 3px,
        flex: 1,
        background: url('./dot.img.svg') left center repeat-x,
    ));

}
.achievementsPopupStatsMetricCount {
    @include mobile-props((
        color: var(--color__coal),
        font-family: $gilroy,
        font-size: (
            0: 15px,
            375: 17px,
        ),

        font-style: normal,
        font-weight: 500,
        line-height: (
            0: 15px,
            375: 18px,
        ),
        letter-spacing: (
            0: -0.2px,
            375: -0.381px,
        ),
    ));
    & .number {
        @include mobile-props((
            font-weight: 400,
            font-family: $n-haas-tx-pro,
        ))
    }
}

/* POPUP BADGE */

.achievementsPopupBadgeRoot {
    @include mobile-props((
        padding: (
            0: 0 16px 40px 16px,
            375: 0 20px 50px 20px,
        ),
    ));
}

.achievementsPopupBadgeCard {
    @include mobile-props((
        background-color: var(--color__onion),
        border-radius: 20px,
        padding: (
            0: 16px 20px,
            375: 16px,
        ),
        display: flex,
        justify-content: center,
        flex-direction: column,
        align-items: center,
    ));
}

.achievementsPopupBadgeIcon {
    @include mobile-props((
        margin-bottom: 4px,
        height: (
            0: 26px,
            375: 36px,
        ),
        width: (
            0: 26px,
            375: 36px,
        ),
    ));

    svg {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.achievementsPopupBadgeTitle {
    @include all-props((
        text-align: center,
        padding-bottom: 2px,
        color: var(--color__hering),
        font-family: $gilroy,
        font-size:(
            0: 15px,
            375: 17px,
        ),
        font-weight: 500,
        line-height: (
            0: 15px,
            375: 18px,
        ),
        letter-spacing: -0.2px,
    ));
}

.achievementsPopupBadgeDescription {
    @include all-props((
        text-align: center,
        color: var(--color__pepper),
        font-family: $gilroy,
        font-size: (
            0: 15px,
            375: 17px,
        ),
        font-style: normal,
        font-weight: 600,
        line-height: (
            0: 15px,
            375: 18px,
        ),
        letter-spacing: (
            0: -0.2px,
            375: -0.381px,
        ),

    ));
}

/* POPUP NY BANNER */
.achievementsPopupNYBanner {
    @include mobile-props((
        padding: (
            0: 0 16px 53px 16px,
            375: 0 20px 48px 20px,
        ),
    ))
}

.achievementsPopupNYbg {
    @include mobile-props((
        box-sizing: border-box,
        background: url('./banner-ny.jpg') center top no-repeat,
        background-size: cover,
        display: flex,
        flex-direction: column,
        justify-content: flex-end,
        padding: (
            0: 16px,
            375: 20px,
        ),
        border-radius: 20px,
    ));

    @include responsive-mobile-props(375px, (
        height: 392px,
    ));
}
