@import '~styles/variables/index';

.giftIngredientRoot {
    @include all-props((
        width: 100%,
        display: flex,
        justify-content: space-between,
        align-items: center,
        font-family: Gilroy,
        font-weight: 600,
    ));
    @include mobile-props((
        padding-top: (
            0: 10px,
            375: 12px
        ),
        padding-bottom: (
            0: 8px,
            375: 10px
        ),
        border-bottom: solid 1px #F1F1F1,
    ));
}

.giftIngredientLeftSide {
    @include mobile-props((
        display: flex,
        align-items: center,
        gap: (
            0: 5px,
            375: 7px
        )
    ))
}

.giftIngredientPricesContainer {
    @include mobile-props((
        display: flex,
        align-items: flex-start,
        gap: 5px,
        margin-bottom: (
            375: 1px,
        ),
        margin-right: (
            375: 1px,
        )
    ))
}

.giftIngredientTitle {
    @include all-props((
        letter-spacing: -0.2px,
        color: #659727,
    ));
    @include mobile-props((
        font-size: (
            0: 16px,
            375: 17px,
        ),
        line-height: 18px,
    ));
    &.small {
        @include mobile-props((
            font-size: (
                0: 11px,
                375: 12px,
            ),
            line-height: 14px,
        ));
    }
    &.crossed {
        @include mobile-props((
            text-decoration: line-through,
        ));
    }
    &.light {
        color: #96BB68,
    }
    &.compressed {
        letter-spacing: -1.5px;
    }
}

.giftIcon {
    @include mobile-props((
        width: (
            0: 21px,
            375: 23px,
        ),
        height: (
            0: 21px,
            375: 23px,
        )
    ))
}
