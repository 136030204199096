$font-path: './font';
$font-name: 'PoplarKozelCyr';

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}.otf") format('opentype');
  font-display: swap;
  font-weight: 400;
}

