@import '~styles/variables/index';
@import '~styles/modules/ruble';

.checkout-notifications {
    position: relative;
    z-index: 3;
    width: 100%;

    &.check-open {
        z-index: 2;
    }

    @media (min-width: 1024px) {
        position: fixed;
    }
}

.priceAnimationEnter {
    transform: scale(1);
    animation: make-bigger 600ms linear running;
}

@keyframes make-bigger {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes loading-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.loading {
    display: inline-block;
    width: 18px;
    height: 18px;
    vertical-align: bottom;
    opacity: 0.7;
    animation: loading-rotate 1s ease-in-out infinite;
}

.checkout {
    background: var(--color__onion);
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 3000;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    transition: filter 600ms 300ms;
    filter: none;

    &.blur {
        transition: filter 600ms alternate;
        filter: blur(25px);
    }
    &.entered {
        position: relative;
        padding-top: 32px;

        .checkout-header {
            position: fixed;
            top: 0;
            z-index: 3;
            width: 100%;
        }
    }

    @media (min-width: 360px) {
        &.entered {
            padding-top: 43px;
        }
    }

    @media (min-width: 1024px) {
        width: 515px;
        border-top: none;

        &.entered {
            transform: translateZ(0);
            position: fixed;
            padding-top: 0;

            .checkout-header {
                position: static;
            }
            // // FRNT-3237 Deprecated
            // &.is-foodcard-form-shown {
            //     transition: none;
            //     transform: none;
            // }
        }
    }

    @media (min-width: 1920px) {
        width: 561px;
    }
}

@keyframes blurring {
    from {
        filter: blur(0px);
    }
    to {
        filter: blur(25px);
    }
}

.basket-mobile-select-overlay {
    background: transparent;
    border: none;
    padding: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    z-index: 5;
}

.basket__tip {
    height: 20px;
    background: transparent;
    border: 0;
    padding: 0;
    outline: none;
    position: relative;

    &:hover {
        cursor: pointer;
        .basket__tip-icon--hover {
            opacity: 1;
        }
        .basket__tip-block {
            opacity: 1;
        }
        .basket__tip-block--telephone {
            bottom: 43px;
        }
    }
}

.basket__tip-icon--hover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: all 300ms ease;
}

.basket__tip-block {
    transition: all 300ms ease;
    opacity: 0;
    box-sizing: border-box;
    position: absolute;
    padding: 8px 18px 13px 18px;
    border-radius: 5px;
    background: var(--color__cuttlefish);
    color: var(--color__coconut);
    font-family: $gilroy;
    font-size: 11px;
    line-height: 1.09;
    letter-spacing: -0.1px;
    text-align: left;
    z-index: 4;
}

.basket__tip--telephone {
    position: absolute;
    top: 15px;
    right: 14px;
    @media (min-width: 640px) {
        top: 19px;
    }

    &.has-error {
        :global
        .tip-text {
            fill: $sundown;
        }

        :global
        .circle {
            stroke: $sundown;
        }
    }
}

.basket__tip-block--telephone {
    bottom: 35px;
    right: -14px;
    width: 266px;
    transition: all 300ms ease;
    pointer-events: none;
    &::after{
        content: '';
        position: absolute;
        right: 19px;
        top: 100%;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid var(--color__cuttlefish);
        clear: both;
    }
}

.basket__tip-block_link {
    color: inherit;
    text-decoration: underline;
}

.checkout-scroll {
    width: 100%;
    max-width: 414px;
    margin: auto;
    overflow-y: auto;

    @media (min-width: 1024px) {
        overflow: auto;
        max-width: initial;
        // padding-bottom: 76px;
    }
}

.checkout__checkout {
    background: var(--color__onion);
    border-radius: 5px;
    box-shadow: 0 2px 10px 8px rgba(1, 1, 1, 0.02);
    // margin: 17px 12px 26px 11px;
    margin: 17px 8px 26px;
    position: relative;

    transition: filter 600ms 300ms;
    filter: none;

    &.blur {
        transition: filter 600ms alternate;
        filter: blur(25px);
    }
    // safari margin bottom
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 20px;
        bottom: -20px;
        background: transparent;
    }

    &.error {
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--color__coconut);
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &::after {
            display: none;
        }
    }

    @media (min-width: 360px) {
        margin-top: 19px;
        margin-bottom: 21px;
    }

    @media (min-width: 414px) {
        margin-left: 16px;
        margin-right: 16px;
    }

    @media (min-width: 1024px) {
        background: var(--color__coconut);
        border-radius: 4px;
        box-shadow: 0 8px 10px 4px rgba(1, 1, 1, 0.01);
        margin: 19px 18px;

        &::after {
            content: '';
            position: absolute;
            background: $concrete;
            width: 1px;
            top: -30px;
            bottom: -60px;
            left: -30px;
            height: initial;
        }
    }

    @media (min-width: 1920px) {
        margin: 18px 19px 16px;
    }
}

.checkout__open-subscription-popup-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    margin: 8px 0 32px;

    @media (min-width: 360px) {
        margin: 14px 0 42px;
    }

    @media (min-width: 1024px) {
        margin: 14px 0 32px;
    }
}

.checkout__open-subscription-popup {
    padding: 0;
    background: transparent;
    border: none;
    position: relative;
    .dots {
        position: absolute;
        left: 0;
        bottom: -4px;
        width: 100%;
    }
}

.checkout__open-subscription-popup-text {
    font-family: $gilroy-bold;
    font-size: 12px;
    line-height: 1.08;
    letter-spacing: -0.22px;
    color: var(--color__cuttlefish);

    @media (min-width: 360px) {
        font-size: 13px;
        line-height: 1;
        letter-spacing: -0.24px;
    }

    @media (min-width: 1024px) {
        font-family: $gilroy-semibold;
    }
}

.scroll-thumb {
    position: relative;
    width: 4px;
    background-color: $gallery;
}

.vertical-scroll-track {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

.basket__empty-wrap {
    box-sizing: border-box;
    height: 100vh;
    transition: all 600ms ease;

    @media (min-width: 1280px) {
        padding: 30px;
    }

    @media (min-width: 1366px) {
        padding: 33px;
    }
    @media (min-width: 1440px) {
        padding: 35px;
    }
    @media (min-width: 1600px) {
        padding: 36px;
    }
    @media (min-width: 1680px) {
        padding: 40px;
    }
    @media (min-width: 1920px) {
        padding: 43px;
    }
}

.basket__empty {
    position: relative;
    background: var(--color__coconut);
    border: solid 1px var(--color__onion);
    border-radius: 8px;
    box-shadow: 0 8px 10px 0 rgba(244, 244, 244, 0.71);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    height: 100%;
    box-sizing: border-box;

    transition: all 600ms ease;
    &.entering {
        top: 100px;
        opacity: 0;
    }
    &.entered {
        top: 0;
        opacity: 1;
    }
    &.exited {
        display: none;
    }
}

.basket__empty-title {
    font-family: $gilroy-semibold;
    font-size: 17px;
    line-height: 1.08;
    letter-spacing: -0.2px;
    text-align: center;
    color: var(--color__cuttlefish);
    margin-bottom: 2px;
}

.checkout__step-by-step-container {
    @include mobile-props((
        position: absolute,
        left: 0,
        top: 0,
        width: 100%,
        height: 100%,
        z-index: 3000,
    ));

    @include desktop-props((
        position: absolute,
        right: 0,
        top: 0,
        width: 470px,
        height: 100%,
        z-index: 3000,
    ));

    &.exited {
        @include all-props((
            transform: translateX(100%),
        ));
    }
    &.entering {
        @include all-props((
            transition: transform 600ms ease-out,
            transform: translateX(0%),
        ));
    }
    &.entered {
        @include all-props((
            transform: translateX(0%),
        ));
    }
    &.exiting {
        @include all-props((
            transition: transform 600ms ease-out,
            transform: translateX(100%),
        ));
    }

    &.checkout__step-by-step-container--shifted {
        &.exited {
            @include mobile-props((
                transform: translateX(-87px),
            ));
        }
        &.exiting {
            @include mobile-props((
                transition: transform 600ms ease-out,
                transform: translateX(-87px),
            ));
        }
    }
}
