/*
    UI Kit BR-901.
    Версия от 24 июня 2021
*/

@import "~global/styles/cool-media-queries/index.global.scss";
@import "~styles/variables/colors.scss";
@import "~styles/variables/fonts.scss";
@import './uiTokens/index.scss';
:global {
    // <H1...H6> Classes
    .LP_H1-1,
    .LP_H1-2,
    .LP_H2-1,
    .LP_H3-1,
    .LP_H4-1,
    .LP_H5-1,
    .LP_H6-1,
    .LPd_H1-1,
    .LPd_H2-1,
    .LPd_H3-1,
    .LPd_H4-1 {
        @include all-props(
            (
                color: var(--color__cuttlefish),
                font-family: $gilroy,
                line-height: inherit,
            )
        );
    }
    .LP_H1-1 {
        // Gilroy Bold
        @include mobile-props(
            (
                font-weight: 700,
                letter-spacing: -1.6px,
                font-size: (
                    0: 44px,
                    360: 50px,
                    375: 52px,
                    414: 58px,
                ),
            )
        );
    }
    .LPd_H1-1 {
        @include desktop-props(
            (
                font-weight: 600,
                letter-spacing: -1.6px,
                font-size: (
                    1024: 62px,
                    1366: 86px,
                    1440: 92px,
                    1680: 110px,
                    1920: 124px,
                ),
            )
        );
    }

    .LP_H1-2 {
        @include mobile-props((
            letter-spacing: -1px,
            font-weight: 700,
            font-size: (
                0: 30px,
                360: 35px,
            ),
            line-height: inherit,
        ));
    }

    .LP_H2-1 {
        // Gilroy Bold
        @include mobile-props(
            (
                font-weight: 700,
                letter-spacing: -1px,
                font-size: (
                    0: 34px,
                    375: 38px,
                ),
            )
        );
    }
    .LPd_H2-1 {
        @include desktop-props(
            (
                font-weight: 600,
                letter-spacing: -3px,
                font-size: (
                    1024: 56px,
                    1366: 66px,
                    1440: 70px,
                    1680: 80px,
                    1920: 80px,
                ),
            )
        );
    }

    .LP_H3-1 {
        // Gilroy Bold
        @include mobile-props(
            (
                font-weight: 700,
                letter-spacing: -1px,
                font-size: (
                    0: 30px,
                    360: 33px,
                    375: 35px,
                    414: 39px,
                ),
            )
        );
    }
    .LPd_H3-1 {
        @include desktop-props(
            (
                font-weight: 600,
                letter-spacing: -0.5px,
                font-size: (
                    1024: 38px,
                    1366: 50px,
                    1440: 52px,
                    1600: 62px,
                ),
            )
        );
    }

    .LP_H4-1 {
        // Gilroy Bold
        @include mobile-props(
            (
                font-weight: 700,
                letter-spacing: -0.87px,
                font-size: (
                    0: 20px,
                    360: 23px,
                    375: 24px,
                    414: 25px,
                ),
            )
        );
    }
    .LPd_H4-1 {
        @include desktop-props(
            (
                font-weight: 700,
                letter-spacing: -1.7px,
                font-size: (
                    1024: 30px,
                    1366: 36px,
                    1440: 36px,
                    1680: 42px,
                    1920: 46px,
                ),
            )
        );
    }

    .LP_H5-1 {
        // Gilroy Bold
        @include mobile-props(
            (
                font-weight: 700,
                letter-spacing: -0.5px,
                font-size: (
                    0: 17px,
                    360: 20px,
                    375: 21px,
                    414: 22px,
                ),
            )
        );
    }

    .LPd_H5-1 {
        @include desktop-props((
            font-weight: 700,
            letter-spacing: -1.5px,
            font-size: (
                1024: 26px,
                1366: 32px,
                1440: 32px,
                1680: 38px,
                1920: 42px,
            ),
        ));
    }

    .LP_H6-1 {
        // Gilroy Medium
        @include mobile-props(
            (
                font-weight: 500,
                letter-spacing: -0.6px,
                font-size: (
                    0: 15px,
                    360: 17px,
                    375: 18px,
                    414: 21px,
                ),
            )
        );
    }


    .LP_p1-1t {
        @include mobile-props(
            (
                font-weight: 400,
                letter-spacing: -0.2px,
                font-size: (
                    0: 17px,
                    375: 20px,
                ),
            )
        );
    }
    // <P> Classes
    .LP_p1-1,
    .LP_p1-2,
    .LP_p2-1,
    .LP_p2-2,
    .LP_p3-1,
    .LP_p4-1,
    .LP_p5-1,
    .LPd_p1-1,
    .LPd_p2-1,
    .LPd_p2-2 {
        @include all-props(
            (
                color: var(--color__cuttlefish),
                font-family: $gilroy,
                line-height: inherit,
            )
        );
    }

    .LP_p1-1 {
        // Gilroy Regular
        @include mobile-props(
            (
                font-weight: 400,
                letter-spacing: -0.2px,
                font-size: (
                    0: 16px,
                    375: 19px,
                ),
            )
        );
    }
    .LPd_p1-1 {
        @include desktop-props(
            (
                font-weight: 500,
                letter-spacing: -0.5px,
                font-size: (
                    1024: 19px,
                    1366: 20px,
                    1440: 20px,
                    1600: 22px,
                ),
            )
        );
    }
    .LP_p1-2 {
        // Gilroy Bold
        @include mobile-props(
            (
                font-weight: 700,
                letter-spacing: -0.5px,
                font-size: (
                    0: 16px,
                    360: 18px,
                    375: 19px,
                    414: 21px,
                ),
            )
        );
    }

    .LP_p2-1 {
        // Gilroy Regular
        @include mobile-props(
            (
                font-weight: 400,
                letter-spacing: -0.5px,
                font-size: (
                    0: 15px,
                    360: 17px,
                    375: 18px,
                    414: 20px,
                ),
            )
        );
    }
    .LPd_p2-1 {
        @include desktop-props(
            (
                font-weight: 300,
                letter-spacing: -0.5px,
                font-size: (
                    1024: 16px,
                    1366: 18px,
                    1440: 18px,
                    1680: 22px,
                    1920: 22px,
                ),
            )
        );
    }
    .LP_p2-2 {
        // Gilroy Bold
        @include mobile-props(
            (
                font-weight: 700,
                letter-spacing: -0.42px,
                font-size: (
                    0: 15px,
                    360: 17px,
                    375: 18px,
                    414: 20px,
                ),
            )
        );
    }
    .LPd_p2-2 {
        @include desktop-props(
            (
                font-weight: 600,
                letter-spacing: -0.5px,
                font-size: (
                    1024: 16px,
                    1366: 18px,
                    1440: 18px,
                    1680: 22px,
                    1920: 22px,
                ),
            )
        );
    }

    .LP_p3-1 {
        // Gilroy Medium
        @include mobile-props(
            (
                font-weight: 500,
                letter-spacing: -0.3px,
                font-size: (
                    0: 17px,
                    360: 17px,
                    375: 18px,
                    414: 19px,
                ),
            )
        );
    }
    .LP_p4-1 {
        // Gilroy Regular
        @include mobile-props(
            (
                font-weight: 400,
                letter-spacing: (
                    0: -0.3px,
                    414: -0.4px,
                ),
                font-size: (
                    0: 14px,
                    360: 15px,
                    375: 16px,
                    414: 18px,
                ),
            )
        );
    }
    .LP_p5-1 {
        // Gilroy Regular
        @include mobile-props(
            (
                font-weight: 400,
                letter-spacing: -0.32px,
                font-size: (
                    0: 14px,
                    360: 15px,
                    375: 16px,
                    414: 17px,
                ),
            )
        );
    }

    // Card text
    .LP_c2-1,
    .LP_c5-1 {
        @include all-props(
            (
                font-family: $gilroy,
                line-height: inherit,
            )
        );
    }
    .LP_c2-1 {
        @include mobile-props(
            (
                font-weight: 500,
                letter-spacing: -0.6px,
                font-size: (
                    0: 16px,
                    360: 17px,
                    375: 18px,
                    414: 20px,
                ),
            )
        );
    }
    .LP_c5-1 {
        @include mobile-props(
            (
                font-weight: 500,
                letter-spacing: -0.8px,
                font-size: (
                    0: 13px,
                    360: 14px,
                    375: 15px,
                    414: 17px,
                ),
            )
        );
    }
    .LPd_c4-1 {
        @include desktop-props(
            (
                font-weight: 400,
                letter-spacing: -0.59px,
                font-size: (
                    1024: 15px,
                    1366: 17px,
                    1440: 17px,
                    1680: 20px,
                    1920: 20px,
                ),
            )
        );
    }
    .LPd_c5-1 {
        @include desktop-props(
            (
                font-weight: 500,
                font-size: (
                    1024: 13px,
                    1366: 16px,
                    1440: 16px,
                    1680: 19px,
                    1920: 19px,
                ),
            )
        );
    }
}
