@import '~styles/variables/index';


.customization-comment {
    position: relative;
    z-index: 3;
    margin-top: 20px;
    padding: 33px 0 44px + 44px + 56px;
    background-color: var(--color__coconut);

    @media (min-width: 360px) {
        padding: 33px 0 44px + 56px + 76px;
    }
    @media (min-width: 375px) {
        padding: 39px 0 44px + 56px + 76px;
    }

    @media (min-width: 1024px) {
        margin-top: 28px;
        margin-bottom: 25px;
        padding: 0 20px 44px $v3-res960-gird-gap;
        border-bottom: 1px solid var(--color__onion);
    }
    // @media (min-width: 1024px) {
    //     margin-top: 38px;
    //     margin-bottom: 30px;
    //     padding: 0 32px 52px $v3-res1024-gird-gap;
    // }
    @media (min-width: 1280px) {
        margin-top: 52px;
        margin-bottom: 20px;
        padding: 0 25px 52px $v3-res1280-gird-gap;
    }
    @media (min-width: 1366px) {
        padding: 0 30px 52px $v3-res1366-gird-gap;
    }
    @media (min-width: 1440px) {
        margin-top: 62px;
        padding: 0 30px 52px $v3-res1440-gird-gap;
    }
    @media (min-width: 1600px) {
        margin-top: 54px;
        padding: 0 44px 52px $v3-res1600-gird-gap;
    }
    @media (min-width: 1680px) {
        margin-top: 56px;
    }
    @media (min-width: 1920px) {
        padding: 0 44px 52px $v3-res1920-gird-gap;
    }
}

.separator {
    position: absolute;
    top: 0;
    left: -24px;

    border-top: 1px solid $wild-sand;
    width: 100vw;

    @media (min-width: 360px) {
        left: -30px;
    }
}

.customization-comment__title-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media (min-width: 1024px) {
        margin-bottom: 16px;
    }

    @media (min-width: 1280px) {
        margin-bottom: 14px;
    }

    @media (min-width: 1440px) {
        margin-bottom: 10px;
    }

    @media (min-width: 1600px) {
        margin-bottom: 18px;
    }

    @media (min-width: 1920px) {
        margin-bottom: 12px;
    }
}

.customization-comment__title-text {
    display: inline-block;
    padding-top: 2%;

    font-family: $gilroy-extrabold;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.06;
    letter-spacing: -0.22px;
    color: var(--color__cuttlefish);

    @media (min-width: 360px) {
        font-size: 17px;
        line-height: 1.12;
        letter-spacing: -0.24px;
    }
    @media (min-width: 375px) {
        font-size: 18px;
        line-height: 1.11;
        letter-spacing: -0.25px;
    }

    @media (min-width: 1024px) {
        color: var(--color__cuttlefish);
        font-family: $gilroy-bold;
        font-size: 16px;
        line-height: 1.06;
        letter-spacing: -0.31px;
    }

    @media (min-width: 1280px) {
        font-size: 17px;
        line-height: 1.06;
        letter-spacing: -0.33px;
    }

    @media (min-width: 1600px) {
        font-size: 18px;
        letter-spacing: -0.35px;
    }

    @media (min-width: 1920px) {
        font-size: 20px;
        line-height: 1.05;
        letter-spacing: -0.39px;
    }
}

