@import "~global/styles/cool-media-queries/index.global.scss";

.upperTextShieldRoot {
    @include all-props((
        width: auto,
        height: 24px,
        z-index: 1,
        position: absolute,
        left: (
            0: 16px,
            375: 16px,
            1024: 17px,
            1366: 17px,
        ),
        top: (
            0: 16px,
            375: 16px,
            1024: 17px,
            1366: 17px,
        ),
    ));

    & .s {
        @include all-props((
            display: (
                0: block,
                375: none,
                1024: block,
                1366: none,
            ),
        ));
    };

    & .m {
        @include all-props((
            display: (
                0: none,
                375: block,
                1024: none,
                1366: block,
                1920: none,
            ),
        ));
    };

    & .l {
        @include all-props((
            display: (
                0: none,
                1024: none,
                1920: block,
            ),
        ))
    };
}

.upperTextShieldImg {
    @include all-props((
        width: 100%,
        height: 100%,
    ))
}
