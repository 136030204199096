@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";


.carrierCommentRoot {
    @include all-props((
        box-sizing: border-box,
        display: flex,
        flex-direction: column,
        width: 100%,
        height: 100%,
        min-height: (
            0: 47px,
            360: 51px,
            1024: 51px,
        ),
        padding: (
            0: 5px 14px 6px 14px,
            360: 8px 14px 7px 14px,
            1024: 8px 14px 7px 14px,
        ),
        background-color: var(--color__coconut),
        outline: none,
        border: 1.5px solid var(--color__grey-salt),
        border-radius: var(--sizeBorderRadiusXS),
    ));

    &.responsive {
        @include responsive-desktop-props(1536px, (
            min-height: 104px,
            padding-top: 8px,
            padding-bottom: 11px,
            padding-left: 13px,
            padding-right: 13px,
        ));
    }

    &.active {
        border-color: var(--color__egg);
    }

    &.disabled {
        border-color: var(--color__salt);
    }

    &.showShadow {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            pointer-events: none;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 30px;
            background: linear-gradient(0deg, #FFFFFF 7.01%, rgba(255, 255, 255, 0.742349) 62.22%, rgba(255, 255, 255, 0) 100%);
        }
    }

    &.trasition-default {
        justify-content: center,
    }
    textarea {
        scrollbar-width: 5px;
        @include desktop-props((
            -webkit-overflow-scrolling: touch,
        ));
    }
    textarea::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5;
    }
    textarea::-webkit-scrollbar-thumb {
        background-color: var(--color__coal);
        border-radius: 5px;
    }
    textarea::-webkit-scrollbar-track {
        background-color: #f5f5f5;
    }
}

.carrierCommentLabel {
    @include all-props((
        border: none,
        outline: none,
        font-family: $gilroy,
        font-weight: var(--font-weight__medium),
        letter-spacing: -0.2px,
        color: var(--color__hering),
        font-size: (
            0: 17px,
            375: 18px,
        ),
    ));

    @include responsive-desktop-props(1366px, (
        font-size: 12px,
        line-height: 12px,
    ));

    &.disabled {
        color: var(--color__oyster);
    }
}

/* trasition */
.carrierCommentLabel {
    transform: unset;
    &:global(.animation-active) {
        transition: padding 0.2s, height 0.2s, font-size 0.2s, transition 0.2s;
    }

    &.trasitionDefault {
        @include all-props((
            padding-bottom: 0px,
            transform: (
                0:   translateY(0px),
                375: translateY(1px),
                1024: translateY(-1px),
            ),
        ));
    }

    &.trasitionActive {
        @include all-props((
            cursor: default,
            padding-bottom: (
                0: 6px,
                375: 5px,
                1024: 5px,
            ),
            height: 10px,
            font-size: 12px,
        ));

        &.responsive {
            @include responsive-desktop-props(1536px, (
                font-size: 12px,
                height: 12px,
                padding-bottom: 5px,
            ));
        }
    }
}

.carrierCommentText {
    @include all-props((
        border: none,
        overflow: auto,
        outline: none,
        resize: none, /*remove the resize handle on the bottom right*/
        box-shadow: none,
        background: transparent,

        color: var(--color__pepper),
        font-size: (
            0: 15px,
            360: 16px,
            1024: 16px,
        ),
        line-height: 17px,
        letter-spacing: -0.2px,
        font-family: $gilroy,
        font-weight: var(--font-weight__medium),

        padding: 0,
        // padding-right: (
        //     0: 16px,
        //     375: 18px,
        //     1024: 18px,
        // ),

    ));

    &.responsive {
        @include responsive-desktop-props(1536px, (
            font-size: 16px,
            line-height: 17px,
        ));
    }

    &:disabled {
        -webkit-text-fill-color: #DBDBDB;
        color: #DBDBDB;
    }

    // &::-webkit-scrollbar {
    //     @include all-props((
    //         display: block,
    //     ));
    // }

    &.hideScrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
         &::-webkit-scrollbar { // chrome, safari, opera
            @include all-props((
                display: none,
            ));
        }
    }
}

:global {
    .text--text {
        @include all-props((
            z-index: 10000,
            font-size: (
                0: 15px,
                360: 16px,
                1024: 16px,
            ),
            line-height: 17px,
            letter-spacing: -0.2px,
            font-family: $gilroy,
            font-weight: var(--font-weight__medium),
            padding-right: (
                0: 16px,
                375: 18px,
                1024: 18px,
            ),
        ));
    }
}
