@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

/* ui-input-container common classes */

.ui-input-container {
    @include all-props((
        width: 100%,
        box-sizing: border-box,
        overflow: hidden,
        display: flex,
        justify-content: space-between,
        border: 1.5px solid var(--color__grey-salt),
        text-align: left,
        white-space: nowrap,
    ));
    @include mobile-props((
        border-radius: var(--sizeBorderRadiusXS),
    ));
    @include desktop-props((
        border-radius: 8px,
    ));

    @media (-webkit-max-device-pixel-ratio: 1.25) {
        border-width: 1px;
    }
}

/* ui-input-container height modifier classes */

.ui-input-container {
    &.large {
        @include mobile-props((
            height: (
                0: 50px,
                375: 54px,
            ),
        ));
        @include desktop-props((
            height: (
                1024: 50px,
                1366: 54px,
            ),
        ));
    }

    &.medium {
        @include mobile-props((
            height: 50px,
        ));
        @include desktop-props((
            height: 50px,
        ));
    }

    &.small {
        @include desktop-props((
            height: (
                1024: 44px,
                1366: 50px,
                1600: 54px,
            ),
        ));
    }
}

/* ui-input-container state classes */

.ui-input-container {
    &.default {
        &.colors-light {
            border-color: #EDEDED
        }
        &.colors-medium {
            border-color: #EDEDED
        }
    }
    &.validation {
        border-color: #F1F1F1,
    }
    &.active {
        border-color: #FFDB19;
    }
    &.error {
        border-color: #FF4C00;
    }
    &.confirmed {
        border-color: var(--color__grey-salt),
    }
    &.disabled {
        border-color: #EDEDED,
    }
}

/* ui-input-content common classes */

.ui-input-content  {
    @include all-props((
        box-sizing: inherit,
        display: flex,
        flex-direction: column,
        justify-content: center,
        height: 100%,
        width: 100%,

        background-color: #FFFFFF,
        border-top-left-radius: inherit,
        border-bottom-left-radius: inherit,
        outline: none,
    ));

    @include mobile-props((
        padding: 8px 0px 3px 14px,
    ));

    @include desktop-props((
        padding: (
            1024: 9px 0px 7px 14px,
        ),
    ));

    &.none {
        @include mobile-props((
            padding: 8px 14px 3px 14px,
        ));

        @include desktop-props((
            padding: (
                1024: 9px 14px 7px 14px,
            ),
        ));
    }
}

/* ui-input-label common classes */

.ui-input-label {
    @include all-props((
        cursor: pointer,
        color: var(--color__hering),
        font-family: $gilroy,
        user-select: none,
    ));
    @include mobile-props((
        font-weight: 500,
        letter-spacing: -0.2px,
        line-height: 20px,
        height: 22px,
    ));
    @include desktop-props((
        margin-top: (
            1024: 1px,
            1680: 2px,
        ),
        height: (
            1024: 14px,
            1366: 18px,
            1600: 20px,
        ),

        font-weight: 400,
        font-size: (
            1024: 14px,
            1366: 16px,
            1600: 17px,
        ),
        letter-spacing: -0.2px,
        line-height: (
            1024: 14px,
            1366: 18px,
            1600: 20px,
        ),
    ));
}

/* ui-input-label height modifier classes */

.ui-input-label {
    &.large {
        @include mobile-props((
            font-size: (
                0: 17px,
                375: 18px,
            ),
        ));
        @include desktop-props((
            font-size: (
                1024: 16px,
                1366: 17px,
            ),
        ));
    }
    &.medium {
        @include mobile-props((
            font-size: (
                0:   18px,
                375: 17px,
            ),
        ));
        @include desktop-props((
            font-size: (
                1024: 16px,
                1366: 17px,
            ),
        ));
    }
    &.small {
         @include desktop-props((
            font-size: (
                1024: 14px,
                1366: 16px,
                1600: 17px,
            ),
        ));
    }
}

/* ui-input-label state classes */

.ui-input-label {
    &.error {
        color: #FF4C00;
    }

    &.disabled {
        color: #DBDBDB
    }
}


/* ui-input-item animation classes */

.ui-input-label {
    transform: unset;
    transition: padding 0.2s, height 0.2s, font-size 0.2s, transition 0.2s;


    &.trasition-default {
        @include mobile-props((
            padding-bottom: 0px,
            transform: (
                0:   translateY(-1px),
                375: translateY(-2px),
            ),
        ));
        @include desktop-props((
            padding-bottom: 0px,
            transform: (
                1024: translateY(-1px),
                1600: translateY(-2px),
            ),
        ));

    }

    &.trasition-active {
        @include all-props((
            cursor: default,
        ));
        @include mobile-props((
            padding-bottom: (
                0: 2px,
                375: 4px,
            ),
            height: 10px,

            line-height: 12px,
            font-size: 10px,
        ));
        @include desktop-props((
            font-size: (
                1024: 10px,
                1366: 12px,
            ),
            letter-spacing: -0.2px,
            line-height: (
                1024: 9px,
                1366: 12px,
            ),
        ));
    }
    &.empty {
        @include mobile-props((
            height: 1px,
            line-height: 1px,
            padding-bottom: 0px,
            margin-bottom: -5px,
        ));
        @include desktop-props((
            height: 1px,
            line-height: 1px,
            padding-bottom: 0px,
            margin-bottom: -2px,
        ));
    }
}

/* ui-input-item common classes */
.ui-input-item {
    @include all-props((
        display: flex,
        align-items: center,
        justify-content: flex-start,
        padding: 0,
        width: 100%,
        background-color: transparent,
        border-color: transparent,
        border: none,

        color: #424242,
        font-family: $gilroy,
    ));
    @include mobile-props((
        font-weight: 600,
        line-height: 20px,
        letter-spacing: -0.2px,
    ));
    @include desktop-props((
        font-weight: 500,
        font-size: (
            1024: 14px,
            1366: 17px,
            1600: 18px,
        ),
        line-height: (
            1024: 14px,
            1366: 18px,
            1600: 20px,
        ),
        letter-spacing: -0.2px,
    ));

        // NOTE: Удаляет нативные радиусы в iOS Safari, их было не видно, но они были
        // -webkit-appearance: none; находится в normalize.scss
        -webkit-border-radius: 0px;
        border-radius: 0px;

    &:disabled {
        // NOTE: iOS Safari недостаточно иметь просто prop color, нужно капитально уточнить
        -webkit-text-fill-color: #DBDBDB;
        color: #DBDBDB;
        opacity: 1;
    }
}

/* ui-input-container height modifier classes */

.ui-input-item {
    &.large {
        @include mobile-props((
            font-size: (
                0: 17px,
                375: 18px,
            ),
        ));
    }
    &.medium {
        @include mobile-props((
            font-size: 18px,
        ));
    }
}

/* ui-input-item animation classes */

.ui-input-item {
    transition: height 0.2s;
    &:focus {
        border: none;
        outline: none;
    }

    &.trasition-default {
        height: 1px;
    }

    &.trasition-active {
        height: 100%;
    }
}

/* ui-input-item colors classes */
.ui-input-item {
    color: var(--color__pepper);

    &.colors-light {
        color: var(--color__hering);
    }
    &.colors-medium {
        color: var(--color__coal);
    }
    &.disabled {
        -webkit-text-fill-color: #DBDBDB;
        color: #DBDBDB;
    }
}

/* input-button-container common classes */

.input-button-container {
    @include all-props((
        box-sizing: border-box,
        display: flex,
        justify-content: center,
        align-items: center,
        background-color: #FFFFFF,

        // border-top-right-radius: inherit,
        // border-bottom-right-radius: inherit,
        outline: none,
    ));
    @include mobile-props((
        padding: 8px 14px 8px 14px,
    ));
    @include desktop-props((
        padding: (
            1024: 15px 14px,
        ),
    ));

    &.large {
        @include desktop-props((
            padding: (
                1024: 15px 14px,
            ),
        ));
    }
    &.submit {
        @include all-props((
            background: var(--color__grey-salt),
        ));
        @include mobile-props((
            padding: 8px 17px 8px 17px,
        ));
        @include desktop-props((
            padding: (
                1024: 15px 17px,
            ),
        ));
    }
}

/* submit type classes */

.submit {
    &.default {
        background-color: var(--color__grey-salt);
        svg path{
            fill: #d1d1d1;
        }
    }
    &.submit {
        svg path{
            fill: #d1d1d1;
        }
    }
    &.active {
        background-color: #FFDB19;
        svg path {
            fill: #9A660A;
        }
    }
    &.validation {
        background-color: #FFFFFF;
    }
    &.confirmed {
        background-color: #FFFFFF;
    }
    &.error {
        background-color: #FFFFFF;
    }
}

/* clear type sommon classe */

.clear {
    &.default {
        svg path{
            fill: transparent;
        }
    }
    &.active {

    }
}

.input-button-container.none {
    display: none;
}

/* input-icon animation script */

:global {
    @keyframes mymove {
        from {
            transform:rotate(0deg)
        }
        to {
            transform:rotate(360deg)
        }
    }
}

/* input-icon common classes */

.input-icon {
    @include all-props((
        pointer-events: none,
    ));
    &.validation {
        svg {
            animation: mymove 0.6s infinite;
        }
    }
}

/* input-icon height modifier classes */

.input-icon {
    &.large {
        @include mobile-props((
            width: (
                0: 18px,
                375: 20px,
            ),
            height: (
                0: 18px,
                375: 20px,
            ),
        ));
        $icon-sizes-large: (
            1024: 18px,
            1366: 20px,
        );
        @include desktop-props((
            width: $icon-sizes-large,
            height: $icon-sizes-large,
        ));
    }
    &.medium {
        $icon-sizes: (
            1024: 14px,
            1366: 18px,
            1600: 20px,
        );
        @include mobile-props((
            width: 20px,
            height: 20px,
        ));
        @include desktop-props((
            width: $icon-sizes,
            height: $icon-sizes,
        ));
    }
    &.small {
        $icon-sizes: (
            1024: 14px,
            1366: 18px,
            1600: 20px,
        );
        @include mobile-props((
            width: 20px,
            height: 20px,
        ));
        @include desktop-props((
            width: $icon-sizes,
            height: $icon-sizes,
        ));
    }
}


.input-icon-svg {
    &.colors-light {
        * {
            fill: #D1D1D1;
        }
    }
    &.colors-medium {
        * {
            fill: var(--color__hering);
        }
    }
    &.error {
        * {
            fill: #FF4C00;
        }
    }
    &.disabled {
        * {
            fill: #F1F1F1;
        }
    }
}
