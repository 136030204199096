@import '~styles/variables/index';

.basket__dishes {
    @include all-props((
        width: 100%,
        background-color: #FFFFFF,
    ));
    &.with-gifts {
        @include all-props((
            padding-bottom: (
                0: $offset_320,
                360: $offset_360,
                414: $offset_414,
                1024: 0,
            ),

        ));
    }
}

/* Модификатор ставит тени если корзина не Trial 5x2 c новыми стилями */

.basket-dishes--with-top-padding {
    @include mobile-props((
        padding-top: (
            0: 16px,
            375: 24px,
            1024: 24px,
        ),
    ));
}

.dishes-list__recipe-wrapper {
    @include mobile-props((
        padding: (
            0: 20px 16px 30px,
            375: 20px 20px 40px,
        ),
    ));
    @include desktop-props((
        padding: 0 20px 20px,
    ));
}

.with-smaller-padding {
    @include mobile-props((
        padding: (
            0: 20px 16px 20px,
            375: 20px 20px 20px,
        ),
    ));
    @include desktop-props((
        padding: 0 20px 20px,
    ));
}

.basket-dishes--with-shadow {
    @include mobile-props((
        box-shadow: 0 2px 10px 8px rgba(1, 1, 1, 0.02),
    ));
}

.basket__dishes-type {
    @include mobile_320 {
        display: flex;
        flex-direction: column;
        // margin-bottom: 8px;
        background: white;
        overflow: hidden;
        // border-top: 1px solid #f5f5f5;

        max-height: 100000px; // for height animation

        &:last-child {
            margin-bottom: 0;
        }

        &.exiting,
        &.exited {
            margin-bottom: 0px;
            max-height: 0;
            opacity: 0;
        }
        &.exiting {
            transition:
                max-height $closing-category-time $delete-dish-time + $closing-category-delay,
                opacity $closing-category-time $delete-dish-time + $closing-category-delay,
                margin-bottom $closing-category-time $delete-dish-time + $closing-category-delay;
            transition-timing-function: cubic-bezier(0.25 0.1 0.25 1);
        }
        &.exited {
            max-height: 0;
        }
    }

    &.preview {
        @include mobile-props((
            padding-top: (
                0:   4px,
                375: 0px,
            ),
            margin-bottom: (
                0: 64px,
                375: 74px,
            ),
        ));
    }
}

.basket-dishes__add-more {
    @include all-props((
        display: flex,
        padding: 0 20px 0,
        padding-bottom: (
            0: 16px,
            375: 24px,
            1024: 24px,
        ),
    ));

    &.trial_control {
        @include all-props((
            padding-bottom: (
                0:   8px,
                360: 16px,
                1024: 16px,
            ),
        ));
    }
}

.basket-dishes__add-more-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    &:active {
        background: #f8f8f8;
    }

    @media (min-width: 1024px) {
        height: auto;
        margin-bottom: 0;
        padding: 14px 18px 15px;
        border: solid 1px $gallery;
        border-radius: 5px;
        background: $wild-sand;
        box-shadow: 0 3px 7px 0 rgba(58, 58, 58, 0.04);

        line-height: 18px;
        color: var(--color__cuttlefish);

        :global
        .plus-group {
            stroke: var(--color__cuttlefish);
        }
    }
}

.basket-dishes__add-more-button-text {
    font-family: $gilroy-bold;
    font-size: 14px;
    letter-spacing: -0.39px;
    color: #6a6a6a;

    @media (min-width: 360px) {
        font-size: 17px;
        letter-spacing: -0.47px;
    }

    @media (min-width: 1024px) {
        margin-left: 11px;

        font-family: $gilroy-medium;
        font-size: 15px;
        letter-spacing: -0.3px;
        color: var(--color__cuttlefish);
    }
}

