@import '~styles/variables/index';


.mobile-nav__main-links {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 0 !important;
    margin-bottom: 0 !important;
}

.mobile-nav__main-item {
    @include mobile_320 {
        display: flex;
        align-items: center;
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }
        &.logout-item.is-authed {
            .mobile-nav__main-link {
                color: var(--color__oyster);
            }

            .mobile-nav__main-link__icon-container {
                filter: $filtered-oyster;
                transform: rotate(180deg);
            }
        }
    }

    @include mobile_320 {
        height: 24px;
        margin-bottom: 16px;
    }

    @include mobile_360 {
        height: 24px;
        margin-bottom: 16px;
    }

    @include mobile_375 {
        height: 28px;
        margin-bottom: 20px;
    }

    @include mobile_393 {
        height: 32px;
        margin-bottom: 24px;
    }

    @include mobile_424 {
        height: 36px;
        margin-bottom: 28px;
    }
}

.mobile-nav__main-link {
    @include mobile_320 {
        display: flex;
        align-items: center;
        height: 100%;
        border: none;
        outline: none;
        background-color: transparent;

        color: var(--color__cuttlefish);
        -webkit-tap-highlight-color: transparent;

        &:active {
            color: var(--color__oyster);
        }
    }
}

.mobile-nav__main-link__icon-container {
    @include mobile_320 {
        margin-right: 12px;
        height: 24px;
        width: 24px;
        filter: $filtered-cuttlefish;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    @include mobile_360 {
        margin-right: 12px;
        height: 24px;
        width: 24px;
    }

    @include mobile_375 {
        margin-right: 20px;
        height: 28px;
        width: 28px;
    }

    @include mobile_393 {
        margin-right: 16px;
        height: 32px;
        width: 32px;
    }

    @include mobile_424 {
        margin-right: 12px;
        height: 36px;
        width: 36px;
    }
}

.mobile-nav__bonus-label {
    @include mobile_320 {
        position: relative;
        top: 2px;
        display: inline-flex;
        align-items: center;
        background: var(--color__egg);

        margin-left: 5px;
        padding: 0px 8px;
        height: 26px;
        border-radius: 13px;

        font-family: $gilroy-bold;
        color: var(--color__choco);
        font-size: 16px;
        letter-spacing: -0.04px;

        & .num {
            position: relative;
            margin-right: 2px;
            font-family: $NHaasGroteskDisp-75Bold;
            letter-spacing: -0.36px;
        }
    }

    @include mobile_375 {
        top: 1px;
        margin-left: 5px;
        padding: 0 8px;
        height: 30px;
        border-radius: 15px;

        font-size: 19px;
        letter-spacing: -0.05px;

        & .num {
            margin-right: 2px;
            letter-spacing: -0.43px;
        }
    }

    @include mobile_393 {
        top: 2px;
        // margin-left: 10px;
        margin-left: 7px;
        padding: 0px 10px;
        height: 30px;
        border-radius: 16px;

        font-size: 20px;
        letter-spacing: -0.05px;

        & .num {
            top: 1px;
            margin-right: 2px;
            letter-spacing: -0.46px;
        }
    }

    @include mobile_424 {
        top: 2px;
        margin-left: 16px;
        padding: 0 12px;
        height: 34px;
        border-radius: 17px;

        font-size: 21px;
        letter-spacing: -0.6px;

        & .num {
            top: 0px;
            margin-right: 2px;
            letter-spacing: -0.48px;
        }
    }
}
