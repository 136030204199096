.blur {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    &.open {
        height: auto;
        min-height: 0;
        animation: blurring 600ms alternate;
        filter: blur(25px);
    }

    @media (min-width: 1024px) {
        min-height: 100vh;
    }
}

@keyframes blurring {
    from {
        filter: blur(0px);
    }
    to {
        filter: blur(25px);
    }
}
