:root {
    --font-weight__ultralight: 200;
    --font-weight__light: 300;
    --font-weight__regular: 400;
    --font-weight__medium: 500;
    --font-weight__semibold: 600;
    --font-weight__bold: 700;
    --font-weight__extrabold: 800;
    --font-weight__black: 900;
}

@media (min-width: 1024px) {
    :root {
        --font-weight__ultralight: 200;
        --font-weight__light: 200;
        --font-weight__regular: 300;
        --font-weight__medium: 400;
        --font-weight__semibold: 500;
        --font-weight__bold: 600;
        --font-weight__extrabold: 700;
        --font-weight__black: 800;
    }
}
