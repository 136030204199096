@import '~styles/variables/index';


$huge-number: 600px;
$item-transition-timeout: 100ms;
$dropdown-transition-timeout: 600ms;

$dropdown-list-margin-left__320 : 40px;
$dropdown-list-margin-left__375 : 48px;
$dropdown-list-margin-left__393 : 44px;
$dropdown-list-margin-left__424 : 52px;


.mobile-nav__ordinary-links {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 0 !important;
    margin-bottom: 0 !important;
}

.mobile-nav__ordinary-item {
    @include mobile_320 {
        position: relative;
        outline: none;
        margin: 0 0 16px;
        padding: 0;
        overflow: visible;
        background: transparent;
        border: none;
    }
    @include mobile_375 {
        margin-bottom: 20px;
    }
    @include mobile_393 {
        margin-bottom: 24px;
    }
    @include mobile_424 {
        margin-bottom: 28px;
    }
}

.mobile-nav__ordinary-link {
    width: 100%;
    @include mobile_320 {
        outline: none;
        margin: 0;
        padding: 0;
        overflow: visible;
        background: transparent;
        border: none;

        color: var(--color__cuttlefish);
        -webkit-tap-highlight-color: transparent;

        &:active {
            color: var(--color__oyster);
        }

        &.button {
            display: flex;
            align-items: center;
            height: 100%;
        }

        &.inconspicuous {
            color: var(--color__oyster);
        }
    }
}

.mobile-nav__ordinary-link__content-container {
    @include mobile_320 {
        position: relative;

        &.with-badge {
            padding-left: $dropdown-list-margin-left__320;
        }
    }
    @include mobile_375 {
        &.with-badge {
            padding-left: $dropdown-list-margin-left__375;
        }
    }
    @include mobile_393 {
        &.with-badge {
            padding-left: $dropdown-list-margin-left__393;
        }
    }
    @include mobile_424 {
        &.with-badge {
            padding-left: $dropdown-list-margin-left__424;
        }
    }
}

.mobile-nav__badge-container {
    border-radius: var(--sizeBorderRadiusXXXS);
    &.star {
        background-color: #2600d6;
    }
    &.persent {
        background-color: #FFDB19;
    }
    @include mobile_320 {
        display: inline-block;
        position: absolute;
        left: 0;

        top: -6px;
        height: 30px;
        width: 30px;

        & > svg {
            height: 100%;
            width: 100%;
        }
    }

    // @include mobile_375 {
    //     height: 34px;
    //     width: 34px;
    // }
    @include mobile_414 {
        top: -9px;
        height: 38px;
        width: 38px;
    }
}

.mobile-nav__dropdown-list-container {
    @include mobile_320 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

.mobile-nav__dropdown-arrow-container {
    @include mobile_320 {
        position: absolute;

        transition: transform $dropdown-transition-timeout;

        &.expanded {
            transform: rotate(90deg) scale(0.7);
        }

        top: 3px;
        right: -16px;
        transform: scale(0.7);
    }
    @include mobile_375 {
        top: 4px;
        right: -17px;
        transform: scale(1);

        &.expanded {
            transform: rotate(90deg) scale(1);
        }
    }
    @include mobile_393 {
        top: 5px;
        right: -18px;
        transform: scale(1);
    }
    @include mobile_424 {
        right: -19px;
        top: 6px;
        transform: scale(1.2);
    }
}


.mobile-nav__dropdown-list {
    list-style: none;
    padding: 0;
    margin: 0;

    padding-left: 0 !important;
    margin-bottom: 0 !important;
}

.mobile-nav__dropdown-list-container .mobile-nav__dropdown-list {
    @include mobile_320 {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        margin-left: $dropdown-list-margin-left__320;

        transition:
            max-height $dropdown-transition-timeout,
            opacity $dropdown-transition-timeout;

        &.expanded {
            max-height: $huge-number;
            opacity: 1;
        }
    }
    @include mobile_375 {
        margin-left: $dropdown-list-margin-left__375;
    }
    @include mobile_393 {
        margin-left: $dropdown-list-margin-left__393;
    }
    @include mobile_424 {
        margin-left: $dropdown-list-margin-left__424;
    }
}


.burgerMenuV2LinkItem {
    @include mobile-props((
        color: var(--color__coal),
        font-family: $gilroy,
        font-size: (
            0: 16px,
            375: 17px,
        ),
        font-weight: 500,
        line-height: (
            0: 17px,
            375: 18px,
        ),
        letter-spacing: -0.2px,
        overflow: hidden,
        white-space: nowrap,
        text-overflow: ellipsis,
    ));
}
