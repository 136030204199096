@import '~styles/variables/index';
@import '~styles/modules/ruble';


.feature {
    &.theme-yellow {
        .feature__title {
            color: $verdun-green;
        }
        .feature__content {
            color: $olive;
        }
    }

    &.size-big {
        @media (min-width: 414px) {
            .feature__title {
                font-size: 23px;
                line-height: 24px;
                margin-bottom: 6px;
            }

            .feature__content {
                font-size: 17px;
                line-height: 22px;
            }
        }
    }
}

.feature__title {
    margin-bottom: 5px;
    font-family: $gilroy-extrabold;
    font-size: 17px;
    letter-spacing: -0.21px;
    color: var(--color__cuttlefish);

    &-price {
        @extend %ruble;
        font-family: $NHaasGroteskTXPro-75Bd;
    }

    @media (min-width: 360px) {
        margin-bottom: 2px;
        font-size: 20px;
        letter-spacing: -0.25px;
    }

    @media (min-width: 375px) {
        margin-bottom: 2px;
        font-size: 21px;
        letter-spacing: -0.26px;
    }

    @media (min-width: 1024px) {
        font-family: $gilroy-semibold;
        margin-bottom: 2px;
        font-size: 17px;
        letter-spacing: -0.18px;
    }

    @media (min-width: 1366px) {
        margin-bottom: 4px;
        font-size: 18px;
        letter-spacing: -0.19px;
    }
}

.feature__content {
    font-family: $gilroy;
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: -0.22px;
    color: var(--color__cuttlefish);

    @media (min-width: 360px) {
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: -0.27px;
    }

    @media (min-width: 1024px) {
        font-family: $gilroy-light;
        font-size: 14px;
        letter-spacing: -0.24px;
        line-height: 1.29;
    }

    @media (min-width: 1366px) {
        font-size: 15px;
        letter-spacing: -0.25px;
        line-height: 1.27;
    }
}

.feature__content-number {
    font-family: $NHaasGroteskTXStd-65Md;
    letter-spacing: -0.19px;

    @media (min-width: 360px) {
        letter-spacing: inherit;
    }

    &.regular {
        font-family: $NHaasGroteskTXStd-55Rg;
    }
}

.feature__content-main {
    font-family: $gilroy-bold;

    @media (min-width: 1024px) {
        font-family: $gilroy-semibold;
    }
}

.feature__content-link {
    position: relative;

    font: inherit;
    color: inherit;

    .dots {
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
    }
}

.feature__content-mobile {
    display: inline;
    font: inherit;

    @media (min-width: 1024px) {
        display: none;
    }
}
