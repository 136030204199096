@import '~styles/variables/index';

.basket__dishes-type-item-title {
    overflow-wrap: break-word;

    @include all-props((
        font-family: $gilroy,
        font-weight: 500,
        font-size: (
            0  : 16px,
            375: 17px,
            393: 18px,
            1024: 18px,
        ),
        letter-spacing: -0.16px,
        line-height: 1,
        color: var(--color__cuttlefish),

        width: (
            0:   140px,
            375: 172px,
            393: 192px,
            1024: 100%,
        ),

        overflow: hidden,
        display: -webkit-box,
        -webkit-box-orient: vertical,
        -webkit-line-clamp: 3,
        text-overflow: ellipsis,
        box-sizing: border-box,
    ));

    @include mobile-props((
        overflow: hidden,
        display: -webkit-box,
        -webkit-box-orient: vertical,
        -webkit-line-clamp: 3,
        text-overflow: ellipsis,
        box-sizing: border-box,
    ));


    &.wide {
        @include all-props((
            width: (
                0: 90%,
                1024: 75%,
            ),
        ));
    }
}

.clamped-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
