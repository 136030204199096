@import '~styles/variables/index';
@import '~global/uikit/index.scss';

.easterboxDetailsRoot {
    @include mobile-props((
        font-family: $gilroy,
        padding: (
            0: 0 16px,
            375: 0 20px,
        ),
        color: #f88e93,
    ));
}

.easterboxDishTitle {
    @include mobile-props((
        font-size: (
            0: 17px,
            375: 18px,
        ),
        font-weight: 500px,
        font-family: $gilroy,
        color: var(--color__cuttlefish),
        margin-bottom: (
            0: 14px,
            375: 18px,
        )
    ))
}

.easterboxGiftsSetList {

}

.easterboxSetList {
    font-style: normal;
    font-weight: 400;
    color: #F29096;

    @include all-props((
        font-family: $gilroy,
        height: auto,
    ));

    @include mobile-props((
        margin-bottom: (
            0: 60px,
            375: 60px,
        ),
    ));
}

.mainCard {
    @include mobile-props((
        margin-bottom: (
            0: 40px,
            375: 50px,
        )
    ));
    @include all-props((
        margin-bottom: (
            1024: 32px,
            1366: 42px,
            1600: 50px,
        )
    ));
}

.mainCardTitle {
    @include all-props((
        font-family: $gilroy,
        margin-bottom: 10px,
    ));
    @include mobile-props((
        font-weight: 600,
        font-size: (
            0: 30px,
            375: 36px,
        ),
    ));
}

.cardContentMobile {
    @include all-props((
        display: (
            1024: none,
        )
    ))
}

.descriptionCardImage,
.descriptionCardImageMobile {
    @include mobile-props((
        aspect-ratio: 1,
        margin-bottom: (
            0: 8px,
            375: 10px,
        ),
        width: 100%,
        border-radius: (
            0: 30px,
            375: 40px,
        ),
        overflow: hidden,
    ));
}

.descriptionCardImageMobile {
    @include mobile-props((
        object-fit: cover,
        object-position: center,
    ));
}

.cardContentTitle {
    @include mobile-props((
        font-size: (
            0: 19px,
            375: 22px,
        ),
        line-height: (
            0: 20px,
            375: 23px,
        ),
        font-weight: 700,
        letter-spacing: -0.5px,
        margin-bottom: (
            0: 6px,
            375: 8px,
        ),
        color: #E78697,
    ));
}

.cardContentList {
    @include mobile-props((
        font-size: (
            0: 16px,
            375: 19px
        ),
        line-height: (
            0: 17px,
            375: 20px,
        ),
        letter-spacing: -0.5px,
        font-weight: 400,
        padding-left: (
            0: 18px,
            375: 21px,
        ),
        color: #E78697,
        margin: 0,
    ));

    p {
        @include mobile-props((
            margin-left: (
                0: -16px,
                375: -20px,
            ),
            margin-bottom: (
                0: 6px,
                375: 6px,
            )
        ))
    }
}

.cardContent {
    list-style-type: disc !important;
}
.cardContentTitle {
    @include all-props((
        font-family: $gilroy,
    ));
    @include mobile-props((
        font-size: (
            0: 19px,
            375: 22px,
        ),
        line-height: (
            0: 20px,
            375: 23px,
        ),
        font-weight: 700,
    ));
}

.cardContentList {
    li:last-child {
        margin-bottom: 0px,
    }
}

.cardContentLiBefore {
    background-color: var(--color__pink);
}

.cardsList {
    @include all-props((
        display: flex,
        flex-direction: (
            0: column,
            1024: row,
        ),
        gap: (
            0: 0px,
            1024: 30px,
            1366: 37px,
            1600: 50px,
        )
    ));

    @include responsive-mobile-props(320px, (
        gap: 30px,
    ));
}

.secondaryCard {
    @include all-props((
        width: 100%,
    ));
}

.cardTitle {
    @include mobile-props((
        font-style: normal,
        font-weight: 700,
    ));
    @include responsive-mobile-props(320px, (
        font-size: 19px,
        line-height: 20px,
        margin-bottom: 8px,
    ))
}
.secondaryCardImage {
    width: 100%;
    aspect-ratio: 2/1;
    object-fit: cover;
    object-position: center;
    @include all-props((
        margin-bottom: 10px,
    ));
    @include responsive-mobile-props(320px, (
        border-radius: 20px,
    ));
}

.description,
.descriptionListLi {
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.5px;
    @include mobile-props((
        font-size: 19px,
        line-height: 20px,
    ));
}
.descriptionList {
    list-style-type: disc !important;
    padding-left: 18px;
}
.descriptionListLiBefore {
    background-color: var(--color__pink);
}

.secondaryCardDesktop {
    @include mobile-props((
        display: none,
    ));
    @include all-props((
        overflow: hidden,
        background-color: var(--color__light_pink),
    ));
}
