@import '~styles/variables/index';

.loading-basket {
    position: absolute;
    background: var(--color__coconut);
    border: solid 1px var(--color__onion);
    border-radius: 5px;
    box-shadow: 0 2px 10px 8px rgba(1, 1, 1, 0.02);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    transition: all 600ms ease;
    &.entering {
        top: 100px;
        opacity: 0;
    }
    &.entered {
        top: 0;
        opacity: 1;
    }
    &.exited {
        display: none;
    }
}

.loading-basket-title {
    font-family: $gilroy;
    font-weight: 700;
    font-size: 17px;
    line-height: 1.08;
    letter-spacing: -0.2px;
    text-align: center;
    color: var(--color__cuttlefish);
    margin-bottom: 2px;
}
