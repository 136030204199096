@import '~styles/variables/index';


.header-tags__root {
    *, *::after, *::before {
        box-sizing: border-box;
    }
}

.header-tags__root {
    @include mobile-props((
        display: flex,
        width: 100%,
        background: var(--color__coconut),

        transition-propterty: opacity,
        transition-duration: 200ms,
        transition-delay: 300ms,
    ));
}

.header-tags__list-container {
    @include mobile_320 {
        position: relative;
        overflow-y: scroll;
        scrollbar-width: none; // ff

        &::-webkit-scrollbar { // chrome, safari, opera
            display: none;
        }
    }
}

.header-tags__list {
    @include mobile-props((
        list-style: none,
        display: flex,
        align-items: center,
        width: fit-content,

        margin: 0,
        padding: (
            0:   0 16px,
            375: 0 20px,
        ),
    ));
}

.header-tags__list-item {
    $horizontal-margins: (
        0:   6px,
        375: 8px,
    );

    @include mobile-props((
        position: relative,
        display: inline-block,
    ));

    &.hidden {
        @include mobile-props((
            display: none,
            margin: 0,
        ));
    }

    &.city-select-tag {
        @include mobile-props((
            margin-right: $horizontal-margins,
        ));
    }

    &.delivery-tag {
        @include mobile-props((
            margin-right: $horizontal-margins,
        ));
    }
    &.simple-tag {
        & + & {
            @include mobile-props((
                margin-left: $horizontal-margins,
            ));
        }
    }
}

.header-tags__list-item-scrolling-area {
    @include mobile_320 {
        position: absolute;
        left: -16px;
        right: -16px;
        height: 1px;
        pointer-events: none;
    }

    @include mobile_375 {
        left: -20px;
        right: -20px;
    }

    @include mobile_424 {
        left: -24px;
        right: -24px;
    }
}


.headerTagsWrapper {
    @include mobile-props((
        display: flex,
        gap: 9px,
        padding: (
            0:   0 16px,
            375: 0 20px,
        ),
    ));
}
