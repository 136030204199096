@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

$burger-transition-step: 300ms;

:global {
@keyframes open-rect-1 {
    from { transform: translate3d(0, 0px, 0) rotate(0deg); }
    to   { transform: translate3d(10px, 0px, 0) rotate(45deg); }
};
@keyframes open-rect-2 {
    from { opacity: 1; }
    to   { opacity: 0; }
};
@keyframes open-rect-3 {
    from { transform: translate3d(0, 0px, 0) rotate(0deg); }
    to   { transform: translate3d(-13px, 12px, 0) rotate(-45deg); }
}

@keyframes close-rect-1 {
    from { transform: translate3d(10px, 0px, 0) rotate(45deg); }
    to   { transform: translate3d(0, 0px, 0) rotate(0deg); }
};
@keyframes close-rect-2 {
    from { opacity: 0; }
    to   { opacity: 1; }
};
@keyframes close-rect-3 {
    from { transform: translate3d(-13px, 12px, 0) rotate(-45deg); }
    to   { transform: translate3d(0, 0px, 0) rotate(0deg); }
}
}


.v2_mobileHeaderRoot {
    @include mobile-props((
        background-color: #fff,
        padding: (
            0: 8px 16px,
            375: 9px 20px,
        ),

        display: flex,
        justify-content: space-between,
        gap: (
            0: 8px,
            375: 10px,
        ),
        box-sizing: border-box,
        height: (
            320: 46px,
            375: 61px,
        ),
        position: relative,
        max-width: 444px,
        margin: 0 auto,
    ));
}

.burgerButtonContainer {
    @include mobile-props((
        position: relative,
        width: (
            0: 24px,
            375: 32px,
        ),
        transform: (
            375: translate(3px, -2px),
        ),
    ))
}

.burgerButton {
    @include mobile-props((
        position: absolute,
        z-index: 3,
        top: 10%,
        left: 0,
        bottom: 0,
        right: 20%,
        // display: flex,
        // align-items: center,
        // justify-content: center,

        // box-sizing: content-box,
        // height: 100%,
        // border: none,
        // padding: (
        //     0:   0 14px,
        //     360: 0 16px,
        //     390: 0 20px,
        // ),
        // width: (
        //     0:   24px,
        //     360: 26px,
        //     375: 28px,
        //     414: 32px,
        // ),
    ));

    & svg {
        $button-sizes: (
            0:   24px,
            375: 28px,
        );

        @include mobile-props((
            position: relative,
            display: inline-block,
            overflow: visible,

            height: $button-sizes,
            width: $button-sizes,
        ));

        path {
            @include mobile-props((
                fill: var(--color__grey-salt),
            ));
        }
    }

    &.isOpen {
        svg {
            path:nth-child(1) {
                @include mobile-props((
                    animation: open-rect-1 0.3s forwards,
                ));
            }
            path:nth-child(3) {
                @include mobile-props((
                    animation: open-rect-2 0.3s forwards,
                ));
            }
            path:nth-child(2) {
                @include mobile-props((
                    animation: open-rect-3 0.3s forwards,
                ));
            }
        }
    }

    &.isClose {
        svg {
            path:nth-child(1) {
                @include mobile-props((
                    animation: close-rect-1 0.3s forwards,
                ));
            }
            path:nth-child(3) {
                @include mobile-props((
                    animation: close-rect-2 0.3s forwards,
                ));
            }
            path:nth-child(2) {
                @include mobile-props((
                    animation: close-rect-3 0.3s forwards,
                ));
            }
        }
    }
}

//

.cityAndDateButtonRoot {
    flex: 1;
    background-color: #fff;
}

.cityAndDateContent {
    @include mobile-props((
        display: flex,
        flex-direction: column,
        align-items: center,
    ))
}

.cityAndTitle {
    @include mobile-props((
        display: flex,
        align-items: center,
    ));
}

.cityAndTitleText {
    @include mobile-props((
        color: var(--color__mushrooms),
        font-family: $gilroy,
        font-size: (
            0: 11px,
            375: 12px,
        ),
        font-style: normal,
        font-weight: 500,
        line-height: (
            0: 14px,
            375: 16px,
        ),
        letter-spacing: -0.2px,
    ))
}

.cityAndTitleIcon {
    @include mobile-props((
        width: (
            0: 8px,
            375: 10px,
        ),
        height: (
            0: 8px,
            375: 10px,
        ),
        padding-bottom: (
            0: 4px,
            375: 0px,
        ),
    ));
}

.cityAndTitleLocation {
    @include mobile-props((
        color: var(--color__pepper),
        // text-align: center,
        font-family: $gilroy,
        font-size: (
            0: 15px,
            375: 16px,
        ),
        font-weight: 500,
        line-height: (
            0: 16px,
            375: 18px,
        ),
        letter-spacing: (
            0: -0.4px,
            375: -0.2px,
        ),
    ));
}

.cityAndTitleLocatoinWhen {}

.cityAndTitleLocatonTo {}

// _---


.filtersButtonRoot {
    @include mobile-props((
        width: (
            0: 24px,
            375: 28px,
        ),
        height: (
            0: 24px,
            375: 28px,
        ),
    ));
}
.filtersButtonIcon {
    @include mobile-props((
        height: 100%,
        padding-top: 10%,
    ));
}
