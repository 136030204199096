@import '~styles/variables/index';


@keyframes loading-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.loading {
    width: 18px;
    height: 18px;
    opacity: 0.69;
    animation: loading-rotate 1s ease-in-out infinite;
}


.filters-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color__coconut);
    min-height: 48px;
    z-index: 4;
    position: relative;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.04);

    &.customization-comment-header {
        z-index: 12;
        box-shadow: none;
    }
}

.filters-header__button {
    min-height: 100%;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: var(--color__coconut);
    border: none;
    padding: 0 23px;
    color: var(--color__pepper);

    &:disabled {
        color: var(--color__oyster);
    }

    &.reset {
        margin-top: 4px;
        svg {
            width: 15px;
            height: 13px;
        }
        path {
            fill: var(--color__pepper);
            stroke: var(--color__pepper);
        }
        &:disabled {
            path {
                fill: var(--color__oyster);
                stroke: var(--color__oyster);
            }
        }
    }

    &.close {
        svg {
            width: 16px;
            height: 16px;
        }
    }

    &.not-display {
        opacity: 0;
        pointer-events: none;
    }
}

.filters-header__button-text {
    margin-left: 6px;
    font-family: $gilroy-bold;
    font-size: 11px;
    letter-spacing: 0.09px;
    color: inherit;
    text-transform: uppercase;
}

.filters-container {
    background: var(--color__coconut);
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 3000;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &.blur {
        animation: blurring 600ms alternate;
        filter: blur(25px);
    }

    &.without-comment {
        padding-bottom: 100px;
    }
}


@keyframes blurring {
    from {
        filter: blur(0px);
    }
    to {
        filter: blur(25px);
    }
}

.filters-scroll {
    position: relative;
    padding: 44px 24px 1px;
    margin-bottom: 20px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    transition: all 300ms ease;
}

.filters {
    max-width: 424px;
    margin: 0 auto;
}

.list {
    display: flex;
    flex-flow: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}

.filter-tag__list-item {
    @include mobile-props((
        position: relative,
        display: inline-block,
        margin: (
            0:   6px 6px 0 0,
            375: 8px 8px 0 0,
        ),
    ));

    &.hidden {
        @include mobile-props((
            display: none,
            margin: 0,
        ));
    }
}

.item__name {
    font-family: $gilroy-medium;
    font-size: 13px;
    letter-spacing: -0.18px;
    color: var(--color__pepper);
    margin-right: 7px;
}

.item__count {
    font-family: $NHaasGroteskDSStd-55Rg;
    font-size: 12px;
    letter-spacing: -0.3px;
    color: var(--color__pepper);
    margin-top: 1px;
}

.item__content {
    display: flex;
}

@media (min-width: 360px) {
    .filters-header{
        min-height: 60px;
    }
    .filters-header__button {
        width: 138px;
        &.reset {
            margin-top: 0;
            padding-left: 31px;
            svg {
                width: 17px;
                height: 15px;
            }
        }
        &.close {
            svg {
                width: 18px;
                height: 18px;
            }
        }
    }
    .filters-header__button-text {
        margin-left: 3px;
        font-size: 13px;
        letter-spacing: -0.13px;
    }


    .filters-scroll {
        padding: 56px 30px 1px;
    }
    .item {
        padding: 6px 13px 7px;
    }
    .item__name {
        font-size: 15px;
    }
    .item__count {
        font-size: 14px;
    }
}

@media (min-width: 375px) {
    .filters-header__button {
        padding: 0 21px;
    }
}

@media (min-width: 414px) {
    .filters-header__button {
        width: 147px;
        padding: 0 30px;
    }
    .item {
        padding: 6px 15px 6px;
    }
    .item__name {
        font-size: 15px;
    }
    .item__count {
        font-size: 14px;
    }
}
