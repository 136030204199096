@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

.basket-overlay-wrapper {
    @include all-props((
        height: 100%,
    ));
}

.basket {
    @include all-props((
        position: (
            0: relative,
            1024: fixed,
        ),
        right: 0,
        top: 0,
        bottom: 0,
        z-index: 1999,
        box-sizing: border-box,
        height: 100%,
        width: (
            0:    100%,
            1024:  470px,
        ),
        background-color: var(--color__coconut),
        display: flex,
        flex-direction: column,

        overflow-y: (
            0: scroll,
            1024: visible,
        ),
    ));

    @include desktop-props((
        box-shadow: -5px 4px 18px rgba(0, 0, 0, 0.03),
    ));

    // @include mobile-props((

    // ));

    @include all-props((
        overflow-y: scroll,
        -webkit-overflow-scrolling: touch,
        max-width: 444px,
        margin: 0 auto,
    ));

    &.entered {
        @include all-props((
            position: (
                0:   relative,
                1024: fixed,
            ),

            padding-top: (
                // 0:   $header-height_320,
                // 360: $header-height_360,
                // 375: $header-height_375,
                // 414: $header-height_414,
                1024: 0,
            ),
            transform: (
                1024: translateZ(0),
            ),
        ));

        .basket-header {
            @include all-props((
                position: (
                    0:   fixed,
                    1024: static,
                ),
                top: 0,
                z-index: 7,
                width: 100%,
                transform: translateZ(2px),
            ));
            &.basket-header--static {
                @include mobile-props((
                    position: absolute,
                    box-shadow: 0px 2px 28px rgba(0, 0, 0, 0.05),
                ));
            }
        }

        .basket-footer {
            @include all-props((
                position: (
                    0:   sticky,
                    1024: static,
                ),
                width: 100%,
                bottom: 0,
                z-index: 10000,
            ));

            &.basket-footer--static {
                @include mobile-props((
                    position: static,
                ));
            }
        }
    }

    &.without-header {
        @include mobile-props((
            padding-top: 0,
        ));
    }

    .basket-preview__content-wrapper {
        @include mobile-props((
            box-sizing: border-box,
            width: 100%,
            max-width: 444px,
            overflow: hidden,
            position: (
                444: absolute,
            ),
            left: (
                444: 50%,
            ),
            transform: (
                444: translateX(-50%),
            ),
        ))
    }
}

/* HIDE SROLLBAR */
.basket {
    -ms-overflow-style: none;
    scrollbar-width: none;
     &::-webkit-scrollbar { // chrome, safari, opera
        @include all-props((
            display: none,
        ));
    }
}
