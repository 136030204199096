@import "~global/styles/cool-media-queries/index.global.scss";

.visualShieldRoot {
    @include all-props((
        height: (
            0: 78px,
            375: 98px,
            1024: 78px,
            1366: 98px,
            1920: 104px,
        ),
        z-index: 1,
        position: absolute,
        left: (
            0: 4px,
            375: 6px,
            1024: 4px,
            1366: 6px,
        ),
        bottom: (
            0: 4px,
            375: 6px,
            1024: 4px,
            1366: 6px,
        ),
    ));
}

.visualShieldImg {
    height: inherit
}
