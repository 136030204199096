@import '~styles/variables/index';


.root {
    box-sizing: border-box;

    *, *::after, *::before {
        box-sizing: border-box;
    }
}

.root {
    width: 100%;
}

.inputs-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comment-form-open-button-container {
    @include all-props((
        position: relative,
        display: flex,
        align-items: center,
        width: 100%,
        background: var(--color__coconut),
        overflow: hidden,

        height: (
            0:    $toggle-comment-button__height_320,
            375:  $toggle-comment-button__height_375,
            414:  $toggle-comment-button__height_414,
            1024:  $toggle-comment-button__height_1024,
        ),
    ));
}

.fake-input {
    @include all-props((
        width: 100%,
        padding: (
            0:   0 14px,
            360: 0 16px,
            375: 0 15px,
            1024: 0 15px,
        ),
        overflow: hidden,
        white-space: nowrap,
        text-overflow: ellipsis,

        font-family: $gilroy,
        font-weight: (
            0: 500,
            1024: 400,
        ),
        font-size: (
            0:   16px,
            375: 17px,
            414: 18px,
            1024: 18px,
        ),
        line-height: 1,
        letter-spacing: (
            0:   -0.2px,
            360: -0.4px,
            1024: -0.4px,
        ),
        color: #b0b0b0,
    ));

    &.comment {
        color: var(--color__cuttlefish);
    }
}

.comment-form-open-button {
    @include all-props((
        position: absolute,
        top: 0,
        z-index: 6,

        width: 100%,
        height: 100%,
        outline: none,
        opacity: 1,
        border-radius: (
            0: var(--sizeBorderRadiusS),
            375: var(--sizeBorderRadiusXS),
        ),
        border: solid 1px var(--color__salt),
        background: transparent,
    ));
}

.tooltip-button {
    @include all-props((
        flex: none,

        margin-left: (
            0:    8px,
            375:  10px,
            1024:  11px,
        ),
        height: (
            0:    27px,
            360:  28px,
            375:  30px,
            414:  34px,
            1024:  32px,
        ),
        width: (
            0:    27px,
            360:  28px,
            375:  30px,
            414:  34px,
            1024:  32px,
        ),
    ));
}

.tooltip-icon {
    position: relative;
    height: 100%;
    width: 100%;

    circle {
        stroke: var(--color__salt);
    }
    path:nth-of-type(1) {
        stroke: var(--color__salt);
        fill: var(--color__salt);
    }
    path:last-child {
        fill: var(--color__salt);
    }

    &.active {
        circle {
            stroke: var(--color__oyster);
        }
        path:nth-of-type(1) {
            stroke: var(--color__oyster);
            fill: var(--color__oyster);
        }
        path:last-child {
            fill: var(--color__oyster);
        }
    }
}

.tooltip-content-container {
    @include mobile-props((
        border-radius: var(--sizeBorderRadiusM),
    ));
    position: absolute;
    z-index: 3;
    top: 49px;
    left: 0;
    width: 100%;
    padding: 21px 23px 25px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.02);
    background-color: #ffeb84;
    opacity: 0;
    pointer-events: none;

    transition: opacity 300ms;

    &::before {
        content: '';

        position: absolute;
        right: 8px;
        top: -6px;

        width: 17px;
        height: 17px;
        transform: rotate(45deg);
        border-radius: 2px;
        background-color: #ffeb84;
    }

    &.top {
        top: initial;
        bottom: 49px;

        &::before {
            top: initial;
            bottom: -6px;
        }
    }

    &.active {
        opacity: 1;
        pointer-events: auto;
        transition: opacity 300ms;
    }

    @media (min-width: 360px) {
        top: 60px;
        padding: 28px 23px 25px 24px;
        width: 99.8%;

        &.top {
            bottom: 60px;
        }
    }
    @media (min-width: 375px) {
        padding: 29px 23px 25px 32px;
        width: 99.5%;
    }
    @media (min-width: 414px) {
        padding: 29px 23px 32px 32px;
        width: 99.3%;
    }

    @media (min-width: 1024px) {
        top: 56px;
        left: initial;
        right: -18px;
        padding: 24px 50px 22px 28px;
        width: 309px;
        border-radius: 4px;

        &::before {
            top: -10px;
            right: 19px;
            height: 30px;
            width: 30px;
            transform: rotate(45deg) skew(-10deg, -10deg);
        }
    }
    @media (min-width: 1024px) {
        padding: 24px 32px 22px 32px;
        width: 294px;
    }
    @media (min-width: 1280px) {
        right: -17px;
        padding: 24px 28px 22px 28px;
        width: 309px;

        &::before {
            top: -9px;
            right: 17px;
        }
    }
    @media (min-width: 1366px) {
        &::before {
            right: 16px;
        }
    }
}

.tooltip-content-title {
    margin-bottom: 6px;

    font-family: $gilroy-extrabold;
    font-size: 15px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.20;
    letter-spacing: -0.19px;
    color: #675700;

    @media (min-width: 360px) {
        margin-bottom: 9px;

        font-size: 17px;
        line-height: 1.18;
        letter-spacing: -0.21px;
    }
    @media (min-width: 414px) {
        margin-bottom: 9px;

        font-size: 18px;
        line-height: 1.11;
        letter-spacing: -0.22px;
    }

    @media (min-width: 1024px) {
        margin-bottom: 7px;

        font-family: $gilroy-semibold;
        font-size: 17px;
        line-height: 1.12;
        letter-spacing: -0.18px;
    }
    @media (min-width: 1280px) {
        font-size: 18px;
        line-height: 1.06;
        letter-spacing: -0.19px;
    }
    @media (min-width: 1600px) {
        font-size: 17px;
        line-height: 1.06;
        letter-spacing: -0.18px;
    }
    @media (min-width: 1680px) {
        font-size: 18px;
        line-height: 1.11;
        letter-spacing: -0.19px;
    }
}

.tooltip-content-text {
    font-family: $gilroy-medium;
    font-size: 13px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.24;
    letter-spacing: -0.22px;
    color: $olive;

    & + & {
        margin-top: 5px;
    }

    @media (min-width: 360px) {
        font-size: 15px;
        line-height: 1.2;
        letter-spacing: -0.25px;

        & + & {
            margin-top: 7px;
        }
    }
    @media (min-width: 414px) {
        font-size: 16px;
        line-height: 1.13;
        letter-spacing: -0.27px;
    }

    @media (min-width: 1024px) {
        font-size: 14px;
        line-height: 1.29;
        letter-spacing: -0.24px;
    }
    @media (min-width: 1280px) {
        font-size: 15px;
        line-height: 1.27;
        letter-spacing: -0.25px;
    }
}

.subtitle-container {
    @include all-props((
        display: flex,
        flex-direction: column,
        padding-bottom: (
            0:   2px,
            375: 0,
            1024: 8px,
        ),
    ));
}

.notification-text {
    @include all-props((
        padding-top: (
            0:   4px,
            414: 8px,
            1024: 14px,
        ),

        font-family: $gilroy,
        font-weight: (
            0:   400,
            1024: 300,
        ),
        font-size: (
            0:    12px,
            375:  13px,
            414:  14px,
            1024:  12px,
            1280: 13px,
            1920: 14px,
        ),
        line-height: normal,
        letter-spacing: (
            0:   -0.44px,
            1024: -0.1px,
        ),
        color: var(--color__hering),
    ));

    &.filters {
        @include desktop-props((
            font-size: (
                1280: 12px,
                1366: 13px,
                1920: 14,
            ),
        ));
    }
}

.success {
    @extend .notification-text;

    @include all-props((
        display: inline-block,
        padding-top: (
            0:   8px,
            414: 12px,
            1024: 10px,
        ),
        color: #4fbc0d,
    ));

    @include desktop-props((
        padding: 10px 0 0,

        font-family: $gilroy,
        font-weight: (
            1024:  500,
            1920: 600,
        ),
        font-size: (
            1024:  12px,
            1280: 13px,
        ),
        line-height: normal,
        letter-spacing: (
            1024:  -0.09px,
            1280: -0.1px,
            1920: -0.11px,
        ),
    ));
}

.show-comment-button {
    text-align: left;
}

.subtitle-comment {
    $big-number_height: 1000px;

    $line-height_320:  1.06;
    $line-height_1024:  1.29;

    @include all-props((
        overflow: hidden,
        min-height: 1.13em,
        max-height: (
            0:   $big-number_height,
            1024: auto,
        ),
        opacity: 0,

        font-family: $gilroy,
        font-weight: (
            0:   500,
            1024: 400,
        ),
        font-size: (
            0:    16px,
            375:  17px,
            414:  18px,
            1024:  14px,
            1280: 15px,
            1920: 16px,
        ),
        line-height: (
            0:    1.06,
            1024:  1.29,
            1280: 1.27,
            1920: 1.25,
        ),
        letter-spacing: (
            0:    -0.23px,
            375:  -0.16px,
            414:  -0.23px,
            1024:  -0.39px,
            1280: -0.41px,
            1920: -0.44px,
        ),
        color: var(--color__cuttlefish),

        transition: max-height 1000ms,
    ));

    &.clipped {
        @include all-props((
            max-height: (
                0:   #{$line-height_320 * 2}em,
                1024: #{$line-height_1024 * 2}em,
            ),
        ));

        &.decorations-default::after {
            @include all-props((
                content: 'еще...',
                text-decoration: underline,
                font-family: $gilroy,
                font-weight: (
                    0:   800,
                    1024: 700,
                ),
            ));
        }
    }

    &.decorations-quote {
        &.clipped {
            @include desktop-props((
                max-height: 54px,
            ));
        }

        &::before {
            @include all-props((
                content: "«",
                font-family: $gilroy,
                font-weight: (
                    0:   500,
                    1024: 400,
                ),
            ));
        }
        &::after {
            @include all-props((
                content: "еще »",
                font-family: $gilroy,
                font-weight: (
                    0:   800,
                    1024: 700,
                ),
                line-height: (
                    0:   1px, // $gilroy-800 увеличивает высоту элемента, и из-за этого обрезка текста работает неправильно
                ),
            ));
        }
    }

    &.visible {
        @include all-props((
            opacity: 1,
            transition: (
                max-height 1000ms,
                opacity 300ms,
            ),
        ));
    }

    &.full {
        @include all-props((
            max-height: 1000px,
        ));

        &.decorations-default {
            &::after {
                content: '';
            }
        }
        &.decorations-quote {
            &::after {
                content: "»";
                font-family: inherit;
            }
        }
    }
}
