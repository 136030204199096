@import '~styles/variables/index';
@import './menu-filters-vars';

.filter-item__content-wrap {
    position: relative;
}

.filter-item__content {
    padding-left: $v3-res960-gird-gap;
    padding-right: 20px;

    @media (min-width: 1024px) {
        padding-left: $v3-res1024-gird-gap;
    }
    @media (min-width: 1280px) {
        padding-left: $v3-res1280-gird-gap;
        padding-right: 20px;
    }
    @media (min-width: 1366px) {
        padding-left: $v3-res1366-gird-gap;
        padding-right: 28px;
    }
    @media (min-width: 1440px) {
        padding-left: $v3-res1440-gird-gap;
        padding-right: 30px;
    }
    @media (min-width: 1600px) {
        padding-left: $v3-res1600-gird-gap;
    }
    @media (min-width: 1920px) {
        padding-left: $v3-res1920-gird-gap;
        padding-right: 42px;
    }
}

.filter-item__reset-button-wrap {
    box-sizing: border-box;
    width: 293px;
    height: $reset-button-wrap-height-960;
    padding: $reset-button-wrap-padding-vertical-960 28px;
    overflow: hidden;

    display: flex;
    // position: sticky;
    position: fixed;
    bottom: 0;
    z-index: 4; // перекрытие комментария

    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
    background: var(--color__coconut);

    // &.is-sticky {
    //     box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.06);
    //     background: var(--color__coconut);
    // }

    &.entering {
        transition: height 400ms, opacity 400ms, padding 400ms;
        opacity: 1;
        height: $reset-button-wrap-height-960;
        padding: $reset-button-wrap-padding-vertical-960 28px;
    }
    &.entered {
        opacity: 1;
        height: $reset-button-wrap-height-960;
        padding: $reset-button-wrap-padding-vertical-960 28px;
    }
    &.exiting {
        transition: height 400ms, opacity 400ms, padding 400ms;
        height: 0;
        opacity: 0;
        padding: 0px 28px;
    }
    &.exited {
        height: 0;
        opacity: 0;
        padding: 0px 28px;
    }

    @media (min-width: 1024px) {
        height: $reset-button-wrap-height-1024;
        padding: $reset-button-wrap-padding-vertical-1024 22px;

        &.entering {
            height: $reset-button-wrap-height-1024;
            padding: $reset-button-wrap-padding-vertical-1024 22px;
        }
        &.entered {
            height: $reset-button-wrap-height-1024;
            padding: $reset-button-wrap-padding-vertical-1024 22px;
        }
        &.exiting {
            padding: 0px 22px;
        }
        &.exited {
            padding: 0px 22px;
        }
    }

    @media (min-width: 1280px) {
        width: 283px;
        height: $reset-button-wrap-height-1280;
        padding: $reset-button-wrap-padding-vertical-1280;

        &.entering {
            height: $reset-button-wrap-height-1280;
            padding: $reset-button-wrap-padding-vertical-1280;
        }
        &.entered {
            height: $reset-button-wrap-height-1280;
            padding: $reset-button-wrap-padding-vertical-1280;
        }
        &.exiting {
            padding: 0px 16px;
        }
        &.exited {
            padding: 0px 16px;
        }
    }

    @media (min-width: 1366px) {
        width: 311px;
        height: $reset-button-wrap-height-1366;
        padding: $reset-button-wrap-padding-vertical-1366 24px;

        &.entering {
            height: $reset-button-wrap-height-1366;
            padding: $reset-button-wrap-padding-vertical-1366 24px;
        }
        &.entered {
            height: $reset-button-wrap-height-1366;
            padding: $reset-button-wrap-padding-vertical-1366 24px;
        }
        &.exiting {
            padding: 0px 24px;
        }
        &.exited {
            padding: 0px 24px;
        }
    }

    @media (min-width: 1440px) {
        width: 317px;
    }

    @media (min-width: 1600px) {
        width: 351px;
        height: $reset-button-wrap-height-1600;
        padding: $reset-button-wrap-padding-vertical-1600 26px;

        &.entering {
            height: $reset-button-wrap-height-1600;
            padding: $reset-button-wrap-padding-vertical-1600 26px;
        }
        &.entered {
            height: $reset-button-wrap-height-1600;
            padding: $reset-button-wrap-padding-vertical-1600 26px;
        }
        &.exiting {
            padding: 0px 26px;
        }
        &.exited {
            padding: 0px 26px;
        }
    }

    @media (min-width: 1920px) {
        width: 419px;
        height: $reset-button-wrap-height-1920;
        padding: $reset-button-wrap-padding-vertical-1920 30px;

        &.entering {
            height: $reset-button-wrap-height-1920;
            padding: $reset-button-wrap-padding-vertical-1920 30px;
        }
        &.entered {
            height: $reset-button-wrap-height-1920;
            padding: $reset-button-wrap-padding-vertical-1920 30px;
        }
        &.exiting {
            padding: 0px 30px;
        }
        &.exited {
            padding: 0px 30px;
        }
    }
}

.filter-item__reset-button {
    width: 100%;
    height: 42px;
    border-radius: 4px;
    background-color: $wild-sand;
    border: none;

    font-family: $gilroy-semibold;
    font-size: 15px;
    letter-spacing: -0.1px;
    color: #474747;

    @media (min-width: 1280px) {
        height: 44px;
    }
    @media (min-width: 1366px) {
        font-size: 16px;
        height: 46px;
    }
    @media (min-width: 1600px) {
        font-size: 17px;
        letter-spacing: -0.11px;
        height: 50px;
    }
    @media (min-width: 1920px) {
        height: 57px;
        font-size: 19px;
        letter-spacing: -0.12px;
    }
}
