@import '~styles/variables/index';

.basket__dish-item {
    @include all-props((
        opacity: 1,
        display: flex,
        position: relative,
        box-sizing: content-box,
        height: (
            0:   72px,
            375: 80px,
            414: 84px,
            1024: 84px,
        ),
        margin: (
            0:  0 0 16px,
            375: 0 0 24px,
            1024: 0 18px 24px,
        ),
    ));

    // &:last-child {
    //     margin: 0,
    // }

    &.without-margin-after-last {
        @include all-props((
            margin-bottom: 0,
        ));
    }

}

.zero-margin_for-last-child {
    @include all-props((
        margin: 0,
    ));
}

/* Animation classes - анимация цдаления блюда */
.basket__dish-item {
    transition: opacity .2s, transform .4s, height .7s, padding .7s;
    transition-timing-function: cubic-bezier(0.25 0.1 0.25 1);

    &.exiting {
        opacity: 0;
        height: 0px;
        transform: scale(0.9);
        padding: 0px;
    }

    &.entering {}
    &.entered {}
}


.basket__dish-item__image--container {
    @include all-props((
        object-fit: cover,
        overflow: hidden,
        display: flex,
        flex: none,
        cursor: pointer,

        margin-right: (
            0:   16px,
            375: 17px,
            414: 20px,
            1024: 20px,
        ),
        height: 100%,
        width: (
            0:   96px,
            375: 108px,
            414: 114px,
            1024: 114px,
        ),
        min-width: (
            0:   96px,
            375: 108px,
            414: 114px,
            1024: 114px,
        ),
        border-radius: (
            0: var(--sizeBorderRadiusXS),
            375: var(--sizeBorderRadiusM),
            1024: var(--sizeBorderRadiusM),
        ),

        -webkit-mask-image: -webkit-radial-gradient(white, black), // Safari https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
    ));

    &:hover {
        .basket__dish-item__image {
            @include all-props((
                transform: scale(1.05),
            ));
        }
    }
}

.basket__dish-item__image {
    transition: all 300ms ease;
    position: relative;
    width: 100%;
}

.basket__dish-item__info--container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    @media (min-width: 414px) {
        justify-content: flex-start;
    }
}

.basket__dish-item__row {
    @include all-props((
        display: flex,
        width: 100%,
    ));

    &.first {
        @include all-props((
            justify-content: space-between,
            align-items: flex-start,
            margin-bottom: (
                0:   7px,
                360: 9px,
                375: 12px,
                414: 10px,
                1024: 10px,
            ),
        ));
    }
    &.second {
        @include all-props((
            align-items: flex-end,
            max-height: (
                0:   17px,
                360: 20px,
                414: 21px,
                1024: 26px,
            ),
        ));
    }
}

.basket__dish-item__title-container {
    width: 100%;
    text-align: left;
}

.basket__dish-item__remove-container {
    @include all-props((
        position: relative,
        top: 3px,
        right: (
            0:   2px,
            375: 3px,
            414: 3px,
            1024: 3px,
        ),

        display: flex,
        flex: none,
        justify-content: center,
        align-items: center,

        height: (
            0:   20px,
            375: 22px,
            414: 25px,
            1024: 25px,
        ),
        width: (
            0:   20px,
            375: 22px,
            414: 25px,
            1024: 25px,
        ),
    ));

    &:active {
        svg > path {
            @include all-props((
                fill: #a3a3a3,
            ));
        }
    }

    svg {
        @include all-props((
            height: 100%,
            width: 100%,
        ));
    }
}

@keyframes make-bigger {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
}

.changed-price__animation {
    transform: scale(1);
    animation: make-bigger 600ms linear running;
}

.basket__dish-item__price-wrapper {
    @include all-props((
        display: flex,
        justify-content: flex-start,
        align-items: baseline,
    ));
}

.basket__dish-item__price {
    @include all-props((
        font-weight: (
            0: 700,
            1024: 600,
        ),
        white-space: nowrap,
        font-size: (
            0:   17px,
            375: 18px,
            414: 19px,
            1024: 18px,
        ),
        color: var(--color__cuttlefish),
    ));

    & .num {
        @include all-props((
            font-family: $haas-txstd,
            font-weight: (
                0: 700,
                1024: 500,
            ),
            letter-spacing: -0.46px,
        ));
    }
}

.basket__dish-item__multiplier {
    @include all-props((
        align-self: center,
        margin: (
            0: 0 7px,
            1024: 0 7px,
        ),

        font-family: $gilroy,
        font-weight: 600,
        font-size: (
            0:   13px,
            375: 14px,
            414: 15px,
            1024: 15px,
        ),
        letter-spacing: -0.44px,
        color: var(--color__cuttlefish),
    ));
}
