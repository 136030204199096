@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

.deliveryZoneTermsRoot {
    flex: 2;
    background-color: #fff;
    @include all-props((
        padding: (
            0: 24px 20px 28px 20px,
            375: 32px 20px 36px 20px,
            1024: 32px 20px 36px 20px,
        ),
    ));
}

.deliveryZoneTermsTitle {
    & p {
        color: var(--color__pepper);
    }
    @include all-props((
        padding-bottom: 12px,
    ));
}

.deliveryZoneTermsDescription {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-right: 20px;
}

.deliveryZoneTermsDescriptionRow {
    @include all-props((
        font-weight: (
            0: 500,
            1024: 400,
        ),
        line-height: (
            0: 16px,
            375: 17px,
            1024: 17px,
        ),
        font-size: (
            0: 15px,
            375: 16px,
            1024: 16px,
        ),
        letter-spacing: -0.2px,
        font-family: $gilroy,
        color: var(--color__pepper),
        position: relative,
        padding-left: (
            0: 17px,
            1024: 17px,
        ),
    ));

    &:before {
        content: '';
        @include all-props((
            width: 5px,
            height: 5px,
            border-radius: 5px,
            background-color: var(--color__pepper),
            position: absolute,
            left: 0,
            top: (
                0: 25%,
                1024: 25%,
        )   ,
        ));
    }
}
