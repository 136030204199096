@import '~styles/variables/index';


.dialogRoot {
    @include all-props((
        position: fixed,
        right: 0,
        left: 0,
        bottom: 0,
        max-height: (
            0:  calc(100dvh - 52px),
            375:  calc(100dvh - 61px)
        ),
        z-index: 5000,
        margin: 0 auto,
        background-color: var(--color__coconut),
        text-align: center,
        transform: translateY(0%),
    ));
    @include mobile-props((
        max-width: 500px,
        box-shadow: 3px -4px 16px rgba(0, 0, 0, 0.05),
        border-radius: (
            0: var(--sizeBorderRadiusXL) var(--sizeBorderRadiusXL) 0 0,
            375: var(--sizeBorderRadiusXXL) var(--sizeBorderRadiusXXL) 0 0,
        ),
    ));
}

.dialogChildrenWrapper {
    @include mobile-props((
        position: relative,
        max-height: (
            0: calc(100dvh - 196px),
            375: calc(100dvh - 219px),
        ),
        overflow: scroll,
        width: 100%,
        box-sizing: border-box,
    ))
}

.dialogHeader {
    @include mobile-props((
        display: flex,
        justify-content: flex-end,
        padding: (
            0:   14px 16px 14px,
            375: 20px 22px 14px,
            414: 20px 20px 16px,
        ),

        background-color: var(--color__coconut),
        border-bottom: 1px solid var(--color__salt),
        border-radius: (
            0: var(--sizeBorderRadiusXL) var(--sizeBorderRadiusXL) 0 0,
            375: var(--sizeBorderRadiusXXL) var(--sizeBorderRadiusXXL) 0 0,
        ),
    ));
}

.dialogCloseButton {
    // reset default button style
    @include all-props((
        border: none,
        outline: none,
        margin: 0,
        padding: 0,
        width: auto,
        overflow: visible,
        background: transparent,
        text-align: unset,
    ));

    $icon-side-sizes: (
        0:    19px,
        375:  22px,
        414:  26px,
        1024: 26px,
    );
    @include all-props((
        cursor: pointer,
        height: $icon-side-sizes,
        width: $icon-side-sizes,
    ));

    &:disabled {
        @include all-props((
            pointer-events: none,
            opacity: 0,
        ));
    }

    g {
        @include mobile-props((
            fill: #f1f1f1,
        ));
    }
}

.dialogTitleContainer {
    @include all-props((
        background-color: var(--color__coconut),
    ));
    @include mobile-props((
        padding: (
            0:   22px 20px 8px,
            375: 24px 22px 9px,
            414: 24px 32px 14px,
        ),
    ));
}

.dialogTitleContainerProfile {
    @include mobile-props((
        padding: (
            0:   20px 16px 8px,
            375: 20px 20px 9px,
            414: 20px 20px 14px,
        ),
    ));
}

.dialogRegularTextContainer {
    @include all-props((
        display: flex,
        justify-content: center,
    ));
    @include mobile-props((
        flex-direction: column,
        transform: (
            0:   translateY(-9px),
            414: translateY(-10px),
        ),
    ));
}

.dialogRegularTexts {
    @include mobile-props((
        display: flex,
        flex-direction: column,
        gap: (
            0: 6px,
            375: 8px
        )
    ));
}

.profileModal {
    @include mobile-props((
        flex-direction: column,
        transform: (
            0:   translateY(0px),
            414: translateY(0px),
        ),
    ));
}

// Усиление селектора, чтобы перебить стили параграфа
.dialogRoot {
    .dialogStrongText {
        @include mobile-props((
            margin: (
                0:   7px 0,
                375: 9px 0,
                414: 10px 0,
            ),
        ));
    }

    .profileStrongText {
        @include mobile-props((
            margin-top: 0,
            margin-bottom: (
                0: 10px,
                375: 12px,
            )
        ));
    }

    .dialogRegularText,
    .dialogExtraText {
        @include mobile-props((
            font-weight: 400,
            font-size: (
                0: 17px,
                375: 18px,
            ),
            line-height: (
                0: 18px,
                375: 19px,
            )
        ));
    }
}

.dialogButtonsContainer {
    @include all-props((
        width: 100%,
        box-sizing: border-box,
        cursor: pointer,
        -webkit-tap-highlight-color: transparent,
        background-color: #FFF,
    ));

    @include mobile-props((
        padding: (
            0:   0 20px 0,
            375: 0 22px 0,
            414: 0 32px 0,
        ),
    ));

    &.reversed {
        flex-direction: row-reverse !important;
    }

    &.oneRow {
        @include mobile-props((
            display: flex,
            flex-direction: row,
            justify-content: center,
            gap: (
                0:   8px,
                375: 11px,
                414: 12px,
            ),
        ));
    }
    &.twoRows {
        @include mobile-props((
            display: flex,
            flex-direction: column,
        ));

        & .dialogButton:first-child {
            @include mobile-props((
                margin-bottom: 12px,
            ));
        }
    }
}

.dialogButtonContainerProfile {
    @include mobile-props((
        padding: (
            0:   11px 16px 11px,
            375: 12px 20px 10px,
        ),
    ));
}

.dialogExtraTextContainer {
    @include all-props((
        color: var(--color__lichi),
    ));
    @include mobile-props((
        padding: (
            0:   16px 20px 0,
            375: 20px 22px 0,
        ),
    ))
}

.start {
    text-align: start;
}

.center {
    text-align: center;
}

.dateSelectListContainerShadow {
    @include mobile-props((
        position: fixed,
        bottom: 72px,
        left: 0,
        width: 100%,
        height: 50px,
        background: linear-gradient(356deg, #FFF -0.67%, rgba(255, 255, 255, 0.00) 96.37%),
        pointer-events: none,
        transition-property: opacity,
        transition-duration: 0.3s
    ));
}
