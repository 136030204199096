// color name by http://chir.ag/projects/name-that-color/

// $white: #fff;
// $white-2: #fefefe;
// $barley-white: #fff6c8;

// $alabaster: #f8f8f8;
// $alabaster-2: #f7f7f7;
// $alabaster-3: #f9f9f9;

// $gallery: #eeeeee;
// $gallery-2: #f0f0f0;
// $gallery-4: #ebebeb;
// $gallery-8: #efefef;

// $mercury: #e9e9e9;
// $mercury-6: #e4e4e4;

// $bright-sun: #ffdf35;

// $sunglow: #ffdd27;

// $saffron: #f3d033;


// $roti: #b8a43d;

// $kournikova: #ffe768;
// $kournikova-2: #ffeb80;
// $kournikova-4: #ffe977;

// $gold: #ffd702;

// $golden-dream: #f3d42c;

// $golden-sand: #f1df7a;

// $buttermilk: #fff3b6;
// $buttermilk-3: #fff2ae;

// $olive-2: #7e6a00;
// $olive-3: #9e8600;

// $olivetone: #837114;

// $olive-grab: #60a22a;

// $picasso-2: #ffee91;
// $picasso-3: #ffee95;
// $picasso-5: #ffef9b;

// $black: #000;

// // tundora
// var(--color__cuttlefish): #404040;
// var(--color__cuttlefish)-2: #4e4e4e;

// var(--color__cuttlefish)-5: #424242;
// var(--color__cuttlefish)-6: #464646;
// var(--color__cuttlefish)-9: #4b4b4b;
// var(--color__cuttlefish)-10: #484848;
// var(--color__cuttlefish)-11: #4a4a4a;
// var(--color__cuttlefish)-12: #494949;
// var(--color__cuttlefish)-15: #434343;
// var(--color__cuttlefish)-16: #444444;

// $silver: #c1c1c1;
// $silver-2: #c3c3c3;
// $silver-3: #b9b9b9;
// $silver-4: #b8b8b8;
// $silver-5: #cacaca;
// $silver-7: #cbcbcb;
// $silver-8: #bababa;
// $silver-9: #c6c6c6;
// $silver-10: #bbbbbb;
// $silver-11: #bfbfbf;

// $gray: #8a8a8a;
// $gray-2: #828282;
// $gray-6: #929292;
// $gray-7: #858585;

// $emperor: #525252;
// $emperor-2: #4f4f4f;
// $emperor-3: #545454;

// $mine-shaft: #272727;
// $mine-shaft-2: #393939;
// $mine-shaft-3: #3c3c3c;
// $mine-shaft-4: #2b2b2b;
// $mine-shaft-7: #3e3e3e;
// $mine-shaft-10: #383838;
// $mine-shaft-11: #3b3b3b;

// $dove-gray: #727272;
// $dove-gray-6: #686868;

// $silver-chalice: #a7a7a7;
// $silver-chalice-2: #9e9e9e;
// $silver-chalice-5: #a5a5a5;
// $silver-chalice-6: #acacac;
// $silver-chalice-9: #a9a9a9;
// $silver-chalice-10: #b0b0b0;

// $alto: #d8d8d8;
// $alto-2: #d7d7d7;
// $alto-5: #cecece;
// $alto-6: #dedede;
// $alto-8: #d4d4d4;
// $alto-9: #d5d5d5;

// $dusty-gray: #9c9c9c;
// $dusty-gray-2: #959595;
// $dusty-gray-3: #979797;
// $dusty-gray-5: #969696;

// $boulder: #7c7c7c;

// $scorpion-4: #585858;
// $scorpion-5: #595959;
// $scorpion-6: #5f5f5f;

// $shuttle-gray: #5d6064;

// $cola-2: #3e3400;

// $wild-sand: #f5f5f5;
// $wild-sand: #f6f6f6;
// $wild-sand-3: #f4f4f4;

// $sundown:  #ffb9b9;

// $seashell: #f1f1f1;

// $aluminium: #a7abb1;

// $concrete: #f2f2f2;
// $concrete-2: #f3f3f3;

// $danube: #6c95d8;

// $mustard: #ffe55b;
// $mustard-2: #ffe65e;
// $mustard-3: #ffe453;
// $mustard-4: #ffe661;
// $mustard-5: #ffe457;

// $himalaya: #726521;
// $himalaya-2: #786a20;

// $verdun-green: #675700;
// $verdun-green-2: #605100;

// $roman: #d75757;

// $salomie: #ffed8c;
// $salomie-2: #ffeb84;

//  var(--color__melon): #fdadad;

// $aqua-forest: #63aa66;

// $candlelight: #ffdc1e;
// $candlelight-2: #ffdd25;

// $cinnamon: #6A5700;
// $cinnamon-2: #6a5700;
// $cinnamon-3: #6c5b00;
// $cinnamon-4: #724600;

// $drover: #fdf0af;

// $sushi: #73AD43;

// $burning-orange: #ff723a;

$alabaster: var(--color__not-ui__alabaster);
$alto: var(--color__not-ui__alto);
$aluminium: var(--color__not-ui__aluminium);
$aqua-forest: var(--color__not-ui__aqua-forest);
$barley-white: var(--color__not-ui__barley-white);
$black: var(--color__not-ui__black);
$boulder: var(--color__not-ui__boulder);
$bright-sun: var(--color__not-ui__bright-sun);
$burning-orange: var(--color__not-ui__burning-orange);
$buttered-rum: var(--color__not-ui__buttered-rum);
$buttermilk: var(--color__not-ui__buttermilk);
$candle: var(--color__not-ui__candle);
$candlelight: var(--color__not-ui__candlelight);
$chelsea-gem: var(--color__not-ui__chelsea-gem);
$cinnamon: var(--color__not-ui__cinnamon);
$cod-gray: var(--color__not-ui__cod-gray);
$cola: var(--color__not-ui__cola);
$concrete: var(--color__not-ui__concrete);
$corn-harvest: var(--color__not-ui__corn-harvest);
$corn: var(--color__not-ui__corn);
$danube: var(--color__not-ui__danube);
$dove-gray: var(--color__not-ui__dove-gray);
$drover: var(--color__not-ui__drover);
$emperor: var(--color__not-ui__emperor);
$gallery: var(--color__not-ui__gallery);
$gold: var(--color__not-ui__gold);
$golden-dream: var(--color__not-ui__golden-dream);
$golden-sand: var(--color__not-ui__golden-sand);
$gray: var(--color__not-ui__gray);
$himalaya: var(--color__not-ui__himalaya);
$kournikova: var(--color__not-ui__kournikova);
$not-ui__melon: var(--color__not-ui__melon);
$mercury: var(--color__not-ui__mercury);
$mine-shaft: var(--color__not-ui__mine-shaft);
$mustard: var(--color__not-ui__mustard);
$nobel: var(--color__not-ui__nobel);
$nutmeg-wood: var(--color__not-ui__nutmeg-wood);
$olive-grab: var(--color__not-ui__olive-grab);
$olive: var(--color__not-ui__olive);
$olivetone: var(--color__not-ui__olivetone);
$picasso: var(--color__not-ui__picasso);
$pirate-gold: var(--color__not-ui__pirate-gold);
$roman: var(--color__not-ui__roman);
$roti: var(--color__not-ui__roti);
$saffron: var(--color__not-ui__saffron);
$salomie: var(--color__not-ui__salomie);
$scorpion: var(--color__not-ui__scorpion);
$shuttle-gray: var(--color__not-ui__shuttle-gray);
$silver-chalice: var(--color__not-ui__silver-chalice);
$silver: var(--color__not-ui__silver);
$sundown: var(--color__not-ui__sundown);
$sunglow: var(--color__not-ui__sunglow);
$supernova: var(--color__not-ui__supernova);
$sushi: var(--color__not-ui__sushi);
$verdun-green: var(--color__not-ui__verdun-green);
$wild-sand: var(--color__not-ui__wild-sand);

:export {
    alabaster: $alabaster;
    alto: $alto;
    aluminium: $aluminium;
    aqua-forest: $aqua-forest;
    barley-white: $barley-white;
    black: $black;
    boulder: $boulder;
    bright-sun: $bright-sun;
    burning-orange: $burning-orange;
    buttered-rum: $buttered-rum;
    buttermilk: $buttermilk;
    candle: $candle;
    candlelight: $candlelight;
    chelsea-gem: $chelsea-gem;
    cinnamon: $cinnamon;
    cod-gray: $cod-gray;
    cola: $cola;
    concrete: $concrete;
    corn-harvest: $corn-harvest;
    corn: $corn;
    danube: $danube;
    dove-gray: $dove-gray;
    drover: $drover;
    emperor: $emperor;
    gallery: $gallery;
    gold: $gold;
    golden-dream: $golden-dream;
    golden-sand: $golden-sand;
    gray: $gray;
    himalaya: $himalaya;
    kournikova: $kournikova;
    not-ui__melon: $not-ui__melon;
    mercury: $mercury;
    mine-shaft: $mine-shaft;
    mustard: $mustard;
    nobel: $nobel;
    nutmeg-wood: $nutmeg-wood;
    olive-grab: $olive-grab;
    olive: $olive;
    olivetone: $olivetone;
    picasso: $picasso;
    pirate-gold: $pirate-gold;
    roman: $roman;
    roti: $roti;
    saffron: $saffron;
    salomie: $salomie;
    scorpion: $scorpion;
    shuttle-gray: $shuttle-gray;
    silver-chalice: $silver-chalice;
    silver: $silver;
    sundown: $sundown;
    sunglow: $sunglow;
    supernova: $supernova;
    sushi: $sushi;
    verdun-green: $verdun-green;
    wild-sand: $wild-sand;
}

