$font-path: '../../assets/fonts';

$src:
  url("#{$font-path}/Gilroy-Medium.woff") format('woff'),
;

@font-face {
  font-family: 'Gilroy-Medium';
  src:  $src;
  font-display: swap;
}
