@import '~styles/variables/index';


.menu {
    // background: var(--color__onion);
    background: var(--color__coconut);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @include mobile-props((
        padding-bottom: (
            0:   70px,
            375: 72px,
            414: 76px,
        ),
    ));

    &.is-hidden {
        padding-bottom: 0;
    }
}

.menu__promo {
    @include mobile_320 {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        margin: 0;
        padding: 0;
    }

    @media (min-width: 1024px) {
        position: sticky;
        z-index: 1000;
        right: 0;
        bottom: $v3-res960-dish-gap-bottom;
        width: calc(50% - #{$v3-res960-gird-gap});
        margin-right: $v3-res960-gird-gap;
        margin-left: 50%;
    }
    @media (min-width: 1024px) {
            bottom: $v3-res1024-dish-gap-bottom;
        width: calc(50% - #{$v3-res1024-gird-gap});
        margin-right: $v3-res1024-gird-gap;
    }
    @media (min-width: 1280px) {
        bottom: $v3-res1280-dish-gap-bottom;
        width: calc(50% - #{$v3-res1280-gird-gap});
        margin-right: $v3-res1280-gird-gap;
    }
    @media (min-width: 1366px) {
        bottom: $v3-res1366-dish-gap-bottom;
        width: calc(50% - #{$v3-res1366-gird-gap});
        margin-right: $v3-res1366-gird-gap;
    }
    @media (min-width: 1440px) {
        bottom: $v3-res1440-dish-gap-bottom;
        width: calc(50% - #{$v3-res1440-gird-gap});
        margin-right: $v3-res1440-gird-gap;
    }
    @media (min-width: 1600px) {
        bottom: $v3-res1600-dish-gap-bottom;
        width: calc(50% - #{$v3-res1600-gird-gap});
        margin-right: $v3-res1600-gird-gap;
    }
    @media (min-width: 1920px) {
        bottom: $v3-res1920-dish-gap-bottom;
        width: calc(50% - #{$v3-res1920-gird-gap});
        margin-right: $v3-res1920-gird-gap;
    }
}

.open-basket-button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--color__coconut);
    z-index: 3;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.05);
    padding: 8px;
}

.menu__dishes-container {
    width: 100%;
    flex: 2;
}

@keyframes blurring {
    from {
        opacity: 0;
        backdrop-filter: blur(0px);
    }
    to {
        opacity: 0.65;
        backdrop-filter: blur(5px);
    }
}

.menu__close-filters {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;

    background: var(--color__coconut);
    opacity: 0.65;
    backdrop-filter: blur(5px);
    animation: blurring 600ms alternate;

    @media (min-width: 1280px) {
        display: none;
    }
}

.dishes-skeleton-wrap {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
}

.dishes-skeleton {
    padding-top: 50px;
    background: var(--color__coconut);
}

@media (min-width: 360px) {
    .menu {
        padding-bottom: 50px + 13px * 2;
    }
    .dishes-skeleton {
        padding-top: 60px;
    }
}

@media (min-width: 414px) {
    .menu {
        padding-bottom: 52px + 12px * 2;
    }
    .dishes-skeleton {
        padding-top: 62px;
    }
}

@media (min-width: 1024px) {
    .menu {
        flex-direction: row;
        align-items: inherit;
        padding-bottom: 0;
        background: $wild-sand;
    }
}

.menu__reset-filters-button-container {
    @include mobile-props((
        margin: (
            0: 22px 0 80px,
            360: 2px 0 88px,
            375: 12px 0 112px,
            414: 6px 0 120px,
        ),
        padding: (
            0:   0 16px,
            375: 0 20px,
        ),

        transition: opacity 300ms,
    ));
    @include desktop-props((
        display: flex,
        justify-content: flex-end,
        align-items: center,

        margin-right: (
            1024: 40px,
            1366: 42px,
            1440: 46px,
            1600: 50px,
            1680: 64px,
        ),
        height: (
            1024:  128px,
            1366: 152px,
            1440: 140px,
            1680: 144px,
            1920: 172px,
        ),
        border-top: 1px solid #EAEAEA,
    ));

    &.menu-updating {
        @include mobile-props((
            opacity: 0,
        ));
    }
}

.menu__reset-filters-button {
    @include desktop-props((
        width: (
            1024:  250px,
            1366: 263px,
            1440: 266px,
            1680: 300px,
            1920: 360px,
        ),
        height: (
            1024:  42px,
            1366: 46px,
            1680: 50px,
            1920: 56px,
        ),
        background: var(--color__coconut),
        border-radius: 4px,

        text-align: center,
        vertical-align: middle,
        color: var(--color__cuttlefish),
        font-family: $gilroy,
        font-weight: 600,
        font-size: (
            1024:  15px,
            1366: 16px,
            1680: 19px,
        ),
        line-height: (
            1024:  18px,
            1366: 19px,
            1680: 23px,
        ),
        letter-spacing: -0.98px,
    ));

    &:hover {
        @include desktop-props((
            background: rgba(255, 255, 255, 0.8),
            color: var(--color__hering),
        ));
    }
}

.menu__reset-filters-button-description-wrapper {
    @include mobile-props((
        text-align: center,
        padding: (
            0: 12px 0px,
            375: 16px 0px,
            414: 17px 0px,
        ),
    ));
}
.menu__reset-filters-button-description {
    @include mobile-props((
        color: var(--color__lichi),
        line-height: (
            0: 118%,
        ),
    ));
}
