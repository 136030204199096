@import '~styles/variables/index';

.ui-paragraph {
    @include all-props((
        color: var(--color__cuttlefish),
    ));
    &.inherit-colors {
        @include all-props((
            color: inherit,
        ));
    }
    &.ellipsis,
    &.clip {
        @include all-props((
            overflow: hidden,
            text-overflow: ellipsis,
            white-space: nowrap,
        ));
    }
    :global(.nowrap) {
        @include all-props((
            white-space: nowrap,
            font-weight: inherit,
        ));
    }

    &.ui-paragraph--level-1_1 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 500,
            font-size: (
                0  : 17px,
                375: 20px,
                414: 21px,
                1024: 21px,
            ),
            line-height: (
                0  : 1.18,
                375: 1.1,
                1024: 1.1,
            ),
            letter-spacing: (
                0  : -0.43px,
                375: -0.5px,
                414: -0.53px,
                1024: -0.53px,
            ),
        ));
        // not official desktop style (referral-page)
        @include desktop-props((
            font-family: $gilroy,
            font-size: (
                1024:  15px,
                1280: 16px,
            ),
            line-height: (
                1024:  1.27,
                1280: 1.19,
            ),
            letter-spacing: (
                1024:  -0.38px,
                1280: -0.4px,
            ),
        ));

        // 'd?' in ui-kit (например, InviteSectionGetBonus.js)
        :global(.num) {
            @include all-props((
                font-family: $haas-txpro,
                font-weight: 400,
                font-size: inherit,
                letter-spacing: inherit,
            ));
            // not official desktop style (referral-page)
            @include desktop-props((
                font-family: $haas-txpro,
                font-weight: 400,
                font-size: inherit,
                letter-spacing: inherit,
            ));
        };

        :global(.nav-link) {
            @include all-props((
                font-family: inherit,
                font-size: inherit,
                letter-spacing: inherit,
                color: inherit,
                text-decoration: underline,
            ));
            // not official desktop style (referral-page)
            @include desktop-props((
                font-family: inherit,
                font-size: inherit,
                letter-spacing: inherit,
                color: inherit,
                text-decoration: underline,
            ));
        };
    }

    &.ui-paragraph--level-2_1 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 600,
            font-size: (
                0  : 18px,
                375: 19px,
                414: 20px,
                1024: 20px,
            ),
            line-height: (
                0  : 1.1,
                414: 1.2,
                1024: 1.2,
            ),
            letter-spacing: -1px,
        ));

        // 'd5' in ui-kit
        :global(.num) {
            @include all-props((
                font-family: $haas-dspro,
                font-weight: 600,
                font-size: inherit,
                letter-spacing: -1px,
                line-height: inherit,
            ));
        }
    }

    &.ui-paragraph--level-2_2 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 500,
            font-size: (
                0  : 17px,
                375: 19px,
                414: 20px,
                1024: 20px,
            ),
            line-height: (
                0  : 34px,
                375: 36px,
                414: 40px,
                1024: 40px,
            ),
            min-height: (
                0  : 34px,
                375: 36px,
                414: 40px,
                1024: 40px,
            ),
            letter-spacing: (
                0:   -0.2px,
                375: -0.3px,
                1024: -0.3px,
            ),
        ));

        // 'd?' in ui-kit (например, MobilePeriodSelect.js)
        :global(.num) {
            @include all-props((
                font-family: $haas-dsstd,
                font-size: inherit,
                letter-spacing: inherit,
            ));
        }

        /*
            Почему-то в этом уровне параграфа указан очень большой line-height,
            в многострочном режиме (в Dialog'е) это выглядит плохо.
            Сейчас здесь указаны примерные параметры, чтобы выглядело ок.
            Их нужно будет заменить на корректные из обновленного UI-kit,
            когда дойдем до задачи обновления типографики.
        */
        &:global(.fixed-lineheight) {
            @include all-props((
                line-height: (
                    0  : 18px,
                    375: 20px,
                    414: 21px,
                ),
            ));
        }
    }

    &.ui-paragraph--level-3_1 {
        @include all-props((
            font-family: $gilroy,
            font-weight: (
                0: 500,
                1024: 400,
            ),
            font-size: (
                0  : 17px,
                375: 18px,
                414: 19px,
                1024: 19px,
            ),
            line-height: (
                0  : 1.11,
                375: 1.11,
                414: 1.11,
                1024: 1.11,
            ),
            letter-spacing: (
                0: -0.4px,
                414: -0.5px,
                1024: -0.5px,
            )
        ));

        & a {
            @include all-props((
                font-family: $gilroy,
                font-weight: (
                    0: 500,
                    1024: 400,
                ),
                font-size: (
                    0  : 17px,
                    375: 18px,
                    414: 19px,
                    1024: 19px,
                ),
                line-height: (
                    0  : 1.11,
                    375: 1.11,
                    414: 1.11,
                    1024: 1.11,
                ),
                letter-spacing: (
                    0: -0.4px,
                    414: -0.5px,
                    1024: -0.5px,
                )
            ));
        }
    }

    &.ui-paragraph--level-3_2 {
        @include all-props((
            font-family: $gilroy,
        ));
    }

    &.ui-paragraph--level-3_2 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 400,
            font-size: (
                0  : 17px,
                375: 18px,
                414: 19px,
                1024: 19px,
            ),
            line-height: 104%,
            letter-spacing: (
                0:   -0.4px,
                375: -0.5px,
                1024: -0.5px,
            ),
        ));
    }

    &.ui-paragraph--level-4_1 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 500,
            font-size: (
                0  : 17px,
                375: 18px,
                1024: 18px,
            ),
            letter-spacing: -0.2px,
        ));
        // not official desktop style (referral-page)
        @include desktop-props((
            font-family: $gilroy,
            letter-spacing: -0.16px,

            font-size: (
                1024:  15px,
            ),
            line-height: (
                1024:  1.07,
            ),
        ));

        // 'd6.1, d6.2' in ui-kit
        :global(.num) {
            @include all-props((
                font-family: $haas-txpro,
                font-weight: 400,
            ));
        }
    }

    &.ui-paragraph--level-4_2 {
        @include all-props((
            font-family: $gilroy,
            font-weight: (
                0: 400,
                1024: 300,
            ),
            letter-spacing: -0.2px,

            font-size: (
                0  : 16px,
                375: 17px,
                1024: 16px,
            ),
            line-height: (
                0  : 17px,
                375: 18px,
                1024: 17px,
            )
        ));

        // 'd6.1, d6.2' in ui-kit
        :global(.num) {
            @include all-props((
                font-family: $haas-txpro,
                font-weight: 400,
                font-size: (
                    0  : 16px,
                    375: 17px,
                    414: 18px,
                    1024: 18px,
                ),
                line-height: (
                    0  : 0.94,
                    375: 1.06,
                    414: 1,
                    1024: 1,
                ),
            ));
        }

        @include desktop-props((
            font-family: $gilroy,
            font-weight: 400,
            letter-spacing: -0.2px,

            font-size: (
                1024  : 17px,
            ),
            line-height: (
                1024  : 18px,
            )
        ));
    }

    &.ui-paragraph--level-5_1 {
        @include all-props((
            font-family: $gilroy,
            font-size: (
                0  : 14px,
                375: 16px,
                414: 17px,
                424: 18px,
                1024: 18px,
            ),
            letter-spacing: (
                0  : -0.06px,
                375: -0.07px,
                414: -0.08px,
                1024: -0.08px,
            ),
            line-height: 1,
        ));

        // 'd7' in ui-kit
        :global(.num) {
            @include all-props((
                font-family: $haas-dsstd,
                font-weight: 300,
                font-size: (
                    0  : 14px,
                    375: 16px,
                    414: 17px,
                    424: 18px,
                    1024: 18px,
                ),
                line-height: inherit,
                letter-spacing: inherit,
            ));
        }
    }

    &.ui-paragraph--level-5_3 {
        @include all-props((
            font-family: $gilroy,
            font-weight: (
                0: 400,
                1024: 300,
                ),
            font-size: (
                0  : 14px,
                375: 15px,
                414: 16px,
                1024: 16px,
            ),
            line-height: inherit,
            letter-spacing: (
                0  : -0.2px,
                375: -0.3px,
                1024: -0.3px,
            ),
        ));
    }

    &.ui-paragraph--level-6_1 {
        @include all-props((
            font-family: $gilroy,
            font-size: (
                0  : 13px,
                375: 14px,
                414: 15px,
                1024: 15px,
            ),
            line-height: (
                0  : 1.08,
                375: 1.07,
                1024: 1.07,
            ),
            letter-spacing: (
                0  : -0.46px,
                375: -0.39px,
                1024: -0.39px,
            ),
        ));

        // 'd?' in ui-kit (например, InviteSectionUseBonus.js)
        :global(.num) {
            @include all-props((
                font-family: $haas-dspro,
                font-weight: 500,
            ));
        }
    }
}
