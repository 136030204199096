@import '~styles/variables/index';


.dialogRoot {
    @include all-props((
        position: fixed,
        right: 0,
        left: 0,
        bottom: 0,
        z-index: 5000,
        margin: 0 auto,
        background-color: var(--color__coconut),
        text-align: center,
        transform: translateY(0%),
    ));
    @include mobile-props((
        max-width: 500px,
        padding-bottom: (
            0:   44px,
            375: 52px,
        ),
        box-shadow: 3px -4px 16px rgba(0, 0, 0, 0.05),
        border-radius: (
            0: var(--sizeBorderRadiusXL) var(--sizeBorderRadiusXL) 0 0,
            375: var(--sizeBorderRadiusXXL) var(--sizeBorderRadiusXXL) 0 0,
        ),
    ));

    @include desktop-props((
        bottom: auto,
        top: 50%,
        transform: translateY(-50%),
        box-sizing: border-box,
    ));

    @include responsive-desktop-props(1536px,(
        // width: 540px, // ширина из макета
        width: 584px, // ширина как у диалогов в ЛК
        border-radius: 20px,
        padding-top: 26px,
        padding-right: 28px,
        padding-bottom: 28px,
        padding-left: 28px,
    ));
}

.dialogHeader {
    @include mobile-props((
        display: flex,
        justify-content: flex-end,
        padding: (
            0:   14px 16px 14px,
            375: 20px 22px 14px,
            414: 20px 20px 16px,
        ),

        background-color: var(--color__coconut),
        border-bottom: 1px solid var(--color__salt),
        border-radius: (
            0: var(--sizeBorderRadiusXL) var(--sizeBorderRadiusXL) 0 0,
            375: var(--sizeBorderRadiusXXL) var(--sizeBorderRadiusXXL) 0 0,
        ),
    ));
}

.dialogCloseButton {
    // reset default button style
    @include all-props((
        border: none,
        outline: none,
        margin: 0,
        padding: 0,
        width: auto,
        overflow: visible,
        background: transparent,
        text-align: unset,
    ));

    $icon-side-sizes: (
        0:    19px,
        375:  22px,
        414:  26px,
        1024: 26px,
    );
    @include all-props((
        cursor: pointer,
        height: $icon-side-sizes,
        width: $icon-side-sizes,
    ));

    @include responsive-desktop-props(1536px, (
        width: 20px,
        height: 20px,
    ));

    &:disabled {
        @include all-props((
            pointer-events: none,
            opacity: 0,
        ));
    }

    g {
        @include mobile-props((
            fill: #f1f1f1,
        ));
        @include desktop-props((
            fill: var(--color__hering),
        ));
    }
}

.dialogTitleContainer {
    @include all-props((
        background-color: var(--color__coconut),
    ));
    @include mobile-props((
        padding: (
            0:   22px 20px 8px,
            375: 24px 22px 9px,
            414: 24px 32px 14px,
        ),
    ));
    // @include desktop-props((
    //     padding: (
    //         1024:  0 30px 24px,
    //         1280: 0 34px 28px,
    //         1680: 0 40px 28px,
    //         1920: 0 48px 30px,
    //     ),
    //     border-radius: (
    //         1024:  11px,
    //         1280: 16px,
    //     ),
    // ));
}

.dialogTitleContainerProfile {
    @include mobile-props((
        padding: (
            0:   20px 16px 8px,
            375: 20px 20px 9px,
            414: 20px 20px 14px,
        ),
    ));
}

.dialogHeadingContainer {
    @include desktop-props((
        display: flex,
        align-items: baseline,
    ));
}

.dialogRegularTextContainer {
    @include all-props((
        display: flex,
        justify-content: center,
    ));
    @include mobile-props((
        flex-direction: column,
        transform: (
            0:   translateY(-9px),
            414: translateY(-10px),
        ),
    ));
    @include desktop-props((
        flex-direction: row,
    ));
}

.dialogRegularTexts {
    @include mobile-props((
        display: flex,
        flex-direction: column,
        gap: (
            0: 6px,
            375: 8px
        )
    ));
}

.profileModal {
    @include mobile-props((
        flex-direction: column,
        transform: (
            0:   translateY(0px),
            414: translateY(0px),
        ),
    ));
}

// Усиление селектора, чтобы перебить стили параграфа
.dialogRoot {
    .dialogStrongText {
        @include mobile-props((
            margin: (
                0:   7px 0,
                375: 9px 0,
                414: 10px 0,
            ),
        ));
        @include desktop-props((
            width: 100%,
            font-family: $gilroy,
            font-weight: 700,
            color: var(--color__cuttlefish),
        ));
        @include responsive-desktop-props(1536px, (
            padding-bottom: 10px,
            padding-left: 34px,
            font-size: 24px,
            line-height: 26px,
            letter-spacing: -0.8px,
        ));
    }

    .profileStrongText {
        @include mobile-props((
            margin-top: 0,
            margin-bottom: (
                0: 10px,
                375: 12px,
            )
        ));
    }

    .dialogRegularText,
    .dialogExtraText {
        @include mobile-props((
            font-weight: 400,
            font-size: (
                0: 17px,
                375: 18px,
            ),
            line-height: (
                0: 18px,
                375: 19px,
            )
        ));
        @include desktop-props((
            min-height: auto,
            font-family: $gilroy,
            font-weight: 400,
            color: var(--color__cuttlefish),
        ));

        @include responsive-desktop-props(1536px, (
            font-size: 18px,
            letter-spacing: -0.3px,
            line-height: 19px,
        ));
    }
}

.dialogButtonsContainer {
    @include all-props((
        cursor: pointer,
        -webkit-tap-highlight-color: transparent,
    ));

    @include mobile-props((
        padding: (
            0:   0 20px 0,
            375: 0 22px 0,
            414: 0 32px 0,
        ),
    ));

    @include desktop-props((
        display: flex,
    ));
    &.reversed {
        flex-direction: row-reverse !important;
    }

    @include responsive-desktop-props(1536px, (
        margin-top: 20px,
    ));

    & p {
        @include desktop-props((
            font-size: (
                1024: 19px,
                1680: 22px,
                1920: 23px,
            ),
        ));
    }

    &.oneRow {
        @include mobile-props((
            display: flex,
            flex-direction: row,
            justify-content: center,
            gap: (
                0:   8px,
                375: 11px,
                414: 12px,
            ),
        ));

        @include desktop-props((
            gap: 16px,
        ));
    }
    &.twoRows {
        @include mobile-props((
            display: flex,
            flex-direction: column,
        ));

        & .dialogButton:first-child {
            @include mobile-props((
                margin-bottom: 12px,
            ));
            @include desktop-props((
                margin-right: 16px,
            ));
        }
    }
}

.dialogButtonContainerProfile {
    @include mobile-props((
        padding: (
            0:   8px 16px 0,
            375: 11px 20px 0,
            414: 6px 20px 0,
        ),
    ));
}

.dialogExtraTextContainer {
    @include all-props((
        color: var(--color__lichi),
    ));
    @include mobile-props((
        padding: (
            0:   16px 20px 0,
            375: 20px 22px 0,
        ),
    ));
    @include desktop-props((
        margin-bottom: (
            1024:  -12px,
            1366: -16px,
            1680: -20px,
        ),
        padding: (
            1024:  18px 30px 0 30px,
            1366: 20px 34px 0 34px,
            1680: 24px 40px 0 40px,
            1920: 26px 48px 0 48px,
        ),
    ));
}

.start {
    text-align: start;
}

.center {
    text-align: center;
}
