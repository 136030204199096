@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

$grass: 'app/views/Basket/BasketWithCustomization/img/dotted.img.svg';

.new-dish-list {
    @include all-props((
        margin-top: (
            0: 20px,
            1024: 20px,
        ),
        padding: (
            0: 0 16px,
            375: 0 20px,
            1024: 0 20px,
        ),
        display: flex,
        flex-direction: column,
        gap: (
            0: 18px,
            375: 20px,
            1024: 20px,
        ),
    ));
}

.tableSettingTogglesWrapper {
    @include all-props((
        padding: (
            0: 18px 16px,
            375: 20px 20px 0 20px,
            1024: 20px 20px 0 20px,
        )
    ))
}

/* NewDishCard */

/* Animation classes - анимация цдаления блюда */

$animation-time: 500ms;
$transform-time: $animation-time * 0.57;
$opacity-time: $animation-time * 0.57;

.new-dish-animation-wrapper {
    @include all-props((
        box-sizing: border-box,
        padding-bottom: (
            0: 18px,
            375: 20px,
            1024: 20px,
        ),

        height: (
             0: 140px,
             375: 154px,
             1024: 154px,
        ),
    ));
    transition: opacity $opacity-time, transform $transform-time, height $animation-time, padding $animation-time;
    transition-timing-function: cubic-bezier(0.25 0.1 0.25 1);

    &.exiting {
        opacity: 0;
        height: 0px;
        transform: scale(0.9);
        padding: 0px;
    }

    // &.entering {}
    // &.entered {}
}

.new-dish-card-root {
    position: relative;
    box-sizing: border-box;
    display: flex;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.07);
    background-color: var(--color__coconut);

    @include all-props((
        border-radius: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
        height: (
            0: 140px,
            375: 154px,
            1024: 154px,
        ),
        width: 100%
    ));
}

.new-dish-card-delete-btn {
    @include all-props((
        position: absolute,
        z-index: 5000,
        background: #fff,
        width: (
            0: 22px,
            375: 24px,
            1024: 24px,
        ),
        height: (
            0: 22px,
            375: 24px,
            1024: 24px,
        ),
        top: 12.4%,
        right: 5.4%,
    ));
    & img {
        width: inherit;
        height: inherit;
    }
}

.new-dish-card-image-row {
    @include all-props((
        width: (
            0: 93px,
            375: 103px,
            1024: 103px,
        ),
        height: inherit,
    ));

    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
}

.new-dish-card-left-row {
    @include all-props((
        width: 100%,
        overflow: auto,
    ));
}

/* NewDishImage */

.new-dish-img-root {
    width: inherit;
    height: inherit;
    object-fit: cover;
    object-position: center;
    border-radius: inherit;
}



/* NewDishRightSideComponent */

.new-dish-content-root {
    box-sizing: border-box;
    @include all-props((
        padding: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
    ));
    display: flex;
    width: 100%;
    height: 100%;
}

.new-dish-controls-root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.with_customization {
        justify-content: flex-start;
    }
}

:global(.fake-title) {
    @include all-props((
        font-size: (
            0: 16px,
            375: 17px,
            1024: 17px,
        ),
        font-family: $gilroy,
    ));
    position: absolute;
    visibility: hidden;
}

.dish-control-title {
    @include all-props((
        position: relative,
        font-family: $gilroy,
        font-weight: (
            0: 500,
            1024: 400,
        ),
        color: var(--color__pepper),
        letter-spacing: (
            0: -0.16px,
            375: -0.2px,
            1024: -0.2px,
        ),
        font-size: (
            0: 16px,
            375: 17px,
            1024: 17px,
        ),
        line-height: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
        text-align: start,
        width: (
            0: calc(100% - 40px),
            375: calc(100% - 40px),
            1024: calc(100% - 40px),
        ),
        margin-bottom: (
            0: 9px,
            1024: 9px,
        ),
    ));
}

.dish-title-root {
    position: relative;
}

.dish-title-first-line {
    text-wrap: nowrap;
    display: block;
}

.dish-title-second-line {
    position: relative;
    width: calc(100% - 20px);
    text-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
}

// .dish-title-second-line::after {
//     content: '...';
//     position: absolute;
//     padding-left: 10px;
// }



.dish-control-portion {
    @include all-props((
        font-size: (
            0: 15px,
            375: 16px,
            1024: 16px,
        ),
        font-family: $gilroy,
        color: var(--color__coal)
    ));
    position: relative;
    display: flex;
    align-items: flex-end;
    width: fit-content;

    &.with_customization {
        margin-bottom: 18px;
    }
}

.dish-control-protion__count {
    @include all-props((
        font-weight: (
            0: 55,
            1024: 500,
        ),
        letter-spacing: -0.34px,
        font-family: $n-haas-tx-std,
    ));
}

.dish-control-protion__divider {
    @include all-props((
        letter-spacing: -0.44px,
        font-weight: (
            0: var(--font-weight__medium),
        ),
        padding: (
            0: 0 6px 0 6px,
            375: 0 4px 0 4px,
            1024: 0 4px 0 4px,
        ),
    ));
}

.dish-control-protion__price {
    @include all-props((
        font-weight: 55,
        // font-style: roman,
        letter-spacing: -0.34px,
        font-family: $n-haas-tx-std,
    ));
}

.rubCharWrapper {
    font-weight: 500;
}

.basket-with--dots {
    &::before {
        content: '';
        background: url($grass) repeat-x;
        @include all-props((
            width: (
                0: 105%,
                375: 109.5%,
                1024: 109.5%,
            ),
            z-index: 5,
            position: absolute,
            height: 3px,
            bottom: -2px,
            left: -1px,
        ));
    }
}

.dish-control-select-wrapper {
    width: 100%;
    margin-top: auto;
}


/* UI BUTTON */

.ui-blue-button-root {
    box-sizing: border-box;
    @include all-props((
        max-width: (
            0: 151px,
            375: 166px,
            1024: 166px,
        ),
        padding: (
            0: 4px 9.5px 4.5px 9px,
            375: 6px 11px,
        ),
        gap: (
            0: 5px,
            375: 6px,
            1024: 6px,
        ),
        border-radius: (
            0: 8px,
            375: 10px,
            1024: 10px,
        ),
    ));

    display: flex;
}

// COLORS
.ui-blue-button-root {
    &.unedited {
        border: 1px solid #0097FF;
        &:disabled {
            opacity: 0.5;
        }
    }

    &.edited {
        border: 1px solid #0097FF;
        background-color: #1B9FFF;
        &:disabled {
            opacity: 0.5;
        }
    }

    &.default {
        border: 1px solid #DBDBDB;
        &:disabled {
            opacity: 0.5;
        }
    }
}

.blue-btn-icon {
    @include all-props((
        height: (
            0: 16px,
            375: 17px,
            1024: 17px,
        ),
        width: (
            0: 16px,
            375: 17px,
            1024: 17px,
        ),
    ));
}

// STATE
.blue-btn-icon {
    &.default {
        display: none;
    }
}

.blue-btn-icon__item {
    @include all-props((
        height: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
        width: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
    ));
}

.blue-btn-content {
    @include all-props((
        font-family: $gilroy,
        letter-spacing: (
            0: -0.1px,
            375: 0px,
            1024: 0px,
        ),
        font-size: (
            0: 14px,
            375: 15px,
            1024: 15px,
        ),
        white-space: nowrap,
    ));
}

// COLOR
.blue-btn-content {
    &.unedited {
        font-weight: 700;
        color: #0097FF;
    }

    &.edited {
        font-weight: 700;
        color: #FFFFFF;
    }

    &.default {
        font-weight: 600;
        color: #747474;
    }
}

.newUpsellDividerGrey {
    @include mobile-props((
        height: (
            0: 18px,
            375: 20px,
            1024: 20px,
        ),
        width: 100%,
        background-color: var(--color__coconut),
    ));

}
.newUpsellDividerWhite {
    @include mobile-props((
        height: (
            0: 18px,
            375: 20px,
            1024: 20px,
        ),
        width: 100%,
        background-color: #fff,
    ));
}

.dishes-list__recipe-wrapper {
    @include mobile-props((
        padding: (
            0: 18px 16px 6px,
            375: 20px 20px 6px,
        ),
    ));
    @include desktop-props((
        padding: 0 20px 20px,
    ));
}
