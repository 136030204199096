@import '~styles/variables/index';

.checkbox {
    @include mobile-props((
        position: relative,
        display: inline-block,
        overflow: hidden,
        width: (
            0: 18px,
            360: 21px,
        ),
        height: (
            0: 18px,
            360: 21px,
        ),
    ));

    @include desktop-props((
        position: relative,
        display: inline-block,
        overflow: hidden,
        width: (
            1024: 20px,
            1600: 22px,
            1920: 24px,
        ),
        height: (
            1024: 20px,
            1600: 22px,
            1920: 24px,
        ),
    ));

    &.version-uikit {
        @include all-props((
            width: 24px,
            height: 24px,
        ));
    }

    &.version-uikit-responsive {
        @include responsive-desktop-props(1536px, (
            width: 24px,
            height: 24px,
        ))
    }
}

.checkbox-input {
    position: absolute;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}
