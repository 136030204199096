$animation-duration: 1000ms;


@keyframes plate {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 102.5% 0;
    }
}

.plate {
    position: relative;
    width: 216px;
    height: 178px;
    border-radius: 5px;
    background-image: linear-gradient(232deg, #ffe974, #ffd704 88%, #ffd90e);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.plate-img {
    height: 84%;
    width: 73%;

    /* Почему-то вебпак понял только путь относительно корня проекта */
    background: url('app/components/Plate/img/plate.png') left top no-repeat;

    background-size: cover;
    animation: plate $animation-duration step-start infinite;
}

@media (min-width: 360px) {
    .plate {
        width: 240px;
        height: 198px;
    }
}
@media (min-width: 375px) {
    .plate {
        width: 248px;
        height: 204px;
    }
}
@media (min-width: 414px) {
    .plate {
        width: 276px;
        height: 227px;
    }
}

@media (min-width: 1024px) {
    .plate {
        width: 290px;
        height: 100%;
        background-image: linear-gradient(214deg, #ffe662, #ffd702);
        border-radius: 0;
    }

    .plate-img {
        margin-top: 10px;
        margin-left: 11px;
        height: 218px;
        width: 228px;
    }
}

@media (min-width: 1024px) {
    .plate {
        width: 304px;
    }
    .plate-img {
        transform: scale(1.03);
    }
}
@media (min-width: 1280px) {
    .plate {
        width: 309px;
    }
    .plate-img {
        transform: scale(1.06);
    }
}
@media (min-width: 1366px) {
    .plate {
        width: 312px;
    }
}
@media (min-width: 1440px) {
    .plate {
        width: 328px;
    }
}
@media (min-width: 1600px) {
    .plate {
        width: 350px;
    }
    .plate-img {
        transform: scale(1.11);
    }
}
@media (min-width: 1680px) {
    .plate {
        width: 370px;
    }
    .plate-img {
        transform: scale(1.3);
    }
}
@media (min-width: 1920px) {
    .plate {
        width: 377px;
    }
}
