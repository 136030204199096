@import '~styles/variables/index';
@import '~styles/variables/header-height-vars';

@import '../MobileDish/variables.scss';


$appearing-timeout: 150ms;
$transition-timeout: 300ms; // should be the same as 'CATEGORY_DISAPPEARING_TIMEOUT' in 'HeaderCategory'


$app-banner-height-320: 71px;
$app-banner-height-360: 76px;

$header-height_320: 93px;
$header-height_360: 104px;
$header-height_375: 108px;
$header-height_414: 122px;

$padding_320: 16px;
$padding_375: 20px;
$padding_414: 24px;


.menu-category-list {
    @include all-props((
        box-sizing: border-box,

        width: 100%,
        opacity: 0,
        padding-top: (
            0:    0,
            1024: 195px,
            1280: 224px,
            1366: 236px,
            1920: 262px,
        ),
    ));
    @include desktop-props((
        transition: padding-top 300ms ease,
    ));

    &.entering,
    &.entered {
        @include all-props((
            opacity: 1,
        ));
    }
    &.entering {
        @include all-props((
            transition: opacity $appearing-timeout,
        ));
    }

    &.mode-old {
        @include mobile-props((
            margin-top: (
                0:   $header-height-320,
                375: $header-height-375,
            ),
        ));
    }
}

.menu-category-list__banner {
    @include desktop-props((
        position: sticky,
        bottom: (
            1024: $v3-res1024-gird-gap,
            1280: $v3-res1280-gird-gap,
            1366: $v3-res1366-gird-gap,
            1440: $v3-res1440-gird-gap,
            1600: $v3-res1600-gird-gap,
            1920: $v3-res1920-gird-gap,
        ),
        margin-left: 50%,
        width: (
            1024: calc(50% - #{$v3-res1024-gird-gap}),
            1280: calc(50% - #{$v3-res1280-gird-gap}),
            1366: calc(50% - #{$v3-res1366-gird-gap}),
            1440: calc(50% - #{$v3-res1440-gird-gap}),
            1600: calc(50% - #{$v3-res1600-gird-gap}),
            1920: calc(50% - #{$v3-res1920-gird-gap}),
        ),
    ));

    &.list {
        @include mobile-props((
            margin: (
                0:   initial,
                414: 0 auto,
            ),
            padding: (
                0:   0 $padding_320 $padding_320,
                375: 0 $padding_375 $padding_375,
                414: 0 $padding_375 24px,
            ),
            width: 100%,
            max-width: (
                0:   100%,
                424: 424px,
            ),
            box-sizing: border-box,
        ));
    }
}

:global
.menu-category-list__banner-transition {
    display: none;
    opacity: 0;
    transform: scale(0.9);

    &-enter {
        opacity: 0;
        transform: scale(0.9);
    }
    &-enter-active {
        opacity: 1;
        transform: scale(1);

        transition:
            opacity 300ms ease-in-out 1300ms,
            transform 300ms ease-in-out 1300ms;
    }
    &-enter-done {
        opacity: 1;
        transform: scale(1);
    }

    &-exit {
        opacity: 1;
        transform: scale(1);
    }
    &-exit-active {
        opacity: 0;
        transform: scale(0.9);

        transition:
            opacity 300ms,
            transform 300ms;
    }
    &-exit-done {
        display: none;
        opacity: 0;
        transform: scale(0.9);
    }
}

.menu-category-list__notification-wrap {
    @include mobile_320 {
        position: fixed;
        z-index: 999;
        top: $header-height_320;
        left: 0;
        width: 100%;
    }
    @include mobile_360 {
        top: $header-height_360;
    }
    @include mobile_375 {
        top: $header-height_375;
    }
    @include mobile_414 {
        top: $header-height_414;
    }
}

.menu-category-list__gift-wrap {
    @include mobile_320 {
        margin-bottom: 20px;
    }
    @include mobile_414 {
        margin-bottom: 26px;
    }
}

.menu-category-container {
    @include mobile-props((
        padding-top: (
            0:   $header-height_320 + 8px,
            360: $header-height_360 - 6px,
            375: $header-height_375 + 5px,
            414: $header-height_414 - 9px,
        ),
    ));


    &.with_new_categories {
        @include mobile-props((
            padding-top: (
                0:   $header-height_320 + 2px,
                360: $header-height_360 - 10px,
                375: $header-height_375,
                414: $header-height_414 - 14px,
            ),
        ));
    }
    @include desktop-props((
        opacity: 1,
        filter: blur(0px),
        transition: (
            opacity 500ms ease-in-out,
            filter 500ms ease-in-out
        ),
    ));

    &.is-dishes-updating {
        @include desktop-props((
            opacity: 0.4,
            filter: blur(2px),
        ));
    }
}

.menu-category-list__des-banner {
    @include mobile_320 {
        margin: 16px 14px 64px;
    }
    @include mobile_360 {
        margin: 16px 16px 79px;
    }
    @include mobile_375 {
        margin: 20px 20px 82px;
    }
    @include mobile_414 {
        margin: 22px 18px 100px;
    }
}

.menu-category-list__banner-ny {
    @include responsive-mobile-props(375px, (
        height: 260px,
        width: 334px,
        border-radius: 14px,
        margin-bottom: 60px,
    ));
    @include mobile-props((
        overflow: hidden,
        margin-left: auto,
        margin-right: auto,
    ));

    @include responsive-desktop-props(1600px, (
        border-radius: 14px,
    ));
    @include desktop-props((
        overflow: hidden,
        width: 40vw,
        height: calc(40vw * 0.7724550898203593),
        margin-left: auto,
        margin-right: auto,
    ));

    a {
        display: block;
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
