@import '~styles/variables/index';


$image-animation-timing: 7s;

$grass: 'app/components/CustomizationComment/CustomizationCommentFiltersImg/img/grass.png';
$lemon: 'app/components/CustomizationComment/CustomizationCommentFiltersImg/img/lemon.png';
$egg: 'app/components/CustomizationComment/CustomizationCommentFiltersImg/img/egg.png';

$lemon-color: #df9100;
$grass-color: #668903;


@keyframes blink {
    0% {
        opacity: 0;
        transform: scale(0.62);
        background-image: url($lemon);
    }
    5%, 26% {
        opacity: 1;
        transform: scale(1);
    }
    32% {
        background-image: url($lemon);
    }
    33% {
        opacity: 0;
        transform: scale(0.62);
        background-image: url($egg);
    }
    38%, 59% {
        opacity: 1;
        transform: scale(1);
    }
    65% {
        background-image: url($egg);
    }
    66% {
        opacity: 0;
        transform: scale(0.62);
        background-image: url($grass);
    }
    71%, 93% {
        opacity: 1;
        transform: scale(1);
    }
    99% {
        opacity: 0;
        transform: scale(0.62);
        background-image: url($grass);
    }
    100% {
        opacity: 0;
        background-image: url($lemon);
    }
}

@keyframes change-color {
    0% {
        opacity: 0.5;
        fill: $lemon-color;
    }
    5%, 28% {
        opacity: 0.8;
        fill: $lemon-color;
    }
    33% {
        opacity: 0.5;
        fill: var(--color__egg);
    }
    38%, 61% {
        opacity: 0.8;
        fill: var(--color__egg);
    }
    66% {
        opacity: 0.5;
        fill: $grass-color;
    }
    71%, 95% {
        opacity: 0.8;
        fill: $grass-color;
    }
    100% {
        opacity: 0.5;
        fill: $lemon-color;
    }
}


.customization-comment__title-img-container {
    flex: none;
    position: relative;
    display: inline-block;
    margin-left: -9px;
    margin-right: 18px;
    width: 58px;
    height: 58px;

    @media (min-width: 360px) {
        margin-left: -10px;
    }
    @media (min-width: 360px) {
        margin-left: -11px;
        width: 67px;
        height: 67px;
    }
    @media (min-width: 375px) {
        width: 72px;
        height: 72px;
    }

    @media (min-width: 1024px) {
        width: 40px;
        height: 40px;
        margin-left: -5px;
        margin-right: 12px;
    }
    @media (min-width: 1280px) {
        margin-top: 2px;
        margin-right: 8px;
    }
    @media (min-width: 1366px) {
        width: 54px;
        height: 54px;
        margin-left: -3px;
        margin-right: 12px;
    }
    @media (min-width: 1440px) {
        margin-right: 10px;
    }
    @media (min-width: 1680px) {
        width: 61px;
        height: 61px;
        margin-left: -6px;
        margin-right: 12px;
    }
    @media (min-width: 1920px) {
        width: 80px;
        height: 80px;
        margin-left: -6px;
        margin-right: 18px;
    }
}

.customization-comment__title-img {
    position: absolute;
    bottom: 7.5%;
    right: 3%;
    flex: none;
    width: 100%;
    height: 100%;
    background: url($grass) no-repeat;
    background-size: contain;

    &.cycled-animation {
        opacity: 1;
        animation: blink $image-animation-timing infinite;
    }
    &.fixed-pic {
        opacity: 1;
        transform: scale(1);

        transition:
            opacity $image-animation-timing,
            transform $image-animation-timing;
    }
}

.icon-container {
    position: absolute;
    display: flex;
    top: 19px;
    left: 20px;
    height: 23px;
    width: 23px;

    @media (min-width: 360px) {
        top: 22px;
        left: 23px;
        height: 27px;
        width: 27px;
    }
    @media (min-width: 375px) {
        top: 24px;
        left: 25px;
        height: 29px;
        width: 29px;
    }
    @media (min-width: 1024px) {
        top: 11px;
        left: 12px;
        height: 20px;
        width: 20px;
    }
    @media (min-width: 1366px) {
        top: 15px;
        left: 16px;
        width: 27px;
        height: 27px;
    }
    @media (min-width: 1680px) {
        top: 18px;
        left: 20px;
        width: 27px;
        height: 27px;
    }
    @media (min-width: 1920px) {
        top: 25px;
        left: 27px;
        width: 32px;
        height: 32px;
    }
}

.circle {
    margin: auto;
    border-radius: 50%;
    background: var(--color__coconut);
    height: 100%;
    width: 100%;
}

.cross-icon-wrapper {
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    height: 12px;
    width: 12px;

    @media (min-width: 360px) {
        height: 14px;
        width: 14px;
    }
    @media (min-width: 375px) {
        height: 16px;
        width: 16px;
    }
    @media (min-width: 1024px) {
        height: 11px;
        width: 11px;
    }
    @media (min-width: 1366px) {
        height: 15px;
        width: 15px;
    }
    @media (min-width: 1680px) {
        height: 13px;
        width: 13px;
    }
    @media (min-width: 1920px) {
        height: 17px;
        width: 17px;
    }
}

.cross-icon {
    margin: auto;
    height: 100%;
    width: 100%;

    &.cycled-animation g {
        animation: change-color $image-animation-timing infinite linear;
    }

    &.fixed-pic g {
        opacity: 0.8;
        fill: $grass-color;

        transition:
            opacity $image-animation-timing linear,
            fill $image-animation-timing linear;
    }
}
