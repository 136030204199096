@import '~styles/variables/index';

.root {
    @include all-props((
        position: relative,
        font-family: $gilroy,
    ));
    @include mobile-props((
        width: auto,
        height: auto,
    ));
    @include desktop-props((
        width: auto,
        height: auto,
    ))
}

.selectedCityFlexContainer {
    @include all-props((
        display: flex,
        align-items: center,
        gap: 2px,
        cursor: pointer,
    ));
}

.selectedCity {
    @include all-props((
        line-height: normal,
        letter-spacing: -0.3px,
    ));
    @include mobile-props((
        color: #9C9C9C,
        font-size: (
            0: 15px,
            375: 17px,
        ),
        font-weight: 500,
    ));
    @include desktop-props((
        color: #9C9C9C,
        font-size: (
            1024: 17px,
            1366: 18px,
            1680: 19px,
        ),
        font-weight: 500,
    ))
}

.selectOptionsContainer {
    @include desktop-props((
        position: absolute,
        top: (
            1024: 27px,
            1366: 27px,
            1680: 31px,
        ),
        right: 0,
        width: 244px,
        padding: (
            1024: 12px,
            1366: 14px,
            1600: 14px,
        ),
        border-radius: 8px,
        box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.07),
        background-color: #FFF,
        z-index: 5,
        box-sizing: border-box,
    ))
}

.cityOption {
    @include all-props((
        padding: 10px 14px,
        border-radius: 6px,
        font-size: 16px,
        line-height: 18px,
        font-weight: 400,
        letter-spacing: -0.2px,
        white-space: nowrap,
        color: #4F4F4F,
    ));
    &:hover {
        background-color: #F1F1F1;
    }
}

.arrowIconWrapper {
    @include mobile-props((
        margin-top: (
            0: -2px,
            375: 0px,
        ),
    ));
    @include desktop-props((
        display: flex,
        align-items: center,
        margin-top: (
            1366: 2px,
        )
    ))
}

.arrowIcon {
    @include mobile-props((
        width: (
            0: 8px,
            375: 10px,
        ),
        height: (
            0: 8px,
            375: 10px,
        ),
    ));
    @include desktop-props((
        width: (
            1024: 9px,
            1366: 9px,
            1680: 10px,
        ),
        height: (
            1024: 9px,
            1366: 9px,
            1680: 10px,
        ),
        // transform-origin: 50% 50%,
    ))
}

.rotated {
    transform: rotate(-180deg);
}

.rootScaling {
    .selectedCity {
        @include responsive-desktop-props(1536px, (
            font-size: 17px,
        ))
    }
    .selectOptionsContainer {
        @include responsive-desktop-props(1536px, (
            top: 27px,
            width: 244px,
            padding: 14px,
            border-radius: 8px,
        ))
    }
    .cityOption {
        @include responsive-desktop-props(1536px, (
            font-size: 16px,
            line-height: 18px,
            padding-top: 10px,
            padding-bottom: 10px,
            padding-left:  14px,
            padding-right: 14px,
            border-radius: 6px,
        ))
    }
    .arrowIconWrapper {
        @include responsive-desktop-props(1536px, (
            margin-top: 3px,
            height: 20px,
        ))
    }
    .arrowIcon {
        @include responsive-desktop-props(1536px, (
            width: 10px,
            height: 10px,
        ))
    }
}
