@import '~styles/variables/index';

@keyframes loading {
    0% { left: 0%; margin-left: -50%}
    50% { left: 100%; margin-left: 0% }
    100% { left: 0%; margin-left: -50% }
}

.skeleton-item {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--color__onion);
    overflow: hidden;
    border-radius: 4px;

    &.with-animation {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            width: 50%;
            min-width: 20px;
            height: 100%;

            background-image:
                linear-gradient(
                    90deg,
                    rgba(#fff , 0) 0,
                    rgba(#fff , 0.5) 50%,
                    rgba(#fff , 0) 100%
                );

            animation: loading 3s infinite;
        }
    }
}
