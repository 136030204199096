@import "~global/styles/fonts/index.scss";
@import '~styles/variables/index';

.step-payment-background-container {
    @include all-props((
        padding: (
            0: 40px 0 113px,
            375: 40px 0 123px,
            1024: 40px 0 123px,
        ),
        background-color: #fff
    ));
}

.payment-method-root {
    box-sizing: border-box;
    width: 100%;
    @include all-props((
        width: (
            0: auto,
            1024: 372px,
        ),
        margin: (
            0: 0 0 40px,
            1024: 0 auto 40px,
        ),
        padding: (
            0: 0 16px,
            375: 0 20px,
            1024: 0,
        ),
    ));
    div {
        box-sizing: border-box;
    }
}

.payment-method-buttons {
    @include all-props((
        border-radius: (
            0: var(--sizeBorderRadiusXL),
            375: var(--sizeBorderRadiusXXL),
            1024: var(--sizeBorderRadiusXXL),
        ),
    ));
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color__salt);
}

.payment-method-button {
    @include all-props((
        padding: (
            0: 13px 19px 13px 21px, // TODO: 13px 20px,
            375: 16px 18px,
            1024: 16px 18px,
        ),
    ));
    border-bottom: 1px solid var(--color__salt);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:nth-last-child(1) {
        border: none;
    }
}

.payment-method-button {
    &.subscription {
        & .payment-method-button__text {
            color: var(--color__grey-salt);
        }
        & .payment-method-button__price {
            color: var(--color__grey-salt);
        }
    }
}

.payment-method-button-left  {
    display: flex;
    align-items: center;
}

.payment-method-button__checkbox {
    display: inline-block;
    @include all-props((
        width: 22px,
        height: 22px,
    ));
    border-radius: 100%;
    border: 2px solid var(--color__grey-salt);
}

.payment-method-button__checkbox {
    &.checked  {
        border: 6px solid var(--color__egg);
    };
}

.payment-method-button__text {
    transition: color 0.1s;
    display: inline-block;
    font-family: $gilroy;
    font-weight: 600;
    color: var(--color__pepper);
    letter-spacing: -0.2px;

    @include all-props((
        font-size: (
            0: 15px,
            375: 17px,
            1024: 17px,
        ),
        margin-left: (
            0: 7px,
            375: 12px,
            1024: 12px,
        ),
    ));
}

.payment-method-button__price {
    transition: color 0.1s;
    color: var(--color__coal);
}
