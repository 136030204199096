@import '~styles/variables/index';

.selectDropdownRoot {
    @include all-props((
        position: relative,
        font-family: $gilroy,
        font-style: normal,
        letter-spacing: -0.2px,
        border: 1.5px solid #EDEDED,
        box-sizing: border-box,
        color: var(--color__pepper),
        cursor: pointer,
    ));
    @include mobile-props((
        width: 100%,
        height: (
            0: 50px,
            375: 54px,
        ),
        padding: (
            0: 8px 14px,
            375: 9px 14px,
        ),
        border-radius: 8px,
        font-weight: 600,
    ));
    @include desktop-props((
        width: 100%,
        height: 54px,
        padding: 9px 14px,
        border-radius: 8px,
        font-weight: 500,
    ))
}

.selectDropdownRootDropdownOpenned {
    @include desktop-props((
        border: 1.5px solid #FFDB19,
    ))
}

.selectDropdownValueFlexContainer {
    @include all-props((
        width: 100%,
        height: 100%,
        display: flex,
        justify-content: space-between,
        align-items: center,
    ))
}

.selectDropdownChosenValue {
    @include mobile-props((
        font-size: (
            0: 17px,
            375: 18px,
        ),
        line-height: (
            0: 18px,
            375: 20px,
        ),
        font-weight: 600,
    ));
    @include desktop-props((
        font-size: 18px,
        line-height: 20px,
        font-weight: 500,
    ));
}

.selectDropdownArrowIcon {
    @include mobile-props((
        width: (
            0: 18px,
            375: 20px,
        ),
        height: (
            0: 18px,
            375: 20px,
        ),
    ));
    @include desktop-props((
        width: 20px,
        height: 20px,
    ))
}

.selectDropdownArrowIconRotated {
    @include desktop-props((
        transform: rotate(180deg)
    ))
}

.selectDropdownOptionsList {
    @include desktop-props((
        position: absolute,
        top: 54px,
        left: -1px,
        z-index: 5,
        width: calc(100% + 2px),
        padding: 14px,
        border-radius: 8px,
        box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.07),
        background-color: #FFFFFF,
        box-sizing: border-box,
    ))
}

.selectDropdownListOption {
    @include desktop-props((
        font-size: 16px,
        line-height: 18px,
        font-weight: 400,
        padding: 10px 14px,
        border-radius: 8px,
        box-sizing: border-box,
    ));
    &:hover {
        @include desktop-props((
            cursor: default,
            background-color: #F1F1F1,
        ));
    }
}
