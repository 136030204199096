@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

.customIngredientsSelectRoot {
    @include all-props((
        width: 100%,
        margin-top: (
            0: 16px,
            375: 12px,
            1024: 12px,
        ),
    ));
}

.customIngredientsSelectButton {
    @include all-props((
        width: 100%,
        padding: (
            0: 7px 12px,
            375: 9px 14px,
            1024: 9px 14px,
        ),
        border-radius: (
            0: 7px,
            1024: 7px,
        ),
        background-color: var(--color__salt),
        display: flex,
        justify-content: space-between,
        align-items: center,
    ));

    &.menu {
        @include all-props((
            height: (
                0: 40px,
                375: 46px,
            ),
            border-radius: (
                0: 7px,
                375: 8px,
            ),
            background-color: var(--color__salt),
            padding: 13px 14px,
        ));
    }

    &.basket {
        @include all-props((
            height: (
                0: 32px,
                375: 36px,
                1024: 36px,
            ),
            padding-left: (
                0: 12px,
                1024: 12px,
            )
        ))
    }
}

.customIngredientsSelectText {
    @include all-props((
        font-family: $gilroy,
        font-size: (
            0: 15px,
            375: 16px,
            1024: 16px,
        ),
        font-weight: 600,
        line-height: (
            0: 18px,
            1024: 18px,
        ),
        letter-spacing: -0.2px,
        // overflow: hidden,
        width: (
            0: 92%,
            375: 92%,
            1024: 92%,
        ),
        display: flex,
    ));

    &.menu {
        @include all-props((
            font-size: (
                0: 16px,
                375: 17px,
                1024: 17px,
            ),
        ));

        & .clampText {
            color: var(--color__pepper);
        }
    }
}

.ingredientValue {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    // padding-right: 10px;
    // color: red;
}

.customIngredientsSelectIcon {
    @include all-props((
        width: (
            0: 14px,
            1024: 14px,
        ),
        height: (
            0: 14px,
            1024: 14px,
        ),
    ));

    &.menu {
        & svg path {
            fill: var(--color__hering),
        }
    }

    &.basket {
        @include all-props((
        width: (
            0: 12px,
            1024: 12px,
        ),
        height: (
            0: 12px,
            1024: 12px,
        ),
    ));
    }
}

.dialog {
    background-color: red;
    position: relative;
    z-index: 1;
}


/* SELECT CONTENT */

.selectIngredientsRoot {
    @include all-props((
        text-align: start,
    ));
}

.selectIngredientsTitle {
    @include all-props((
        padding: (
            0: 20px 16px 4px 16px,
            375: 20px 20px 5px 20px,
            1024: 0px 0px 10px 0px,
        ),

        color: #424242,
        font-family: $gilroy,
        font-size: (
            0: 21px,
            375: 24px,
            1024: 24px,
        ),
        font-style: normal,
        font-weight: 800,
        line-height: (
            0: 22px,
            375: 25px,
            1024: 25px,
        ),
        letter-spacing: (
            0: -0.6px,
            1024: -0.8px,
        ),
    ));
}

.selectIngredientsItems {
    @include all-props((
        padding: (
            0: 0px 16px,
            375: 0px 20px,
            1024: 0px 0px 20px 0,
        ),
    ));
}

.selectIngredientsItemButton {
    @include all-props((
        display: flex,
        justify-content: space-between,
        align-items: center,
        width: 100%,
        text-align: start,
        gap: (
            0: 6px,
            1024: 16px,
        ),
        padding: (
            0: 12px 0,
            1024: 14px 0,
        ),
        border-bottom: 1px solid #F1F1F1,
    ));


    &:nth-last-child(-n + 1) {
        border: none;
    }
}

.selectIngredientsItemText {
    @include all-props((
        color: var(--color__cuttlefish),
        font-family: $gilroy,
        font-size: (
            0: 17px,
            375: 18px,
            1024: 18px,
        ),
        font-style: normal,
        font-weight: 500,
        line-height: 18px,
        letter-spacing: -0.2px,
        text-align: start,
        flex-basis: 91%,
    ));

    &:global(.selected) {
        font-weight: 700;
    }
}

.ingredientType {
    color: var(--color__hering);
    @include all-props((
        padding-right: (
            0: 4px,
            375: 6px,
            1024: 6px,
        )
    ));

}

.selectIngredientsSubmit {
    @include all-props((
        padding: (
            0: 12px 16px 0 16px,
            375: 13px 20px 0 20px,
            1024: 13px 20px 0 20px,
        ),
    ));
}

.clampText {
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}
