@import '~styles/variables/index';


.header-category__button {
    @include mobile_320 {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background: transparent;
        text-align: center;
        color: var(--color__cuttlefish);

        transition: color 100ms;

        &:active {
            color: var(--color__oyster);
        }
    }
}

.header-category__content {
    @include mobile-props((
        position: relative,
        display: inline-flex,
        justify-content: center,
    ));
}

.header-category__arrow-container {
    @include mobile-props((
        position: absolute,
        top: 0,
        right: 0,
        transform: (
            0:   translateY(6px),
            360: translateY(7px),
            375: translateY(9px),
        ),
    ));
}

.header-category__filter-button-container {
    @include mobile-props((
        position: absolute,
        right: 0,
        top: 0,
        bottom: 0,
    ));

    &.is-above-notifications {
        @include mobile-props((
            z-index: 1003,
        ));
    }
}

.header-category__filter-button-container.is-some-filter--selected {
    &::after {
        content: "";
        @include mobile-props((
            cursor: pointer,
            position: absolute,
            background-color: #ffdb00,
            width: 9px,
            height: 9px,
            border-radius: 2px,
            transform: (
                0: translate(329%, -479%),
                360: translate(337%,-518%),
                375: translate(402%,-515%),
                414: translate(656%,-564%),
            )
        ));
    }
}
