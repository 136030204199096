@import '~styles/variables/index';

.basket-shifter {
    @include mobile-props((
        transform: translateX(0px),
        transition: transform 600ms ease-out,
    ));
    &.is-shifted {
        @include mobile-props((
            transform: translateX(-87px),
            // margin-left: -87px,
        ));
    }
}

.basket-mobile-select-overlay {
    background: transparent;
    border: none;
    padding: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 5;
}

.basket-notifications {
    position: fixed;
    left: 0;
    width: 100%;

    opacity: 1;
    z-index: 10000;
    transition: opacity 500ms ease-in-out 500ms;

    &.is-hidden {
        pointer-events: none;
        opacity: 0;
        transition: opacity 500ms ease-in-out;
    }

    @media (min-width: 1024px) {
        position: static;
    }
}

.customization-comment {
    @include all-props((
        padding: (
            0:   38px $basket-horizontal-paddings_320 16px,
            375: 35px $basket-horizontal-paddings_375 21px,
            414: 41px $basket-horizontal-paddings_375 25px,
            1024: 41px 20px 20px 20px,
        ),
        border-radius: (
            1024: 4px,
        ),
        box-shadow: (
            1024: 0 8px 10px 4px rgba(1, 1, 1, 0.01),
        ),
        background-color: var(--color__coconut),
        border-bottom: 7px solid var(--color__salt),

    ));
}

.customization-comment__title {
    @include all-props((
        margin-bottom: (
            0:   8px,
            375: 12px,
            414: 8px,
            1024: 12px,
        ),
    ));

    &.is-comment-hidden {
        @include mobile-props((
            margin-bottom: 0,
        ));
    }
}


.basket-categories__scroll-content {
    @include all-props((
        transition: filter 600ms 300ms,
        filter: none,
        // padding-bottom: (
        //     0: 68px,
        //     375: 74px,
        // ),
        margin: 0 auto,
        width: 100%,
    ));

    @include mobile-props((
        height: auto,
    ));

    @include desktop-props((
        height: 100%,
        overflow: auto,
    ));

    &.basket-categories__scroll-content--preview {
        @include mobile-props((
            background-color: var(--color__coconut),
            overflow: auto,
            -webkit-overflow-scrolling: touch,
        ));
    }

    &.blur {
        @include all-props((
            transition: filter 600ms alternate,
            filter: blur(25px),
        ));
    }

    &::-webkit-scrollbar {
        display: none,
    }
}


.basket-filler {
    @include all-props((
        position: fixed,
        z-index: 4,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background-color: var(--color__coconut),
        opacity: 0,
        visibility: hidden,
    ));
    &.active {
        @include all-props((
            opacity: 1,
            visibility: visible,
            transition: opacity 200ms 1300ms,
        ));
    }
}

.basket-footer {
    @include all-props((
        opacity: 1,
        transition: opacity 500ms ease-in-out,
        min-height: (
            0: 68px,
            360: 70px,
            375: 74px,
            1024: 74px,
        ),
    ));
}

.basket__empty-rouble-container {
    @include all-props((
        display: inline-block,
        height: (
            0:   10px,
            360: 12px,
            1024: 12px,
        ),
        width: 10px,
        margin-left: 2px,
    ));
}


.basket-dishes-selections-root {
    @include mobile-props((
        margin: 0 auto,
        padding-top: (
            0: 30px,
            375: 40px,
        ),
        margin-bottom: (
            0: 30px,
            375: 40px,
        ),
    ));
}
.basket-menu-selections-root {
    @include mobile-props((
        background-color: var(--color__salt),
        margin: 0 auto,
        padding-top: (
            0: 30px,
            375: 40px,
        ),
        margin-bottom: (
            0: 30px,
            375: 40px,
        ),
    ));


    @include desktop-props((
        background-color: var(--color__salt),
        padding: 30px $basket-horizontal-paddings_375 40px,
    ));

    &.basket-menu-selections-root--white {
        @include mobile-props((
            background-color: var(--color__coconut),

            padding-top: (
                0: 26px,
                375: 30px,
            ),
            padding-bottom: (
                0: 0px,
                375: 0px,
            ),
            margin-bottom: 0,
        ));
    }

    &.BR1042 {
        @include all-props((
            padding-top: (
                0: 19px,
                375: 28px,
            ),
            padding-bottom: (
                375: 8px,
            ),
            margin-bottom: 0,
            background-color: #FFFFFF
        ));
    }
}

.basket-menu-selections-slider__list-container {
    @include mobile-props((
        overflow-y: scroll,
        scrollbar-width: none, // ff
        position: relative,
    ));

    &::-webkit-scrollbar { // chrome, safari, opera
        @include mobile-props((
            display: none,
        ));
    }
}
.basket-dishes-selections-title {
    @include all-props((
       padding: (
           0: 0px 16px 10px 16px,
           375: 0px 19px 12px 19px,
           1024: 0px 19px 12px 19px,
       ),
   ));
}
.basket-menu-selections-title {
    @include all-props((
        font: inherit,
        display: block,
        padding: (
            0: 0px 16px 12px 16px,
            375: 0px 19px 12px 19px,
            1024: 0px 0 12px 0,
        ),
    ));
    @include desktop-props((
        font-size: 21px,
        font-weight: 700,
        line-height: 22px,
    ));
}

.basket-check-area {
    @include all-props((
        position: relative,
        z-index: 2,
        background-color: var(--color__coconut),
        padding: (
            0:   26px $basket-horizontal-paddings_320,
            375: 30px $basket-horizontal-paddings_375 54px,
            1024: 30px $basket-horizontal-paddings_375,
        ),
   ));
}
