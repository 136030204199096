@keyframes loading-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.loading {
    width: 18px;
    height: 18px;
    opacity: 0.69;
    animation: loading-rotate 2s ease-in-out infinite;
}
