@import '~styles/variables/index';
@import './variables.scss';


$transition-timeout: 300ms; // should be the same as 'CATEGORY_DISAPPEARING_TIMEOUT' in 'HeaderCategory'

.dish-card__root {
    box-sizing: border-box;

    *, *::after, *::before {
        box-sizing: border-box;
    }
    overflow: hidden;
}

// margins are defined like this
// (in '.dish-card__container') because
// if margins and height are in '.dish-card__root'
// there are some freezes when dish flies to basket and
// height and margins are collapsing at the same time
.dish-card__root {
    @include all-props((
        margin: (
            0:   0 $horizontal-padding__320,
            375: 0 $horizontal-padding__375,
            1024: 0 $horizontal-padding__375,
        ),
        height: (
            0:   calc($card-height__320 + $dish-margin-bottom__320),
            375: calc($card-height__375 + $dish-margin-bottom__375),
            414: calc($card-height__414 + $dish-margin-bottom__375),
            1024: calc($card-height__414 + $dish-margin-bottom__375),
        ),
        width: 100%,
    ));

    &.withCustomizationSelect {
        @include mobile-props((
            height: (
                0:   calc($card-height__320 + calc($dish-margin-bottom__320 + 36px)),
                375: calc($card-height__375 + calc($dish-margin-bottom__375 + 40px)),
                414: calc($card-height__414 + calc($dish-margin-bottom__375 + 39px)),
            ),
        ));
    }

    &.large {
        @include all-props((
            height: (
                0: calc(382px + $dish-margin-bottom__320 + 36px),
                375: calc(443px + $dish-margin-bottom__375 + 40px),
                1024: calc(443px + $dish-margin-bottom__375 + 40px),
            ),
        ))
    }
    &.withGift {
        @include all-props((
            height: (
                0:   calc(254px + calc($dish-margin-bottom__320 + 39px)),
                375: calc(338px + calc($dish-margin-bottom__375 + 45px)),
                414: calc(338px + calc($dish-margin-bottom__375 + 45px)),
                1024: calc(338px + calc($dish-margin-bottom__375 + 45px)),
            ),
        ))
    }
}

.dish-card__container {
    @include all-props((
        overflow: hidden,
        position: relative,  // for move down when add to basket
        display: flex,
        flex-direction: column,
        align-items: center,

        width: 100%,
        background-color: var(--color__coconut),
        box-shadow: none,

        margin-bottom: (
            0:   $dish-margin-bottom__320,
            375: $dish-margin-bottom__375,
            1024: $dish-margin-bottom__1024,
        ),

        opacity: 1,
        transform: scale(1),
        transition: (
            opacity $transition-timeout,
            transform $transition-timeout
        ),
    ));

    &.is-dish-updating {
        @include all-props((
            opacity: 0,
            transform: scale(0.9),
        ));
    }
}


.dish-card__image-container {
    @include all-props((
        position: relative, // for MiniDetails
        overflow: hidden,
        height: (
            0: $dish-image-height,
            414: $dish-image-max-height,
            1024: $dish-image-max-height,
        ),
        width: 100%,
        border-radius: (
            0: var(--sizeBorderRadiusL),
            375: var(--sizeBorderRadiusXL),
            1024: var(--sizeBorderRadiusXL),
        ),
        box-shadow: none,
    ));

    &.details-visible {
        box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.02);
    }

    &.large {
        @include all-props((
            height: (
                0: 382px,
                375: 443px,
                1024: 443px,
            ),
        ))
    }

    &.customSize {
        @include all-props((
            height: (
                0: 186px,
                375: 257px,
                1024: 257px,
            )
        ))
    }

}

.dish-card__image-sold-out-wrapper {
    @include all-props((
        height: 100%,
    ));

    &.visible {
        @include all-props((
            pointer-events: none,
            opacity: 0.40,
        ));
    }
}

.dish-card__description-container {
    @include all-props ((
        width: 100%,
    ));
}

.spa-march-dish-label-wrapper {
    position: absolute;

    @include all-props((
        left: (
            0: 3.5%,
            1024: 4.5%,
        ),
        bottom: (
            0: 3.5%,
            1024: 6.5%,
        )
    ));

    & img {
        @include responsive-mobile-props(375px, (
            height: 21px,
        ));
        @include responsive-desktop-props(1680px, (
            height: 24px,
        ));
    }
}
