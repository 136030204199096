$font-path: '../../assets/fonts';
$font-name: 'Gilroy';

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-Thin.woff") format('woff');
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-UltraLight.woff") format('woff');
  font-display: swap;
  font-weight: 200;
}

@font-face {
  font-family: $font-name;
  src: url("#{$font-path}/#{$font-name}-Light.woff") format('woff');
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-Regular.woff") format('woff');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-Medium.woff") format('woff');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-Semibold.woff") format('woff');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-Bold.woff") format('woff');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-Extrabold.woff") format('woff');
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-Black.woff") format('woff');
  font-display: swap;
  font-weight: 900;
}
