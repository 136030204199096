@import '~styles/variables/index';
@import '~styles/variables/header-height-vars';


$burger-transition-step: 300ms;


@keyframes open-rect-1 {
    from { transform: translate3d(0, 0px, 0) rotate(0deg); }
    to   { transform: translate3d(10px, 0px, 0) rotate(45deg); }
};
@keyframes open-rect-2 {
    from { opacity: 1; }
    to   { opacity: 0; }
};
@keyframes open-rect-3 {
    from { transform: translate3d(0, 0px, 0) rotate(0deg); }
    to   { transform: translate3d(-13px, 12px, 0) rotate(-45deg); }
}

@keyframes close-rect-1 {
    from { transform: translate3d(10px, 0px, 0) rotate(45deg); }
    to   { transform: translate3d(0, 0px, 0) rotate(0deg); }
};
@keyframes close-rect-2 {
    from { opacity: 0; }
    to   { opacity: 1; }
};
@keyframes close-rect-3 {
    from { transform: translate3d(-13px, 12px, 0) rotate(-45deg); }
    to   { transform: translate3d(0, 0px, 0) rotate(0deg); }
}

.navigation {
    @include all-props((
        background-color: var(--color__coconut),
        box-sizing: border-box,
        display: flex,
        justify-content: space-between,
        align-items: center,
        position: relative,
        z-index: 3,
        padding-bottom: 0,
        margin-bottom: -1px,
    ));
    @include desktop-props((
        border-bottom: solid 1px $wild-sand,
        box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.03),
        padding-left: 11px,
        padding-right: $v3-res960-gird-gap,
        padding-bottom: 0,
        transition: all 300ms ease,
        height: (
            1024: 70px,
            1366: 78px,
        ),
        padding: (
            1024: 0 $v3-res1024-gird-gap,
            1280: 0 $v3-res1280-gird-gap,
            1366: 0 $v3-res1366-gird-gap,
            1440: 0 $v3-res1440-gird-gap,
            1600: 0 $v3-res1600-gird-gap,
            1920: 0 $v3-res1920-gird-gap,
        ),
    ));
}

.navigation-wrapper {
    width: 100%;

    @include desktop_960 {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.navigation-navbar {
    @include desktop_960 {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        height: 50px;
        justify-content: center;
        flex-direction: row;
        max-height: initial;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
}

.logotype {
    @include desktop_960 {
        box-sizing: border-box;
        position: relative;
        top: -1px;
        left: -2px;
        margin-right: 28px;

        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 21px;

        svg {
            display: block;
            width: 32px;
            height: 32px;
        }
    }
    @include desktop_1280 {
        top: -6px;
        left: -6px;
        margin-right: 33px;

        svg {
            width: 157px;
            height: 20px;
        }
    }
    @include desktop_1366 {
        top: -4px;
        margin-right: 83px;

        svg {
            width: 181px;
            height: 23px;
        }
    }
    @include desktop_1440 {
        left: -4px;
        margin-right: 85px;
    }
    @include desktop_1600 {
        left: -7px;
        margin-right: 80px;
    }
    @include desktop_1680 {
        // left: -9px;
        // margin-right: 71px;
    }
    @include desktop_1920 {
        // left: -7px;
        // margin-right: 73px;
    }
}

.logotype-action {
    border: none;
    background: transparent;
    outline: none;
}

.nav {
    display: flex;
    align-items: center;
    flex-direction: column;

    & + & {
        margin-top: 14px
    }
    @include desktop_960 {
        flex-direction: row;
        height: 100%;
        align-items: center;

        padding-bottom: 6px;
    }
    @include desktop_1366 {
        padding-bottom: 3px;
    }
}

.nav__item-wrap {
    font-size: 1em;
    margin: 0;
    padding: 0;
    line-height: 1;
}

.nav__item {
    color: $silver;
    font-family: $gilroy-light;
    font-size: 15px;
    margin-right: 28px;
    letter-spacing: -0.2px;
    position: relative;
    text-decoration: none;
    transition: color 300ms ease;

    &:visited {
        color: $silver;
    }

    &:hover {
        color: var(--color__cuttlefish);
        font-family: $gilroy-medium;
        letter-spacing: 0px;
    }

    &.active {
        top: 1px;
        margin-right: 23px;

        font-family: $gilroy-medium;

        font-weight: 600;
        color: var(--color__cuttlefish);

        &:hover {
            letter-spacing: -0.2px;
        }
        &::after {
            content: '';
            // bottom: 5px;
            // height: 4px;
            position: absolute;
            top: 43px;
            left: 3px;
            right: 3px;
            height: 4px;
            background: $bright-sun;
        }
    }

    &.hidden {
        display: none;
    }

    &.ny {
        display: none;
        display: flex;
        align-items: inherit;

        @media (min-width: 1024px) {
            display: flex;
        }
    }

    @include desktop_960 {
        &.active {
            font-family: $gilroy-semibold;
        }
    }
    @media (min-width: 1024px) and (max-width: 1280px) {
        margin-right: 14px;
    }
    @media (min-width: 1150px) and (max-width: 1280px) {
        margin-left: 24px;
    }
    @include desktop_1280 {
        margin-left: 0;
        margin-right: 48px;

        &.active {
            margin-right: 43px;

            &::after {
                top: 43px;
            }
        }
    }
    @include desktop_1366 {
        margin-right: 54px;

        &.active {
            margin-right: 61px;

            font-size: 16px;
            letter-spacing: -0.25px;

            &::after {
                top: 46px;
                left:  2px;
                right: 2px;
            }
        }
    }
}

.nav__item-title {
    @include desktop_960 {
        font-family: $gilroy;
        line-height: 1;

        font-size: 15px;
        letter-spacing: -0.47px;

        & .num {
            font-family: $NHaasGroteskTXPro-55Rg;
            letter-spacing: -0.45px;
            white-space: nowrap;
        }

        &.with-icon {
            display: inline-flex;
            align-items: center;

            svg {
                width: 30px;
                margin-right: 5px;
            }
        }
    }
    @include desktop_1366 {
        font-size: 16px;
        letter-spacing: -0.46px;

        & .num {
            letter-spacing: -0.48px;
        }

        &.with-icon {
            svg {
                width: 32px;
                margin-right: 6px;
            }
        }
    }
}

.ny-menu-item-img-wrap {
    width: 32px;
    height: 26px;
    overflow: hidden;
    margin-right: 9px;
    transform: translate(7px, -9px);

    @media (min-width: 1024px) {
      margin-right: 13px;
    }

    @media (min-width: 1280px) {
      margin-right: 15px;
      transform: translate(7px, -9px) scale(1.16);
    }

    @media (min-width: 1366px) {
      transform: translate(5px, -11px) scale(1.25);
    }

    @media (min-width: 1440px) {
      transform: translate(3px, -11px) scale(1.3);
    }

    @media (min-width: 1600px) {
      transform: translate(0px, -11px) scale(1.4);
    }

    @media (min-width: 1680px) {
      transform: translate(2px, -11px) scale(1.45);
    }
}

@keyframes change-img {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-50%);
    }
}

.ny-menu-item-img-wrap-wrap {
    width: 64px;
    display: flex;

    animation: change-img 1.5s infinite step-end;
}

.ny-menu-item-img {
    height: 24px;
    margin-top: 2px;

    &:last-child {
      height: 26px;
      margin-top: 0px;
    }
}

.text {
    &.big {
        display: none;
    }
    @media (min-width: 1366px) {
        &.big {
            display: inline;
        }
        &.small {
            display: none;
        }
    }
}

.burger-button {
    @include mobile-props((
        position: absolute,
        z-index: 3,
        top: 45%,
        left: 0,
        transform: translateY(-50%),

        display: flex,
        align-items: center,
        justify-content: center,

        box-sizing: content-box,
        height: 100%,
        border: none,
        background: var(--color__coconut),

        padding: (
            0:   0 20px,
        ),
        width: (
            0:   24px,
            375: 32px,
        ),
    ));

    & svg {
        $button-sizes: (
            0:   24px,
            375: 32px,
        );

        @include mobile-props((
            position: relative,
            display: inline-block,
            overflow: visible,

            height: $button-sizes,
            width: $button-sizes,
        ));

        path {
            @include mobile-props((
                fill: var(--color__salt),
            ));
        }
    }

    &.is-open {
        svg {
            path:nth-child(1) {
                @include mobile-props((
                    animation: open-rect-1 0.3s forwards,
                ));
            }
            path:nth-child(3) {
                @include mobile-props((
                    animation: open-rect-2 0.3s forwards,
                ));
            }
            path:nth-child(2) {
                @include mobile-props((
                    animation: open-rect-3 0.3s forwards,
                ));
            }
        }
    }

    &.is-close {
        svg {
            path:nth-child(1) {
                @include mobile-props((
                    animation: close-rect-1 0.3s forwards,
                ));
            }
            path:nth-child(3) {
                @include mobile-props((
                    animation: close-rect-2 0.3s forwards,
                ));
            }
            path:nth-child(2) {
                @include mobile-props((
                    animation: close-rect-3 0.3s forwards,
                ));
            }
        }
    }
}

.navigation-category {
    @include mobile_320 {
        position: relative;
        z-index: 2;
        background: var(--color__coconut);
    }

    @include mobile-props((
        max-width: 444px,
        margin: (
            444: 0 auto,
        ),
    ));
}

.navigation-category__wrap {
    @include mobile-props((
        height: (
            0:   $header-height-320,
            375: $header-height-375,
        ),
        position: relative,
        opacity: 1,
        transition: opacity 0.3s,
    ));

    &.is-hidden {
        @include mobile-props((
            opacity: 0,
            pointer-events: none,
        ));
    }
}

.navigation-tags {
    @include mobile-props((
        box-sizing: border-box,

        // padding-bottom: (
        //     0:   16px,
        //     375: 24px,
        // ),
        padding-bottom: 8px,
        background-color: var(--color__coconut),

        transition-property: (opacity, padding-bottom, height),
        transition-duration: 300ms,
    ));

    &.is-hidden {
        @include mobile-props((
            opacity: 0,
            pointer-events: none,
            display: none,
        ));
    }
}

.button-visibility--wrapper {
    opacity: 0;
}

// BR-1077
.header-category__content {
    @include mobile-props((
        position: relative,
        display: inline-flex,
        justify-content: center,
    ));
}

.header-category__arrow-container {
    @include mobile-props((
        position: absolute,
        top: 0,
        right: 0,
        transform: (
            0:   translateY(6px),
            360: translateY(7px),
            375: translateY(9px),
        ),
    ));
}

.header-category__button {
    @include mobile_320 {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background: transparent;
        text-align: center;
        color: var(--color__cuttlefish);

        transition: color 100ms;

        &:active {
            color: var(--color__oyster);
        }
    }
}

.header-category__filter-button-container {
    @include mobile-props((
        position: absolute,
        right: 0,
        top: 0,
        bottom: 0,
    ));
}
