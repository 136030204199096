@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

.deliveryDateSelectRoot {
    @include mobile-props((
        height: 100%,
        display: flex,
        flex-direction: column,
        flex: 1 1 auto,
        padding: (
            0: 20px 16px,
            375: 20px,
        ),
    ));
}

.deliveryDateSelectTitle {
    @include mobile-props((
        color: var(--color__pepper),
        font-family: $gilroy,
        font-size: (
            0: 21px,
            375: 24px,
        ),
        font-style: normal,
        font-weight: 800,
        line-height: (
            0: 22px,
            375: 25px,
        ),
        letter-spacing: -0.6px,
        padding-bottom: (
            0: 12px,
            375: 16px,
        ),
    ));
}
//
.deliveryDateSelectCityButtons {
    @include mobile-props((
        background-color: var(--color__salt),
        margin-bottom: (
            0: 12px,
            375: 16px,
        ),
        padding: (
            0: 4px,
        ),
        border-radius: 8px,
    ));
    display: flex;
    position: relative;
}

.deliveryDateSelectCityButton {
    @include mobile-props((
        flex: 1,
        background-color: 'transparent',
        position: relative,
        height: (
            0: 28px,
            375: 32px,
        ),
        border-radius: (
            0: 6px,
        ),
    ));

    &.selected {
        .deliveryDateSelectCityButtonText {
            color: var(--color__cuttlefish);
        }
    }
}

.deliveryDateSelectCityButtonText {
    @include mobile-props((
        color: var(--color__hering),
        text-align: center,
        font-family: $gilroy,
        font-size: (
            0: 14px,
            375: 15px,
        ),
        font-style: normal,
        font-weight: 500,
        line-height: (
            0: 16px,
            375: 17px,
        ),
    ));

    position: absolute;
    top: 22%;
    left: 0;
    right: 0;
    z-index: 1;
}


.deliveryDateSelectCityButtonMask {
    @include mobile-props((
        background: #fff,
        position: absolute,
        width: calc(50% - 4px),
        height: calc(100% - 8px),
        pointer-events: none,
        transition: right 0.2s,
        border-radius: 6px,
    ));

    &.msk {
        right: calc(50%),
    }

    &.spb {
        right: 4px,
    }

}


//
.deliveryDateSelectListScrollWrapper {
    @include mobile-props((
        position: relative,
        flex: 1 1 auto,
        overflow-y: auto,
        border-top: 1px solid  var(--color__salt),
        border-radius: (
            0: 16px,
        ),
        scrollbar-width: none,
        margin-bottom: 102px,
    ))
}

.deliveryDateSelectList {
    @include mobile-props((
        border-left: 1px solid  var(--color__salt),
        border-right: 1px solid  var(--color__salt),
        border-bottom: 1px solid  var(--color__salt),
    ))
}

.deliveryDateSelectList {
    @include mobile-props((
        padding: (
            0: 0 20px,
        ),
        border-radius: 16px,
        border-left: 1px solid  var(--color__salt),
        border-right: 1px solid  var(--color__salt),
        border-bottom: 1px solid  var(--color__salt),
    ))
}

.deliveryDateSelectListItem {
    @include mobile-props((
        padding: (
            0: 14px 0px,
            375: 16px 0px,
        ),
        box-sizing: border-box,
        width: 100%,
        height: (
            0: 50px,
            375: 54px,
        ),
        border-bottom: 1px solid var(--color__salt),
        display: flex,
        justify-content: space-between,
        align-items: center,
    ));

    &:nth-last-child(-n + 1) {
        border: none;
    }
}

.deliveryDateSelectListItemText {
    @include mobile-props((
        color: var(--color__pepper),
        font-family: $gilroy,
        font-size: (
            0: 16px,
            375: 18px,
        ),
        font-style: normal,
        font-weight: 500,
        line-height: (
            0: 17px,
            375: 18px,
        ),
        letter-spacing: -0.2px,
    ));

    &.selected {
        font-weight: 700,
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.deliveryDateSelectListItemCheckbox {}


.dateSelectListContainerShadow {
    @include mobile-props((
        position: fixed,
        bottom: 68px,
        left: 0,
        width: 100%,
        height: 54px,
        background: linear-gradient(
            357deg, #FFF 35.08%,
            rgba(255, 255, 255, 0.44) 115.35%,
            rgba(255, 255, 255, 0.00) 227.39%
        ),
        pointer-events: none,
        transition-property: opacity,
        transition-duration: 0.3s
    ));
}


//
.deliveryDateSelectSubmitWrapper {
    @include mobile-props((
        width: 100%,
        box-sizing: border-box,
        position: absolute,
        bottom: 0,
        padding: (
            0: 11px 16px,
            375: 12px 20px 10px 20px,
        ),
        background: #FFF,
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.05),
    ));
}

.deliveryCitiesTabsRoot {
    @include mobile-props((
        display: flex,
        flex-shrink: 0,
        width: 100%,
        height: (
            0: 52px,
            375: 60px,
        ),
        margin-bottom: (
            0: 12px,
            375: 16px,
        ),
        border-radius: (
            0: 8px,
            375: 10px,
        ),
        border: 1px solid #EDEDED,
        overflow: hidden,
    ))
}

.deliveryCitiesTab {
    @include mobile-props((
        display: flex,
        flex-direction: column,
        justify-content: center,
        align-items: center,
        width: 100%,
        height: 100%,
        color: var(--color__coal),
        font-family: $gilroy,
        font-size: (
            0: 13px,
            375: 14px,
        ),
        font-style: normal,
        font-weight: 700,
        line-height: (
            0: 14px,
            375: 18px,
        ),
        letter-spacing: -0.2px,
        border-right: 1px solid #EDEDED
    ));
    &:last-child {
        @include mobile-props((
            border: none,
        ))
    }
}


.deliveryCitiesTabContentWrapper {
    @include mobile-props((
        width: fit-content,
        display: flex,
        flex-direction: column,
        text-align: start,
    ));
}

.subfield {
    @include mobile-props((
        color: var(--color__hering),
        font-weight: 500,
        line-height: (
            0: 14px,
            375: 16px,
        ),
    ));
}

.activeTab {
    @include mobile-props((
        color: #4F4F4F,
        background-color: #F1F1F1,
    ));
    .subfield {
        @include mobile-props((
            color: #4F4F4F,
        ));
    }
}

.selectCityDropdownWrapper {
    @include mobile-props((
        margin-bottom: (
            0: 12px,
            375: 16px,
        )
    ))
}
