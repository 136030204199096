@import '~styles/variables/index';

.basketSetsSelectionSliderRoot {
    @include all-props((
        position: absolute,
        // сложные вычисления чтобы слайдер был видимым за границами белого контейнера
        bottom: (
            0: 38px,
            375: 46px,
            1024: 46px,
        ),
        left: 0,
        padding-left: (
            0: 38px,
            375: 46px,
            1024: 46px,
        ),
        padding-right: (
            0: 38px,
            375: 46px,
            1024: 46px,
        ),
        width: (
            0: calc(100% - 72px),
            375: calc(100% - 92px),
            1024: calc(100% - 92px),
        ),
        display: flex,
        align-items: center,
        gap: 10px,
        overflow-y: scroll,
        scrollbar-width: none,
    ));
    &::-webkit-scrollbar {
        @include mobile-props((
            display: none,
        ));
    }
}

.basketSetsSelectionSliderCard {
    @include all-props((
        width: (
            0: 120px,
            375: 138px,
            1024: 138px,
        ),
        height: (
            0: 93px,
            375: 107px,
            1024: 107px,
        ),
        flex-shrink: 0,
        border-radius: 20px,
        background-size: cover,
        background-position: center,
    ));
    &.green1 {
        background-image: url('./img/green/green1.jpg');
    }
    &.green2 {
        background-image: url('./img/green/green2.jpg');
    }
    &.green3 {
        background-image: url('./img/green/green3.jpg');
    }
    &.green4 {
        background-image: url('./img/green/green4.jpg');
    }
    &.green5 {
        background-image: url('./img/green/green5.jpg');
    }
    &.green6 {
        background-image: url('./img/green/green6.jpg');
    }


    &.halloween1 {
        background-image: url('./img/halloween/halloween1.jpg');
    }
    &.halloween2 {
        background-image: url('./img/halloween/halloween2.jpg');
    }
    &.halloween3 {
        background-image: url('./img/halloween/halloween3.jpg');
    }
    &.halloween4 {
        background-image: url('./img/halloween/halloween4.jpg');
    }
    &.halloween5 {
        background-image: url('./img/halloween/halloween5.jpg');
    }

    &.hp1 {
        background-image: url('./img/hp/hp1.jpg');
    }
    &.hp2 {
        background-image: url('./img/hp/hp2.jpg');
    }
    &.hp3 {
        background-image: url('./img/hp/hp3.jpg');
    }
    &.hp4 {
        background-image: url('./img/hp/hp4.jpg');
    }
    &.hp5 {
        background-image: url('./img/hp/hp5.jpg');
    }
    &.hp6 {
        background-image: url('./img/hp/hp6.jpg');
    }
    &.hp7 {
        background-image: url('./img/hp/hp7.jpg');
    }
    &.hp8 {
        background-image: url('./img/hp/hp8.jpg');
    }

    &.ny1 {
        background-image: url('./img/ny/ny1.jpg');
    }
    &.ny2 {
        background-image: url('./img/ny/ny2.jpg');
    }
    &.ny3 {
        background-image: url('./img/ny/ny3.jpg');
    }
    &.ny4 {
        background-image: url('./img/ny/ny4.jpg');
    }
    &.ny5 {
        background-image: url('./img/ny/ny5.jpg');
    }

    &.romantic1 {
        background-image: url('./img/romantic/romantic1.jpg');
    }
    &.romantic2 {
        background-image: url('./img/romantic/romantic2.jpg');
    }
    &.romantic3 {
        background-image: url('./img/romantic/romantic3.jpg');
    }
    &.romantic4 {
        background-image: url('./img/romantic/romantic4.jpg');
    }
    &.romantic5 {
        background-image: url('./img/romantic/romantic5.jpg');
    }
    &.romantic6 {
        background-image: url('./img/romantic/romantic6.jpg');
    }
    &.romantic7 {
        background-image: url('./img/romantic/romantic7.jpg');
    }

    &.maslenitsa1 {
        background-image: url('./img/maslenitsa/maslenitsa1.jpg');
    }
    &.maslenitsa2 {
        background-image: url('./img/maslenitsa/maslenitsa2.jpg');
    }
    &.maslenitsa3 {
        background-image: url('./img/maslenitsa/maslenitsa3.jpg');
    }
    &.maslenitsa4 {
        background-image: url('./img/maslenitsa/maslenitsa4.jpg');
    }

    &.marchgifts1 {
        background-image: url('./img/marchgifts/dish_promoblock_1.jpg');
    }
    &.marchgifts2 {
        background-image: url('./img/marchgifts/dish_promoblock_2.jpg');
    }
    &.marchgifts3 {
        background-image: url('./img/marchgifts/dish_promoblock_3.jpg');
    }

    &.march1 {
        background-image: url('./img/march/march1.jpg');
    }
    &.march2 {
        background-image: url('./img/march/march2.jpg');
    }
    &.march3 {
        background-image: url('./img/march/march3.jpg');
    }
    &.march4 {
        background-image: url('./img/march/march4.jpg');
    }
    &.march5 {
        background-image: url('./img/march/march5.jpg');
    }

    &.easter1 {
        background-image: url('./img/easter/easter1.jpg');
    }
    &.easter2 {
        background-image: url('./img/easter/easter2.jpg');
    }
    &.easter3 {
        background-image: url('./img/easter/easter3.jpg');
    }
    &.easter4 {
        background-image: url('./img/easter/easter4.jpg');
    }
    &.easter5 {
        background-image: url('./img/easter/easter5.jpg');
    }
}
