@import "~global/styles/fonts/index.scss";
@import '~styles/variables/index';

.basket-check__root {
    @include all-props((
        background-color: var(--color__coconut),
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.07),
        border-radius: (
            0:   var(--sizeBorderRadiusXXL),
            375: var(--sizeBorderRadius4XL),
            1024: var(--sizeBorderRadius4XL),
        ),

        padding: (
            0:   20px,
            375: 22px 20px,
            1024: 22px 20px,
        )
    ));


}

.basket-check__heading {
    @include all-props((
        color: var(--color__pepper),
        margin-bottom: (
            0: 10px,
            375: 12px,
        ),
    ));
}

.basket-check__item {
    @include all-props((
        width: 100%,
        border-top: 1px solid var(--color__salt),
        padding: (
            0: 13px 0 12px,
            375: 16px 0,
            1024: 16px 0,
        ),
    ));

    &:last-child {
        @include all-props((
            padding-bottom: 0,
        ));
    }
}

.bonuses-notifier__heading-wrapper{
    margin: 14px 0px;
}

.bonuses-notifier__bottom-wrapper {
    margin-top: 14px;
}




