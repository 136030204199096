@import '~styles/variables/index';

.basketUpsellSelectionsCardRoot {
    @include all-props((
        box-sizing: border-box,
        display: flex,
        align-items: center,
        width: 100%,
        height: (
            0: 55px,
            375: 64px,
            1024: 64px,
        ),
        border-radius: 16px,
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.05),
        overflow: hidden,
        font-size: (
            0: 16px,
            375: 17px,
            1024: 17px,
        ),
        line-height: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
        font-weight: 500,
        letter-spacing: -0.2px
    ));
    &:not(:last-child) {
        @include all-props((
            margin-bottom: (
                0: 8px,
                375: 10px,
                1024: 10px,
            )
        ))
    }
}

.basketUpsellSelectionsIconWrapper {
    @include all-props((
        width: (
            0: 91px,
            375: 105px,
            1024: 105px,
        ),
        height: 100%,
        padding-right: (
            0: 16px,
            375: 20px,
            1024: 20px,
        ),
        background-size: cover,
        object-fit: cover,
    ));
}
