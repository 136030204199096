@import '~styles/variables/index';

.radiomark {
    @include all-props((
        display: flex,
        justify-content: center,
        align-items: center,
        position: relative,
        box-sizing: border-box,
        width: 100%,
        height: 100%,
        background: var(--color__coconut),
        border: 1px solid $gallery,
        border-radius: 50%,
    ));

    @include desktop-props((
        width: (
            1024: 100%,
            1600: 22px,
            1920: 24px,
        ),
        height: (
            1024: 100%,
            1600: 22px,
            1920: 24px,
        ),
    ));

    &.checked {
        @include all-props((
            border: 1px solid var(--color__egg),
            background: var(--color__egg),
        ));
    }

    &::before {
        content: '';

        @include all-props((
            position: absolute,
            left: 50%,
            top: 50%,
            transform: translate(-50%, -50%),
            width: 9px,
            height: 9px,
            border-radius: 50%,
            background: var(--color__coconut),
        ));
    }

    &.version-uikit-responsive {
        @include all-props((
            border-width: 2px,
            border-color: var(--color__salt),
        ));

        @include responsive-desktop-props(1536px, (
            width: 22px,
            height: 22px,
            border-width: 2px,
        ));

        &::before {
            @include all-props((
                width: 10px,
                height: 10px,
            ));
            @include responsive-desktop-props(1536px, (
                width: 10px,
                height: 10px,
            ));
        }
    }
}
