@import '~styles/variables/index';


.ui-close-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box !important;
    border: none;
    background: transparent;
    height: 100%;
    width: 100%;

    svg {
        * {
            fill: var(--color__salt)
        }
    }

    &:active {
        svg * {
            fill: var(--color__oyster);
        }
    }

    @include mobile_414 {
        svg {
            width: 32px;
            height: 32px;
        }
    }
}
