@import '~styles/variables/index';

.badge__root {
    @include mobile-props((
        display: flex,
        justify-content: center,
        align-items: center,

        height: (
            0:   20px,
            360: 21px,
            375: 24px,
            414: 26px,
        ),
        width: (
            0:   42px,
            360: 46px,
            375: 52px,
            414: 60px,
        ),
        border-radius: 50%,
    ));
}

.badge__value {
    @include mobile-props ((
        display: flex,
        justify-content: center,
        align-items: baseline,

        font-family: $inter,
        font-style: italic,
        font-weight: 900,
        font-size: (
            0:   11px,
            360: 12px,
            375: 13px,
            414: 15px,
        ),
        line-height: (
            0:   13px,
            360: 15px,
            375: 16px,
            414: 18px,
        ),
        letter-spacing: -0.78px,
    ));

    & svg,
    & svg path {
        @include mobile-props((
            fill: inherit,
        ));
    }
}

.badge__value-star-container {
    $star-sizes: (
        0:   10px,
        360: 11px,
        375: 12px,
        414: 14px,
    );
    @include mobile-props((
        display: inline-block,
        height: $star-sizes,
        width: $star-sizes,
    ));
}

.badge__value-minus {
    @include mobile-props((
        display: inline-block,
        align-self: center,
        transform: (
            0:   translateY(0.5px),
            360: translateY(1px),
            375: translateY(0.5px),
            414: translateY(-0.6px),
        ),

        height: (
            0:   1.46px,
            360: 1.62px,
            375: 1.77px,
            414: 2px,
        ),
        width: (
            0:   5.54px,
            360: 6.12px,
            375: 6.71px,
            414: 7.5px,
        ),
    ));
}
