@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

.giftDishCardRoot {
    @include all-props((
        box-sizing: border-box,
        background-color: #FFFFFF,
        box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.07),
        border-radius: (
            0: 16px,
            375: 20px,
            1024: 20px,
        ),
        display: flex,
        gap: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
    ));

}

.giftDishCardLeft {
    @include all-props((
        box-sizing: border-box,
        overflow: hidden,
        border-top-left-radius: inherit,
        border-bottom-left-radius: inherit,
        width: (
            0: 93px,
            375: 103px,
            1024: 103px,
        ),
    ))
}

.giftDishCardImg {
    @include all-props((
        box-sizing: border-box,
        height: 100%,
        overflow: hidden,
        width: inherit,
    ));
}

.giftDishCardRight {
    @include all-props((
        box-sizing: border-box,
        display: flex,
        flex-direction: column,
        justify-content: center,
        padding-right: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
    ));
}

.giftDishCardTitle {
    @include all-props((
        color: var(--color__pepper),
        font-family: $gilroy,
        font-size: (
            0: 15px,
            375: 16px,
            1024: 16px,
        ),
        font-style: normal,
        font-weight: (
            0: 500,
            1024: 400,
        ),
        line-height: (
            0: 17px,
            375: 18px,
            1024: 18px,
        ),
        letter-spacing: -0.2px,
    ));
}

.giftDishCardDesc {
    @include all-props((
        color: var(--color__mushrooms),
        font-family: $gilroy,
        font-size: (
            0: 13px,
            375: 15px,
            1024: 15px,
        ),
        font-weight: 400,
        line-height: (
            0: 14px,
            375: 16px,
            1024: 16px,
        ),
        text-transform: lowercase,
    ));
}
