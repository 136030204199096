@import '~styles/variables/index';

.street-list-overlay {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.01);
    z-index: 1;
    cursor: default;
    border: none;
}

.street-list {
    position: absolute;
    overflow: hidden;
    top: 49px; // input height - 1
    width: 100%;
    box-sizing: border-box;
    z-index: 4;
    border-top: solid 1px var(--color__salt);
    border-bottom: solid 1px var(--color__not-ui__alto);
    border-left: solid 1px var(--color__not-ui__alto);
    border-right: solid 1px var(--color__not-ui__alto);

    background: var(--color__coconut);
    box-shadow: 0 7px 12px 0 rgba(240, 240, 240, 0.4);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    @media (min-width: 1024px) {
        top: 51px; // input height - 1
    }
}

.street-item {
    @include all-props((
        display: block,
        width: 100%,
        box-sizing: border-box,
        padding: (
            0: 5px 15px,
            375: 7px 18px,
        ),
        font-family: $gilroy,
        font-weight: var(--font-weight__medium),
        font-size: (
            0: 15px,
            375: 16px,
        ),
        line-height: (
            0: 14px,
            375: 15px,
        ),
        background: var(--color__coconut),
        border: solid 1px transparent,
        letter-spacing: -0.2px,
        color: var(--color__pepper),
        text-align: left,
    ));

    @include desktop-props((
        font-size: (
            1024: 16px,
        ),
        padding: (
            1024: 5px 9px,
        ),
        line-height: (
            1024: 12px,
        )
    ));

    // @include desktop-props((
    //     display: block,
    //     width: 100%,
    //     box-sizing: border-box,
    //     background: var(--color__coconut),
    //     border: solid 1px transparent,
    //     padding: 13px 18px,
    //     font-family: $gilroy-medium,
    //     letter-spacing: -0.1px,
    //     color: var(--color__cuttlefish),
    //     text-align: left,
    //     transition: all 150ms,
    //     font-size: (
    //         1024: 14px,
    //         1280: 15px,
    //     ),
    // ));

    & + & {
        @include desktop-props((
            // border-top: solid 1px var(--color__salt),
            border-top: solid 1px transparent,
        ));
    }
    &:hover {
        @include desktop-props((
            background: var(--color__melon),
            border: solid 1px var(--color__melon),
        ));
    }
    &:hover + & {
        @include desktop-props((
            border-top: solid 1px transparent,
        ));
    }
    &.highlight {
        @include desktop-props((
            border: solid 1px var(--color__melon),
        ));
    }
}

.street-item__footer {
    @include all-props((
        font-family: $gilroy,
        font-weight: var(--font-weight__regular),
        font-size: 13px,
        line-height: 15px,
        letter-spacing: -0.2px,
        color: var(--color__lichi),
    ));

    // @include desktop-props((
    //     font-family: $gilroy-light,
    //     font-size: 10px,
    //     line-height: normal,
    //     letter-spacing: -0.1px,
    //     color: var(--color__cuttlefish),
    // ));
}

.scroll-thumb {
    position: relative;
    right: -4px;
    width: 4px;
    background-color: var(--color__not-ui__picasso);
}

.mobile-button {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    background: transparent;
    z-index: 1;
}
