@import '~styles/variables/index';

.font-style {
    font-family: $gilroy;
    color: #83533B;
}

.halloweenDetailsRoot {
    @include mobile-props((
        box-sizing: border-box,
        padding: (
            0: 0 16px,
            375: 0 20px,
        ),
    ));
}

.halloweenDetailsTitle {
    @extend .font-style;
    @include mobile-props((
        font-size: (
            0: 19px,
            375: 21px,
        ),
        font-weight: 800,
        line-height: (
            0: 23px,
        ),
        letter-spacing: (
            0: -0.4px,
            375: -0.6px,
        ),
        padding-bottom: (
            0: 8px,
            375: 10px,
        ),
    ));
}

.smallMargin {
    @include all-props((
        padding-bottom: (
            0: 4px,
            375: 6px,
        ),
    ))
}

.halloweenDetailsDescription {
    @extend .font-style;
    @include mobile-props((
        font-size: (
            0: 15px,
            375: 17px,
        ),
        font-weight: 500,
        line-height: (
            0: 15px,
            375: 18px,
        ),
        letter-spacing: (
            0: -0.2px,
            375: -0.381px,
        ),
        padding-bottom: (
            0: 28px,
            375: 36px,
        ),
    ));
}

.topIndent {
    @include mobile-props((
        margin-top: (
            0: 50px,
            375: 80px,
        ),
    ));
}

.halloweenDetailsList {
    @include mobile-props((
        display: flex,
        flex-direction: column,
        gap: (
            0: 50px,
            375: 58px,
        ),
    ));
}

/* HalloweenDishCardForDetail =============== */

.halloweenDishCardRoot {
    @include mobile-props((
        border-radius: (
            0: 8px,
            375: 14px,
        ),
        border: 1px solid #E5D1C7,
    ));
}
.halloweenDishCardImg {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    width: 100%;
}
.halloweenDishCardImgItem {
    width: 100%;
    border-radius: inherit;
    object-position: center
}
.halloweenDishCardTitle {
    @extend .font-style;
    @include mobile-props((
        font-size: (
            0: 17px,
            375: 19px,
        ),
        font-weight: 800,
        line-height: (
            0: 17px,
            375: 19px,
        ),
        letter-spacing: -0.5px,
        padding: (
            0: 19px 19px 9px 19px,
            375: 19px 19px 6px 19px,
        ),
    ));
}

.halloweenDishCardDesc {
    @extend .font-style;
    @include mobile-props((
        font-size: (
            0: 14px,
        ),
        font-weight: 400,
        line-height: (
            0: 13.7px,
        ),
        letter-spacing: -0.44px,
        padding: (
            0: 0px 19px 19px 19px,
            375: 0px 19px 12px 19px,
        ),
    ));
}


// HelloweenOfferCompoent =======
.helloweenOfferImgWrapper {
    @include mobile-props((
        padding-bottom: (
            0: 8px,
            375: 10px,
        ),
    ));
}

.helloweenOfferImg {
    @include mobile-props((
        width: 100%,
        border-radius: (
            0: 25px,
            375: 30px,
        ),
    ));
}

.helloweenOfferRoot {
    @include mobile-props((
        padding-bottom: (
            0: 29px,
            375: 41px,
        ),
        padding-top: (
            0: 50px,
            375: 80px,
        ),
    ));
}

.helloweenOfferText {
    @extend .font-style;
    @include mobile-props((
        font-size: (
            0: 16px,
            375: 19px,
        ),
        font-weight: 400,
        line-height: (
            0: 16.6px,
            375: 20.7px,
        ),
        letter-spacing: -0.5px,
    ));

    &.bold {
        font-weight: 500;
    }
}

.helloweenOfferList {
    @include mobile-props((
        padding: 8px 0px,
    ));
    & .helloweenOfferText {
        @include mobile-props((
            position: relative,
            padding-left: (
                0: 13px,
                375: 16px,
            ),
        ));

        &::before {
            content: '·';
            position: absolute;
            left: -2px;
            @include mobile-props((
                font-size: (
                    0: 36px,
                    375: 42px,
                ),
            ));
        }
    }
}
