@import '~styles/variables/index';

@keyframes appearing {
    from { opacity: 0, }
    to { opacity: 1, }
}

/* МОБИЛЬНЫЕ СТИЛИ КОНТЕНТА / UI Kit ======================================== */
/* COMMON */
.ui-button-content-conrainer {
    @include all-props((
        color: var(--color__choco),
        position: relative,
        display: flex,
        flex-direction: column,
        justify-content: center,
        align-items: center,
        flex: none,
        height: 100%,
        width: 100%,
    ));
    &.content,
    &.loading {
        @include all-props((
            animation: appearing 200ms,
        ));
    }
}

/* COMMON */
.ui-button-content {
    @include all-props((
        // position: absolute,
        display: flex,
        align-items: baseline,
        width: auto,
        white-space: nowrap,
    ));
}

/* COLOR */
.ui-button-content {
    transition: color 350ms;

    &.primary {
        @include all-props((
            color: var(--color__choco),
        ));
    }

    &.secondary {
        @include all-props((
            color: var(--color__coal),
        ));
    }

    &.tertiary {
        @include all-props((
            color: var(--color__pepper),
        ));
    }

    &.primary.disabled,
    &.secondary.disabled,
    &.tertiary.disabled {
        @include all-props((
            cursor: default,
            color: var(--color__hering),
        ));
    }
    &.primary.disabled.primary-melon {
        @include all-props((
            color: var(--color__kurkuma),
        ));
    }
    &.primary.disabled.primary-grey-salt {
        @include all-props((
            color: var(--color__hering),
        ));
    }
}

/* SIZE */
.ui-button-content {
    $price-container-indent: 4px;
    $currency-indent: 2px;

    @include all-props((
        font-family: $gilroy,
        font-weight: var(--font-weight__bold),
        line-height: 22px,
        transform: (
            0:   translateY(-1px),
            360: translateY(0px),
            1024: translateY(0px),
        ),
    ));

    &.large {
        @include all-props((
            font-size: (
                0:   17px,
                375: 18px,
                1024: 18px,
            ),
        ));

        & :global(.title) {
            @include all-props((
                font: inherit,
            ));
        }

        & :global(.num) {
            @include all-props((
                font-family: $haas-dsstd,
                font-weight: 500,
                letter-spacing: inherit,
            ));
        }

        & :global(.rub) {
            @include all-props((
                font-family: $gilroy,
                font-weight: 600,
            ));

            &:last-child {
                @include all-props((
                    margin-left: $currency-indent,
                    margin-right: 0,
                ));
            }
            &:first-child {
                @include all-props((
                    margin-left: 0,
                    margin-right: $currency-indent,
                ));
            }
        }

        & :global(.price) {
            @include all-props((
                margin-left: $price-container-indent,
                display: inline-block,
            ));
            &:last-child {
                @include all-props((
                    margin-left: $price-container-indent,
                ));
            }
            &:first-child {
                @include all-props((
                    // в промо-чекауте используется этот отступ
                    margin-left: $price-container-indent,
                ));
            }
         }

        // TODO: Вынести из обших стилей, обозначить как модификатор
        & :global(.original-price) {
            @include all-props((
                display: inline-block,
                align-self: center,
                margin-left: (
                    0: 4px,
                    375: 5px,
                    1024: 5px,
                ),
                padding: 3px 6px,
                background-color: var(--color__choco),
                border-radius: 4px, // :rage-4px:
                white-space: nowrap,

                font-size: (
                    0: 11px,
                    375: 12px,
                ),
                line-height: (
                    0: 11px,
                    375: 12px,
                ),
                color: #FFEAC4,
            ));

            @include desktop-props((
                align-self: center,
                margin-left: (
                    1024: 4px,
                ),
                padding: 3px 6px,
                background-color: var(--color__choco),
                border-radius: 4px,
                white-space: nowrap,

                font-size: (
                    1024: 11px,
                ),
                line-height: (
                    1024: 11px,
                ),
                color: #FFEAC4,
            ));

            :global(.num) {
                @include all-props((
                    display: inline-block,
                    text-decoration: line-through,
                ));
            }

            :global(.rub) {
                @include all-props((
                    display: inline-block,
                    letter-spacing: -0.05px,
                ));
                @include desktop-props((
                    letter-spacing: -0.05px,
                ));
            }
        }
    }

    &.medium {
        @include all-props((
            font-size: (
                0:   17px,
                375: 18px,
            ),
        ));

        & :global(.num) {
            @include all-props((
                font-family: $haas-dsstd,
                font-weight: 500,
                letter-spacing: inherit,
            ));
        }

        & :global(.rub) {
            $currency-indent: (
                0:   3px,
                414: 3px,
            );

            @include all-props((
                font-weight: 600,
            ));

            &:last-child {
                @include all-props((
                    margin-left: $currency-indent,
                    margin-right: 0,
                ));
            }
            &:first-child {
                @include all-props((
                    margin-left: 0,
                    margin-right: $currency-indent,
                ));
            }
        }

        & :global(.multiplier) {
            @include all-props((
                bottom: (
                    0:   2px,
                    414: 3px,
                ),

                margin: (
                    0:   0px 3px 0 5px,
                    375: 0px 5px 0 5px,
                    414: 0px 6px 0 5px,
                    1024: 0px 6px 0 5px,
                ),

                font-weight: 700,
                font-size: (
                    0:   16px,
                    375: 17px,
                ),
            ));
        }

        // TODO: Вынести из обших стилей, обозначить как модификатор
        & :global(.original-price) {
            @include all-props((
                align-self: center,
                margin-left: (
                    0: 2px,
                    375: 2px,
                    1024: 2px,
                ),
                padding: 2px 4px,
                background-color: var(--color__choco),
                border-radius: 4px, // :rage-4px:
                white-space: nowrap,

                font-size: (
                    0: 11px,
                    375: 12px,
                ),
                line-height: (
                    0: 11px,
                    375: 12px,
                ),
                color: #FFEAC4,
            ));

            @include desktop-props((
                align-self: center,
                margin-left: 6px,
                padding: 3px 5px,
                background-color: var(--color__choco),
                border-radius: 4px,
                white-space: nowrap,

                font-size: (
                    1024: 12px,
                ),
                line-height: (
                    1024: 12px,
                ),
                color: #FFEAC4,
            ));

            :global(.num) {
                @include all-props((
                    text-decoration: line-through,
                ));
            }

            :global(.rub) {
                @include all-props((
                    letter-spacing: -0.05px,
                    margin-left: 0,
                    font-size: inherit,
                ));
                @include desktop-props((
                    letter-spacing: -0.05px,
                ));
            }
        }
    }

    &.small {
        @include all-props((
            font-weight: 600,
            font-size: (
                0:   14px,
                375: 15px,
            ),
        ));

        & :global(.num) {
            @include all-props((
                font-family: $haas-txstd,
                letter-spacing: inherit,
            ));
            @include all-props((
                font-weight: 700,
            ));
        }

        & :global(.rub) {
            $currency-indent: (
                0:   2px,
                414: 3px,
                1024: 3px,
            );

            @include all-props((
                font-weight: 700,
            ));

            &:last-child {
                @include all-props((
                    margin-left: $currency-indent,
                    margin-right: 0,
                ));
            }
            &:first-child {
                @include all-props((
                    margin-left: 0,
                    margin-right: $currency-indent,
                ));
            }
        }
    }
}

/* МОБИЛЬНЫЕ СТИЛИ КНОПКИ / UI Kit ========================================== */
/* SIZE */
.ui-button {
    @include all-props((
        cursor: pointer,
        box-sizing: border-box,
        display: block,
    ));

    &.responsive {
        @include all-props((
            border-radius: (
                0: 11px, // стили не из ui-токенов, но из ui-кита
                375: var(--sizeBorderRadiusL),
                1024: var(--sizeBorderRadiusL),
            ),
            width: 100%,
            height: (
                0: 46px,
                375: 50px,
                1024: 50px,
            ),
        ));
    }

    &.large {
        @include all-props((
            border-radius: (
                0: 11px, // стили не из ui-токенов, но из ui-кита
                375: var(--sizeBorderRadiusL),
                1024: var(--sizeBorderRadiusL),
            ),
            width: (
                0: 100%,
                1024: 100%,
            ),
            height: (
                0: 46px,
                375: 50px,
                1024: 52px,
            ),
        ));
    }

    &.medium {
        @include all-props((
            width: 100%,
            border-radius: (
                0: var(--sizeBorderRadiusS),
                375: var(--sizeBorderRadiusM),
                1024: var(--sizeBorderRadiusM),
            ),
            min-width: (
                0: 86px,
                375: 96px,
                1024: 96px,
                1366: 102px,
            ),
            height: (
                0: 40px,
                375: 46px,
                1024: 48px,
            ),
            padding: (
                0: 0 12px,
                375: 0 14px,
                1024: 0 12px,
                1366: 0 14px,
            ),
        ));
    }

    &.small {
        @include all-props((
            border-radius: (
                0: var(--sizeBorderRadiusXXXS),
            ),
            width: 100%,
            height: (
                0: 28px,
                414: 32px,
                1024: 32px,
            ),
        ));
    }
}

/* COLOR */
.ui-button {
    transition: background-color 350ms, border-color 350ms;

    &.primary {
        background-color: #FFDB19;
        border: 1px solid #FFDB19;

        &:active {
            background-color: #FFE971;
        }
    }

    &.secondary {
        background-color: #F1F1F1;
        border: 1px solid #F1F1F1;
    }

    &.tertiary {
        background-color: #FFFFFF;
        border: 1px solid #F1F1F1;
    }

    &:disabled {
        background-color: #F1F1F1;
        border: 1px solid #F1F1F1;

        &:active {
            background-color: #F1F1F1;
            border: 1px solid #F1F1F1;
        }
    }

    &:disabled.primary-melon {
        background-color: var(--color__melon);
        border: 1px solid var(--color__melon);

        &:active {
            background-color: var(--color__melon);
            border: 1px solid var(--color__melon);
        }
    }

    &:disabled.primary-grey-salt {
        background-color: var(--color__grey-salt);
        border: 1px solid var(--color__grey-salt);

        &:active {
            background-color: var(--color__grey-salt);
            border: 1px solid var(--color__grey-salt);
        }
    }
}

/* ДЕКСТОП ================================================================== */
/* NOTE: Десктопные стили написаны на основе страницы upsell и не утверждены
в UI-ките ю Цвет в зависимости от состояния описаны в блоке с мобильными стилями */
.ui-button-content {
    @include desktop-props((
        font-family: $gilroy-medium,
        letter-spacing: -0.5px,
        font-size: 16px,
    ));
}

/* NOTE: Десктопные стили написаны на основе страницы upsell и не утверждены в UI-ките */
.ui-button-content {
    @include desktop-props((
        font-family: $gilroy,
        font-size: (
            1024: 17px,
            1366: 18px,
        ),
    ));

    &.large, &.medium {
        & :global(.rub) {
            @include desktop-props((
                margin-left: 3px,
            ));
        }
        & :global(.price) {
            @include desktop-props((
                margin-left: 4px,
            ));
        }
    }

    &.large {
        @include desktop-props((
            letter-spacing: (
                1024: 0,
            ),
        ));
        & :global(.num) {
            @include desktop-props((
                font-family: $NHaasGroteskTXStd-65Md,
                letter-spacing: -0.5px,
            ));
        }
        & :global(.rub) {
            @include desktop-props((
                margin-left: 3px,
            ));
        }
    }

    &.medium {
        & :global(.rub) {
            @include desktop-props((
                margin-left: 2px,
                font-size: 18px,
            ));
        }
    }

    &.small {
        & :global(.num) {
            @include desktop-props((
                font-weight: 500,
                font-size: 20px,
                letter-spacing: -0.46px,
            ));
        }
    }

    &.small  {
        & :global(.rub) {
            @include desktop-props((
                font-family: $gilroy,
                font-weight: 400,
                font-size: 20px,
            ));
        }
    }
}

/* NOTE: Десктопные стили написаны на основе страницы 5x2 */
.ui-button {
    @include desktop-props((
        padding: 0 12px,
        height: (
            1024: 48px,
            1366: 52px,
        ),
        max-height: (
            1024: 52px,
        ),
        border-radius: (
            1024: 8px,
            1366: 9px,
        ),
        width: 100%,
    ));
    &.small  {
        @include desktop-props((
            height: 37px,
        ));
    }

}

/* LEGACY / NOTE: Не используются. Новогодьние стили ======================== */

/*
.button__root сейчас .ui-button
.button__root {
    &.new-year {
        @include all-props((
            height: (
                360: 45px,
                414: 52px,
            ),
            max-height: (
                360: 45px,
                414: 52px,
            ),
        ));
        @include desktop-props((
            height: (
                1024:  36px,
                1366: 42px,
                1440: 46px,
                1680: 50px,
                1920: 52px,
            ),
            max-height: (
                1024:  36px,
                1366: 42px,
                1440: 46px,
                1680: 50px,
                1920: 52px,
            ),
        ));
    }
    &.age-gate {
        @extend .new-year;
    }
}
*/

/*
.button__content сейчас .ui-button-content
.button__content {
    &.new-year {
        @include all-props((
            font-family: $gilroy-extrabold,
            letter-spacing: -0.55px,

            font-size: (
                0:   15px,
                360: 17px,
                414: 20px,
            ),
        ));
        @include desktop-props((
            font-family: $gilroy-bold,  // font-weight: 800,
            letter-spacing: -0.55px,

            font-size: (
                1024:  16px,
                1366: 18px,
                1440: 19px,
                1680: 20px,
                1920: 22px,
            ),
        ));

        :global(.num) {
            @include desktop-props((
                font-family: $NHaasGroteskTXStd-75Bd,
                letter-spacing: -0.72px,
            ));
        }
        &.small :global(.num) {
            @include all-props((
                font-size: (
                    0:   15px,
                    360: 17px,
                    414: 20px,
                    1024: 18px,
                ),
            ));
        }
        :global(.multiplier)::after {
            content: 'x';

            @include desktop-props((
                position: relative,
                display: inline-block,
                line-height: 0,
                bottom: 2px,
                letter-spacing: -0.05px,
                font-size: 16px,
            ));
        }
    }

    &.age-gate {
        @extend .new-year;

        @include desktop-props((
            font-family: $gilroy-bold,  // font-weight: 800,
            letter-spacing: -0.5px,
            font-size: (
                1024: 13px,
                1366: 16px,
                1680: 19px,
                1920: 22px,
            ),
            line-height: (
                1024: 1.69,
                1366: 1.38,
                1680: 1.16,
                1920: 1,
            ),
        ));

        :global(.num) {
            @include desktop-props((
                font-family: $NHaasGroteskTXStd-75Bd,
                font-size: inherit !important,
            ));
        }
    }
}
*/
