@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

.selectTagRoot {
    @include mobile-props((
        border-radius: 7px,
        border: 1px solid var(--color__salt),
        padding: (
            0: 6px 8px,
        ),
        background-color: var(--color__coconut),
        flex: 2,
    ));
}

.selectTagHandlerWrapper {
    @include mobile-props((
        display: flex,
        width: 100%,
        justify-content: space-between,
    ));
}

.selectTagContent {
    text-align: start;
}

.selectTagContentPlaceholder {
    @include mobile-props((
        color: var(--color__hering),
        font-family: $gilroy,
        font-size: (
            0: 12px
        ),
        font-style: normal,
        font-weight: 500,
        line-height: (
            0: 12px,
        ),
        letter-spacing: -0.2px,
    ))

}

.selectTagContentText {
    @include mobile-props((
        color: var(--color__cuttlefish),
        font-family: $gilroy,
        font-size: (
            0: 14px,
            375: 16px,
        ),
        font-style: normal,
        font-weight: 600,
        line-height: (
            0: 16px,
            375: 18px,
        ),
        letter-spacing: -0.2px,
    ))
}

.selectTagContentIcon {
    @include mobile-props((
        display: flex,
        flex-direction: column,
        height: auto,
        justify-content: center,
        padding-right: (
            0: 2px,
        ),
    ));
}

.selectTagContentIconWrapper {
    @include mobile-props((
        width: (
            0: 12px,
            375: 14px,
        ),
        height: (
            0: 12px,
            375: 14px,
        ),
    ));

}
