@mixin mobile_320 {
    @media (min-width: 320px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile_360 {
    @media (min-width: 360px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile_375 {
    @media (min-width: 375px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile_393 {
    @media (min-width: 375px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile_414 {
    @media (min-width: 414px) and (max-width: 1023px) {
        @content;
    }
}

@mixin mobile_424 {
    @media (min-width: 414px) and (max-width: 1023px) {
        @content;
    }
}

@mixin desktop_960 {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin desktop_1024 {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin desktop_1280 {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin desktop_1366 {
    @media (min-width: 1366px) {
        @content;
    }
}

@mixin desktop_1440 {
    @media (min-width: 1440px) {
        @content;
    }
}

@mixin desktop_1600 {
    @media (min-width: 1600px) {
        @content;
    }
}

@mixin desktop_1680 {
    @media (min-width: 1680px) {
        @content;
    }
}

@mixin desktop_1920 {
    @media (min-width: 1920px) {
        @content;
    }
}

@mixin desktop_2560 {
    @media (min-width: 2560px) {
        @content;
    }
}
