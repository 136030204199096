@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";


// MenuSelections
.menu-selections-slider__root {
    @include mobile-props((
        display: flex,
        width: 100%,
        padding-bottom: (
            0:   50px,
            375: 60px,
        ),
    ));
}

// MenuSelectionsTitle
.menu-selections-title {
    @include mobile-props((
        line-height: 108%,
        margin: 0 auto,
        margin-top: (
            414: -1px,
        ),
        padding-bottom: (
            0: 4px,
            360: 8px,
            414: 12px,
        ),
        width: (
            0: 288px,
            360: 328px,
            375: 335px,
            414: 374px,
        )

    ));
}

// SelectionsMobileSlider
.menu-selections-slider__list-container {
    @include all-props((
        overflow-y: scroll,
        scrollbar-width: none, // ff
        position: relative,
        padding-top: (
            0:   8px,
            360: 0px,
        ),
    ));

    &::-webkit-scrollbar { // chrome, safari, opera
        @include all-props((
            display: none,
        ));
    }
}
.menu-selections-slider__list {
    @include all-props((
        list-style: none,
        display: flex,
        align-items: center,
        width: fit-content,

        margin: 0,
        padding: (
            0:   0 16px,
            375: 0 19px,
            1024: 0,
        ),
    ));
}
.menu-selections-slider__list-item {
    @include all-props((
        margin-right: (
            0: 8px,
            375: 10px,
            1024: 15px,
        ),
    ));
    &:last-child {
        margin: 0;
    }
}

// MenuSelectionsItemContent
.menu-selections-slider-item--indent-wrapper {
    @include all-props((
        padding: (
            0: 12px 12px 0 12px,
            375: 12px 14px 0 14px,
            1024: 12px 14px 0 14px,
        ),
    ));
}

.menu-selections-slider-item__title {
    @include all-props((
        text-align: left,
        font-family: $gilroy,
        color: var(--color__coconut),
        font-weight: (
            0: 700,
            1024: 600,
        ),
        line-height: 100%,
        letter-spacing: -0.3px,
        font-size: (
            0: 16px,
            375: 17px,
            1024: 17px,
        ),
        white-space: nowrap,
    ));
}


