@import '~styles/variables/index';


// .region-select--wrapper {
//     position: relative;
//     z-index: 1;
// }

// Кнопка в хедера в мобильном меню

.button {
    right: 0;
    position: relative;
    z-index: 10;
    // button-text
    &-text {
        position: relative;
        color: var(--color__hering);
        span {
            @include mobile-props((
                font-family: $gilroy,
                letter-spacing: -0.3px,
                padding-right: 11px,

                font-size: (
                    0: 15px,
                    360: 17px,
                    375: 18px,
                    414: 20px,
                )
            ));
            @include desktop-props((
                font-family: $gilroy,
                letter-spacing: -0.3px,
                padding-right: 11px,
                font-size: 20px,
            ));
        }
        // Тругольник кнопки модального окна в mobile header
        // button-text::after
        &.open {
            color: var(--color__cuttlefish);
        }
    }
};
.mobile-city-select-tri {
    display: inline-block;
    position: absolute;
    right: 0;
    @include mobile-props((
        top: (
            0: 36%,
            414: 40%,
        ),
        transform: (
           0: scale(0.7) translate(2px, 4px),
           360: scale(0.8) translate(1px, 3px),
           414: scale(.92) translate(3px,0px),
        ),
    ));
    @include desktop-props((
        top: 40%,
        transform: (scale(.92) translate(3px,0px)),
    ));

    &.open {
        @include mobile-props((
            transform: (
                0: rotate(180deg) scale(0.7) translate(-2px, -4px),
                360: rotate(180deg) scale(0.8) translate(-1px, -3px),
                414: rotate(180deg) scale(.92) translate(-3px, -4px),
            )
        ))
    }

    &.disabled::after {
        opacity: 0,
    }

}

// Модальное окно
// wrapper

.mobile-city-select-overlay {
    @include mobile-props((
        position: absolute,
        top: 0,
        right: -5vw,
        bottom: 0,
        background-color: transparent,
        width: 100vw,
        height: 100vh,
        z-index: 2,
    ));
}

.dialog {
    position: fixed;
    z-index: 10;
    min-width: 280px;
    max-width: 280px;
    background: var(--color__salt);
    top: 58px;
    right: 0px;
    left: 0px;
    margin: auto;
    @include mobile-props((
        border-radius: (
            0: var(--sizeBorderRadiusL),
        )
    ));
    text-align: center;
    padding: 16px;
    box-sizing: content-box;

    @media(min-width: 375px) {
        min-width: 320px;
        max-width: 320px;
        top: 66px;
        padding: 20px;
    }
    @media(min-width: 414px) {
        min-width: 360px;
        max-width: 360px;
        top: 78px;
    }
     // Треугольник указатель модального окна в mobile header
     &::before {
        content: "";
        position: absolute;
        top: -19px;
        border: 10px solid transparent;
        border-bottom-color: var(--color__salt);
        @include all-props((
            right: (
                0: 104px,
                360: 92px,
                375: 114px,
                414: 124px,
            ),
        ));
    }
    // block
    &-content {
        @include mobile-props((
            padding: (
                0: 12px 0 14px 0,
                375: 12px 0 22px 0,
                414: 12px 0 28px 0,
            ),
        ));
        @media(min-width: 414px) {
            padding-top: 16px;
            padding-bottom: 22px;
        }
    }
}

.dialog-button__item {
    box-sizing: border-box;
    &:nth-child(1){
        @include mobile-props((
            margin-bottom:(
                0: 10px,
                375: 16px,
            )
        ))
    };
    // &.unSelected {
    //     border: 1px solid var(--color__coconut) ;
    // }
}

h2.dialog-content__title.crutch-classes  {
    @include mobile-props((
        letter-spacing: -0.7px,
        font-size: (
            414: 25px,
        )
    ))
}
p.dialog-content__item.crutch-classes {
    @include mobile-props((
        letter-spacing: -0.4px,
        line-height: (
            0: 1,
            375: 1.16,
        ),
    ))
}

