@import '~styles/variables/index';


/* EMPTY BASKET ANIMATION */
:global {
    @keyframes emptyBasketEnteredTitleAnimation {
        0% {
            opacity: 0;
            top: 30px;
        }
        80% {
          top: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes emptyBasketEnteredTitleAnimation {
        0% {
            opacity: 0;
            top: 30px;
        }
        80% {
          top: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes emptyBasketEnteredImageAnimation {
        0% {
            opacity: 0;
            top: 50px;
        }
        95% {
          top: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes emptyBasketEnteredImageAnimation {
        0% {
            opacity: 0;
            top: 50px;
        }
        95% {
          top: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

/* COMMON */
.basket__empty {
    @include all-props((
        height: (
            0: 88vh,
            1024: 100%,
        ),
        display: flex,
        flex-direction: column,
        align-items: center,
        justify-content: center,
        background-color: var(--color__coconut),
    ));
}

.basket__empty-text-container {
    -webkit-animation: emptyBasketEnteredTitleAnimation 600ms;
    animation: emptyBasketEnteredTitleAnimation 600ms;
    @include all-props((
        position: relative,
        display: flex,
        flex-direction: column,
        align-items: center,
    ));

    @include mobile-props((
        margin-bottom: (
            0: 14px,
            360: 16px,
        ),
    ));

    @include desktop-props((
        margin-bottom: (
            1024: 32px,
            1920: 4px,
        ),
    ));
}

.basket__empty-title {
    @include mobile-props((
        color: var(--color__cuttlefish),
        opacity: 0.94,
        font-family: $gilroy-extrabold,
        letter-spacing: (
            0: -0.72px,
            360: -0.86px,
            414: -0.9px,
        ),
        line-height: (
            0: 1.3,
            360: 1.08,
            414: 1.04,
        ),
        font-size: (
            0: 20px,
            360: 24px,
            414: 25px,
        ),
        margin-bottom: (
            360: 4px,
        ),
    ));

    @include desktop-props((
        color: var(--color__cuttlefish),
        font-family: $gilroy-bold,
        font-size: (
            1024: 28px,
            1920: 30px,
        ),
        letter-spacing: (
            1024: -1.03px,
            1920: -1.11px,
        ),
        margin-bottom: (
            1024: 4px,
        ),
    ));
}

.basket__empty-subtitle {
    @include mobile_320 {
        font-family: $gilroy;
        font-size: 14px;
        letter-spacing: -0.14px;
        color: var(--color__cuttlefish);
    }
    @include mobile_360 {
        font-size: 16px;
        letter-spacing: -0.16px;
    }

    @include desktop_960 {
        font-family: $gilroy-light;
        font-size: 16px;
        line-height: 1.13;
        letter-spacing: -0.52px;
        color: var(--color__pepper);
    }
}

.plate-pic {
    -webkit-animation: emptyBasketEnteredImageAnimation 1100ms;
    animation: emptyBasketEnteredImageAnimation 1100ms;

    @include mobile-props((
        position: relative,
        margin-bottom: (
            0: 2px,
            360: 8px,
            375: 16px,
            414: -2px,
        ),
        width: (
            0: 246px,
            360: 299px,
            414: 364px,
        ),
    ));

    @include desktop-props((
        position: relative,
        margin-bottom: (
            1024: 15px,
            1920: 20px,
        ),
        width: (
            1024: 320px,
            1920: 428px,
        ),
    ));
}

