@import '~styles/variables/index';

.NYLPBannerRoot {
    @include all-props((
        display: flex,
        width: (
            1024: calc(100% - #{$v3-res1024-gird-gap}),
            1366: calc(100% - #{$v3-res1366-gird-gap}),
            1440: calc(100% - #{$v3-res1440-gird-gap}),
            1600: calc(100% - #{$v3-res1600-gird-gap}),
            1920: calc(100% - #{$v3-res1920-gird-gap}),
        ),
        border-radius: (
            1024: 12px,
            1366: 14px,
            1600: 16px,
            1920: 20px
        ),
        font-family: $gilroy,
        background-color: #325A41,
        color: var(--color__coconut),
        box-sizing: border-box,
        overflow: hidden,
    ))
}

.NYLPBannerDescContainer {
    @include all-props((
        width: 100%,
        padding: (
            1024: 40px,
            1366: 42px,
            1440: 44px,
            1600: 50px,
            1920: 60px,
        ),
        box-sizing: border-box
    ))
}

.NYLPBannerTitle {
    @include desktop-props((
        font-size: (
            1024: 44px,
            1366: 46px,
            1440: 50px,
            1600: 56px,
            1920: 60px,
        ),
        line-height: (
            1024: 39.6px,
            1366: 41.4px,
            1440: 45px,
            1600: 50.4px,
            1920: 54px,
        ),
        font-weight: 400,
        letter-spacing: (
            1024: -1.4px,
            1366: -1.2px,
            1440: -1.2px,
            1600: -1px,
            1920: -1px,
        ),
        margin-bottom: (
            1024: 8px,
            1366: 10px,
            1440: 12px,
            1600: 12px,
            1920: 14px,
        ),
        opacity: (
            1024: 0.9,
            1600: 1,
        ),
    ))
}

.NYLPBannerDescription {
    @include desktop-props((
        font-size: (
            1024: 16px,
            1366: 18px,
            1440: 18px,
            1600: 20px,
            1920: 20px,
        ),
        line-height: (
            1024: 17.6px,
            1366: 19.8px,
            1440: 19.8px,
            1600: 22px,
            1920: 22px,
        ),
        font-weight: 400,
        letter-spacing: (
            1024: -0.52px,
            1366: -0.8px,
            1440: -0.44px,
            1600: -0.361px,
            1920: -0.361px,
        ),
    ))
}

.NYLPBannerImageContainer {
    @include desktop-props((
        min-width: (
            1024: 433px,
            1366: 464px,
            1440: 472px,
            1600: 551px,
            1920: 660px,
        ),
        width: (
            1024: 47.792494%,
            1366: 47.835052%,
            1440: 47.773279%,
            1600: 47.7883781%,
            1920: 47.826087%,
        ),
        flex-shrink: 0,
        height: auto,
        background-image: url('./img/NYLPBanner.jpg'),
        background-size: cover,
        background-position: center,
    ));
}

.NYLPBannerDescL {
    @include desktop-props((
        display: (
            1024: none,
            1920: block,
        )
    ))
}

.NYLPBannerDescRegular {
    @include desktop-props((
        display: (
            1024: block,
            1920: none,
        )
    ))
}
