$font-path: '../../assets/fonts';
$font-name: 'Geometria-ExtraBold';

$src:
  url("#{$font-path}/#{$font-name}.woff") format('woff')
;

@font-face {
    font-family: $font-name;
    src:  $src;
    font-display: block;
    font-display: swap;
}
