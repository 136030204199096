@import '~styles/variables/index';

.desinfection-info-popup {
    background: var(--color__coconut);
    position: fixed;
    z-index: 3000;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;

    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
        width: 439px;
        box-shadow: 0 2px 30px 0 rgba(198, 198, 198, 0.29);
        &.above-sidebar {
            width: 515px;
        }
    }
    @media (min-width: 1280px) {
        width: 458px;
        &.above-sidebar {
            width: 515px;
        }
    }
    @media (min-width: 1366px) {
        width: 460px;
        &.above-sidebar {
            width: 515px;
        }
    }
    @media (min-width: 1600px) {
        width: 484px;
        &.above-sidebar {
            width: 515px;
        }
    }
    @media (min-width: 1680px) {
        width: 528px;
        &.above-sidebar {
            width: 515px;
        }
    }
    @media (min-width: 1920px) {
        width: 541px;
        &.above-sidebar {
            width: 561px;
        }
    }
}

.popup-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    min-height: 48px;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.04);

    @media (min-width: 360px) {
        min-height: 60px;
    }

    @media (min-width: 1024px) {
        position: relative;
        transform: translateX(-100%);
        justify-content: center;
        width: 57px;
        min-height: 59px;
        box-shadow: none;
    }
}

.popup-header__close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    height: 100%;
    width: 30%;
    padding-right: 24px;
    border: none;
    outline: none;
    background: var(--color__coconut);

    svg {
        width: 16px;
        height: 16px;
    }

    @media (min-width: 360px) {
        svg {
            width: 18px;
            height: 18px;
        }
    }

    @media (min-width: 414px) {
        svg {
            width: 20px;
            height: 20px;
        }
    }

    @media (min-width: 1024px) {
        width: 100%;
        padding: 0;
        border: solid 1px $concrete;

        justify-content: center;

        svg {
            width: 16px;
            height: 16px;
        }
    }
}

.popup-content {
    overflow-y: scroll;
    padding: 55px 32px;

    @media (min-width: 360px) {
        padding: 59px 40px 40px 32px;
    }
    @media (min-width: 375px) {
        padding: 61px 32px 41px 34px;
    }
    @media (min-width: 414px) {
        padding: 45px 45px 41px 34px;
    }
    @media (min-width: 1024px) {
        overflow-y: auto;
        margin-top: -3px;
        padding: 26px 46px 20px 40px;
    }
    @media (min-width: 1280px) {
        padding: 31px 46px 20px 40px;
    }
    @media (min-width: 1440px) {
        padding: 43px 46px 20px 43px;
    }
    @media (min-width: 1440px) {
        padding: 51px 46px 20px 43px;
    }
    @media (min-width: 1920px) {
        padding: 51px 46px 20px 43px;
    }
    @media (min-width: 1920px) {
        padding: 70px 46px 20px 43px;
    }
}

.desinfection-popup-title {
    font-family: $gilroy-extrabold;
    font-size: 28px;
    line-height: 1;
    letter-spacing: -1.03px;
    color: #424242;

    &--desktop {
        display: none;
    }

    @media (min-width: 360px) {
        font-size: 32px;
        letter-spacing: -1.18px;
    }
    @media (min-width: 375px) {
        font-size: 33px;
        letter-spacing: -1.22px;
    }
    @media (min-width: 414px) {
        font-size: 36px;
        letter-spacing: -1.33px;
    }
    @media (min-width: 1024px) {
        &--desktop {
            display: block;
        }
        &--mobile {
            display: none;
        }

        font-family: $gilroy-bold;
        font-size: 27px;
        line-height: 30px;
        letter-spacing: -0.99px;
    }
    @media (min-width: 1280px) {
        font-size: 28px;
        letter-spacing: -1.03px;
    }

    @media (min-width: 1920px) {
        font-size: 29px;
        line-height: 31px;
        letter-spacing: -1.07px;
    }
}

.desinfection-popup__items {
    margin-top: 12px;

    @media (min-width: 360px) {
        margin-top: 18px;
    }
    @media (min-width: 375px) {
        margin-top: 20px;
    }
    @media (min-width: 414px) {
        margin-top: 23px;
    }
    @media (min-width: 1024px) {
        margin-top: 28px;
    }
    @media (min-width: 1280px) {
        margin-top: 30px;
    }
    @media (min-width: 1920px) {
        margin-top: 28px;
    }
}

.desinfection-popup__item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    @media (min-width: 360px) {
        margin-bottom: 18px;
    }
    @media (min-width: 375px) {
        margin-bottom: 19px;
    }
    @media (min-width: 414px) {
        margin-bottom: 20px;
    }
    @media (min-width: 1024px) {
        margin-bottom: 23px;
    }
}

.desinfection-popup__item-image {
    display: flex;

    svg {
        display: block;
        width: 49px;
        height: 49px;
    }
    @media (min-width: 360px) {
        svg {
            width: 53px;
            height: 53px;
        }
    }
    @media (min-width: 375px) {
        svg {
            width: 56px;
            height: 56px;
        }
    }
    @media (min-width: 414px) {
        svg {
            width: 63px;
            height: 63px;
        }
    }
    @media (min-width: 1024px) {
        svg {
            width: 51px;
            height: 51px;
        }
    }
}

.desinfection-popup__item-content {
    margin-left: 21px;

    @media (min-width: 360px) {
        margin-left: 14px;
    }
    @media (min-width: 375px) {
        margin-left: 18px;
    }
    @media (min-width: 414px) {
        margin-left: 12px;
    }
    @media (min-width: 1024px) {
        margin-left: 16px;
    }
    @media (min-width: 1280px) {
        margin-left: 21px;
    }
    @media (min-width: 1440px) {
        margin-left: 23px;
    }
}

.desinfection-popup__item-text {
    font-family: $gilroy-medium;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.52px;
    color: #424242;

    &--desktop {
        display: none;
    }

    .desinfection-popup__item-text__number {
        font-family: $NHaasGroteskTXPro-55Rg;
    }

    @media (min-width: 360px) {
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.59px;
    }

    @media (min-width: 375px) {
        font-size: 17px;
        line-height: 19px;
        letter-spacing: -0.63px;
    }

    @media (min-width: 414px) {
        font-size: 19px;
        line-height: 21px;
        letter-spacing: -0.7px;
    }

    @media (min-width: 1024px) {
        &--desktop {
            display: block;
        }
        &--mobile {
            display: none;
        }
        color: #404040;
        font-family: $gilroy;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.52px;
    }
}
