@import "~global/styles/cool-media-queries/index.global.scss";
@import '~styles/variables/index';


.notifierWrapper {
    font-family: Gilroy;
    text-align: start;
    @include mobile-props((
        padding: 14px,
        border-radius: 14px,
        background-color: #E3E7F8,
    ));
}


.notifierContentContainer {
    width: 100%;
    @include mobile-props((
        display: flex,
        flex-direction: row,
        justify-content: space-between,
        align-content: center,
        gap: 8px,
    ));

    .linkIcon {
        align-self: center;
        justify-self: end;
    }
}

.notifierSimpleContent {
    @include mobile-props((
        width: 100%,
        display: flex,
        justify-content: space-between,
        align-items: center,
    ));
}

.notifierContent {
    @include mobile-props((
        width: 100%,
        display: flex,
        flex-direction: column,
        justify-content: center,
    ))
}

.notifierText {
    color: #7483D2;
    @include mobile-props((
        font-size: 15px,
        line-height: 16px,
        font-weight: 500,
        letter-spacing: -0.16px,
        font-style: normal,
    ));
}

.notifierTextSmall {
    color: #7483D2;
    @include mobile-props((
        font-size: 14px,
        line-height: 14px,
        font-weight: 500,
        letter-spacing: -0.16px,
        font-style: normal,
    ));
}

.notifierTextSmallBold {
    color: #7483D2;
    @include mobile-props((
        font-size: 14px,
        line-height: 15px,
        font-weight: 700,
        letter-spacing: -0.16px,
        font-style: normal,
    ));
}


.bonusesAmountCont {
    display: inline-flex;
    align-items: center;
    @include all-props((
        gap: (
            0: 2px,
            375: 4px,
        ),
    ));
    padding-left: 4px;
}

.bonusesAmount {
    color: #7483D2;
    font-family: $haas-txstd;
    font-style: normal;
    font-weight: 500;

    @include mobile-props((
        font-size: (
            0: 14px,
            375: 15px,
        ),
        line-height: (
            0: 14px,
            375: 15px,
        ),
        letter-spacing: -0.2px,
    ));
}

.bonusesIconLarge {
    @include mobile-props((
        width: 38px,
        height: 38px,
    ));

    rect {
        width: 100%;
        height: 100%;
    }
}

.bonusesIconSmall {
    display: inline;
    @include mobile-props((
        width: (
            0: 13px,
            375: 15px,
        ),
        height: (
            0: 13px,
            375: 15px,
        )
    ));

    rect {
        width: 100%;
        height: 100%;
    }
}
.linkIcon {
    width: auto;
    flex-shrink: 0;
}
