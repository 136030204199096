@import '~styles/variables/index';

.customization-comment__sidebar {
    @include desktop_960 {
        position: fixed;
        z-index: 3000;
        right: 0;
        top: 0;
        bottom: 0;
        width: 515px;
        background: var(--color__coconut);
    }
    @include desktop_1920 {
        width: 560px;
    }
}

.sidebar__close {
    @include desktop_960 {
        position: absolute;
        transform: translateX(-100%);

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 57px;
        min-height: 59px;
        background: var(--color__coconut);
        padding: 0;
        outline: none;
        border: solid 1px $concrete;

        svg {
            width: 16px;
            height: 16px;
        }
    }
}
