@import "~global/styles/cool-media-queries/index.global.scss";

.upperShieldRoot {
    &.kozel {
        @include all-props((
            width: (
                0: 60px,
                375: 70px,
                1024: 60px,
                1366: 70px,
                1920: 90px,
            ),
            height: (
                0: 60px,
                375: 70px,
                1024: 60px,
                1366: 70px,
                1920: 90px,
            ),
        ))
    }

    @include all-props((
        width: (
            0: 116px,
            375: 136px,
            1024: 116px,
            1366: 136px,
            1920: 194px,
        ),
        height: (
            0: 123px,
            375: 144px,
            1024: 123px,
            1366: 144px,
            1920: 205px,
        ),
        z-index: 1,
        position: absolute,
        left: (
            0: 10px,
            375: 12px,
            1024: 10px,
            1366: 12px,
        ),
        top: (
            0: 10px,
            375: 12px,
            1024: 10px,
            1366: 12px,
        ),
    ));

    & .s {
        @include all-props((
            display: (
                0: block,
                375: none,
                1024: block,
                1366: none,
            ),
        ));
    };

    & .m {
        @include all-props((
            display: (
                0: none,
                375: block,
                1024: none,
                1366: block,
                1920: none,
            ),
        ));
    };

    & .l {
        @include all-props((
            display: (
                0: none,
                1024: none,
                1920: block,
            ),
        ))
    };
}

.upperShieldImg {
    @include all-props((
        width: 100%,
        height: 100%,
    ))
}
