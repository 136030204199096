@import '~styles/variables/index';

.achievementsBannerBig {
    @include mobile-props((
        position: fixed,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0,
        pointer-events: none,
        z-index: 1100,
        transition: opacity 500ms ease-in-out,
    ));

    &.achievementsBannerBigVisible {
        @include mobile-props((
            opacity: 1,
            pointer-events: all,
        ));

        .achievementsBannerBackdrop {
            @include mobile-props((
                backdrop-filter: blur(6px),
            ))
        }
    }
}

.achievementsBannerBackdrop {
    @include mobile-props((
        position: absolute,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: rgba(255, 255, 255, 0.60),
        transition: backdrop-filter 500ms ease-in-out,
    ));
}

.achievementsBannerContent {
    background-image: url('../img/legend.png');

    @include mobile-props((
        box-sizing: border-box,
        position: absolute,
        top: 50%,
        transform: translateY(-50%),
        background-color: #FF6B00,
        background-position-y: -18%,
        background-size: contain,
        background-repeat: no-repeat,
        box-shadow: 3px -4px 16px 0px rgba(0, 0, 0, 0.05),
    ));

    @include responsive-mobile-props(375px, (
        left: 20px,
        right: 20px,
        border-radius: 30px,
        padding: 20px,
    ));
}

.achievementsBannerClose {
    @include mobile-props((
        position: absolute,
    ));

    @include responsive-mobile-props(375px, (
        top: 20px,
        right: 20px,
        width: 28px,
        height: 28px,
    ));

    img {
        width: 100%;
        height: 100%;
    }
}

.achievementsBannerTitle {
    @include mobile-props((
        font-family: $gilroy,
        color: var(--color__coconut),
        font-weight: var(--font-weight__bold),
    ));
    @include responsive-mobile-props(375px, (
        font-size: 40px,
        line-height: 37px,
        letter-spacing: -1px,
    ));
}

.achievementsBannerText {
    @include mobile-props((
        font-family: $gilroy,
        color: var(--color__coconut),
        font-weight: var(--font-weight__medium),
    ));
    @include responsive-mobile-props(375px, (
        margin-top: 197px,
        font-size: 18px,
        line-height: 19px,
        letter-spacing: -0.2px,
    ));
}

.achievementsBannerButton {
    @include responsive-mobile-props(375px, (
        margin-top: 20px,
    ))
}
