@import "~global/styles/cool-media-queries/index.global.scss";

.combinedShieldRoot {
    @include all-props((
        height: (
            0: 66px,
            375: 77px,
            1024: 66px,
            1366: 77px,
            1920: 78px,
        ),
        z-index: 1,
        position: absolute,
        left: (
            0: 10px,
            375: 12px,
            1024: 10px,
            1366: 12px,
        ),
        bottom: (
            0: 10px,
            375: 12px,
            1024: 10px,
            1366: 12px,
        ),
    ));

    &.chef {
        @include all-props((
            height: (
                0: 50px,
                375: 60px,
                1024:50px,
                1366: 60px,
                1920: 61px,
            ),
        ));
    }

    & .s {
        @include all-props((
            display: (
                0: block,
                375: none,
                1024: block,
                1366: none,
            ),
        ));
    };

    & .m {
        @include all-props((
            display: (
                0: none,
                375: block,
                1024: none,
                1366: block,
                1920: none,
            ),
        ));
    };

    & .l {
        @include all-props((
            display: (
                0: none,
                1024: none,
                1920: block,
            ),
        ))
    };
}

.combinedShieldImg {
    @include all-props((
        height: (
            0: 66px,
            375: 77px,
            1024: 66px,
            1366: 77px,
            1920: 78px,
        ),
    ));

    &.chef {
        @include all-props((
            height: (
                0: 50px,
                375: 60px,
                1024:50px,
                1366: 60px,
                1920: 61px,
            ),
        ));
    }
}
