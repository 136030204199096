@import '~styles/variables/index';
@import "~global/styles/fonts/index.scss";

.single-step-checkout__delivery-area {
    @include all-props((
        padding: (
            0: 33px 16px 26px,
            375: 37px 20px 22px,
            1024: 25px 28px 22px 26px,
        ),
        background-color: var(--color__coconut),
    ));
    @include desktop-props((
        margin: 25px 20px 20px 20px,
        border-radius: 20px,
    ))
}

.single-step-checkout__rows {
    @include all-props((
        list-style: none,
        margin-top: (
            0: 8px,
            375: 10px,
            1024: 10px,
        ),
        margin-bottom: 0,
        padding-left: 0,
    ))
}

.single-step-checkout__row {
    @include all-props((
        border-top: 1px solid var(--color__salt),
        padding-left: 0,
    ));
}

$row-gap: 11px;
$row-img-width: 14px;

.single-step-checkout__row-edit {
    @include all-props((
        display: flex,
        align-items: justify-content,
        gap: $row-gap,
        padding: (
            0: 15px 0,
            375: 17px 0,
            1024: 17px 0,
        ),
        width: 100%,
    ));
}

.single-step-checkout__row--edit-content {
    @include all-props((
        width: calc(100% - $row-gap - $row-img-width),
        text-align: left,
    ));
}

.single-step-checkout__row--edit-arrow {
    @include all-props((
        width: $row-img-width,
        display: flex,
        align-items: flex-start,
        padding-top: 2px,
    ));

    img {
        @include all-props((
            width: 100%,
        ));
    }
}

// internal content

.single-step-checkout__row-phone {
    @include all-props((
        font-family: $haas-disp,
        font-weight: 600,
        font-size: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
        line-height: (
            0: 18px,
            375: 20px,
            1024: 20px,
        ),
        color: var(--color__pepper),
    ));
}

.single-step-checkout__text-primary {
    @include all-props((
        font-family: $gilroy,
        font-weight: 600,
        font-size: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
        line-height: (
            0: 18px,
            375: 20px,
            1024: 20px,
        ),
        letter-spacing: -0.2px,
        color: var(--color__pepper),
    ));
}

.single-step-checkout__row-address-main {
    @include all-props((
        text-overflow: ellipsis,
        white-space: nowrap,
        overflow: hidden,
    ));
}

.single-step-checkout__text-secondary {
    @include mobile-props((
        font-family: $gilroy,
        font-weight: 500,
        font-size: (
            0: 15px,
            375: 16px,
            1024: 16px,
        ),
        line-height: (
            0: 18px,
            375: 20px,
            1024: 20px,
        ),
        letter-spacing: -0.2px,
        color: var(--color__hering),
    ));
}

.single-step-checkout__address-comment {
    @include all-props((
        margin-top: (
            0: 7px,
            375: 10px,
            1024: 10px,
        ),
        font-family: $gilroy,
        font-weight: 500,
        font-size: (
            0: 15px,
            375: 16px,
            1024: 16px,
        ),
        line-height: 17px,
        letter-spacing: -0.2px,
        color: var(--color__pepper),
    ));
}

.single-step-checkout__row-payment {
    @include all-props((
        margin-top: (
            0: 0px,
            375: -3px,
            1024: -3px,
        )
    ));
}

.single-step-checkout__row-payment-active-method {
    @include all-props((
        margin-top: (
            0: 0px,
            375: 1px,
            1024: 1px,
        )
    ));
}

.single-step-checkout__row-paymen-subscription-label {
    @include all-props((
        display: inline-block,
        background-color: var(--color__dill),
        border-radius: 5px,
        font-family: $gilroy,
        font-weight: 700,
        font-size: (
            0: 13px,
            375: 14px,
            1024: 14px,
        ),
        line-height: 13px,
        margin-top: (
            0: 5px,
            375: 6px,
            1024: 6px,
        ),
        padding: (
            0: 4px 8px 5px,
            375: 5px 8px,
            1024: 5px 8px,
        ),
        color: var(--color__coconut),
    ))
}
