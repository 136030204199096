$dish-image-heigth-1024: 238px;
$dish-image-heigth-1280: 242px;
$dish-image-heigth-1366: 256px;
$dish-image-heigth-1440: 271px;
$dish-image-heigth-1600: 304px;
$dish-image-heigth-1920: 364px;

$basket-button-size-960: 41px;
$basket-button-size-1024: 43px;
$basket-button-size-1366: 46px;
$basket-button-size-1440: 49px;
$basket-button-size-1600: 55px;
$basket-button-size-1920: 66px;
