@import '~styles/variables/index';

.datesWrapper {
    @include desktop-props((
        position: relative,
        display: flex,
        gap: 8px,
    ));
}

.datesListContainer {
    @include all-props((
        width: 100%,
        overflow-y: scroll,
        overflow-x: visible,
        padding-right: 20px,
        scroll-behavior: smooth,
    ));

    @include desktop-props((
        position: relative,
        overflow-y: hidden,
        padding-right: 0,
    ));

    &::-webkit-scrollbar { // chrome, safari, opera
        @include all-props((
            display: none,
        ));
    }
}

.datesList {
    @include all-props((
        display: flex,
        flex-wrap: nowrap,
        gap: 8px,
    ));
}

.datesListSpacer {
    @include mobile-props((
        min-width: 20px,
    ));
}

.dateSelectButton {
    @include all-props((
        width: (
            0: 80px,
            375: 94px,
            1024: 94px,
        ),
        min-width: (
            0: 80px,
            375: 94px,
            1024: 94px,
        ),
        height: (
            0: 52px,
            375: 62px,
            1024: 62px,
        ),
        padding: (
            0: 9px 12px,
            375: 12px 14px,
            1024: 12px 14px,
        ),
        border-radius: (
            0: 8px,
            375: 10px,
            1024: 10px,
        ),
        color: var(--color__pepper),
        text-align: left,
        border: 2px solid var(--color__grey-salt),
    ));
}

.dateSelectButtonActive {
    @include mobile-props((
        background: var(--color__coconut),
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.04),
    ));
    @include desktop-props((
        background: var(--color__egg),
        color: var(--color__choco),
        border: 2px solid var(--color__grey-salt),
    ));
    @include all-props((
        border: none,
    ));
}

.dateSelectTitle {
    @include all-props((
        font-family: $gilroy,
        font-size: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
        font-weight: var(--font-weight__bold),
        line-height: 18px,
        letter-spacing: -0.2px,
        text-transform: capitalize,
    ));

    @include mobile-props((
        color: #747474,
    ));
    @include desktop-props((
        color: #747474,
    ));

    &.dateSelectTitleWihte {
        color: var(--color__coal);
    }
    &.active {
        @include mobile-props((
            color: #4F4F4F,
        ));
        @include desktop-props((
            color: var(--color__choco),
        ));
    }
}

.dateSelectSubtitle {
    @include all-props((
        margin-top: 2px,
        font-family: $gilroy,
        font-size: (
            0: 13px,
            375: 15px,
            1024: 15px,
        ),
        font-weight: var(--font-weight__medium),
        line-height: (
            0: 15px,
            375: 17px,
            1024: 17px,
        ),
        letter-spacing: -0.2px,
    ));

    @include mobile-props((
        color: #747474,
    ));
    @include desktop-props((
        color: #747474,
    ));

    &.dateSelectSubtitleWhite {
        color: var(--color__hering),
    }
    &.active {
        @include mobile-props((
            color: #4F4F4F,
        ));
        @include desktop-props((
            color: var(--color__choco),
        ));
    }
}

.dateScrollButton {
    @include desktop-props((
        display: flex,
        align-items: center,
        justify-content: center,
        width: 32px,
        min-width: 32px,
        height: 62px,
        border-radius: 10px,
        border: 2px solid var(--color__grey-salt),
        color: var(--color__pepper),
        text-align: left,
    ));

    &:disabled{
        @include desktop-props((
            border-color: var(--color__salt),
        ));
    }

    &.dateScrollButtonWhite {
        background-color: var(--color__coconut);
    }

    &.dateScrollButtonTrasnparent {
        background-color: transparent;
    }

    img {
        width: 100%;
        @include responsive-desktop-props(1024px, (
            height: 12px,
        ))
    }

    &.dateScrollButtonBack {
        img {
            transform: rotate(-180deg);
        }
    }
    // &.dateScrollButtonForward { }
}


.responisveContainer {
    &.datesWrapper {
        @include responsive-desktop-props(1536px, (
            gap: 8px,
        ));
    }

    .datesList {
        @include responsive-desktop-props(1536px, (
            gap: 8px,
        ));
    }

    .dateSelectButton {
        @include desktop-props((
            box-sizing: border-box,
        ));

        @include responsive-desktop-props(1536px, (
            width: 92px,
            min-width: 92px,
            height: 62px,
            padding-top: 12px,
            padding-bottom: 12px,
            border-radius: 10px,
            border-width: 2px,
        ));
    }

    .dateSelectTitle {
        @include responsive-desktop-props(1536px, (
            font-size: 18px,
            line-height: 18px,
        ));
    }

    .dateSelectSubtitle {
        @include responsive-desktop-props(1536px, (
            font-size: 15px,
            line-height: 17px,
        ));
    }

    .dateScrollButton {
        @include desktop-props((
            box-sizing: border-box,
        ));

        @include responsive-desktop-props(1536px, (
            width: 30px,
            min-width: 30px,
            height: 62px,
            padding-top: 12px,
            padding-bottom: 12px,
            border-radius: 10px,
            border-width: 2px,
        ));
    }
}
