@import "~global/styles/cool-media-queries/index.global.scss";

.nextOverlayRoot {
    @include all-props((
        position: fixed,
        z-index: 10000,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,

        height: 100%,
        width: 100%,
    ));

    &:global(.exited) {
        @include all-props((
            pointer-events: none,
            visibility: hidden,
        ));
    }

    &:global(.entering) {
        // display: none;
    }

    &:global(.entered) {
        // display: none;
    }

    &:global(.exiting) {
        // display: none;
    }
}

.nextOverlayBlurLayer {
    $initialWhite: rgba(255, 255, 255, 0);
    $blurredWhite: rgba(255, 255, 255, 0.3);

    @include all-props((
        position: absolute,
        z-index: 1,
        top: 0,

        width: 100%,
        height: 100%,

        // background-color: var(--color__coconut),
        backdrop-filter: blur(4px),
        transition: (
            backdrop-filter 500ms,
            background-color 500ms
        ),
    ));


    &:global(.exited) {
        @include all-props((
            visibility: hidden,
            backdrop-filter: blur(0px),
            background-color: $initialWhite,
        ));
    }

    &:global(.entering) {
        @include all-props((
            backdrop-filter: blur(4px),
            background-color: $blurredWhite,
        ));
    }

    &:global(.entered) {
        @include all-props((
            backdrop-filter: blur(4px),
            background-color: $blurredWhite,
        ));
    }

    &:global(.exiting) {
        @include all-props((
            backdrop-filter: blur(0px),
            background-color: $initialWhite,
        ));
    }
}

.nextOverlayCloseLayer {
    @include all-props((
        border: none,
        outline: none,
        margin: 0,
        padding: 0,
        width: auto,
        overflow: visible,
        background: transparent,
        text-align: unset,
    ));

    @include all-props((
        position: absolute,
        z-index: 1,
        top: 0,

        width: 100%,
        height: 100%,
    ));
}

.nextOverlayContentLayer {
    height: 100%;
    position: absolute;
    z-index: 1;
    width: 100%;
    transition: transform 500ms;

    &:global(.exited) {
        @include all-props((
            transform: (
                0:    translateY(100%),
                1024:  translateY(100vh),
            ),
        ));
    }

    &:global(.entering) {
        @include all-props((
            transform: translateY(0%),
        ));
    }

    &:global(.entered) {
        @include all-props((
            transform: translateY(0%),
        ));
    }

    &:global(.exiting) {
        @include all-props((
            transform: (
                0:    translateY(100%),
                1024:  translateY(100vh),
            ),
        ));
    }
}


