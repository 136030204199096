@import '~styles/variables/index';

$grass: 'app/assets/dots.img.svg';

.portions-select {
    @include all-props((
        position: relative,
        overflow: hidden,
        cursor: pointer,
    ));

    &.disabled {
        @include all-props((
            padding-bottom: (
                0:   3px,
                360: 4px,
                414: 2px,
                1024: 2px,
            ),
        ));
    }
}

.portions-select__text-container {
    position: relative;
    color: #404040;
    top: -1px;

    &.disabled {
        top: 0;
    }

    &:active {
        color: #dbdbdd;
    }
    &.disabled:active {
        color: #404040;
    }
}

.portions__count {
    @include all-props((
        font-size: 13px,
        font-family: (
            0:  $NHaasGroteskTXStd-75Bd,
            1024:  $NHaasGroteskTXStd-65Md,
        ),
        letter-spacing: 0.06px,
    ));

    &.small, &.trial {
        @include all-props((
            margin-right: 0px,

            font-size: (
                0:   14px,
                375: 15px,
                414: 16px,
                1024: 16px,
            ),
            letter-spacing: (
                0:   -0.44px,
                375: -0.34px,
                1024: -0.34px,
            ),
        ));
    }
    &.large {
        @include all-props((
            font-size: (
                360: 14px,
                414: 15px,
                1024: 15px,
            ),
        ));
    }
}

.portions__units {
    &.small, &.trial {
        @include all-props((
            font-family: $gilroy,
            font-weight: (
                0: 700,
                1024: 600,
            ),
            font-size: (
                0: 16px,
                375: 17px,
                414: 18px,
                1024: 18px,
            ),
            line-height: (
                0: 19px,
                375: 20px,
                414: 22px,
                1024: 22px,
            ),
            letter-spacing: -0.44px,
        ));
    }
    &.large {
        @include all-props((
            font-family: $gilroy,
            font-weight: 700,
            font-size: (
                0:   14px,
                360: 15px,
                414: 16px,
                1024: 16px,
            ),
            letter-spacing: (
                0:   -0.44px,
                360: -0.34px,
                1024: -0.34px,
            ),
        ));
    }
}

.portions-select__dots {
    position: absolute;
    z-index: 10;
    left: 0px;
    transform: translateY(-6.5px);
    width: 108px;

    @media (min-width: 360px) {
        transform: translateY(-5.5px);
    }
    @media (min-width: 375px) {
        &.small {
            // width: 195%;
            width: 138px;
        }
        &.large {
            // width: 100%;
            width: 138px;
        }
    }
}


.basket-with--dots {
    &::before {
        content: '';
        background: url($grass) center center repeat-x;
        @include all-props((
            z-index: 5,
            position: absolute,
            height: 3px,
            width: 100%,
            bottom: -2px,
            left: 1px,
        ));
    }
}
