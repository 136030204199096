@import '~styles/variables/index';

.discount--wrap,
.discount__text--wrap {
    display: flex;
    align-items: center;
}

.discount__text--wrap {
    border: none;
    background: none;
    text-align: left;
    margin-left: 12px;
    padding: 0;

    @media (min-width: 1920px) {
        margin-left: 9px;
        margin-top: -2px;
    }
}

.discount__number {
    font-family: $gilroy;
    font-size: 28px;
    letter-spacing: -1.2px;
    color: var(--color__cuttlefish);
    margin-right: 4px;

    @media (min-width: 1366px) {
        font-size: 30px;
        letter-spacing: -2.4px;
    }

    @media (min-width: 1920px) {
        font-size: 34px;
        letter-spacing: -2.31px;
    }
}

.discount__number-value {
    font: inherit;
    letter-spacing: inherit;
    font-family: $NHaasGroteskDisp-55Roman;
}

.discount__text {
    margin-left: 2px;
    font-family: $gilroy;
    font-size: 13px;
    line-height: 1.08;
    color: var(--color__cuttlefish);
    letter-spacing: -0.25px;

    @media (min-width: 1366px) {
        margin-left: 7px;
        font-size: 15px;
        line-height: 1.07;
        letter-spacing: -0.29px;
    }

    @media (min-width: 1920px) {
        margin-top: -1px;
        margin-left: 6px;
        font-size: 16px;
        line-height: 1.06;
        letter-spacing: -0.31px;
    }
}

.discount__open-info-wrap {
    margin: 6px 0 0 50px;

    @media (min-width: 1920px) {
        margin-top: 8px;
    }
}

.discount__open-info {
    padding: 0;
    background: transparent;
    border: none;
    position: relative;

    .dots {
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 100%;
    }

    @media (min-width: 1920px) {
        .dots {
            bottom: -4px;
        }
    }
}

.discount__open-info-text {
    font-family: $gilroy-bold;
    font-size: 14px;
    letter-spacing: -0.36px;
    color: var(--color__cuttlefish);

    @media (min-width: 1366px) {
        font-size: 15px;
        line-height: 1.07;
        letter-spacing: -0.39px;
    }

    @media (min-width: 1600px) {
        font-size: 16px;
        letter-spacing: -0.41px;
    }

    @media (min-width: 1920px) {
        font-size: 17px;
        letter-spacing: -0.44px;
    }
}
