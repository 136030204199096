
@import '~styles/variables/index';

.ui-heading {
    @include all-props((
        color: var(--color__cuttlefish),
    ));
    &.inherit-colors {
        @include all-props((
            color: inherit,
        ));
    }

    &.ui-heading--level-0 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 700,
            letter-spacing: -4.43px,
            line-height: 1,

            font-size: (
                0:   48px,
                375: 52px,
                414: 56px,
                1024: 56px,
            ),
        ));

        // 'd1' in ui-kit
        :global(.num) {
            @include all-props((
                font-family: $haas-txstd,
                font-weight: 700,
                line-height: inherit,
                font-size: inherit,
                letter-spacing: -2.17px,
            ));
        }
    }

    &.ui-heading--level-1_1 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 900,
            letter-spacing: -1.35px,

            font-size: (
                0:   30px,
                375: 34px,
                414: 36px,
                1024: 36px,
            ),
            line-height: (
                0:   1.2,
                375: 1.06,
                414: 1,
                1024: 1,
            )
        ));
    }

    &.ui-heading--level-1_2 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 800,
            letter-spacing: -0.93px,

            font-size: (
                0:   30px,
                375: 34px,
                414: 36px,
                1024: 36px,
            ),
            line-height: (
                0:   1.07,
                375: 0.94,
                414: 0.89,
                1024: 0.89,
            )
        ));
        // not official desktop style (referral-page)
        @include desktop-props((
            font-family: $gilroy,
            font-weight: 700,
            letter-spacing: -1.43px,

            font-size: (
                1024:  26px,
                1280: 27px,
            ),
            line-height: (
                1024:  1.23,
                1280: 1.19,
            ),
        ));

        // 'd2' in ui-kit
        :global(.num) {
            @include all-props((
                font-family: $haas-dspro,
                font-weight: 700,
                font-size: inherit,
                letter-spacing: inherit,
            ));
            // not official desktop style (referral-page)
            @include desktop-props((
                font-family: $haas-dspro,
                font-weight: 700,
                font-size: inherit,
                letter-spacing: inherit,
            ));
        }
        :global(.ruble) {
            @include all-props((
                font-family: $gilroy,
                font-weight: 700,
                font-size: inherit,
                letter-spacing: -4.43px,
            ));
            // not official desktop style (referral-page)
            @include desktop-props((
            ));
        }
    }

    &.ui-heading--level-2_1 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 800,
            font-size: (
                0:   21px,
                360: 23px,
                375: 25px,
                414: 27px,
                1024: 27px,
            ),
            letter-spacing: (
                0:   -0.9px,
                360: -0.9px,
                375: -0.9px,
                414: -1px,
                1024: -1px,
            ),
        ));

        :global(.micro) {
            font-family: inherit;
            font-weight: inherit;
            @include all-props((
                    font-size: (
                    0:   15px,
                    360: 16px,
                    375: 17px,
                    414: 18px,
                    1024: 18px,
                ),
            ))
        }
    }

    &.ui-heading--level-2_2 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 800,
            letter-spacing: -0.93px,

            font-size: (
                0:   22px,
                375: 25px,
                414: 27px,
                1024: 27px,
            ),
            line-height: (
                0:   1.45,
                375: 1.28,
                414: 1.19,
                1024: 1.19,
            ),
        ));
        // not official desktop style (referral-page)
        @include desktop-props((
            font-family: $gilroy,
            font-weight: 700,
            letter-spacing: -0.93px,
            font-size: (
                1024:  23px,
            ),
            line-height: (
                1024:  1.04,
            ),
        ));

        // 'd3' in ui-kit
        :global(.num) {
            @include all-props((
                font-family: $haas-dspro,
                font-weight: 700,
                font-size: inherit,
                letter-spacing: inherit,
            ));
            // not official desktop style (referral-page)
            @include desktop-props((
                font-family: $haas-dspro,
                font-weight: 700,
                font-size: inherit,
                letter-spacing: inherit,
            ));
        }
        :global(.ruble) {
            // not official desktop style (referral-page)
            @include desktop-props((
                letter-spacing: inherit,
            ));
        }
    }

    &.ui-heading--level-3_1 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 900,
            letter-spacing: -1px,

            font-size: (
                0:   21px,
                375: 24px,
                414: 25px,
                1024: 25px,
            ),
        ));
    }

    &.ui-heading--level-3_2 {
        @include all-props((
            font-family: $gilroy,
            font-weight: (
                0: 800,
                1024: 700,
            ),
            font-size: (
                0:   21px,
                375: 24px,
                1024: 24px,
            ),
            line-height: 104%,
            letter-spacing: (
                0:   -0.6px,
                375: -0.7px,
                1024: -0.6px,
            ),
        ));
    }

    &.ui-heading--level-4_1 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 500,
            line-height: 1,

            font-size: (
                0:   19px,
                375: 22px,
                414: 23px,
                1024: 24px,
            ),
            letter-spacing: (
                0:   -0.51px,
                375: -0.59px,
                414: -0.62px,
                1024: -0.62px,
            ),
        ));

        // 'd4.1' in ui-kit
        :global(.num) {
            @include all-props((
                font-family: $haas-txpro,
                font-weight: 400,
                line-height: inherit,

                font-size: (
                    0:   17px,
                    375: 20px,
                    414: 21px,
                    1024: 21px,
                ),
                letter-spacing: (
                    0:   -0.48px,
                    375: -0.56px,
                    414: -0.59px,
                    1024: -0.59px,
                ),
            ));
        }
    }

    &.ui-heading--level-4_2 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 800,
            line-height: normal,
            letter-spacing: (
                360: -0.2px,
                1024: -0.2px,
            ),

            font-size: (
                0:   19px,
                375: 22px,
                414: 23px,
                1024: 23px,
            ),
        ));
        // not official desktop style (referral-page)
        @include desktop-props((
            font-family: $gilroy,
            font-weight: 700,
            font-size: 17px,
            line-height: normal,
            letter-spacing: -0.78px,
        ));

        // 'd4.2' in ui-kit
        :global(.num) {
            @include all-props((
                font-family: $haas-dsstd,
                font-weight: 500,
                font-size: (
                    0:   19px,
                    375: 20px,
                    414: 21px,
                    1024: 21px,
                ),
                letter-spacing: inherit,
            ));

            // not official desktop style
            @include desktop-props((
                font-family: $haas-dsstd,
                font-weight: 500,
                font-size: (
                    0:   19px,
                    375: 20px,
                    414: 21px,
                    1024: 21px,
                ),
                letter-spacing: inherit,
            ));
        }
    }
}

/* v2 стили / Новый UIKit */
.ui-heading {
    &.ui-heading--level-3_2-v2 {
        @include all-props((
            font-family: $gilroy,
            font-weight: 800,
            letter-spacing: -0.2px,
            font-size: (
                0: 17px,
                375: 20px,
                1024: 20px,
            ),
        ));
    }

}
