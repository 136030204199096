/*
    В файле описаны цвета, которых нет в ui-kit. Скорее всего все deprecated.
    нужно заменить на что-то или уточнить, что с ними делать
*/
:root {
    /*
        используется на лендингах:
        landings/promo-sets/src/styles/colors.scss
        landings/trial/src/styles/colors.scss
    */
    --color__not-ui__alabaster: #f7f7f7;
    --color__not-ui__alto: #d7d7d7;
    --color__not-ui__candle: #FFD713;
    --color__not-ui__cod-gray: #181818;
    --color__not-ui__corn-harvest: #91610D;
    --color__not-ui__corn: #ecc400;
    --color__not-ui__dove-gray: #656565;
    --color__not-ui__gold: #FFD700;
    --color__not-ui__melon: #fdadad;
    --color__not-ui__roman: #d75757;
    --color__not-ui__silver: #B8B8B8;
    --color__not-ui__wild-sand: #F5F5F5;

    /*
        используются в десктопе:
        landings/index/src/styles/colors.scss
    */
    --color__not-ui__bright-sun: #ffe23e;
    --color__not-ui__buttered-rum: #b0760f;
    --color__not-ui__candlelight: #FFDD25;
    --color__not-ui__chelsea-gem: #986100;
    --color__not-ui__concrete: #f2f2f2;
    --color__not-ui__gallery: #efefef;
    --color__not-ui__gray: #838383;
    --color__not-ui__mercury: #e1e1e1;
    --color__not-ui__nobel: #B5B5B5;
    --color__not-ui__nutmeg-wood: #644700;
    --color__not-ui__pirate-gold: #BF7A00;
    --color__not-ui__silver-chalice: #9E9E9E;
    --color__not-ui__sunglow: #FFD831;
    --color__not-ui__supernova: #fbcb01;
    --color__not-ui__verdun-green: #625300;

    /*
        используются в spa десктопе:
        elementaree/src/styles/variables/colors.scss
    */
    --color__not-ui__aluminium: #a7abb1;
    --color__not-ui__aqua-forest: #63aa66;
    --color__not-ui__barley-white: #fff6c8;
    --color__not-ui__black: #000000;
    --color__not-ui__boulder: #7c7c7c;
    --color__not-ui__burning-orange: #ff723a;
    --color__not-ui__buttermilk: #fff3b6;
    --color__not-ui__cinnamon: #6A5700;
    --color__not-ui__cola: #3e3400;
    --color__not-ui__danube: #6c95d8;
    --color__not-ui__drover: #fdf0af;
    --color__not-ui__emperor: #545454;
    --color__not-ui__golden-dream: #f3d42c;
    --color__not-ui__golden-sand: #f1df7a;
    --color__not-ui__himalaya: #786a20;
    --color__not-ui__kournikova: #ffe768;
    --color__not-ui__mine-shaft: #3e3e3e;
    --color__not-ui__mustard: #ffe65e;
    --color__not-ui__olive: #7e6a00;
    --color__not-ui__olive-grab: #60a22a;
    --color__not-ui__olivetone: #837114;
    --color__not-ui__picasso: #ffee91;
    --color__not-ui__roti: #b8a43d;
    --color__not-ui__saffron: #f3d033;
    --color__not-ui__salomie: #ffeb84;
    --color__not-ui__scorpion: #585858;
    --color__not-ui__shuttle-gray: #5d6064;
    --color__not-ui__sundown:  #ffb9b9;
    --color__not-ui__sushi: #73AD43;
}
