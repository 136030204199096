@import '~styles/variables/index';

.basketSetsSelectionRootButton {
    @include all-props((
        display: block,
        width: 100%,
    ));
}

.basketSetsSelectionRootWrapper {
    @include all-props((
        padding-bottom: (
            0: 4px,
            375: 10px,
            1024: 10px,
        ),
        max-width: 444px,
        background-color: #fff,
    ))
}

.basketSetsSelectionRoot {
    @include all-props((
        position: relative,
        box-sizing: border-box,
        display: flex,
        flex-direction: column,
        justify-content: flex-end,
        width: 100%,
        height: (
            0: 518px,
            375: 607px,
            1024: 607px,
        ),
        padding: (
            0: 16px,
            375: 20px,
            1024: 20px,
        ),
        background-position: top,
        background-size: cover,
        font-family: $gilroy,
        font-style: normal,
        color: #747474,
        overflow: hidden,
    ));

    &.maslenitsa {
        background-image: url('./img/maslenitsa_bg.jpg'),
    }
    &.romantic {
        background-image: url('./img/romantic_bg.jpg'),
    }
    &.green {
        background-image: url('./img/green_bg.jpg'),
    }
    &.marchgifts {
        background-image: url('./img/marchgifts_bg.jpg'),
    }
    &.march {
        background-image: url('./img/march_bg.jpg'),
    }
    &.easter {
        background-image: url('./img/easter_bg.jpg'),
    }
}

.basketSetsSelectionSliderContainer {
    @include all-props((
        padding: (
            0: 22px,
            375: 26px,
            1024: 26px,
        ),
        background-color: #FFF,
        border-radius: (
            0: 22px,
            375: 24px,
            1024: 24px,
        )
    ))
}

.basketHiddenBlock {
    @include all-props((
        width: (
            0: 120px,
            375: 138px,
            1024: 138px,
        ),
        height: (
            0: 93px,
            375: 107px,
            1024: 107px,
        ),
        flex-shrink: 0,
        opacity: 0,
    ));
}

.basketSetsSelectionTitle {
    @include all-props((
        text-align: start,
        margin-bottom: (
            0: 2px,
            375: 4px,
            1024: 4px,
        ),
        font-size: (
            0: 21px,
            375: 24px,
            1024: 24px,
        ),
        line-height: (
            0: 22px,
            375: 25px,
            1024: 25px,
        ),
        font-weight: 700,
        letter-spacing: -0.8px,
        color: #747474,
    ));

    &.march {
        color: #A26D4A
    }
    &.marchgifts {
        color: #F3959E
    }
    &.easter {
        color: #E88DA4
    }
}

.basketSetsSelectionDescription {
    @include all-props((
        padding-left: 0,
        margin-top: 0,
        margin-bottom: (
            0: 22px,
            375: 26px,
            1024: 26px,
        ),
        font-size: (
            0: 16px,
            375: 17px,
            1024: 17px,
        ),
        line-height: (
            0: 16.5px,
            375: 18px,
            1024: 18px,
        ),
        font-weight: 500,
        letter-spacing: -0.361px,
        color: #747474,
        list-style-position: inside,
        list-style: none,
        text-align: start,
    ));

    &.march {
        color: #A26D4A
    }
    &.marchgifts {
        color: #F3959E
    }
    &.easter {
        color: #E88DA4
    }
}
