@import '~styles/variables/index';

.mobileCategoryTabRoot {
    @include mobile-props((
        width: auto,
        display: flex,
        align-items: center,
        wrap: nowrap,
        padding: 4px 12px 4px 4px,
        gap: (
            0: 6px,
            375: 6px,
        ),
        background-color: #FFF,
        border-radius: (
            0: 8px,
            375: 10px,
        ),
        text-transform: lowercase,
        font-size: (
            0: 15px,
            375: 16px,
        ),
        line-height: 16px,
        font-weight: 500,
        letter-spacing: -0.2px,
        color: #9C9C9C,
        transition: color 150ms ease-in-out,
    ))
}

.mobileCategoryTitle {
    @include mobile-props((
        white-space: nowrap,
    ))
}

.mobileCategoryTitleActive {
    @include mobile-props((
        color: #747474,
        transition: color 150ms ease-in-out,
    ))
}

.mobileCategoryIcon {
    @include mobile-props((
        width: (
            0: 24px,
            375: 28px,
        ),
        height: (
            0: 24px,
            375: 28px,
        ),
        border-radius: (
            0: 6px,
            375: 8px,
        ),
        overflow: hidden,
        background-size: cover,
        background-position: center,
    ));

    &.main {
        background-image: url('../../../../../../../assets/categories/main.jpg')
    };
    &.soups {
        background-image: url('../../../../../../../assets/categories/soups.jpg')
    };
    &.salads {
        background-image: url('../../../../../../../assets/categories/salads.jpg')
    };
    &.breakfasts {
        background-image: url('../../../../../../../assets/categories/breakfasts.jpg')
    };
    &.desserts {
        background-image: url('../../../../../../../assets/categories/desserts.jpg')
    };
    &.upsell {
        background-image: url('../../../../../../../assets/categories/upsell.jpg')
    };
    &.snaks {
        background-image: url('../../../../../../../assets/categories/snaks.jpg')
    };
    &.vitamins {
        background-image: url('../../../../../../../assets/categories/vitamins.jpg')
    };
    &.farm {
        background-image: url('../../../../../../../assets/categories/farm.jpg')
    }
}
