@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";
@import '~styles/variables/index';


.selectIngredientsRoot {
    @include desktop-props((
        height: (
            1024: 46px,
            1366: 47px,
            1440: 49px,
            1600: 50px,
            1920: 67px,
        ),
        position: relative,
        border: 1px solid var(--color__salt),
        box-sizing: border-box,
        min-width: (
            1024: 191px,
            1366: 202px,
            1440: 214px,
            1600: 238px,
            1920: 287px,
        ),

        width: (
            1024: 191px,
            1366: 202px,
            1440: 214px,
            1600: 238px,
            1920: 287px,
        ),
        border-radius: (
            1024: 4px,
        ),
        padding: (
            1024: 0 14px,
            1366: 0 14px 0 15px,
            1440: 0 15px 0 17px,
            1600: 0 17px 0 19px,
            1920: 0 21px 0 21px,
        ),
        background-color: #fff
    ));

    &:global(.open) {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}

.selectIngredientsButton {
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    background-color: aqua;
    height: 100%;
    text-align: start;
    background-color: #fff
}

.selectIngredientsTextRow {
    @include desktop-props((
        width: 100%,
        display: flex,
        align-items: center,
    ));
}

.ingredientType {
    @include desktop-props((
        color: var(--color__hering),
        font-family: $gilroy,
        font-size: (
            1024: 15px,
            1600: 17px,
            1920: 20px,
        ),
        font-style: normal,
        font-weight: 400,
        line-height: (
            1024: 18px,
            1600: 19px,
            1920: 22px,
        ),
        letter-spacing: -0.2px,
    ));
}

.ingredientValue {
    @include desktop-props((
        flex: 1,
        color: var(--color__cuttlefish),
        font-family: $gilroy,
        font-size: (
            1024: 15px,
            1600: 17px,
            1920: 20px,
        ),
        font-style: normal,
        font-weight: 400,
        line-height: (
            1024: 18px,
            1600: 19px,
            1920: 22px,
        ),
        letter-spacing: -0.2px,
        text-overflow: ellipsis,
        white-space: nowrap,
        overflow: hidden,
    ));
}

.ingredientIcon {
    @include desktop-props((
        width: (
            1024: 13px,
            1600: 15px,
            1920: 18px,
        ),
        padding-left: (
            1024: 8px,
        ),
    ));
}

// -------

.selectIngredientsList {
    @include desktop-props((
        position: absolute,
        width: calc(100% + 2px),
        background-color: #fff,
        z-index: 1,
        left: -1px,
        top: calc(100% - 1px),
        display: flex,
        flex-direction: column,
        border-bottom-left-radius: 4px,
        border-bottom-right-radius: 4px,
    ));

}

.selectIngredientsListButton {
    @include desktop-props((
        background-color: #fff,
        box-sizing: border-box,
        text-align: start,
        padding: (
            1024: 0px 14px,
            1366: 0px 14px 0px 15px,
            1440: 0px 15px 0px 17px,
            1600: 0px 17px 0px 19px,
            1920: 0px 21px 0px 21px,
        ),
        transition: all 150ms,
        overflow: hidden,
        width: 100%,
        border: 1px solid var(--color__salt),
        border-bottom: 1px solid transparent,
        height: (
            1024: 46px,
            1366: 47px,
            1440: 49px,
            1600: 50px,
            1920: 67px,
        ),
    ));

    &:global(.selected) {
        background-color: #FEF6C7;
        border: 1px solid #F5EBBA;
    }

    // FIRST ITEM
    &:hover,
    &.active {
        & .selectIngredientsListItemText {
            color: var(--color__cuttlefish);
        }
        border: 1px solid #F5EBBA;
        border-bottom: 1px solid #FEF6C7;
        background-color: #FEF6C7;
    }

    // SECOND ITEM
    &:hover + &,
    &.active + & {
        border-top: solid 1px #F5EBBA;

    }

    &:last-child {
        border-bottom: 1px solid var(--color__salt);;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4%;

        &:hover,
        &.active {
            border-bottom: solid 1px #F5EBBA;
        }

        &:global(.selected) {
            background-color: #FEF6C7;
            border: 1px solid #F5EBBA;
        }
    }
}

.selectIngredientsListItemText {
    @include desktop-props((
        color: #838383,
        font-family: $gilroy,
        font-size: (
            1024: 15px,
            1600: 17px,
            1920: 20px,
        ),
        font-style: normal,
        font-weight: 400,
        line-height: (
            1024: 18px,
            1600: 19px,
            1920: 22px,
        ),
        letter-spacing: -0.2px,
    ));
}
