@import '~styles/variables/index';

/* ANIMATION */
@keyframes blurring {
    from {
        filter: blur(0px);
    }
    to {
        filter: blur(10px);
    }
}

/* COMMON CLASSES */
.blur {
    animation: blurring 600ms alternate;
    filter: blur(10px);
}


.mobile-auth__root-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}

.mobile-auth__content-container {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.phone-input-screen {
        width: 100%;
        margin: 0 auto;
    }

    &.need-display-plate {
        flex-direction: row;
        align-items: flex-start;
        padding: 0 !important;

        .mobile-auth__plate-container {
            display: flex;
        }
    }
}

.mobile-auth__plate-container {
    display: none;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: linear-gradient(214deg,#ffe662,#ffd702);
}

.mobile-auth__form-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 100%;
    width: 100%;
}

.mobile-auth__title-wrap {
    margin-bottom: 11px;
    text-align: center;
}

.mobile-auth__title {
    user-select: none;
    display: inline-block;
    max-width: 239px;
    font-family: $gilroy-extrabold;
    font-size: 17px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.81px;
    color: var(--color__pepper);
    text-align: center;
}

.mobile-auth__subtitle {
    font-family: $gilroy;
    font-size: 10px;
    text-align: center;
    color: var(--color__hering);
    line-height: 18px;
    margin-top: 7px;
}

.mobile-auth__pincode-input {
    position: relative;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 62px;
    width: 192px;
    height: 44px;
    border-radius: 5px;
}

.mobile-auth__input-correct-icon {
    position: absolute;
    right: 15px;
    top: 36%;
}

.mobile-auth__input-loading-icon {
    position: absolute;
    right: 15px;
    top: 32%;
}

.mobile-auth__button--container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding-bottom: 4px;
    margin-bottom: 3px;

    &.phone-input-screen {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.mobile-auth__link-button {
    border: none;
    background: none;

    padding: 0;
    padding-bottom: 5px;
    position: relative;
    overflow: hidden;

    font-family: $gilroy-medium;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.12px;
    color: var(--color__cuttlefish);

    &.disabled {
        cursor: default;
    }

    &.with-timer span {
        font: inherit;
    }
}

.mobile-auth__phone-number {
    margin-left: 10px;

    font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}

.mobile-auth__dots {
    position: absolute;
    bottom: 1px;
    left: 0px;
    margin-top: 2px;
    width: 656px;
    opacity: 0.69;
}

.mobile-auth__telephone-input {
    position: relative;
    height: 44px;
    margin-bottom: 7px;
    width: 100%;

    form {
        height: 100%;
    }
}

.mobile-auth__button {
    width: 100%;
    height: 43px;
    padding: 0;
    border-radius: 3px;
    background: $kournikova;
    border: solid 1px $mustard;
    outline: none;

    transition: all 300ms ease;

    font-family: $gilroy-bold;
    font-size: 14px;
    letter-spacing: -0.31px;
    color:  $verdun-green;

    &:disabled {
        box-shadow: none;
        background: $wild-sand;
        border: solid 1px $wild-sand;

        color: $alto;
    }
}

.mobile-auth__terms-conditions-text {
    cursor: default;
    text-align: center;
    max-width: 424px;
    margin: 0 auto;
    padding-top: 8px;

    font-family: $gilroy-light;
    font-size: 8px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: $silver;
}

.mobile-auth__text-link {
    color: $silver;
    text-decoration: underline;
}


@media (min-width: 360px) {
    .mobile-auth__title-wrap {
        margin-bottom: 11px;
    }

    .mobile-auth__title {
        font-size: 20px;
        letter-spacing: -0.81px;
    }

    .mobile-auth__pincode-input {
        margin-bottom: 68px;
        width: 216px;
        height: 50px;
    }

    .mobile-auth__button--container {
        margin-bottom: 2px;
    }

    .mobile-auth__link-button {
        font-size: 14px;
        letter-spacing: -0.14px;
    }

    .mobile-auth__phone-number {
        font-size: 14px;
    }

    .mobile-auth__subtitle {
        font-size: 14px;
        line-height: 21px;
    }

    .mobile-auth__telephone-input {
        height: 50px;
        margin-bottom: 8px;
    }

    .mobile-auth__button {
        height: 48px;
        border-radius: 5px;

        font-size: 17px;
        letter-spacing: -0.38px;
    }

    .mobile-auth__terms-conditions-text {
        font-size: 10px;
    }
}

@media (min-width: 375px) {
    .mobile-auth__title-wrap {
        margin-bottom: 10px;
    }

    .mobile-auth__pincode-input {
        margin-bottom: 60px;
        width: 216px;
        height: 52px;
    }

    .mobile-auth__button--container {
        margin-bottom: 3px;
    }

    .mobile-auth__link-button {
        font-size: 15px;
        letter-spacing: -0.15px;
    }

    .mobile-auth__telephone-input {
        height: 52px;
    }

    .mobile-auth__button {
        height: 50px;
        letter-spacing: -0.47px;
    }
}

@media (min-width: 414px) {
    .mobile-auth__title-wrap {
        margin-bottom: 11px;
    }
    .mobile-auth__title {
        font-size: 21px;
        letter-spacing: -0.91px;
    }

    .mobile-auth__pincode-input {
        margin-bottom: 69px;
        width: 239px;
        height: 55px;
    }

    .mobile-auth__button--container {
        margin-bottom: 4px;
    }

    .mobile-auth__link-button {
        font-size: 16px;
        letter-spacing: -0.16px;
    }

    .mobile-auth__phone-number {
        font-size: 15px;
    }

    .mobile-auth__telephone-input {
        height: 55px;
        margin-bottom: 11px;
    }

    .mobile-auth__button {
        height: 52px;
        border-radius: 5px;

        font-size: 18px;
        letter-spacing: -0.4px;
    }
}

@media (min-width: 1024px) {
    .mobile-auth__root-container {
        margin: auto;
        justify-content: center;
        height: initial;
    }

    .mobile-auth__content-container {
        height: 330px;
        border-radius: 8px;
        box-shadow: 1px 5px 17px 0 rgba(0, 0, 0, 0.03);
        border: solid 1px var(--color__salt);
        background: var(--color__coconut);
        padding: 0;
        overflow: hidden;

        &.need-display-plate {
            .mobile-auth__plate-container {
                width: 290px;
            }
        }
    }

    .mobile-auth__form-container {
        width: 412px;
        padding: 92px 55px 0;
    }

    .mobile-auth__title-wrap {
        max-width: 100%;
        margin-bottom: 8px;
        text-align: center;
    }

    .mobile-auth__title {
        max-width: 100%;

        font-family: $gilroy-bold;
        font-size: 17px;
        line-height: 1.29;
        letter-spacing: -0.32px;
        color: var(--color__pepper);
    }

    .mobile-auth__telephone-input {
        height: 52px;
        margin-bottom: 12px;
    }

    .mobile-auth__button--container {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12px;

        &.phone-input-screen {
            margin-left: 0 0 12px;
            box-sizing: border-box;
            height: 50px;
            padding-bottom: 0px;
        }
    }

    .mobile-auth__button {
        box-sizing: border-box;
        height: 100%;

        font-family: $geometria-extrabold;
        font-size: 13px;
        letter-spacing: -0.45px;
        text-transform: uppercase;
    }

    .mobile-auth__pincode-input {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 47px;
        width: 208px;
        height: 56px;
    }

    .mobile-auth__subtitle {
        margin-top: 4px;
        font-family: $gilroy-light;
    }

    .mobile-auth__link-button {
        font-family: $gilroy;
        font-size: 13px;
        letter-spacing: -0.13px;
    }

    .mobile-auth__terms-conditions-text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -35px;
        padding-top: 0;

        font-family: $gilroy-ultralight;
        font-size: 9px;
    }

    .mobile-auth__text-link {
        font-family: $gilroy-ultralight;
    }

}

@media (min-width: 1024px) {
    .mobile-auth__content-container {
        height: 344px;

        &.need-display-plate {
            .mobile-auth__plate-container {
                width: 304px;
            }
        }
    }

    .mobile-auth__form-container {
        padding: 99px 59px 0;
        width: 430px;
    }

    .mobile-auth__title-wrap {
        margin-bottom: 9px;
    }

    .mobile-auth__title {
        font-size: 19px;
        line-height: 1.16;
        letter-spacing: -0.36px;
    }

    .mobile-auth__button--container {
        margin-bottom: 12px;

        &.phone-input-screen {
            height: 50px;
        }
    }

    .mobile-auth__button--container {
        margin-bottom: 13px;
    }

    .mobile-auth__link-button {
        font-size: 14px;
        letter-spacing: -0.14px;
    }

    .mobile-auth__phone-number {
        font-size: 14px;
    }

    .mobile-auth__terms-conditions-text {
        bottom: -43px;

        font-size: 10px;
    }
}

@media (min-width: 1280px) {
    .mobile-auth__content-container {
        height: 356px;

        &.need-display-plate {
            .mobile-auth__plate-container {
                width: 309px;
            }
        }
    }

    .mobile-auth__button--container {
        margin-bottom: 11px;
    }

    .mobile-auth__link-button {
        font-size: 16px;
        letter-spacing: -0.16px;
    }

    .mobile-auth__phone-number {
        font-size: 15px;
    }

    .mobile-auth__form-container {
        width: 441px;
        padding: 100px 61px 0;
    }

    .mobile-auth__title-wrap {
        margin-bottom: 8px;
    }

    .mobile-auth__title {
        font-size: 22px;
        line-height: 1;
        letter-spacing: -0.41px;
    }

    .mobile-auth__telephone-input {
        height: 54px;
        margin-bottom: 12px;
    }

    .mobile-auth__button--container {
        margin-bottom: 12px;

        &.phone-input-screen {
            height: 54px;

            font-size: 14px;
            font-weight: 800;
            letter-spacing: -0.48px;
        }
    }

    .mobile-auth__terms-conditions-text {
        bottom: -53px;

        font-size: 11px;
    }
}

@media (min-width: 1366px) {
    .mobile-auth__content-container {
        height: 360px;

        &.need-display-plate {
            .mobile-auth__plate-container {
                width: 312px;
            }
        }
    }

    .mobile-auth__subtitle {
        font-size: 17px;
    }

    .mobile-auth__form-container {
        width: 446px;
        padding: 112px 58px 0;
    }

    .mobile-auth__title-wrap {
        margin-bottom: 13px;

        &.with-subtitle {
            margin-top: -20px;
        }
    }

    .mobile-auth__title {
        min-width: 120%;
        margin-left: -10%;

        font-size: 23px;
        line-height: 0.96;
        letter-spacing: -0.43px;
    }

    .mobile-auth__telephone-input {
        height: 56px;
        margin-bottom: 16px;
    }

    .mobile-auth__button--container {
        margin-bottom: 12px;

        &.phone-input-screen {
            height: 56px;

            font-size: 15px;
            font-weight: 800;
            letter-spacing: -0.48px;
        }
    }

    .mobile-auth__pincode-input {
        margin-bottom: 66px;
    }

    .mobile-auth__terms-conditions-text {
        bottom: -58px;
    }
}

@media (min-width: 1440px) {
    .mobile-auth__content-container {
        height: 379px;

        &.need-display-plate {
            .mobile-auth__plate-container {
                width: 328px;
            }
        }
    }

    .mobile-auth__button--container {
        margin-bottom: 10px;
    }

    .mobile-auth__link-button {
        font-size: 17px;
        letter-spacing: -0.17px;
    }

    .mobile-auth__phone-number {
        font-size: 16px;
    }

    .mobile-auth__form-container {
        width: 470px;
        padding: 105px 62px 0;
    }

    .mobile-auth__title-wrap {
        margin-bottom: 11px;
    }

    .mobile-auth__title {
        font-size: 24px;
        line-height: 0.92;
        letter-spacing: -0.45px;
    }

    .mobile-auth__telephone-input {
        height: 58px;
        margin-bottom: 14px;
    }

    .mobile-auth__button--container {
        margin-bottom: 12px;

        &.phone-input-screen {
            height: 60px;

            font-size: 15px;
            font-weight: 800;
            letter-spacing: -0.48px;
        }
    }

    .mobile-auth__terms-conditions-text {
        bottom: -55px;
    }
}

@media (min-width: 1600px) {
    .mobile-auth__content-container {
        height: 404px;

        &.need-display-plate {
            .mobile-auth__plate-container {
                width: 350px;
            }
        }
    }

    .mobile-auth__form-container {
        width: 500px;
        padding: 118px 74px 0;
    }

    .mobile-auth__terms-conditions-text {
        bottom: -58px;
    }
}

@media (min-width: 1680px) {
    .mobile-auth__content-container {
        height: 427px;

        &.need-display-plate {
            .mobile-auth__plate-container {
                width: 370px;
            }
        }
    }

    .mobile-auth__form-container {
        width: 528px;
        padding: 130px 80px 0;
    }

    .mobile-auth__title {
        font-size: 25px;
        line-height: 0.88;
        letter-spacing: -0.47px;
    }

    .mobile-auth__terms-conditions-text {
        bottom: -55px;

        font-size: 12px;
    }
}

@media (min-width: 1920px) {
    .mobile-auth__button--container {
        margin-bottom: 9px;
    }

    .mobile-auth__link-button {
        font-size: 18px;
        letter-spacing: -0.18px;
    }

    .mobile-auth__phone-number {
        font-size: 17px;
    }

    .mobile-auth__content-container {
        height: 438px;

        &.need-display-plate {
            .mobile-auth__plate-container {
                width: 377px;
            }
        }
    }

    .mobile-auth__form-container {
        width: 545px;
        padding: 133px 88px 0;
    }

    .mobile-auth__title-wrap {
        margin-bottom: 12px;

        &.with-subtitle {
            margin-top: -24px;
        }
    }

    .mobile-auth__title {
        font-size: 26px;
        line-height: 0.85;
        letter-spacing: -0.49px;
    }

    .mobile-auth__telephone-input {
        height: 60px;
        margin-bottom: 12px;
    }

    .mobile-auth__button--container {
        &.phone-input-screen {
            height: 62px;
            margin-bottom: 133px;

            font-size: 15px;
            font-weight: 800;
            letter-spacing: -0.48px;
        }
    }

    .mobile-auth__terms-conditions-text {
        bottom: -60px;

        font-size: 13px;
        line-height: 1.23;
    }
}
