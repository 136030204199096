@import '~styles/variables/index';

.dialog-animated__bg {
    @include all-props((
        position: fixed,
        z-index: 10000,
        right: 0,
        top: 0,
        width: 100%,
        height: 100%,
    ));

    &.exited {
        visibility: hidden;
    }
    &.entering {
        transition: all 400ms;

        @include mobile-props((
            backdrop-filter: blur(4px),
            background-color: rgba(255, 255, 255, 0.6),
        ));
        @include desktop-props((
            background-color: rgba(66, 66, 66, 0.60),
        ));
    }
    &.entered {
        @include mobile-props((
            backdrop-filter: blur(4px),
            background-color: rgba(255, 255, 255, 0.6),
        ));
        @include desktop-props((
            background-color: rgba(66, 66, 66, 0.60),
        ));
    }
    &.exiting {
        transition: all 400ms;
        backdrop-filter: none;
        background-color: transparent;
    }
}

.dialog-animated__close {
    @include all-props((
        opacity: 0,
        position: absolute,
        left: 0,
        top: 0,
        bottom: 0,
        right:0 ,
    ));
}

.dialog-animated__content {
    @include all-props((
        position: fixed,
        z-index: 1000,
        right: 0,
        top: 0,
        width: 100%,
        height: 100%,
    ));

    &.exited {
        transform: translateY(100%);
    }
    &.entering {
        transition: transform 400ms;
        transform: translateY(0);
    }
    &.entered {
        transform: translateY(0);
    }
    &.exiting {
        transition: transform 400ms;
        transform: translateY(100%);
    }
}
