@import '~styles/variables/index';


.empty-comment-button {
    box-sizing: border-box;
    width: 100%;
    height: 47px;
    background: $sunglow;
    border: 1px solid $candlelight;
    border-radius: 4px;
    box-shadow: 0 5px 12px 0 rgba(226, 226, 226, 0.78);

    font-family: $gilroy-semibold;
    font-size: 15px;
    color: $olive;
    letter-spacing: -0.23px;
}

.empty-comment-description {
    margin-top: 14px;
    font-family: $gilroy;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.11px;
    color: var(--color__cuttlefish);

    @media (min-width: 1366px) {
        margin-top: 20px;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.12px;
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.button-text {
    margin-bottom: 6px;
    font-family: $gilroy;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.39px;
    text-align: left;

    opacity: 0;
    animation: appear 200ms forwards;

    @media (min-width: 1280px) {
        font-size: 15px;
        letter-spacing: -0.41px;
    }

    @media (min-width: 1366px) {
        font-size: 16px;
        letter-spacing: -0.44px;
    }

    @media (min-width: 1600px) {
        font-size: 17px;
        line-height: 21px;
        letter-spacing: -0.47px;
    }

    @media (min-width: 1920px) {
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.5px;
    }
}

.decorations {
    font: inherit;
    padding-bottom: 2px;
    background: url('app/components/CustomizationComment/CustomizationCommentRoleButton/dots.img.svg') left bottom repeat-x;

    &::before {
        content: "«",
    }
    &::after {
        content: "»",
    }
}
