@import "~global/styles/cool-media-queries/index.global.scss";
@import '~styles/variables/colors.scss';
@import '~styles/variables/ui-colors.scss';

:global {
// Modifiers
.notfound--mobile {
    @include desktop-props((
        display: none,
    ));
    @include mobile-props((
        display: block,
    ));
}
.notfound--desktop {
    @include desktop-props((
        display: block,
    ));
    @include mobile-props((
        display: none,
    ));
}
// Common
.notfound-block {
    @include all-props((
        margin: 0 auto,
        color: #fff,
        display: flex,
        flex-direction: column,
        font-family: $gilroy,
        background-repeat: no-repeat,
        background-size: cover,
     ));
    @include desktop-props((
        background-image: url('~global/layouts/404/images/404bg-desktop.jpg'),
        border-radius: (
            1024: 8px,
            1366: 12px,
            1680: 14px,
        ),
        height:(
            1024: 206px,
            1366: 276px,
            1680: 340px,
            1920: 388px,
        ),
        width: (
            1024: 682px,
            1366: 910px,
            1680: 1120px,
            1920: 1280px,
        ),
        margin-top: (
            1024: 38px,
            1680: 50px,
            1920: 54px,
        ),
        margin-bottom: (
            1024: 20px,
        ),
        text-align: left,
        justify-content: center,
    ));

    @include mobile-props((
        background-image: url('~global/layouts/404/images/404bg-mobile.jpg'),
        border-radius: (
            0: var(--sizeBorderRadiusXL),
            375: var(--sizeBorderRadiusXXL),
        ),
        height:(
            0: 374px,
            360: 421px,
            375: 438px,
            414: 483px,
        ),
        width: (
            0: 292px,
            360: 329px,
            375: 338px,
            414: 374px,
        ),
        margin-top: (
            414: 24px,
        ),
        text-align: center,
        justify-content: flex-end,
     ));

    .notfound-block-message {
        @include desktop-props((
            padding-left: (
                1024: 305px,
                1366: 409px,
                1680: 512px,
                1920: 584px,
            )
        ));
        @include mobile-props((
            padding-bottom: (
                0: 26px,
                360: 30px,
                414: 34px,
            ),
        ));
        .notfound-block-message__title {
            @include desktop-props((
                line-height: 108%,
                letter-spacing: (
                    1024: -0.6px,
                    1366: -0.9px,
                    1680: -1px,
                ),
                font-weight: 700,
                font-size: (
                    1024: 28px,
                    1366: 36px,
                    1680: 42px,
                    1920: 46px,
                ),
            ));
            @include mobile-props((
                font-weight: 800,
                padding-bottom: 2px,
                letter-spacing: (
                    0: -0.93px,
                ),
                font-size: (
                    0: 27px,
                    360: 30px,
                    375: 33px,
                    414: 36px,
                ),
            ));
        }
        .notfound-block-message__description {
            @include mobile-props((
                font-weight: 500,
                padding-bottom: 2px,
                letter-spacing: (
                    0: -0.12px,
                ),
                font-size: (
                    0: 16px,
                    360: 18px,
                    375: 19px,
                    414: 21px,
                ),
                line-height: (
                    360: 1.2,
                )
            ));

        }
        .notfound-block-message-links {
            .notfound-block-message-links__text {
                @include desktop-props((
                    line-height: (
                        1366: 1.3,
                        1680: 1.4,
                        1920: 1.5,
                    ),
                    letter-spacing: (
                        1024: -0.6px,
                        1920: -0.3px,
                    ),
                    font-weight: 400,
                    font-size: (
                        1024: 18px,
                        1366: 20px,
                        1680: 24px,
                        1920: 26px,
                    ),
                ));
            }
            .notfound-block-message-links__item:hover {
                opacity: 0.9;
            }
            .notfound-block-message-links__item {
                @include all-props((
                    color: #fff,
                    text-decoration: underline,
                ));
                @include desktop-props((
                    line-height: 108%,
                    letter-spacing: (
                        1024: -0.6px,
                        1920: -0.3px,
                    ),
                    font-weight: 600,
                    font-size: (
                        1024: 18px,
                        1366: 20px,
                        1680: 24px,
                        1920: 26px,
                    ),
                ));
                @include mobile-props((
                    font-weight: 800,
                    letter-spacing: (
                        0: -0.12px,
                    ),
                    font-size: (
                        0: 17px,
                        360: 19px,
                        375: 20px,
                        414: 22px,
                    ),
                ));
            }

            .notfound-block-message-links__devider {
                @include mobile-props((
                    font-size: (
                        0: 16px,
                        360: 18px,
                        375: 19px,
                        414: 21px,
                    ),
                    font-weight: 500,
                    letter-spacing: (
                        0: -0.12px,
                    ),
                ));
            }
        }
    }
}
}
