@import "~global/styles/cool-media-queries/index.global.scss";

.dish-image-shield__root {
}

.dish-image-shield__img {
    @include mobile-props((
        width: 100%,
    ));
    @include desktop-props((
        width: 100%,
    ));
}
