@import "~global/styles/cool-media-queries/index.global.scss";

.deliveryZoneCardRoot {

    background-color: #fff;
    box-sizing: border-box;
    @include all-props((
        margin-left: (
            0: 16px,
            375: 20px,
            1024: 20px,
        ),
        margin-right: (
            0: 16px,
            375: 20px,
            1024: 20px,
        ),
        margin-bottom: (
            0: 20px,
            375: 24px,
            1024: 24px,
        ),
        border-radius: (
            0: 12px,
            375: 16px,
            1024: 16px,
        ),
        padding-top: (
            0: 16px,
            375: 20px,
            1024: 20px,
        ),
        overflow: hidden,
    ));
}

.deliveryZoneCardTitle {
    & p {
       color: var(--color__pepper);
    }
    @include all-props((
        padding: (
            0: 0 20px 8px 20px,
            375: 0 20px 10px 20px,
            1024: 0 20px 10px 20px,
        )
    ));
}

.deliveryZoneCardRows {
    @include all-props(());
}
