@import '~styles/variables/index';

$loader-appearing-timout: 200ms;
$loader-vanishing-timout: 500ms;

.loader__root {
    @include all-props((
        position: fixed,
        z-index: 5000,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,

        display: flex,
        flex-direction: column,
        justify-content: center,
        align-items: center,

        background-color: var(--color__coconut),

        // disappearing
        opacity: 0,
        visibility: hidden,
        transition: (
            visibility 0s $loader-vanishing-timout,
            opacity $loader-vanishing-timout,
        ),
    ));

    &.loading {
        @include all-props((
            // appearing
            opacity: 1,
            visibility: visible,
            transition: (
                visibility 0s,
                opacity $loader-appearing-timout,
            ),
        ));
    }
}

.loader__content-container {
    @include all-props((
        position: relative,
    ));
}

.loader__text {
    @include all-props((
        color: var(--color__cuttlefish),
        font-family: $gilroy,
    ));
    @include mobile-props((
        letter-spacing: -0.5px,
        font-weight: 700,
        padding-bottom: (
            0: 5px,
            360: 6px,
            375: 7px,
            414: 8px,
        ),
        font-size: (
            0: 17px,
            375: 20px,
            414: 21px,
        ),
    ));
    @include desktop-props((
        font-weight: 600,
        padding-bottom: (
            1024: 6px,
            1440: 7px,
            1680: 9px,
            1920: 12px,
        ),
        font-size: (
            1024: 17px,
            1366: 19px,
            1440: 20px,
            1680: 21px,
            1920: 24px,
        ),
    ));
}

@keyframes FillProgresBar {
    0%   { width: 0%;   left: 0; }
    50%  { width: 100%; left: 0; }
    51%  { width: 100%; right: 0; left: auto; }
    100% { width: 0%;   right: 0; }
}

.loader__progress-bar {
    @include all-props((
        position: absolute,
        background-color: #ffdb19,
        width: 100%,
    ));
    @include mobile-props((
        height: 3px,
    ));
    @include desktop-props((
        height: (
            1024: 4px,
            1440: 5px,
            1920: 6px,
        ),
    ));

    &.with-animation {
        @include all-props((
            animation: FillProgresBar 700ms ease-in-out infinite,
            animation-direction: alternate,
        ));
    }
}
