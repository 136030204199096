@import '~styles/variables/index';

$left-offset: (
    0:   96px,
    375: 108px,
    1024: 108px,
);
$center-left-offset: (
    0:   108px,
    375: 128px,
    1024: 128px,
);

.mobile-select {
    @include all-props((
        display: flex,
        flex-direction: column,
        background: var(--color__coconut),
        max-height: 70vh,
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.05),

        border-top-left-radius: (
            0: var(--sizeBorderRadiusXL),
            375: var(--sizeBorderRadiusxXX),
            1024: var(--sizeBorderRadiusXXL),
        ),
        border-top-right-radius: (
            0: var(--sizeBorderRadiusXL),
            375: var(--sizeBorderRadiusXXL),
            1024: var(--sizeBorderRadiusXXL),
        ),
    ));
    @include mobile-props((
        position: fixed,
        z-index: 5000,
        right: 0,
        left: 0,
        bottom: 0,
    ));
    @include desktop-props((
        position: fixed,
        z-index: 5000,
        right: 0,
        bottom: 0,
        width: 470px,
        background: var(--color__coconut),
    ));

    &.exited {
        transform: translateY(100%);
    }
    &.entering {
        transition: transform 500ms;
        transform: translateY(0%);
    }
    &.entered {
        transform: translateY(0%);
    }
    &.exiting {
        transition: transform 500ms;
        transform: translateY(100%);
    }
}

.mobile-select__close-row {
    @include all-props((
        position: relative,
        display: flex,
        justify-content: flex-end,
        align-items: center,

        min-height: (
            0:   48px,
            375: 56px,
            1024: 56px,
        ),
        border-bottom: solid 1px var(--color__salt),
    ));
}

.mobile-select__title-container {
    @include all-props((
        display: flex,
        align-items: center,
        justify-content: center,

        height: 100%,
        width: 100%,
    ));
}

.mobile-select__close-container {
    $sizes: (
        0:   26px,
        375: 32px,
        1024: 32px,
    );

    @include all-props((
        box-sizing: content-box !important,

        position: absolute,
        z-index: 1,
        top: 0,
        bottom: 0,
        margin: auto,

        display: flex,
        justify-content: flex-end,
        align-items: center,

        padding: (
            0:   11px 16px,
            375: 14px 18px,
            1024: 14px 18px,
        ),
        height: $sizes,
        width: $sizes,
    ));
}

.mobile-select__list--wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    @include all-props((
        overflow-y: scroll,
        padding-bottom: 44px,
    ));

    &::after {
        content: '';

        @include all-props((
            pointer-events: none,
            position: fixed,
            bottom: 0,
            height: (
                0:   50px,
                375: 70px,
                1024: 70px,
            ),
            width: 100%,
            background: linear-gradient(0deg, var(--color__coconut) 0%, rgba(255, 255, 255, 0) 100%),
        ));
    }
}

.mobile-select__list {
    @include all-props((
        display: flex,
        flex-direction: column,
        justify-content: center,
        list-style: none,
        margin: 0,
        padding-left: 0,
    ));
}

.mobile-select__list__header-container {
    @include all-props((
        margin-top: 12px,
        padding: (
            0:   16px 0 8px,
            375: 16px 0 4px,
            1024: 16px 0 4px,
        ),
        border-top: 1px solid var(--color__salt),
    ));
}

.mobile-select__list__header-text {
    @include all-props((
        color: var(--color__cuttlefish),
        font-family: $gilroy,
        font-weight: (
            0: 700,
            1024: 600,
        ),
        font-size: (
            0:   17px,
            375: 19px,
            1024: 19px,
        ),
        line-height: (
            0:   21px,
            375: 22px,
            1024: 22px,
        ),
        letter-spacing: -0.2px,
    ));
}

.mobile-select__list-item {
    text-align: center;
    & + & {
        @include all-props((
            margin-top: 0,
        ));
    }
}
.mobile-select__list-item--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-select__list-item__title-container {
    @include all-props((
        display: flex,
        align-items: center,
        color: var(--color__cuttlefish),
    ));

    & span {
        font-weight: var(--font-weight__regular);
        width: auto;
        margin: 0 4px;
    }

    & strong {
        @include all-props((
            margin-right: 8px,
            text-transform: capitalize,
            font-family: $gilroy,
            font-weight: var(--font-weight__bold),
            font-size: (
                0:   18px,
                375: 20px,
                1024: 20px,
            ),
            line-height: (
                0:   34px,
                375: 36px,
                1024: 36px,
            ),
            letter-spacing: -0.3px,
        ));
    }

    &:active {
        @include all-props((
            color: var(--color__oyster),
        ));
    }

    &.left-align {
        @include all-props((
            padding-left: $left-offset,
        ));
    }
    &.center {
        @include all-props((
            justify-content: center,
        ));
    }
    &.center-left-align {
        @include all-props((
            padding-left: $center-left-offset,
        ));
    }
    &.selected * {
        @include all-props((
            font-weight: var(--font-weight__extrabold),
        ));
    }
}

.mobile-select__list-item__button {
    // reset default button style START
    @include all-props((
        border: none,
        outline: none,
        margin: 0,
        padding: 0,
        width: auto,
        overflow: visible,
        background: transparent,
        text-align: unset,
    ));
    // END

    @include mobile-props((
        width: 100%,
        color: var(--color__cuttlefish),
        text-align: left,

        transition: color 150ms,
    ));

    &:active {
        @include all-props((
            color: var(--color__oyster),
        ));
    }
    &:disabled .mobile-select__list-item__title-container {
        @include all-props((
            color: var(--color__oyster),
        ));
    }
}
