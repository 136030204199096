@import '~styles/variables/index';
@import '~global/uikit/index.scss';

.marchDetailsRoot {
    @include mobile-props((
        font-family: $gilroy,
        padding: (
            0: 0 16px,
            375: 0 20px,
        ),
        color: #f88e93,
    ));
}

.marchDishTitle {
    @include mobile-props((
        font-size: (
            0: 17px,
            375: 18px,
        ),
        font-weight: 500px,
        font-family: $gilroy,
        color: var(--color__cuttlefish),
        margin-bottom: (
            0: 14px,
            375: 18px,
        )
    ))
}

.marchGiftsSetList {
    font-style: normal;
    font-weight: 400;
    color: #F88E93;

    @include all-props((
        font-family: $gilroy,
        height: auto,
    ));
    @include mobile-props((
        margin-bottom: (
            0: 50px,
            375: 60px,
        ),
    ));
}

.mainCard {
    @include mobile-props((
        margin-bottom: (
            0: 31px,
            375: 40px,
        )
    ));
    @include desktop-props((
        margin-bottom: (
            1024: 32px,
            1366: 42px,
            1600: 49px,
        )
    ));
}

.mainCardTitle {
    @include mobile-props((
        font-size: (
            0: 30px,
            375: 36px,
        ),
        line-height: (
            0: 26.55px,
            375: 31.86px,
        ),
        font-weight: 600,
        margin-bottom: (
            0: 8px,
            375: 10px,
        ),
        letter-spacing: -1px,
    ));
}

.descriptionCardImageMobile {
    @include mobile-props((
        width: 100%,
        border-radius: 30px,
        margin-bottom: (
            0: 8px,
            375: 10px,
        ),
    ));
}

.descriptionCard {
    overflow: hidden;
    @include mobile-props((
        display: (
            0: none,
        ),
    ));
}

.cardContent {
    list-style-type: disc !important;
    width: 100%;

    @include mobile-props((
        margin-bottom: (
            0: 40px,
            375: 50px,
        ),
        font-size: (
            0: 16px,
            375: 19px,
        ),
        line-height: (
            0: 16.64px,
            375: 19.76px,
        ),
        letter-spacing: -0.5px
    ));
}

.cardContent li:before {
    background-color: #f88e93;
}

.cardsList {
    @include all-props((
        display: flex,
        flex-direction: (
            0: column,
            1024: row,
        ),
        gap: (
            0: 0px,
            1024: 32px,
            1366: 41px,
            1600: 49px,
        )
    ));
    @include mobile-props((
        gap: (
            0: 30px,
            375: 40px,
        )
    ));
}

.secondaryCard {
    @include all-props((
        width: 100%,
    ));
}

.cardTitle {
    @include mobile-props((
        font-size: (
            0: 19px,
            375: 22px,
        ),
        line-height: (
            0: 19.76px,
            375: 22.88px,
        ),
        margin-bottom: (
            0: 8px,
            375: 10px,
        ),
        letter-spacing: (
            0: -0.5px,
            375: -0.5px,
        ),
        font-style: normal,
        font-weight: 700,
    ));
}
.secondaryCardImage {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
}

.cardTitle {
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.5px;
}

.descriptionText {
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.5px;
}

.menuWrapper {
    @include mobile-props((
        max-width: 378px,
        margin: 0 auto,
        margin-bottom: (
            0: 50px,
            375: 80px,
        )
    ));
}

.menuTitle {
    @include all-props((
        font-family: $gilroy,
        color: #F88E93,
    ));
    @include mobile-props((
        font-size: (
            0: 30px,
            375: 36px,
        ),
        line-height: (
            0: 28.5px,
            375: 31.86px,
        ),
        letter-spacing: -1px,
        font-weight: 600,
        margin-bottom: 10px,
        padding-left: (
            0: 22px,
            375: 22px,
        )
    ));
}

.descriptionCardWrapper {
    @include all-props((
        position: relative,
    ));
}

.videoSliderCardButton {
    @include all-props((
        position: absolute,
        top: 50%,
        left: 50%,
        transform: translate(-50%, -50%)
    ));
}

