@import '~styles/variables/index';

.overlay-tooltip__close-wrap {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px;
}
