$font-path: '../../assets/fonts';
$font-name: 'Neue-Haas-Grotesk-Display-Pro-65-Medium';

$src:
  url("#{$font-path}/#{$font-name}.woff") format('woff'),
;

@font-face {
  font-family: $font-name;
  src:  $src;
  font-display: swap;
}
