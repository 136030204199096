@import "~global/uikit/index.scss";

@import "~global/layouts/404/styles/404.global.scss";
@import "~global/layouts/footer/styles/footer.global.scss";

body {
    &::-webkit-scrollbar {
        display: none;
    }
}
