@import '~styles/variables/index';

.deliveryPrice {
    @include all-props((
        display: flex,
        align-items: center,
        border-bottom: 1px solid var(--color__salt),
        padding: 16px 20px,
    ));

    &.noBorder {
        @include all-props((
            border-bottom: none,
        ));
    }
}

.deliveryPriceImg {
    @include all-props((
        width: (
            0: 16px,
            375: 20px,
            1024: 20px,
        ),
        height: (
            0: 16px,
            375: 20px,
            1024: 20px,
        ),
        margin-right: 8px,
    ));
}

.deliveryPriceText {
    @include all-props((
        font-family: $gilroy,
        font-weight: var(--font-size__medium),
        letter-spacing: -0.2px,
        font-size: (
            0: 15px,
            375: 17px,
            1024: 17px,
        ),
        line-height: (
            0: 17px,
            375: 18px,
            1024: 18px,
        ),
        color: var(--color__pepper),
    ));
    @include mobile-props((
        font-weight: var(--font-size__medium),
    ));
    @include desktop-props((
        font-weight: var(--font-size__semibold),
    ));
}

.responsiveContainer {
    &.deliveryPrice {
        @include desktop-props((
            padding: 0,
        ));

        @include responsive-desktop-props(1536px, (
            padding-bottom: 12px,
        ));
    }

    .deliveryPriceImg {
        @include responsive-desktop-props(1536px, (
            width: 20px,
            height: 20px,
            margin-right: 8px,
        ));
    }

    .deliveryPriceText {
        @include responsive-desktop-props(1536px, (
            font-size: 17px,
            line-height: 18px,
        ));
    }
}
