@import '~styles/variables/index';

.menu-filter-section {
    transition: margin 400ms ease-in-out;
    margin-bottom: 14px;

    &.is-expanded {
        margin-bottom: 34px;
    }

    @media (min-width: 1024px) {
        margin-bottom: 17px;
    }
    @media (min-width: 1280px) {
        margin-bottom: 20px;
        &.is-expanded {
            margin-bottom: 40px;
        }
    }
    @media (min-width: 1366px) {
        margin-bottom: 25px;
        &.is-expanded {
            margin-bottom: 60px;
        }
    }
    @media (min-width: 1600px) {
        margin-bottom: 25px;
        &.is-expanded {
            margin-bottom: 55px;
        }
    }
    @media (min-width: 1920px) {
        margin-bottom: 38px;
    }
}

.menu-filter-option__expand {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    width: 100%;
    border: none;
    background: transparent;
    text-align: left;
    padding: 0;
    margin-bottom: 12px;

    font-family: $gilroy-bold;
    font-size: 16px;
    color: var(--color__cuttlefish);
    letter-spacing: -0.12px;

    span {
        font: inherit;
    }

    @media (min-width: 1280px) {
        margin-bottom: 12px;
        font-size: 17px;
        letter-spacing: -0.13px;
    }

    @media (min-width: 1600px) {
        font-size: 18px;
        letter-spacing: -0.1px;
    }

    @media (min-width: 1920px) {
        font-size: 20px;
        letter-spacing: -0.15px;
    }
}

.menu-filter-option__arrow {
    transform: rotate(-90deg);
    transition: transform 250ms;

    &.is-expanded {
        transform: rotate(0deg);
    }

    @media (min-width: 1920px) {
        transform: rotate(-90deg) scale(1.5);

        &.is-expanded {
            transform: rotate(0deg) scale(1.5);
        }
    }
}
