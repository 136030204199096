@import '~styles/variables/index';

$normal-size-height: 43px;
$normal-size-width: 130px;

$normal-size-height-1280: 46px;
$normal-size-width-1280: 138px;

$normal-size-height-1366: 47px;
$normal-size-width-1366: 146px;

$normal-size-height-1440: 50px;
$normal-size-width-1440: 155px;

$normal-size-height-1600: 56px;
$normal-size-width-1600: 173px;

$normal-size-height-1920: 67px;
$normal-size-width-1920: 208px;

$large-size-height: 50px;
$large-size-width: 160px;

$small-size-width-320: 107px;
$small-size-height-320: 40px;

$small-size-width-360: 120px;
$small-size-height-360: 48px;

$small-size-width-414: 128px;
$small-size-height-414: 51px;


.select {
    position: relative;
    &.small {
        height: $small-size-height-320;
        width: $small-size-width-320;
    }
}

.select-button {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    height: 38px;
    width: 124px;
    border-radius: 4px;
    box-shadow: 0 7px 11px 0 rgba(215, 215, 215, 0.31);
    background: var(--color__coconut);
    border: solid 1px $concrete;
    padding: 0;
    overflow: hidden;

    &.small {
        width: $small-size-width-320;
        height: $small-size-height-320;
        border: solid 1px var(--color__onion);
        box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.02);
        border-radius: 4px;
    }

    &:disabled {
        width: 100px;
    }
}

.select-value {
    padding: 0;
}

.select-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 35px;
    height: 36px;
    border: none;
    padding: 0;
    background: transparent;
    border-left: solid 1px $wild-sand;
    &.small {
        width: $small-size-height-320;
        height: $small-size-height-320;
        border: none;
    }
}

.select-toggle-icon {
    display: flex;
    transition: transform 150ms ease;
    transform: rotate(0);

    &.small {
        position: relative;
        top: -2px;
        display: block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid var(--color__pepper);
    }
}

.dropdown-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: default;
    opacity: 0;
    z-index: 2;
}

.select-dropdown-container {
    position: absolute;
    // TODOL У точек больше)))
    z-index: 110000;
    transition: transform 200ms, opacity 200ms;

    &.dropdownEnter {
        transform: scale(0.74);
        opacity: 0;
    }

    &.dropdownEnterActive {
        transform: scale(1);
        opacity: 1;
    }

    &.dropdownExitActive {
        transform: scale(1);
        opacity: 1;
    }

    &.dropdownExit {
        transform: scale(0.74);
        opacity: 0;
    }
}

.transparent {
    opacity: 0;
    width: 100%;
    cursor: default;
    height: 10px;
    &.transparentSmall {
        height: 75px; // height basket bottom button
    }
}

.select-dropdown {
    width: $normal-size-width;
    box-shadow: 0 7px 11px 0 rgba(220, 220, 220, 0.31);
    box-sizing: border-box;
    overflow: hidden;
}

.select-dropdown-item {
    overflow: hidden;
    background: var(--color__coconut);
    border: none;
    width: 100%;
    padding: 0;
    border: 1px solid $mercury;
    border-bottom: 1px solid transparent;

    transition: all 150ms;

    &:hover + &,
    &.active + & {
        border-top: solid 1px $kournikova;
    }

    &:hover,
    &.active {
        border: 1px solid $kournikova;
        border-bottom: 1px solid $barley-white;
    }

    &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    &:last-child {
        border-bottom: 1px solid $mercury;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        &:hover,
        &.active {
            border-bottom: solid 1px $kournikova;
        }
    }
}


/* Size overrides */

.large {
    .select,
    .select-button {
        height: $large-size-height;
        width: $large-size-width;
    }

    .select-toggle {
        width: $large-size-height;
        height: $large-size-height - 2px;
    }

    .select-dropdown {
        width: $large-size-width;
    }
}

.small {
    .select-dropdown {
        width: $small-size-width-320;
    }
}

.basket {
    .select-button {
        width: 97px;
        height: 34px;
        box-shadow: 0 3px 9px 0 rgba(221, 221, 221, 0.31);
        &:disabled {
            width: 80px;
        }
    }
    .select-toggle {
        width: 31px;
        height: 34px;
        svg {
            width: 12px;
            height: 7px;
        }
    }
}

@media (min-width: 360px) {
    .select {
        &.small {
            height: $small-size-height-360;
            width: $small-size-width-360;
        }
    }

    .select-button {
        height: 44px;
        width: 138px;
        &:disabled {
            width: 105px;
        }
        &.small {
            width: $small-size-width-360;
            height: $small-size-height-360;
            &.disabled {
                width: $small-size-width-360;
            }
        }
    }
    .select-toggle {
        width: 42px;
        height: 40px;
        &.small {
            width: $small-size-height-360;
            height: $small-size-height-360;
        }
    }
    .select-toggle-icon {
        &.small {
            top: -4px;
            left: -4px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid var(--color__pepper);
        }
    }

    .basket {
        .select-button {
            width: 117px;
            height: 40px;
            box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.03);
            &:disabled {
                width: 100px;
            }
        }
        .select-toggle {
            width: 38px;
            height: 40px;
            svg {
                width: 13px;
                height: 8px;
            }
        }
    }
}

@media (min-width: 375px) {
    .basket {
        .select-button {
            width: 123px;
            &:disabled {
                width: 110px;
            }
        }
    }
}

@media (min-width: 414px) {
    .select {
        &.small {
            height: $small-size-height-414;
            width: $small-size-width-414;
        }
    }
    .select-button {
        &.small {
            width: $small-size-width-414;
            height: $small-size-height-414;
            &.disabled {
                width: $small-size-width-414;
            }
        }
    }
    .select-toggle {
        &.small {
            width: $small-size-height-414;
            height: $small-size-height-414;
        }
    }
    .select-toggle-icon {
        &.small {
            top: -2px;
            left: -4px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid var(--color__pepper);
        }
    }
}

@media (min-width: 1024px) {
    .select-button {
        box-shadow: 0 7px 11px 0 rgba(215, 215, 215, 0.22);
        height: $normal-size-height;
        width: $normal-size-width;

        &:disabled {
            width: $normal-size-width;
        }
    }
    .select-toggle {
        width: $normal-size-height - 3px;
        height: $normal-size-height - 2px;
    }
    .basket {
        .select-button {
            height: $normal-size-height-1280;
            width: $normal-size-width-1280;
            &:disabled {
                width: 126px;
            }
        }
        .select-toggle {
            width: $normal-size-height-1280 - 3px;
            height: $normal-size-height-1280 - 2px;
        }
    }
}

@media (min-width: 1024px) {
    .select-button {
        height: $normal-size-height-1280;
        width: $normal-size-width-1280;
    }
    .select-toggle {
        width: $normal-size-height-1280 - 3px;
        height: $normal-size-height-1280 - 2px;
    }
    .select-dropdown {
        width: $normal-size-width-1280;
    }
}

@media (min-width: 1366px) {
    .select-button {
        height: $normal-size-height-1366;
        width: $normal-size-width-1366;
    }
    .select-toggle {
        width: $normal-size-height-1366 - 3px;
        height: $normal-size-height-1366 - 2px;
    }
    .select-dropdown {
        width: $normal-size-width-1366;
    }
}

@media (min-width: 1440px) {
    .select-button {
        height: $normal-size-height-1440;
        width: $normal-size-width-1440;

        &.placement-details {
            height: $normal-size-height-1366;
            width: $normal-size-width-1366;
        }
    }
    .select-toggle {
        width: $normal-size-height-1440 - 3px;
        height: $normal-size-height-1440 - 2px;

        &.placement-details {
            height: $normal-size-height-1366 - 3px;
            width: $normal-size-height-1366 - 2px;
        }
    }
    .select-dropdown {
        width: $normal-size-width-1440;
        &.placement-details {
            width: $normal-size-width-1366;
        }
    }
}

@media (min-width: 1600px) {
    .select-button {
        height: $normal-size-height-1600;
        width: $normal-size-width-1600;

        &.placement-details {
            height: $normal-size-height-1366;
            width: $normal-size-width-1366;
        }
    }

    .select-toggle {
        width: $normal-size-height-1600 - 3px;
        height: $normal-size-height-1600 - 2px;

        &.placement-details {
            height: $normal-size-height-1366 - 3px;
            width: $normal-size-height-1366 - 2px;
        }
    }

    .select-dropdown {
        width: $normal-size-width-1600;
        &.placement-details {
            width: $normal-size-width-1366;
        }
    }
}

@media (min-width: 1920px) {
    .select-button {
        height: $normal-size-height-1920;
        width: $normal-size-width-1920;

        &.placement-details {
            height: $normal-size-height-1366;
            width: $normal-size-width-1366;
        }
    }
    .select-toggle {
        width: $normal-size-height-1920 - 3px;
        height: $normal-size-height-1920 - 2px;

        &.placement-details {
            height: $normal-size-height-1366 - 3px;
            width: $normal-size-height-1366 - 2px;
        }
    }
    .select-toggle-icon {
        transform: translate(2px, 1px) scale(1.4);
    }
    .select-dropdown {
        width: $normal-size-width-1920;

        &.placement-details {
            width: $normal-size-width-1366;
        }
    }
}
