@import '~styles/variables/index';

// NOTE: Фоновая картинка предается и ставится в inline стилях в Banner.ui.js
// TODO: Нужно добавить базовый цвет фона если не передан путь backgroundImgae
.ui-banner--background-wrapper {
    @include all-props((
        width: 100%,
        height: 100%,
        border-radius: inherit,
        background-size: cover,
        background-position: center top,
        background-repeat: no-repeat,
    ));

    &.xs {
        @include all-props((
            background-position: center center,
        ));
    }
}

/* Общие стили */
.ui-banner {
    @include all-props((
        box-sizing: border-box,
        word-break: break-all,
        border-radius: (
            0: var(--sizeBorderRadiusL),
            375: var(--sizeBorderRadiusXL),
            1024: var(--sizeBorderRadiusXL),
        ),

    ));
}

/* Figma UIKit name: Banner/xs/* */
.ui-banner-small {
    background-size: cover;
    @include all-props((
        width: (
                0: 115px,
                375: 125px,
                1024: 125px,
            ),
            height: (
                0: 157px,
                375: 170px,
                1024: 170px,
            ),
            border-radius: (
                0: var(--sizeBorderRadiusXL),
                375: var(--sizeBorderRadiusXXL),
                1024: var(--sizeBorderRadiusXXL),
            ),
    ));
}

/* Figma UIKit name: Banner/m/* */
.ui-banner-medium {
    background-size: contain;
    @include all-props((
        border-radius: (
            0: var(--sizeBorderRadiusXL),
            375: var(--sizeBorderRadiusXXL),
            1024: var(--sizeBorderRadiusXXL),
        ),
        width: (
            0: 90vw,
            360: 90vw,
            415: 384px,
            1024: 410px,
        ),
    ));
}


/* Класс костыль задающий высоту баннеру c без выносных элементов (Тарлка из BR-942) */
// TODO: При первой возможности удалить этот говнокод
.m-small {
    @include all-props((
        height: (
            0: 36vw,
            415: 154px,
            1024: 164px,
        ),
    ));
}
/* Класс костыль задающий высоту баннеру c без выносных элементов (Тарлка из BR-942) */
// TODO: При первой возможности удалить этот говнокод
// .m-medium {
//     @include all-props((
//         height: (
//             0: 40vw,
//             415: 166px,
//             1024: 166px,
//         ),
//     ));
// }
// /* Класс костыль задающий высоту баннеру c выносными элементами (Тарлка из BR-942) */
// // TODO: При первой возможности удалить этот говнокод
// .m-big {
//     @include all-props((
//         height: (
//             0: 42.63vw,
//             415: 184px,
//             1024: 184px,
//         ),
//     ));
// }
