@import "~global/styles/cool-media-queries/index.global.scss";
@import "../index.scss";

.menuSkeletonPageRoot {
    @include all-props((
        min-height: 100vh,
        background-color: #fff,
        padding-top: (
            0: 68px,
            375: 76px,

        ),
    ));
}

.manuTagsSkeletonRoot {
    @include all-props((
        display: flex,
        gap: (
            0: 10px,
        ),
        margin-bottom: (
            0: 16px,
            375: 32px,
        ),
        padding-right: (
            0: 16px,
        ),
        overflow: hidden,
        margin-left: (
            0: 16px,
        ),
    ));
}

.manuTagsSkeletonItem {
    @include all-props((
        border-radius: (
            0: 6px,
            375: 7px,
        ),
        min-width: (
            0: 138px,
            375: 156px,
        ),
        height: (
            0: 32px,
            375: 38px,
        ),
        background-color: #F9F9F9,
        flex: 0,
    ));

}

.menuSetsCardSkeletonRoot {
    @include all-props((
        display: flex,
        gap: (
            0: 10px,
        ),
        margin-bottom: (
            0: 40px,
        ),
        margin-left: (
            0: 16px,
        ),
        overflow: hidden,
    ));
}

.menuSetsCardSkeletonItem {
    @include all-props((
        border-radius: (
            0: 14px,
            375: 16px,
        ),
        min-width: (
            0: 138px,
            375: 158px,
        ),
        height: (
            0: 207px,
            375: 234px,
        ),
        background-color: #F9F9F9,
        flex: 0,
    ));

}

.menuSkeletonDishListTitleSkeleton {
    @include all-props((
        width: (
            0: 167px,
        ),
        height: (
            0: 20px,
            375: 24px,
        ),
        border-radius: (
            0: 5px,
            375: 7px,
        ),
        margin: 0 auto,
        background-color: #F9F9F9,
        margin-bottom: (
            0: 8px,
            375: 10px,
        ),
    ));
}

// MenuDishCardSkeleton
.menuDishCardSkeletonRoot {
    @include all-props((
        padding: (
            0: 0 16px 0 16px,
        ),
        margin-bottom: (
            0: 50px,
            375: 60px,
        ),
    ));
}

.menuDishCardSkeletonImg {
    @include all-props((
        background-color: #F9F9F9,
        height: (
            0: 188px,
            375: 257px,
        ),
        width: 100%,
        border-radius: 12px,
        margin-bottom: (
            0: 8px,
            375: 14px,
        ),
    ));
}

.menuDishCardSkeletonContent {
    @include all-props((
        display: flex,
        justify-content: space-between,
    ));
}

.menuDishCardSkeletonLeft {}

.menuDishCardSkeletonOne {
    @include all-props((
        width: (
            0: 208px,
            375: 231px,
        ),
        height: (
            0: 14px,
            375: 16px,
        ),
        background-color: #F9F9F9,
        border-top-right-radius: (
            0: 5px,
        ),
        border-top-left-radius: (
            0: 5px,
        ),
        border-bottom-right-radius: (
            0: 5px,
        ),
    ));
}

.menuDishCardSkeletonTwo {
    @include all-props((
        width: (
            0: 144px,
        ),
        height: (
            0: 14px,
        ),
        background-color: #F9F9F9,
        margin-bottom: (
            0: 14px,
        ),
        border-bottom-right-radius: (
            0: 5px,
        ),
        border-bottom-left-radius: (
            0: 5px,
        ),
    ));
}

.menuDishCardSkeletonThree {
    @include all-props((
        width: (
            0: 174px,
        ),
        height: (
            0: 18px,
            375: 20px,
        ),
        background-color: #F9F9F9,
        border-radius: (
            0: 5px,
            375: 6px,
        ),
    ));
}

.menuDishCardSkeletonRight {
    @include all-props((
        width: (
            0: 72px,
            375: 84px,
        ),
        height: (
            0: 40px,
            375: 46px,
        ),
        background-color: #F9F9F9,
        border-radius: 8px,
    ));
}

// MenuSlectionSkeleton
.menuSlectionSkeletonRoot {
    @include all-props((
        gap: 8px,
        display: flex,
        overflow: hidden,
        padding-left: 16px,
        margin-bottom: (
            0: 50px,
            375: 60px,
        ),
    ));
}

.menuSlectionSkeletonItem {
    @include all-props((
        min-width: (
            0: 115px,
            375: 125px,
        ),
        height: (
            0: 157px,
            375: 168px,
        ),
        border-radius: (
            0: 14px,
            375: 16px,
        ),
        background-color: #F9F9F9,
    ));
}
