@import '~styles/variables/index';


$general-timeout: 700ms;
$general-delay: 700ms;
$short-delay: 300ms;


.overlay-tooltip-wrap {
    position: fixed;
    left: 0;
    top: 0px;
    bottom: 0;
    z-index: 1002;

    width: 100%;

    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(0px);

    &.entering {
        visibility: visible;
        transition: background-color $general-timeout, backdrop-filter $general-timeout;
        background-color: rgba(255, 255, 255, 0.81);
        backdrop-filter: blur(8px);
    }
    &.entered {
        visibility: visible;
        background-color: rgba(255, 255, 255, 0.81);
        backdrop-filter: blur(8px);
    }

    &.exiting {
        visibility: visible;
        transition: background-color $general-timeout ease-in-out $short-delay, backdrop-filter $general-timeout ease-in-out $short-delay;
        background-color: rgba(255, 255, 255, 0);
        backdrop-filter: blur(0px);
    }
    &.exited {
        visibility: hidden;
        background-color: rgba(255, 255, 255, 0);
        backdrop-filter: blur(0px);
    }
}

.overlay-tooltip {
    opacity: 0;
    height: 100%;

    &.entering {
        transition: opacity $general-timeout ease-in-out $short-delay;
        opacity: 1;
    }
    &.entered {
        opacity: 1;
    }

    &.exiting {
        transition: opacity $general-timeout ease-in-out;
        opacity: 0;
    }
    &.exited {
        opacity: 0;
    }
}
