@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";


.phoneInputContainer {
    box-sizing: border-box;

    @include mobile-props((
        border-radius: var(--sizeBorderRadiusXS),
        height: (
            0: 50px,
            375: 54px,
        ),
        position: relative,
        display: block,
        border: 1.5px solid var(--color__grey-salt),
        background-color: var(--color__coconut),
    ));

    @include desktop-props((
        position: relative,
        display: block,
        overflow: hidden,
        border-radius: 8px,
        height: (
            1024: 50px,
            1366: 54px,
        ),
        border: 1.5px solid var(--color__grey-salt),
        background-color: var(--color__coconut),
    ));

    &.focusWithin {
        border: 1.5px solid var(--color__egg),
    }

    &.inputError {
        border-color: #FF4C00;
    }
}

.phoneInputPlaceholder {
    @include all-props((
        position: absolute,
        cursor: pointer,
        color: var(--color__hering),
        font-family: $gilroy,
        user-select: none,
        font-weight: var(--font-weight__medium),
        z-index: 1,
    ));
    @include mobile-props((
        left: 14px,
        top: 7px,

        letter-spacing: -0.2px,
        height: 10px,
        line-height: 12px,
        font-size: 12px,
    ));
    @include desktop-props((
        left: 14px,
        top: 7px,

        font-size: 12px,
        letter-spacing: -0.2px,
        line-height: (
            1024: 9px,
            1366: 12px,
        ),
    ));
}

.phoneInput {
    @include mobile-props((
        box-sizing: border-box,
        background-color: transparent,
        width: 100%,
        font-family: $haas-disp,
        font-weight: 600,
        font-size: (
            0: 17px,
            375: 18px,
        ),
        padding: 18px 14px 18px 14px,
        height: 100%,
        // width: 100%,
        position: absolute,
        top: 0px,
        border-radius: var(--sizeBorderRadiusXS),
        border: none,
        outline: none,
        color: var(--color__hering),
    ));

    @include desktop-props((
        box-sizing: border-box,
        font-family: $haas-disp,
        font-weight: 500,
        font-size: 18px,
        padding: 0 14px,
        height: 50px,
        width: 100%,
        position: absolute,
        top: 0px,
        border-radius: 8px,
        border: none,
        outline: none,
        color: var(--color__hering),
    ));

    &.mask {
        color: var(--color__pepper),
    }

    &.withPlaceholder {
        @include all-props((
            top: 7px,
        ));
    }
}

.phoneInput:-webkit-autofill,
.phoneInput:-webkit-autofill:hover,
.phoneInput:-webkit-autofill:focus,
.phoneInput:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--color__coconut) inset !important;
}

.phoneInputIconContainer {
    @include all-props((
        position: absolute,
        right: 0,
        top: 0,
        height: 100%,
        display: flex,
        align-items: center,
        padding-right: 14px,
    ));

    // услиление селектора
    .uiKitInputButtonIcon {
        position: static;
    }
}

.phoneInputContainer.responsive {
    @include responsive-desktop-props(1536px, (
        height: 54px,
    ));

    .phoneInputPlaceholder {
        @include responsive-desktop-props(1536px, (
            left: 14px,
            top: 7px,
            font-size: 12px,
            line-height: 12px,
        ));
    }

    .phoneInput {
        @include responsive-desktop-props(1536px, (
            font-size: 18px,
            padding-left: 13px,
            padding-right: 13px,
            height: 50px,
            border-radius: 8px,
        ));

        .withPlaceholder {
            @include responsive-desktop-props(1536px, (
                top: 7px,
            ));
        }
    }

    .phoneInputIconContainer {
        @include responsive-desktop-props(1536px, (
            padding-right: 13px,
        ));
    }
}
