@import '~styles/variables/index';


.mobile-nav__social-links {
    @include mobile_320 {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 8px;
    }

    &.center {
        @include mobile_320 {
            align-items: center;
            justify-content: center;
        }
    }
}

.mobile-nav__social-item {
    @include mobile_320 {
        height: 48px;
        width: 48px;

        & + & {
            margin-left: 8px;
        }
    }
    @include mobile_375 {
        height: 52px;
        width: 52px;
    }
    @include mobile_393 {
        height: 54px;
        width: 54px;

        & + & {
            margin-left: 12px;
        }
    }
    @include mobile_424 {
        height: 56px;
        width: 56px;
    }
}

.mobile-nav__social-link {
    @include mobile_320 {
        display: block;
        height: 100%;
        width: 100%;
        -webkit-tap-highlight-color: transparent;

        & > svg {
            height: 100%;
            width: 100%;
        }

        & > img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
        }

        &.vk:active {
            svg g > use {
                fill: var(--color__vk-tap);
            }
        }
        &.messenger:active {
            svg g > path:first-child {
                fill: var(--color__messenger-tap);
            }
        }
        &.phone:active {
            svg g > path:first-child {
                fill: var(--color__salt);
            }
        }
    }
}
