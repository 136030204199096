@import '~styles/variables/index';

.step-layout__container {
    @include all-props((
        position: relative,
        display: flex,
        height: 100%,
        flex-direction: column,
        z-index: 2,
    ));
}

.step-layout__header {
    @include all-props((
        display: flex,
        min-height: (
            0:   52px,
            375: 60px,
            1024: 56px,
        ),
        box-shadow: 0px 2px 28px rgba(0, 0, 0, 0.04),

        // safari shadow fix
        position: relative,
        z-index: 1,

        background-color: var(--color__coconut),
    ));
}

.step-layout__back-button-container {
    @include all-props((
        height: (
            0: 100%,
            1024: inherit,
        ),
        width: (
            0:   82px,
            375: 110px,
            1024: 110px,
        ),
    ));
}

.step-layout__title-container {
    @include all-props((
        width: 100%,
        height: (
            0: 100%,
            1024: inherit,
        ),
        display: flex,
        flex-direction: column,
        justify-content: center,
    ));
}

.step-layout__title {
    @include all-props((
        text-align: center,
        font-family: $gilroy,
        font-weight: var(--font-weight__bold),
        font-size: (
            0:  16px,
            375: 18px,
            1024: 18px,
        ),
        letter-spacing: -0.4px,
        color: #bebebe,
        position: relative,
        top: 2px,
    ));

    &.step-layout__title-single {
        @include all-props((
            top: 0,
        ));
    }
}

.step-layout__subtitle {
    @include all-props((
        text-align: center,
        font-family: $gilroy,
        font-weight: var(--font-weight__regular),
        font-size: (
            0:  12px,
            375: 14px,
            1024: 14px,
        ),
        letter-spacing: -0.2px,
        color: #bebebe,
    ));
}

.step-layout__content {
    @include all-props((
        height: (
            0: 100%,
            1024: calc(100vh - 74px),
        ),
        background-color: #f6f6f6,
        overflow-y: scroll,
    ));
    @include mobile-props((
        -webkit-overflow-scrolling: touch,
    ));

    &.step-layout__content__fill-content {
        @include all-props((
            padding: (
                0:   34px 16px 68px,
                360: 32px 16px 74px,
                375: 36px 20px 74px,
                1024: 50px 40px 74px,
            ),
        ));
    }
}

.step-layout__content-header {
    @include all-props((
        display: flex,
        justify-content: center,

        margin-bottom: (
            0:   8px,
            360: 8px,
            375: 10px,
            1024: 10px,
        ),
    ));
}
