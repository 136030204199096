@import '~styles/variables/index';

.ny-banner-wrap {
    @include media(desktop) {
        width: 100%;
        position: relative;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.04);

        @include props-by-media(
            height (
                1024: 116px,
                1366: 143px,
            )
        );
    }
}

.ny-banner {
    display: flex;
    height: 100%;
    background: #fff;
    border-radius: 6px;
    overflow: hidden;
}

.ny-banner__img-wrap {
    height: 100%;
    max-width: 40%;
}

.ny-banner__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right center;
}

.ny-banner__content {
    padding-left: 28px;

    @media (min-width: 1366px) {
        padding-left: 33px;
    }

    @media (min-width: 1600px) {
        padding-left: 35px;
    }
}

.ny-banner__title {
    margin: 23px 0 3px;
    font-family: $gilroy-bold;
    font-size: 18px;
    letter-spacing: -0.65px;
    color: #4e4e4e;

    @media (min-width: 1366px) {
        margin: 31px 0 3px;
        font-size: 22px;
        letter-spacing: -0.8px;
    }
}

.ny-banner__text {
    font-family: $gilroy;
    font-size: 14px;
    letter-spacing: -0.22px;
    color: #4f4f4f;

    @media (min-width: 1366px) {
        font-size: 16px;
        letter-spacing: -0.25px;
    }
}

.ny-banner__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 147px;
    height: 32px;
    margin-top: 8px;
    border-radius: 3px;
    background-color: #ffdd27;

    font-family: $gilroy-medium;
    font-size: 14px;
    color: #8f5f0c;
    letter-spacing: -0.21px;

    @media (min-width: 1366px) {
        width: 164px;
        height: 37px;
        border-radius: 4px;
        font-size: 14px;
    }
}

.ny-banner__close {
    position: absolute;
    right: 0;
    top: 6px;
    padding: 20px;
    background: transparent;
    border: none;

    svg {
        width: 16px;
        height: 16px;
    }

    @media (min-width: 1366px) {
        right: 0;
        top: 15px;
    }
}




