@import '~styles/variables/index';
@import '~global/uikit/index.scss';


.addressSearch {
    position: relative;
}

.addressSearchInput {
    @include all-props((
        position: relative,
        z-index: 3,
        width: 100%,
    ));
}

.addressSearchSuggestions {
    @include all-props((
        position: absolute,
        top: calc(100% - var(--sizeBorderRadiusXS)),
        left: 0,
        z-index: 2,
        width: 100%,
        margin: 12px 0,
        box-sizing: border-box,
        border: 1.5px solid var(--color__grey-salt),
        border-bottom-left-radius: var(--sizeBorderRadiusXS),
        border-bottom-right-radius: var(--sizeBorderRadiusXS),
        overflow: hidden,
        background-color: var(--color__coconut),
    ));

    @media (-webkit-max-device-pixel-ratio: 1.25) {
        border-width: 1px;
    }
}
