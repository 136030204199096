@import '~styles/variables/index';

.paperRecipeRowRoot {
    @include all-props((
        box-sizing: border-box,
        width: 100%,
        height: auto,
        padding: 17px 20px,
        border-radius: 16px,
        background-color: #FFF,
        box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.07),
        font-family: $gilroy,
        font-style: normal,
    ));
}

.paperRecipeRowFlexContainer {
    @include all-props((
        width: 100%,
        height: 100%,
        display: flex,
        align-items: center,
        gap: 8px,
    ))
}

.paperRecipeRowContentIcon {
    @include all-props((
        flex-shrink: 0,
    ))
}

.paperRecipeRowContent {
    @include all-props((
        display: flex,
        align-items: center,
        gap: 12px,
        width: 100%,
        height: auto,
    ))
}

.paperRecipeRowContentIcon {
    @include mobile-props((
        width: (
            0: 26px,
            375: 30px,
        ),
        height: (
            0: 26px,
            375: 30px,
        )
    ));
    @include desktop-props((
        width: 30px,
        height: 30px,
    ))
}

.paperRecipeRowContentText {
    @include all-props((
        position: relative,
        color: #747474,
        letter-spacing: -0.2px,
    ));
    @include mobile-props((
        font-size: (
            0: 14px,
            375: 15px,
        ),
        line-height: (
            0: 15px,
            375: 16px,
        ),
        font-weight: 500,
    ));
    @include desktop-props((
        font-size: 15px,
        line-height: 16px,
        font-weight: 400,
    ))
}

.paperRecipeRowTextDecoration {
    cursor: pointer;
    &::before {
        content: '';
        background: url('./img/dots.img.svg') center center repeat-x;
        @include all-props((
            width: 100%,
            z-index: 5,
            position: absolute,
            height: 3px,
            bottom: -4px,
        ));
    }
}

.paperRecipeRowToggleWrapper {
    @include all-props((
        display: flex,
        align-items: center,
        gap: (
            0: 8px,
            375: 12px,
            1024: 12px,
        ),
        width: auto,
        flex-shrink: 1,
    ))
}

.paperRecipePriceRow {
    @include all-props((
        white-space: nowrap
    ));
    @include all-props((
        font-size: (
            0: 14px,
            375: 15px,
            1024: 15px,
        ),
        font-style: normal,
        font-weight: 500,
        line-height: (
            0: 15px,
            375: 18px,
            1024: 18px,
        ),
        letter-spacing: -0.2px,
        color: #747474,
    ))
}

.paperRecipeRowModalTitle {
    @include all-props((
        font-family: $gilroy,
        color: #424242,
        font-size: (
            0: 21px,
            375: 24px,
            1024: 24px,
        ),
        line-height: (
            0: 22px,
            375: 25px,
            1024: 26px,
        ),
        font-weight: 800,
        letter-spacing: (
            0: -0.6px,
            1024: -0.8px,
        ),
        margin-bottom: (
            0: 10px,
            375: 12px,
            1024: 8px,
        ),
        text-align: start,
    ))
}

.paperRecipeRowModalDesc {
    padding: 0;
    margin: 0;
    list-style: none;
    color: #4F4F4F;
    @include mobile-props((
        position: relative,
    ));
    @include all-props((
        font-family: $gilroy,
        font-size: (
            0: 17px,
            375: 18px,
            1024: 18px,
        ),
        line-height: (
            0: 18px,
            375: 19px,
            1024: 19px,
        ),
        font-weight: 400,
        letter-spacing: (
            0: -0.5px,
            1024: -0.8px,
        ),
        text-align: start,
    ));
    @include desktop-props((
        margin-bottom: 20px,
    ))
}


