@import '~styles/variables/index';


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  background-color: var(--color__coconut);
  color: inherit;
}


.input {
    @include mobile-props((
        border-radius: (
            0: var(--sizeBorderRadiusXXS),
            375: var(--sizeBorderRadiusXS),
        ),
    ));
    @include desktop-props((
        border-radius: 5px,
    ));

    background-color: var(--color__coconut);
    border: solid 1px $concrete;
    box-sizing: border-box;
    padding: 20px 15px 15px 13px;
    outline: none;
    font-family: $gilroy-semibold;
    font-size: 12px;
    line-height: 1;
    letter-spacing: -0.1px;
    color: var(--color__cuttlefish);
    width: 100%;
    height: 40px;
    transition: all 300ms ease;
    &::placeholder {
        font: inherit !important;
        color: $silver-chalice;
    }

    &.has-error {
        border-color: $sundown;
    }

    &:focus {
        border: solid 1px $alto;
        background-color: var(--color__coconut);
        &::placeholder {
            color: $silver-chalice;
        }
    }
    &--street-mobile {
        padding-right: 30px;
    }
    &--street {
        padding-right: 30px;
        font-size: 12px;
    }
    &--apartment,
    &--entrance,
    &--floor {
        padding-right: 26px;
        font-family: $gilroy-semibold;
        // font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
        -moz-appearance: textfield;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            appearance: none;
        }
    }
    &--telephone {
        letter-spacing: 0;
        color: var(--color__cuttlefish);
        padding: 12px 14px 13px 14px;
        font-size: 13px;
        font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
        letter-spacing: -0.2px;
        transition: all 300ms ease;
        &::placeholder {
            color: $silver;
            font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
            letter-spacing: 0.5px;
        }

        &.v303 {
            box-sizing: border-box;
            height: 100% !important;
            transition: all 300ms ease;

            font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: -0.23px;
            color: var(--color__cuttlefish);
            &::placeholder {
                color: $silver;
                letter-spacing: -0.23px;
            }
            &::selection {
                background: transparent;
            }
        }

        &.input-shadow {
            color: #b8b8b8;
            user-select: none;
        }

        &.input-original {
            position: absolute;
            background-color: transparent;
        }
    }
    &--code {
        .input__label {
            display: none;
        }
        .input {
            height: 100%;
            text-align: center;
            padding: 0;

            font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
            font-size: 22px;
            letter-spacing: 5px;
        }
        .input-error {
            text-align: center;
        }
    }
    &--promocode {
        &:focus {
            border: solid 1px var(--color__salt);
            border-right: none;
        }
        &.has-error {
            background: var(--color__coconut);
            border-color: $sundown;
        }
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        width: 158px;
        height: 40px;

        &::placeholder {
            color: $silver-chalice;
        }
    }
    /*
        background-color от :-webkit-autofill невозможно перебить другим background-ом,
        поэтому приходится заполнять все тенью нужного цвета
    */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        color: var(--color__cuttlefish) !important;
        -webkit-box-shadow: inset 0 0 0px 9999px var(--color__coconut) ;
    }
}

.input--street.suggestion {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.input-error-container {
    height: 100%;
    position: relative;
    display: flex;

    .input-error {
        position: absolute;
        bottom: -11px;
        color: $roman;
        font-family: $gilroy;
        font-size: 9px;
        line-height: 1;
        width: 100%;
        white-space: nowrap;
    }
}

.v303 {
    .input-error {
        bottom: -16px;
    }
}

.input--has-float-label {
    display: flex;
    position: relative;
    height: 100%;

    &.fill-width {
        flex: 2;
        .input {
            width: 100%;
        }
    }

    .input,
    .textarea-mobile-comment {
        &::placeholder {
            opacity: 0;
        }
    }

    .input:placeholder-shown + .input__label,
    .textarea-mobile-comment:placeholder-shown + .input__label {
        color: $silver;
        font-family: $gilroy-medium;
        font-size: 12px;
        letter-spacing: -0.1px;
        position: absolute;
        left: 16px;
        top: 11px;
    }

    // label styles white input focus (small text)
    .input:focus + .input__label,
    .input:not(:placeholder-shown) + .input__label,
    .textarea-mobile-comment:focus + .input__label,
    .textarea-mobile-comment:not(:placeholder-shown) + .input__label,
    .input__label {
        position: absolute;
        left: 14px;
        top: 9px;
        cursor: text;
        color: $silver;
        font-family: $gilroy;
        font-size: 8px;
        letter-spacing: -0.1px;
        transition: all 300ms;
    }

    .input.has-error:hover + .input__label {
        color: $silver;
    }

    &.promocode {
        .input:focus + .input__label,
        .input:not(:placeholder-shown) + .input__label,
        .input__label {
            color: $silver-chalice;
        }
    }
}

.textarea {
    display: block;
    position: relative;
    width: 100%;
    min-height: 52px;
    box-sizing: border-box;
    padding: 16px 62px 19px 16px;
    border-radius: var(--sizeBorderRadiusXXS);
    background-color: var(--color__coconut);
    border: solid 1px $mercury;
    font-family: $gilroy-medium;
    font-size: 15px;
    letter-spacing: -0.1px;
    color: var(--color__cuttlefish);
    resize: none;
    outline: none;
    overflow: hidden;
}

.textarea-mobile-comment {
    display: block;
    position: relative;
    width: 100%;
    min-height: 40px;
    box-sizing: border-box;
    padding: 15px 15px 0 13px;
    border-radius: var(--sizeBorderRadiusXXS);
    background-color: var(--color__coconut);
    border: solid 1px $concrete;
    font-family: $gilroy-medium;
    font-size: 14px;
    letter-spacing: -0.1px;
    color: var(--color__cuttlefish);
    resize: none;
    outline: none;
    overflow: hidden;
    &::placeholder {
        color: $silver-chalice;
        font-family: $gilroy;
        font-size: 15px;
    }
    &:focus {
        border: solid 1px $alto;
    }
}

@media (min-width: 360px) {
    .textarea-mobile-comment {
        min-height: 50px;
        padding: 20px 15px 0 14px;
    }
    .input {
        font-size: 15px;
        color: var(--color__cuttlefish);
        padding: 20px 15px 9px 14px;
        height: 50px;
        &--telephone-auth {
            font-size: 16px;
            &::placeholder {
                font-size: 16px;
            }
        }
        &--telephone {
            color: var(--color__cuttlefish);
            padding: 15px;
            font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
            letter-spacing: -0.2px;
            transition: all 300ms ease;
            &::placeholder {
                color: $silver;
                font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
                font-size: 16px;
                letter-spacing: initial;
            }

            &.v303 {
                font-size: 18px;
                letter-spacing: -0.26px;

                &::placeholder {
                    letter-spacing: -0.26px;
                }
            }
        }
        &--code {
            .input {
                padding: 0;
                font-size: 24px;
            }
        }

        &--promocode {
            height: 50px;
            width: 170px;
        }
    }
    .input--has-float-label {
        .input:placeholder-shown + .input__label,
        .textarea-mobile-comment:placeholder-shown + .input__label {
            font-family: $gilroy-medium;
            font-size: 13px;
            left: 15px;
            top: 17px;
        }
        // label styles white input focus (small text)
        .input:focus + .input__label,
        .input:not(:placeholder-shown) + .input__label,
        .textarea-mobile-comment:focus + .input__label,
        .textarea-mobile-comment:not(:placeholder-shown) + .input__label,
        .input__label {
            color: $silver-chalice;
            left: 15px;
            top: 13px;
            font-size: 9px;
        }
    }
    .input-error-container {
        .input-error {
            bottom: -12px;
            font-size: 10px;
        }
    }

    .v303 {
        .input-error {
            bottom: -16px;
        }
    }
}

@media (min-width: 375px) {
    .input {
        &--telephone {
            &.v303 {
                font-size: 19px;
                letter-spacing: -0.27px;

                &::placeholder {
                    letter-spacing: -0.27px;
                }
            }
        }
        &--code {
            .input {
                padding: 0;
                font-size: 25px;
            }
        }
        &--promocode {
            width: 208px;
        }
    }
}

@media (min-width: 414px) {
    .input {
        &--telephone {
            &.v303 {
                font-size: 20px;
                letter-spacing: -0.29px;

                &::placeholder {
                    letter-spacing: -0.29px;
                }
            }
        }
        &--code {
            .input {
                padding: 0;
                font-size: 26px;
            }
        }
    }
}


@media (min-width: 1024px) {
    .textarea-mobile-comment {
        padding: 18px 20px 0;
        font-family: $gilroy;
    }
    .input {
        font-family: $gilroy-medium;
        font-size: 14px;
        // padding: 21px 18px 15px 18px;
        // В ЛК верхний оступ 24px
        padding: 24px 18px 15px 18px;
        height: 52px;
        color: var(--color__cuttlefish);
        &:hover,
        &:focus {
            border: solid 1px $alto;
            background-color: var(--color__coconut);
            &::placeholder {
                color: $silver-chalice;
            }
        }
        &.has-error {
            border-color: $sundown;
        }
        &--code {
            .input {
                font-size: 24px;
            }
        }
        &--telephone {
            font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
            font-size: 17px;
            padding: 16px 18px 15px 18px;
            &::placeholder {
                font-size: 17px;
                color: $silver;
            }

            &.v303 {
                padding: 16px 18px 15px 18px;

                font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
                font-size: 17px;
                letter-spacing: -0.24px;
                &::placeholder {
                    color: $silver;
                }
            }

            &.input-original {
                background-color: transparent;
            }

        }
        &--apartment,
        &--entrance,
        &--floor {
            font-size: 15px;
            font-family: $gilroy-semibold;
            // font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
        }
        &--promocode {
            padding: 21px 18px 15px 16px;
            width: 224px;
            font-family: $gilroy-semibold;
            letter-spacing: -0.2px;
            &:hover,
            &:focus {
                border-right: none;
                &.has-error {
                    border-color:  var(--color__melon);
                }
            }
        }
    }

    .input--has-float-label {
        .input:placeholder-shown + .input__label,
        .textarea-mobile-comment:placeholder-shown + .input__label {
            color: $silver;
            font-family: $gilroy-light;
            font-size: 14px;
            left: 20px;
            top: 19px;
        }
        .textarea-mobile-comment:placeholder-shown + .input__label {
            top: 17px;
        }

        // label styles white input focus (small text)
        .input:focus + .input__label,
        .input:not(:placeholder-shown) + .input__label,
        .textarea-mobile-comment:focus + .input__label,
        .textarea-mobile-comment:not(:placeholder-shown) + .input__label,
        .input__label {
            left: 18px;
            top: 9px;
            color: $silver;
            font-family: $gilroy-light;
            font-size: 10px;
        }
        .textarea-mobile-comment:focus + .input__label,
        .textarea-mobile-comment:not(:placeholder-shown) + .input__label {
            left: 20px;
            top: 7px;
        }

        .input.has-error:hover + .input__label {
            color: $silver;
        }
    }

    // promocode
    .input--has-float-label.promocode {
        .input:placeholder-shown + .input__label {
            color: $silver;
            font-family: $gilroy-light;
            // font-size: 13px;
            // left: 20px;
            // top: 20px;
        }

        // label styles white input focus (small text)
        .input:focus + .input__label,
        .input:not(:placeholder-shown) + .input__label,
        .input__label {
            // left: 17px;
            // top: 12px;
            color: $silver;
            font-family: $gilroy-light;
            // font-size: 10px;
        }

        .input.has-error:hover + .input__label {
            color: $silver;
        }
    }

    .input-error-container {
        .input-error {
            bottom: -10px;
            font-family: $gilroy-light;
        }
    }

    .v303 {
        .input-error {
            bottom: -16px;
        }
    }
}

@media (min-width: 1024px) {
    .input {
        &--telephone {
            &.v303 {
                font-size: 19px;
                letter-spacing: -0.27px;
            }
        }
    }
}

@media (min-width: 1280px) {
    .input {
        font-size: 15px;
        &--telephone {
            &.v303 {
                font-size: 19px;
                letter-spacing: -0.27px;
            }
        }
        &--apartment,
        &--entrance,
        &--floor {
            font-size: 16px;
        }
    }
    .textarea-mobile-comment {
        font-size: 15px;
    }
}

@media (min-width: 1366px) {
    .input {
        &--telephone {
            &.v303 {
                font-size: 20px;
                letter-spacing: -0.29px;
            }
        }
    }
}

@media (min-width: 1440px) {
    .input {
        &--telephone {
            &.v303 {
                font-size: 21px;
                letter-spacing: -0.3px;
            }
        }
    }
}

@media (min-width: 1600px) {
    .input {
        &--telephone {
            &.v303 {
                font-size: 21px;
                letter-spacing: -0.3px;
            }
        }
    }
}

@media (min-width: 1680px) {
    .input {
        &--telephone {
            &.v303 {
                font-size: 21px;
                letter-spacing: -0.3px;
            }
        }
    }
}

@media (min-width: 1920px) {
    .input {
        &--telephone {
            &.v303 {
                font-size: 23px;
                letter-spacing: -0.33px;
            }
        }
    }
}
