@import '~styles/variables/index';

.newUpsellCategoryRoot {
   @include all-props((
        position: relative,
        box-sizing: border-box,
        flex-shrink: 0,
        width: (
            0: 140px,
            375: 150px,
            1024: 150px,
        ),
        height: (
            0: 140px,
            375: 150px,
            1024: 150px,
        ),
        padding: (
            0: 14px 10px 14px 14px,
            375: 16px 12px 16px 16px,
            1024: 16px 12px 16px 16px,
        ),
        font-family: $gilroy,
        font-weight: 500,
        font-size: (
            0: 16px,
            375: 17px,
            1024: 17px,
        ),
        line-height: (
            0: 16px,
            375: 17px,
        ),
        font-style: normal,
        letter-spacing: -0.1,
        color: #929292,
        border-radius: (
            0: 18px,
            375: 20px,
            1024: 20px,
        ),
        background-color: #FFF,
   ));
}

.newUpsellCategoryTitlesWrapper {
    @include all-props((
        width: 100%,
        display: flex,
        justify-content: space-between,
        align-items: flex-end,
    ))
}

.newUpsellCategoryDuration {
    @include all-props((
        position: absolute,
        top: (
            0: 10px,
            375: 12px,
            1024: 12px,
        ),
        right: (
            0: 10px,
            375: 12px,
            1024: 12px,
        ),
    ))
}

.newUpsellCategoryIconContainer {
    img {
        @include all-props((
            position: absolute,
            top: (
                0: 27px,
                375: 32px,
                1024: 32px,
            ),
            left: 0,
            width: (
                0: 140px,
                375: 150px,
                1024: 150px,
            ),
            height: (
                0: 140px,
                375: 150px,
                1024: 150px,
            ),
            object-fit: cover,
        ))
    }
}

.newUpsellCategoryDurationXS {
    @include all-props((
        display: (
            0: block,
            375: none,
            1024: none,
        ),
        width: 66px,
        height: 21px
    ));
}

.newUpsellCategoryDurationMedium {
    @include all-props((
        display: (
            0: none,
            375: block,
            1024: block,
        ),
        width: 70px,
        height: 23px,
    ));
}
