@import '~styles/variables/index';

// PAPER RECIPES STYLES
@import '~styles/variables/index';

.orderSupplementsTogglesRoot {
    @include all-props((
        box-sizing: border-box,
        width: 100%,
        height: auto,
        padding: 17px 20px,
        border-radius: 16px,
        background-color: #FFF,
        box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.07),
        font-family: $gilroy,
        font-style: normal,
    ));
}

.orderSupplementsRowFlexContainer {
    @include all-props((
        width: 100%,
        height: 100%,
        display: flex,
        align-items: center,
        gap: 8px,
    ))
}

.orderSupplementsRowContentIcon {
    @include all-props((
        flex-shrink: 0,
    ))
}

.orderSupplementsRowContent {
    @include all-props((
        display: flex,
        align-items: center,
        gap: 12px,
        width: 100%,
        height: auto,
    ))
}

.orderSupplementsRowContentIcon {
    @include mobile-props((
        width: (
            0: 26px,
            375: 30px,
        ),
        height: (
            0: 26px,
            375: 30px,
        )
    ));
    @include desktop-props((
        width: 30px,
        height: 30px,
    ))
}

.orderSupplementsRowContentText {
    @include all-props((
        position: relative,
        color: #747474,
        letter-spacing: -0.2px,
    ));
    @include mobile-props((
        font-size: (
            0: 14px,
            375: 15px,
        ),
        line-height: (
            0: 15px,
            375: 16px,
        ),
        font-weight: 500,
    ));
    @include desktop-props((
        font-size: 15px,
        line-height: 16px,
        font-weight: 400,
    ))
}

.orderSupplementsRowTextDecoration {
    cursor: pointer;
    &:not(.recipe)::before {
        content: '';
        background: url('../TableSetting/img/dots.img.svg') center center repeat-x;
        @include all-props((
            width: (
                0: 78px,
                375: 83px,
                1024: 83px,
            ),
            z-index: 5,
            position: absolute,
            height: 3px,
            bottom: -4px,
        ));
    }
    &.recipe::before {
        content: '';
        background: url('../TableSetting/img/dots.img.svg') center center repeat-x;
        @include all-props((
            width: (
                0: 145px,
                375: 155px,
                1024: 155px,
            ),
            z-index: 5,
            position: absolute,
            height: 3px,
            bottom: -4px,
        ));
    }
}

.orderSupplementsRowToggleWrapper {
    @include all-props((
        display: flex,
        align-items: center,
        gap: (
            0: 8px,
            375: 12px,
            1024: 12px,
        ),
        width: auto,
        flex-shrink: 1,
    ))
}

.orderSupplementsPriceRow {
    @include all-props((
        white-space: nowrap
    ));
    @include all-props((
        font-size: (
            0: 14px,
            375: 15px,
            1024: 15px,
        ),
        font-style: normal,
        font-weight: 500,
        line-height: (
            0: 15px,
            375: 18px,
            1024: 18px,
        ),
        letter-spacing: -0.2px,
        color: #747474,
    ))
}

.orderSupplementsDivider {
    @include all-props((
        width: 100%,
        height: 1px,
        background-color: #F1F1F1,
        margin: 9px 0,
    ))
}

.orderSupplementsDialogImage {
    @include mobile-props((
        width: 100%,
        height: (
            0: 224px,
            375: 258px,
        ),
        border-radius: (
            0: 16px,
            375: 20px,
        ),
        overflow: hidden,
        margin-bottom: (
            0: 10px,
            375: 13px,
            1024: 7px,
        )
    ));
    @include desktop-props((
        width: 100%,
        height: 410px,
        border-radius: 18px,
        overflow: hidden,
    ))
}

.orderSupplementsModalTitle {
    @include all-props((
        font-family: $gilroy,
        color: #424242,
        font-size: (
            0: 21px,
            375: 24px,
            1024: 24px,
        ),
        line-height: (
            0: 22px,
            375: 25px,
            1024: 26px,
        ),
        font-weight: 800,
        letter-spacing: (
            0: -0.6px,
            1024: -0.8px,
        ),
        margin-bottom: (
            0: 10px,
            375: 12px,
            1024: 8px,
        ),
        text-align: start,
    ))
}

.orderSupplementsModalList {
    padding: 0;
    margin: 0;
    list-style: none;
    color: #4F4F4F;
    li {
        @include mobile-props((
            position: relative,
            padding-left: 9px
        ));
        @include all-props((
            font-family: $gilroy,
            font-size: (
                0: 17px,
                375: 18px,
                1024: 18px,
            ),
            line-height: (
                0: 18px,
                375: 19px,
                1024: 19px,
            ),
            font-weight: 400,
            letter-spacing: (
                0: -0.5px,
                1024: -0.8px,
            ),
            text-align: start,
        ));
    }
    li::before {
        content: '';
        position: absolute;
        @include mobile-props((
            left: 0,
            top: (
                0: 8px,
                375: 9px,
            ),
            width: 4px,
            height: 4px,
            background-color: #4F4F4F,
            border-radius: 50%,
        ));
    }
    li:last-child {
        @include mobile-props((
            margin-bottom: (
                0: 10px,
                375: 12px,
            ))
        );
        @include desktop-props((
            margin-bottom: 20px,
        ))
    }
}

.orderSupplementsModalDesc {
    padding: 0;
    margin: 0;
    list-style: none;
    color: #4F4F4F;
    @include mobile-props((
        position: relative,
    ));
    @include all-props((
        font-family: $gilroy,
        font-size: (
            0: 17px,
            375: 18px,
            1024: 18px,
        ),
        line-height: (
            0: 18px,
            375: 19px,
            1024: 19px,
        ),
        font-weight: 400,
        letter-spacing: (
            0: -0.5px,
            1024: -0.8px,
        ),
        text-align: start,
    ));
    @include desktop-props((
        margin-bottom: 20px,
    ))
}
