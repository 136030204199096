@import '~styles/variables/index';


.best-sets-slider__root {
    *, *::after, *::before {
        box-sizing: border-box;
    }
}

.best-sets-slider__root {
    @include mobile-props((
        display: flex,
        width: 100%,
    ));
}

.best-sets-slider--padding-on-empty-dishes {
    @include mobile-props((
        padding-top: (
            320: 42px,
            360: 44px,
            375: 48px,
            414: 54px,
        ),
    ));
}

.best-sets-slider__disclaimer {
    p {
        @include mobile-props((
            padding: (
                0:   0 16px 5px,
                375: 0 20px 8px,
            ),
            text-align: center,
        ));
    }
}

.best-sets-slider__list-container {
    @include mobile-props((
        overflow-y: scroll,
        scrollbar-width: none, // ff
        position: relative,
        padding-top: (
            0:   8px,
            375: 8px,
            414: 12px,
        ),
        padding-bottom: (
            0:   40px,
            375: 50px,
        ),
    ));

    &::-webkit-scrollbar { // chrome, safari, opera
        @include mobile-props((
            display: none,
        ));
    }
}

.best-sets-slider__list {
    @include mobile-props((
        list-style: none,
        display: flex,
        align-items: center,
        width: fit-content,

        margin: 0,
        padding: (
            0:   0 16px,
            375: 0 20px,
        ),
    ));
}

.best-sets-slider__list-item {
    @include mobile-props((
        position: relative,
        display: inline-block,
    ));

    & + & {
        @include mobile-props((
            margin-left: (
                0:   10px,
                375: 12px,
            ),
        ));
    }
}

.best-sets-slider__item-button {
    @include mobile-props((
        display: block,
        border: none,
        outline: none,
        margin: 0,
        padding: 0,
        width: auto,
        background: transparent,
        text-align: unset,
        overflow: hidden,

        border-radius: (
            0: var(--sizeBorderRadiusXL),
            375: var(--sizeBorderRadiusXXL),
        ),

        box-shadow: (
            0:   4px 8px 14px rgba(0, 0, 0, 0.04),
            375: 4px 10px 16px rgba(0, 0, 0, 0.04),
        ),
    ));
}

.best-sets-slider__item-root {
    @include mobile-props((
        width: (
            0:   138px,
            360: 156px,
            375: 160px,
            414: 178px,
        ),
    ));
}

.best-sets-slider__item-image-container {
    @include mobile-props((
        position: relative,
    ));
}

.best-sets-slider__item-image {
    @include mobile-props((
        height: (
            0:   135px,
            360: 152px,
            375: 158px,
            414: 174px,
        ),
        width: 100%,
        object-fit: cover,
        object-position: center,
    ));
}

.best-sets-slider__item-badge-container {
    @include mobile-props((
        position: absolute,
        top: (
            0:   9px,
            360: 12px,
        ),
        right: (
            0:   9px,
            375: 12px,
        )
    ));
}

.best-sets-slider__item-desc-container {
    @include mobile-props((
        padding: (
            0:   6px 5px 0 11px,
            360: 8px 5px 0 14px,
            375: 8px 5px 0 13px,
            414: 10px 5px 0 15px,
        ),
        height: (
            0:   69px,
            360: 78px,
            375: 82px,
            414: 90px,
        ),
    ));
}

.best-sets-slider__item-title {
    @include mobile-props((
        margin-bottom: (
            0:   -1px,
            360: 1px,
            375: -1px,
        ),

        color: var(--color__pepper),
        font-family: $gilroy,
        font-weight: 600,
        font-size: (
            0:   14px,
            360: 16px,
            375: 17px,
            414: 18px,
        ),
        line-height: (
            0:   17px,
            360: 19px,
            375: 20px,
            414: 21px,
        ),
        letter-spacing: -0.3px,
    ));
}

.best-sets-slider__item-description {
    @include mobile-props((
        margin-bottom: (
            0:   1px,
            360: 0,
            375: 1px,
        ),

        color: #838383,
        font-family: $gilroy,
        font-weight: 400,
        font-size: (
            0:   13px,
            360: 15px,
            375: 16px,
            414: 17px,
        ),
        line-height: (
            0:   15px,
            360: 17px,
            375: 19px,
            414: 20px,
        ),
        letter-spacing: -0.3px,
    ));
}

.best-sets-slider__item-price {
    @include mobile-props((
        color: var(--color__pepper),
        font-size: (
            0:   13px,
            360: 15px,
            375: 16px,
            414: 18px,
        ),
        line-height: (
            0:   16px,
            360: 18px,
            375: 19px,
            414: 22px,
        ),
        letter-spacing: -0.1px,
    ));

    & .num {
        @include mobile-props((
            font-family: $haas-dsstd,
            font-weight: 700,
        ));
    }
}

.best-sets-slider__item-link-text {
    @include mobile-props((
        color: var(--color__pepper),
        font-family: $gilroy,
        font-weight: 400,
        font-size: (
            0:   13px,
            360: 15px,
            375: 16px,
            414: 17px,
        ),
        line-height: (
            0:   15px,
            360: 17px,
            375: 19px,
            414: 20px,
        ),
        letter-spacing: -0.3px,
        text-decoration-line: underline,
    ));
}

