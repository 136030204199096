@import '~styles/variables/index';
@import '~styles/variables/header-height-vars';
@import '~global/header/styles/header.global.scss';
@import "~global/styles/cool-media-queries/index.global.scss";


.header__fixed-content {
    position: fixed;
    background-color: #FFF;

    &.profile-page-header {
        @include all-props((
            position: (
                0: fixed,
                1024: relative,
            ),
        ));
        @include desktop-props((
            display: flex,
            justify-content: center,
            border-bottom: 1px solid #f1f1f1,
        ));
    }

    width: 100%;
    z-index: 1000;

    @media (min-width: 1024px) {
        box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.03);
    }
}

.app-header--wrapper {
    @include desktop-props((
        background-color: #fff,
        border-bottom: 1px solid #f1f1f1,
        padding: (
            960: 0 $v3-res960-gird-gap 0 11px,
            1024: 0 $v3-res1024-gird-gap,
            1280: 0 $v3-res1280-gird-gap,
            1366: 0 $v3-res1366-gird-gap,
            1440: 0 $v3-res1440-gird-gap,
            1600: 0 $v3-res1600-gird-gap,
            1920: 0 $v3-res1920-gird-gap,
        )
    ));
}

.app-profile-header--wrapper {
    /* FRNT-3379 */
    @include responsive-desktop-props(1536px, (
        width: 1300px,
    ));

    // @include desktop-props((
    //     width: (
    //         1024: 960px,
    //         1280: 1200px,
    //         1680: 1313px,
    //         1920: 1350px,
    //         2560: 1500px,
    //     ),
    // ));
}
