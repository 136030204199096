@import "~global/styles/cool-media-queries/index.global.scss";
@import "../index.scss";

.basketSkeletonPageRoot {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    position: relative;
    z-index: 300;
    background-color: #FFF;
    -ms-overflow-style: none;
    scrollbar-width: none;
     &::-webkit-scrollbar { // chrome, safari, opera
        @include all-props((
            display: none,
        ));
    }
}

.basketSkeletonHeader {
    @include all-props((
        margin-bottom: 34px,
    ));
}

.basketTitleSkeleton {
    @include all-props((
        width: (
            0: 167px,
            1024: 167px,
        ),
        height: (
            0: 20px,
            375: 24px,
            1024: 24px,
        ),
        border-radius: (
            0: 5px,
            375: 7px,
            1024: 7px,
        ),
        margin: (
            0: 0 16px 10px 16px,
            375: 0 16px 14px 16px,
            1024: 0 16px 14px 16px,
        ),
    ));
}

/* BasketSkeletonCard */
.basketSkeletonCardRoot {
    @include all-props((
        margin-bottom: (
            0: 16px,
            375: 20px,
            1024: 20px,
        ),
        display: flex,
        justify-content: space-between,
        padding: 0 16px 0 16px,
    ));
    &.unpreassembled {
        background-color: red;
    }
}

.basketSkeletonCardLeft {
    @include all-props((
        width: (
            0: 96px,
            375: 107px,
            1024: 107px,
        ),
        height: (
            0: 71px,
            375: 80px,
            1024: 80px,
        ),
        border-radius: (
            0: 8px,
            375: 10px,
            1024: 10px,
        ),
    ));

}

.basketSkeletonCardRight {

}

.basketSkeletonCardRowOne {
    @include all-props((
        width: (
            0: 176px,
            375: 210px,
            1024: 210px,
        ),
        height: (
            0: 14px,
            375: 16px,
            1024: 16px,
        ),
        border-top-right-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
        border-top-left-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
        border-bottom-right-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        )
    ));

}

.basketSkeletonCardRowTwo {
    @include all-props((
        border-bottom-right-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
        border-bottom-left-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
        width: (
            0: 132px,
            375: 157px,
            1024: 157px,
        ),
        height: (
            0: 14px,
            375: 16px,
            1024: 16px,
        ),
        margin-bottom: (
            0: 4px,
            375: 10px,
            1024: 10px,
        ),
    ));
}

.basketSkeletonCardRowThree {
    @include all-props((
        width: (
            0: 157px,
            1024: 157px,
        ),
        height: (
            0: 20px,
            1024: 20px,
        ),
        border-radius: (
            0: 5px,
            375: 6px,
            1024: 6px,
        )
    ));
}

/* BasketSkeletonDishListButton */
.basketSkeletonDishListButton {
    @include all-props((
        width: (
            0: 288px,
            375: 335px,
            1024: 335px,
        ),
        height: (
            0: 48px,
            375: 50px,
            1024: 50px,
        ),
        border-radius: (
            0: 11px,
            375: 12px,
            1024: 12px,
        ),
        margin: (
            0: 0 16px 20px 16px,
            375: 0 16px 26px 16px,
            1024: 0 16px 26px 16px,
        ),
    ));
}

/* BasketSkeletonTagsArea */
.basketSkeletonTagsAreaRoot {
    @include all-props((
        background-color: #F9F9F9,
        padding: 30px 16px 30px 16px,
    ));

}

.basketSkeletonTagsAreaContent {
    @include all-props((
        margin-bottom: (
            0: 14px,
            375: 16px,
            1024: 16px,
        ),
    ));

}

.basketSkeletonTagsAreaContentOne {
    @include all-props((
        width: (
            0: 252px,
            1024: 252px,
        ),
        height: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
        border-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
        margin-bottom: (
            0: 4px,
            375: 6px,
            1024: 6px,
        )
    ));
}

.basketSkeletonTagsAreaContentTwo {
    @include all-props((
        width: (
            0: 162px,
            1024: 162px,
        ),
        height: (
            0: 32px,
            1024: 32px,
        ),
        border-top-left-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
        border-top-right-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
    ));

}

.basketSkeletonTagsAreaContentThree {
    @include all-props((
        width: (
            0: 252px,
            1024: 252px,
        ),
        height: (
            0: 32px,
            1024: 32px,
        ),
        border-bottom-left-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
        border-bottom-right-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
        border-top-right-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
    ));
}

.basketSkeletonTagsAreaList {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    @include all-props((
        margin-bottom: (
            0: 24px,
            1024: 24px,
        ),
    ));
}

.basketSkeletonTagsAreaListItem {
    @include all-props((
        height: (
            0: 40px,
            1024: 40px,
        ),
        border-radius: (
            0: 7px,
            1024: 7px,
        ),
        flex: 1 0 48%,
    ));
}

.basketSkeletonTagsAreaListItemOther {
    @include all-props((
        height: (
            0: 40px,
            1024: 40px,
        ),
        border-radius: 7px,
        width: 48%,
    ));
}

/* BasketSkeletonSelectionsArea */
.basketSkeletonSelectionsRoot {
    @include all-props((
        display: flex,
        gap: 8px,
        padding: (
            0: 20px 0 20px 16px,
            1024: 20px 0 20px 16px,
        ),
        overflow: hidden,
    ));
    &.unpreassembled {
        background-color: #f9f9f9;
        padding: 24px 0 24px 16px;
    }
}

.basketSkeletonSelectionsItem {
    @include all-props((
        height: (
            0: 157px,
            375: 168px,
            1024: 168px,
        ),
        min-width: (
            0: 115px,
            375: 125px,
            1024: 125px,
        ),
        border-radius: (
            0: 14px,
            375: 16px,
            1024: 16px,
        ),
    ));
    &.unpreassembled {
        background-color: #fff;
    }
}

/* BasketSkeletonCheckArea */
.basketSkeletonCheckAreaRoot {
    @include all-props((
        padding: (
            0: 0 16px,
            1024: 0 16px,
        ),
    ));
}

.basketSkeletonCheckCard {
    @include all-props((
        padding: (
            0: 20px,
            1024: 20px,
        ),
        background-color: #F9F9F9,
        border-radius: (
            0: 16px,
            1024: 16px,
        ),
        margin-bottom: 30px,
    ));

}

.basketSkeletonCheckTitle {
    @include all-props((
        width: (
            0: 186px,
            375: 222px,
            1024: 222px,
        ),
        height: (
            0: 18px,
            375: 24px,
            1024: 24px,
        ),
        border-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
        margin-bottom: 28px,
    ));

}

.basketSkeletonCheckRows {
    @include all-props((
        flex-direction: column,
        display: flex,
        gap: (
            0: 28px,
            375: 32px,
            1024: 32px,
        ),
        width: 100%,
    ));

}
.basketSkeletonCheckRow {
    @include all-props((
        display: flex,
        justify-content: space-between,
        width: 100%,
        margin-bottom: (
            0: 8px,
            1024: 8px,
        ),
    ));
}

.basketSkeletonCheckRowLeft {
    @include all-props((
        width: (
            0: 124px,
            375: 146px,
            1024: 146px,
        ),
        height: (
            0: 18px,
            375: 24px,
            1024: 24px,
        ),
        border-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
    ));
}

.basketSkeletonCheckRowRight {
    @include all-props((
        width: (
            0: 62px,
            375: 74px,
            1024: 74px,
        ),
        height: (
            0: 18px,
            375: 24px,
            1024: 24px,
        ),
        border-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
    ));
}

.basketSkeletonCheckButton {
    @include all-props((
        height: (
            0: 50px,
            1024: 50px,
        ),
        border-radius: (
            0: 8px,
            1024: 8px,
        ),
        margin-bottom: (
            0: 30px,
            375: 22px,
            1024: 22px,
        ),
    ));

}

.basketSkeletonCheckTotal {
    @include all-props((
        width: (
            0: 62px,
            375: 74px,
            1024: 74px,
        ),
        height: (
            0: 18px,
            375: 24px,
            1024: 24px,
        ),
        border-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
    ));
}


.basketSkeletonCommentAreaRoot {
    @include all-props((
        padding: 0 16px,
        margin-bottom: (
            0: 40px,
            375: 60px,
            1024: 60px,
        )
    ));

}

.basketSkeletonCommentRowOne {
    @include all-props((
        width: (
            0: 162px,
            375: 167px,
            1024: 167px,
        ),
        height: (
            0: 20px,
            375: 24px,
            1024: 24px,
        ),
        border-radius: (
            0: 5px,
            375: 7px,
            1024: 7px,
        ),
        margin-bottom: (
            0: 10px,
            1024: 10px,
        ),
    ));

}

.basketSkeletonCommentRowTwo {
    @include all-props((
        height: (
            0: 50px,
            375: 54px,
            1024: 54px,
        ),
        border-radius: (
            0: 8px,
            1024: 8px,
        ),
        width: 100%,
        margin-bottom: (
            0: 10px,
            375: 16px,
            1024: 16px,
        ),
    ));

}

.basketSkeletonCommentRowThree {
    @include all-props((
        width: 100%,
        height: (
            0: 16px,
            375: 24px,
            1024: 24px,
        ),
        border-radius: (
            0: 4px,
            375: 6px,
            1024: 6px,
        ),
    ));

}
