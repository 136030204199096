$font-path: '../../assets/fonts';


$src:
  url("#{$font-path}/Gilroy-Semibold.woff") format('woff'),
;

@font-face {
  font-family: 'Gilroy-SemiBold';
  src:  $src;
  font-display: swap;
}
