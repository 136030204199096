$font-path: '../../assets/fonts';
$font-name: 'NHaasGroteskDSStd';


@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-25Th.woff") format('woff');
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-35XLt.woff") format('woff');
  font-display: swap;
  font-weight: 200;
}

@font-face {
  font-family: $font-name;
  src: url("#{$font-path}/#{$font-name}-45Lt.woff") format('woff');
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-55Rg.woff") format('woff');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-65Md.woff") format('woff');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-75Bd.woff") format('woff');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-95Blk.woff") format('woff');
  font-display: swap;
  font-weight: 900;
}
