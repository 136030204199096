@import '~styles/variables/index';

.mobile-categories-select-wrap {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3000;

    &.exited {
        visibility: hidden;
        backdrop-filter: blur(0px);
        background-color: rgba(255, 255, 255, 0);
    }
    &.entering {
        transition: backdrop-filter 500ms, background-color 500ms;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.9);
    }
    &.entered {
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.9);
    }
    &.exiting {
        transition: backdrop-filter 500ms, background-color 500ms;
        backdrop-filter: blur(0px);
        background-color: rgba(255, 255, 255, 0);
    }
}

.mobile-categories-select__close-overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.mobile-categories-select {
    @include mobile-props((
        border-radius: (
            0: var(--sizeBorderRadiusXL) var(--sizeBorderRadiusXL) 0 0,
            375: var(--sizeBorderRadiusXXL) var(--sizeBorderRadiusXXL) 0 0,
        )
    ));
    @include mobile_320 {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;

        display: flex;
        flex-direction: column;
        background: var(--color__coconut);

        &.exited {
            transform: translateY(100%);
        }
        &.entering {
            transition: transform 500ms;
            transform: translateY(0%);
        }
        &.entered {
            transform: translateY(0%);
        }
        &.exiting {
            transition: transform 500ms;
            transform: translateY(100%);
        }
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.05);
    }

    @include mobile_414 {
        box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.05);
    }
}

.mobile-categories-select__close-row {
    display: flex;
    justify-content: flex-end;
    border-bottom: solid 1px var(--color__onion);
}

.mobile-categories-select__close-container {
    @include mobile_320 {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        width: 26px;
        height: 26px;
        padding: 11px 16px;
    }
    @include mobile_375 {
        width: 32px;
        height: 32px;
        padding: 14px 18px;
    }
    @include mobile_414 {
        padding: 19px 16px;
    }
}

.mobile-categories-list {
    @include mobile_320 {
        list-style: none;
        padding: 0;

        margin-top: 23px;
        margin-bottom: 42px;
    }
    @include mobile_375 {
        margin-top: 24px;
        margin-bottom: 52px;
    }
    @include mobile_414 {
        margin-bottom: 65px;
    }
}

.mobile-categories-list-item {
    @include mobile_320 {
        & + & {
            margin-top: 20px;
        }
    }
    @include mobile_375 {
        & + & {
            margin-top: 21px;
        }
    }
    @include mobile_414 {
        & + & {
            margin-top: 23px;
        }
    }
}

.mobile-categories-list-item.mobile-categories-list-item--subcategory {
    @include mobile_320 {
        margin-top: 13px;
    }
    @include mobile_375 {
        margin-top: 14px;
    }
    @include mobile_414 {
        margin-top: 15px;
    }
}

.mobile-categories-list-item__title-wrap {
    @include mobile_320 {
        text-align: center;
        color: var(--color__cuttlefish);
    }
}

.mobile-categories-list-item .mobile-categories-list-item__subcategory {
    @include mobile_320 {
        font-family: $gilroy-medium;
        font-weight: normal;
    }
}

.mobile-categories-list-item__button {
    width: 100%;
    text-align: center;
    color: var(--color__cuttlefish);

    transition: color 150ms;

    &:active {
        color: var(--color__hering);
    }
    &:disabled {
        color: var(--color__oyster);
    }
}
