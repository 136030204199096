@keyframes loading {
    0% { left: 0%; margin-left: -50%}
    100% { left: 100%; margin-left: 0% }
}

.skeletonAnimation {
    position: relative;
    overflow: hidden;

    background-image: linear-gradient(90deg, #F8F8F8 6.22%, rgba(248, 248, 248, 0.5) 124.88%, rgba(248, 248, 248, 0.4) 124.88%);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        min-width: 20px;
        height: 100%;
        background:  linear-gradient(90deg, rgba(244, 244, 244, 0) 0%, #F2F1F1 48.96%, rgba(244, 244, 244, 0) 100%);
        animation: loading 1500ms 300ms infinite;
    }
}

