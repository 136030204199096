$font-path: '../../assets/fonts';
$font-name: 'Inter';
$italic: 'Italic';

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-Thin-BETA.woff") format('woff');
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-ExtraLight-BETA.woff") format('woff');
  font-display: swap;
  font-weight: 200;
}

@font-face {
  font-family: $font-name;
  src: url("#{$font-path}/#{$font-name}-Light-BETA.woff") format('woff');
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-Regular.woff") format('woff');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-Medium.woff") format('woff');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-SemiBold.woff") format('woff');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-Bold.woff") format('woff');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-ExtraBold.woff") format('woff');
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-Black.woff") format('woff');
  font-display: swap;
  font-weight: 900;
}

@font-face {
    font-family: $font-name;
    src:
      url("#{$font-path}/#{$font-name}-Thin#{$italic}-BETA.woff") format('woff');
    font-display: swap;
    font-style: italic;
    font-weight: 100;
  }

  @font-face {
    font-family: $font-name;
    src:
      url("#{$font-path}/#{$font-name}-ExtraLight#{$italic}-BETA.woff") format('woff');
    font-display: swap;
    font-style: italic;
    font-weight: 200;
  }

  @font-face {
    font-family: $font-name;
    src: url("#{$font-path}/#{$font-name}-Light#{$italic}-BETA.woff") format('woff');
    font-display: swap;
    font-style: italic;
    font-weight: 300;
  }

  @font-face {
    font-family: $font-name;
    src:
      url("#{$font-path}/#{$font-name}-#{$italic}.woff") format('woff');
    font-display: swap;
    font-style: italic;
    font-weight: 400;
  }

  @font-face {
    font-family: $font-name;
    src:
      url("#{$font-path}/#{$font-name}-Medium#{$italic}.woff") format('woff');
    font-display: swap;
    font-style: italic;
    font-weight: 500;
  }

  @font-face {
    font-family: $font-name;
    src:
      url("#{$font-path}/#{$font-name}-SemiBold#{$italic}.woff") format('woff');
    font-display: swap;
    font-style: italic;
    font-weight: 600;
  }

  @font-face {
    font-family: $font-name;
    src:
      url("#{$font-path}/#{$font-name}-Bold#{$italic}.woff") format('woff');
    font-display: swap;
    font-style: italic;
    font-weight: 700;
  }

  @font-face {
    font-family: $font-name;
    src:
      url("#{$font-path}/#{$font-name}-ExtraBold#{$italic}.woff") format('woff');
    font-display: swap;
    font-style: italic;
    font-weight: 800;
  }

  @font-face {
    font-family: $font-name;
    src:
      url("#{$font-path}/#{$font-name}-UI-Black#{$italic}.woff") format('woff');
    font-display: swap;
    font-style: italic;
    font-weight: 900;
  }
