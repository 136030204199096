@import '~styles/variables/index';
@import '~global/uikit/index.scss';

.miragoshaDetailsRoot {
    @include mobile-props((
        font-family: $gilroy,
        padding: (
            0: 0 16px,
            375: 0 20px,
        ),
    ));
}

.miragoshaVideoLinkCover {
    @include mobile-props((
        display: block,
        width: 100%,
        box-sizing: border-box,
        border-radius: (
            0: 10px,
            375: 12px,
        ),
        margin-bottom: (
            0: 14px,
            375: 18px,
        )
    ))
}

.miragoshaDetaisVideoLink {
    @include mobile-props((
        width: 100%,
        height: (
            0: 48px,
            375: 52px,
        ),
        display: flex,
        justify-content: center,
        align-items: center,
        box-sizing: border-box,
        border-radius: (
            0: 11px,
            375: 12px,
        ),
        margin-bottom: (
            0: 20px,
            375: 24px,
        ),
        background-color: #F1F1F1,
    ))
}

.miragoshaDetaisVideoLinkTitle {
    @include mobile-props((
        font-family: $gilroy,
        font-size: (
            0: 17px,
            375: 18px,
        ),
        line-height: 22px,
        font-weight: 700,
        color: #747474,
    ))
}
