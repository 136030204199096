@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

.selectPortionRoot {
    @include all-props((
        text-align: start,
    ));
}

.selectPortionTitle {
    @include all-props((
        padding: (
            1024: 0px 0px 10px 0px,
        ),

        color: #424242,
        font-family: $gilroy,
        font-size: (
            1024: 24px,
        ),
        font-style: normal,
        font-weight: 800,
        line-height: (
            1024: 25px,
        ),
        letter-spacing: (
            1024: -0.8px,
        ),
    ));
}

.selectPotionItems {
    @include all-props((
        padding: (
            1024: 0px 0px 20px 0,
        ),
    ));
}

.selectPortionItemButton {
    @include all-props((
        display: flex,
        justify-content: space-between,
        align-items: center,
        width: 100%,
        text-align: start,
        gap: (
            1024: 16px,
        ),
        padding: (
            1024: 14px 0,
        ),
        border-bottom: 1px solid #F1F1F1,
    ));

    &:nth-last-child(-n + 1) {
        border: none;
    }
}

.selectPortionItemText {
    @include all-props((
        color: var(--color__cuttlefish),
        font-family: $gilroy,
        font-size: (
            0: 17px,
            375: 18px,
            1024: 18px,
        ),
        font-style: normal,
        font-weight: 500,
        line-height: 18px,
        letter-spacing: -0.2px,
        text-align: start,
        flex-basis: 91%,
    ));

    &:global(.selected) {
        font-weight: 700;
    }
}
