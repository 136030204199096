@import '~styles/variables/index';
@import '~styles/modules/ruble';

.basketmini {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    box-shadow: none;
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    padding: 5px 23px 0 12px;
    outline: none;
    position: relative;
    cursor: pointer;

    &.is-hidden {
        cursor: default;
        visibility: hidden;
    }
}

.basketmini--wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 128px;
}

.basketmini__title {
    font-family: $gilroy;
    font-size: 10px;
    color: $silver;
    &.delivery {
        color: var(--color__cuttlefish);
    }
}

.basketmini__price {
    color: var(--color__cuttlefish);
    font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
    font-size: 19px;
}

.basketmini__currency {
    @extend %ruble;
    color: var(--color__cuttlefish);
    font-size: 18px;
    padding-left: 3px;
}

.basketmini__icon {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 37px;
    box-sizing: border-box;
    top: -1px;
    right: -1px;
    svg {
        width: 20px;
        height: 19px;
    }
}

.basketmini__count {
    position: absolute;
    top: 4px;
    right: -2px;
    box-sizing: border-box;
    padding: 1px 4px;
    background: $sunglow;
    color: $cola;
    border-radius: 1px;
    font-family: $gilroy-bold;
    font-size: 6px;
    letter-spacing: -0.3px;
}

.basketmini__basket-icon {
    position: relative;
}

// при одинаковом имени анимации, анимация не работает
// закоменнтировал анимацию числа, может захотят вернуть
.change-1 {
    &.basketmini {
        animation: change-1 500ms linear running;
    }
    // .basketmini__count {
    //     animation: change-count-1 500ms linear running;
    // }
}
.change-2 {
    &.basketmini {
        animation: change-2 500ms linear running;
    }
    // .basketmini__count {
    //     animation: change-count-2 500ms linear running;
    // }
}

@keyframes change-1 {
    0% {
        top: 0px
    }
    50% {
        top: -10px;
    }
    100% {
        top: 0px
    }
  }

@keyframes change-2 {
    0% {
        top: 0px
    }
    50% {
        top: -10px;
    }
    100% {
        top: 0px
    }
}

@keyframes change-count-1 {
    0% {
        top: 7px;
    }
    50% {
        top: 2px;
    }
    100% {
        top: 7px;
    }
  }

@keyframes change-count-2 {
    0% {
        top: 7px;
    }
    50% {
        top: 2px;
    }
    100% {
        top: 7px;
    }
}

@media (min-width: 360px) {
    .basketmini {
        height: 48px;
    }
    .basketmini__icon {
        height: 44px;
        svg {
            width: 24px;
            height: 23px;
        }
    }
    .basketmini__count {
        top: 7px;
        padding: 2px 4px;
        font-size: 7px;
    }
}

@media (min-width: 1024px) {
    .basketmini {
        width: 170px;
        height: 43px;
        border-radius: 5px;
        box-shadow: 0 7px 11px 0 rgba(215, 215, 215, 0.22);
        border: solid 1px $concrete;
        padding: 0;
        top: -1px;
    }

    .basketmini--wrap {
        padding-right: 7px;
        margin-top: -5px;
    }

    .basketmini__title {
        font-family: $gilroy-light;
        font-size: 10px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.02px;
        color: $silver;
    }

    .basketmini__price {
        font-family: $Neue-Haas-Grotesk-Display-Pro-65-Medium;
        font-size: 17px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.07px;
        color: var(--color__cuttlefish);
    }

    .basketmini__currency {
        color: var(--color__cuttlefish);
        font-size: 16px;
        letter-spacing: -0.05px;
        padding-left: 2px;
    }

    .basketmini__icon {
        min-width: 42px;
        height: 43px;
        background: $sunglow;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        &.empty {
            background: var(--color__coconut);
            border: solid 1px $concrete;
        }
    }

    .basketmini__basket-icon {
        top: 3px;
        left: -1px;
    }

    .basketmini__arrow-icon {
        top: 1px;
        left: 2px;
        svg {
            height: 13px;
        }
    }

    .basketmini__count {
        top: 6px;
        right: 6px;
        border-radius: 2px;
        border: none;
        padding: 0px 5px 0 5px;
        background: var(--color__coconut);
        box-shadow: 0 3px 8px 0 rgba(131, 131, 131, 0.24);
        min-width: 14px;

        font-family: $gilroy-semibold;
        font-size: 10px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.43px;
        color: $olivetone;
    }
}

@media (min-width: 1024px) {
    .basketmini {
        width: 182px;
        height: 46px;
        padding: 0;
        top: 0px;
    }

    .basketmini--wrap {
        padding-right: 7px;
        margin-top: -2px;
    }

    .basketmini__currency {
        padding-left: 2px;
    }

    .basketmini__icon {
        min-width: 45px;
        height: 46px;
    }

    .basketmini__basket-icon {
        top: 3px;
        left: 0px;
    }
}

@media (min-width: 1280px) {
    .basketmini {
        width: 190px;
        height: 47px;
        padding: 0;
        top: 0px;
    }

    .basketmini--wrap {
        padding-right: 7px;
        margin-top: -2px;
    }

    .basketmini__price {
        font-size: 19px;
        line-height: 1.2;
        letter-spacing: -0.07px;
    }

    .basketmini__currency {
        padding-left: 3px;

        font-size: 18px;
        letter-spacing: -0.05px;
    }

    .basketmini__icon {
        min-width: 47px;
        height: 47px;
    }

    .basketmini__basket-icon {
        top: 3px;
        left: -2px;
    }

    .basketmini__count {
        top: 6px;
        right: 7px;
    }
}

@media (min-width: 1366px) {
    .basketmini {
        padding: 0;
        top: 1px;
    }

    .basketmini--wrap {
        padding-right: 7px;
        margin-top: -2px;
    }

    .basketmini__basket-icon {
        top: 3px;
        left: 0px;
    }

    .basketmini__count {
        top: 6px;
        right: 7px;
    }
}

@media (min-width: 1440px) {
    .basketmini {
        padding: 0;
        top: 0px;
    }

    .basketmini--wrap {
        padding-right: 7px;
        margin-top: -2px;
    }

    .basketmini__basket-icon {
        left: -2px;
        top: 4px;
    }

    .basketmini__count {
        top: 8px;
        right: 7px;
    }
}

@media (min-width: 1600px) {
    .basketmini {
        width: 202px;
        height: 50px;
        padding: 0;
        top: 4px;
    }

    .basketmini--wrap {
        margin-left: 11px;
        margin-top: -2px;
    }

    .basketmini__title {
        font-size: 11px;
    }

    .basketmini__price {
        font-size: 20px;
        letter-spacing: -0.08px;
    }

    .basketmini__currency {
        padding-left: 2px;

        font-size: 19px;
        letter-spacing: -0.05px;
    }

    .basketmini__icon {
        min-width: 50px;
        height: 50px;
    }

    .basketmini__basket-icon {
        left: -2px;
        top: 4px;
    }

    .basketmini__count {
        top: 8px;
        right: 7px;

        font-size: 11px;
        letter-spacing: -0.47px;
    }
}

@media (min-width: 1920px) {
    .basketmini {
        width: 250px;
        height: 62px;
        padding: 0;
        top: 2px;
    }

    .basketmini--wrap {
        margin-left: 49px;
        margin-top: -2px;
    }

    .basketmini__title {
        font-size: 14px;
        letter-spacing: 0.03px;
    }

    .basketmini__price {
        font-size: 24px;
        letter-spacing: -0.09px;
        line-height: 1;
    }

    .basketmini__currency {
        padding-left: 4px;

        font-size: 23px;
        letter-spacing: -0.05px;
    }

    .basketmini__icon {
        min-width: 62px;
        height: 62px;
    }

    .basketmini__basket-icon {
        left: -1px;
        top: 4px;

        svg {
            height: 29px;
            width: 29px;
        }
    }

    .basketmini__count {
        right: 10px;
        top: 9px;
        min-width: 21px;

        font-size: 13px;
        letter-spacing: -0.56px;
    }
}
