.image-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    border-radius: inherit;
}

.image {
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.contain {
    object-fit: contain;
}

.cover {
    object-fit: cover;
}
