@import '~styles/variables/index';

.timeSelectContainer {
    @include all-props((
        position: relative,
        background: var(--color__coconut),
        border-radius: 16px,
        margin: (
            0: 16px 0,
            375: 24px 0,
            1024: 24px 0,
        ),
    ));
}

.timeSelectContainerDisabled {
    @include all-props((
        margin: (
            0: 16px 0 0,
            375: 24px 0 0,
            1024: 24px 0 0,
        ),
    ))
}

.timeSelectListContainer {
    @include all-props((
        padding: 0px 20px,
    ));

    &.timeSelectListContainerLimited {
        @include desktop-props((
            position: relative,
            max-height: 326px,
            overflow-y: scroll,
            overflow-x: visible,
        ));

        &::-webkit-scrollbar { // chrome, safari, opera
            @include desktop-props((
                display: none,
            ));
        }
    }
}

.timeSelectListContainerShadow {
    @include desktop-props((
        position: absolute,
        bottom: 0,
        left: 0,
        width: 100%,
        height: 54px,
        background: linear-gradient(346deg, #FFF 35.08%, rgba(255, 255, 255, 0.44) 115.35%, rgba(255, 255, 255, 0.00) 227.39%),
        pointer-events: none,
    ));
}

.responisveContainer {
    .timeSelectContainer {
        @include responsive-desktop-props(1536px, (
            margin-top: 20px,
        ));
        @include desktop-props((
            margin-bottom: 0,
        ));
    }

    .timeSelectListContainer {
        @include desktop-props((
            padding: 0,
        ));

        &.timeSelectListContainerLimited {
            @include responsive-desktop-props(1536px, (
                max-height: 326px,
            ));
        }

        &.timeSelectListContainerHasScroll {
            @include desktop-props((
                box-sizing: border-box,
            ));
            @include responsive-desktop-props(1536px, (
                margin-bottom: 8px,
            ))
        }
    }

    .timeSelectListContainerShadow {
        @include responsive-desktop-props(1536px, (
            height: 54px,
        ));
    }
}
