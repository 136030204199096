@import "~global/styles/cool-media-queries/index.global.scss";

.newSpaHeaderRoot {
    @include desktop-props((
        display: flex,
        justify-content: space-between,
    ));
}
/* FRNT-3379 */
.profileRoot {
    @include responsive-desktop-props(1536px, (
        padding-top: 17px,
        padding-bottom: 19px,
    ));
}

.menuRoot {
    @include desktop-props((
        padding: (
            1024: 12px 0,
            1366: 15px 0,
            1680: 16px 0,
        ),
    ));
}

.newSpaHeaderNavigation {
    @include mobile-props((
        display: flex,
        align-items: center,
    ));

    @include desktop-props((
        display: flex,
        align-items: center,
        gap: (
            1024: 18px,
            1366: 20px,
        ),
    ));
    & :global(.header-city-lever) {
        padding: 0;
    }
}

.scalingNav {
    @include responsive-desktop-props(1536px, (
        gap: 21px,
    ));
}

// .profile {
//     gap: 0;
// }

.newSpaHeaderLogoWrapper {
    @include desktop-props((
        display: flex,
        width: (
            1024: 200px,
            1366: 248px,
            1680: 250px,
        ),
    ));


    & img {
        width: inherit;
    }

    &.scaling {
        @include responsive-desktop-props(1536px, (
            width: 248px,
        ));
    }
}

.newSpaHeaderLogoItem {
    @include all-props((
        width: inherit,
    ));
}

.newSpaHeaderAvatarIcon {
    @include desktop-props((
        width: (
            1024: 40px,
            1366: 44px,
            1680: 48px,
        ),
        height: (
            1024: 40px,
            1366: 44px,
            1680: 48px,
        ),
    ));
}
