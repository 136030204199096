@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";

.deliveryZoneTimeAndPriceRoot {
    @include all-props((
        padding: (
            0: 16px 20px 0 20px,
            375: 24px 20px 0 20px,
            1024: 24px 20px 0 20px,
        ),
    ));
}

.deliveryPriceRow {
    @include all-props((
        padding-bottom: (
            0: 14px,
            375: 20px,
            1024: 20px,
        ),
    ));
}

.deliveryPriceRowTitle {
    @include all-props((
        color: #4F4F4F,
        font-weight: (
            0: 700,
            1024: 600,
        ),
        letter-spacing: -0.2px,
        font-family: $gilroy,
        padding-bottom: (
            375: 2px,
            1024: 2px,
        ),
        line-height: (
            0: 20px,
            1024: 20px,
        ),
        font-size: (
            0: 18px,
            375: 19px,
            1024: 19px,
        ),

    ));
}

.deliveryPriceRowValue {
    @include all-props((
        color: #9C9C9C,
        font-weight: (
            0: 500,
            1024: 400,
        ),
        letter-spacing: -0.2px,
        font-family: $gilroy,
        line-height: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
        font-size: (
            0: 15px,
            1024: 16px,
        ),

    ));
}

/* DeliveryGowfoodDaysRow */

.deliveryGowfoodDaysRowRoot {
    @include all-props((
        padding-bottom: (
            0: 14px,
            375: 18px,
            1024: 18px,
        ),
    ));

}

.deliveryGowfoodDaysRowTitle {
    @include all-props((
        color: #4F4F4F,
        font-weight: 700,
        letter-spacing: -0.2px,
        font-family: $gilroy,
        padding-bottom: (
            0: 6px,
            375: 8px,
            1024: 8px,
        ),
        line-height: (
            0: 20px,
            1024: 20px,
        ),
        font-size: (
            0: 18px,
            375: 19px,
            1024: 19px,
        ),

    ));
}

.deliveryGowfoodDaysRowDays {
    @include all-props((
        display: flex,
        gap: (
            0: 8px,
            375: 11px,
            1024: 11px,
        )
    ));
}

.deliveryGowfoodDaysRowDaysItem {
    @include all-props((
        width: (
            0: 33px,
            375: 38px,
            1024: 38px,
        ),
        height: (
            0: 28px,
            375: 38px,
            1024: 38px,
        ),
        border: 1px solid var(--color__salt),
        background-color: var(--color__onion),
        color: var(--color__pepper),
        display: flex,
        justify-content: center,
        align-items: center,
        font-family: $gilroy,
        border-radius: (
            0: 4px,
            375: 8px,
            1024: 8px,
        ),
        font-weight: (
            0: 600,
            1024: 500,
        ),
        font-size: (
            0: 15px,
            375: 16px,
            1024: 16px,
        ),
        line-height: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
        letter-spacing: -0.2px,
    ));
}

/* deliveryTimeIntervalsRoot */

.deliveryTimeIntervalsRoot {
    @include all-props((
        padding-bottom: (
            0: 22px,
            375: 24px,
            1024: 24px,
        ),
    ));
}

.deliveryTimeIntervalsSection {
    @include all-props((
        padding-bottom: (
            0: 14px,
            1024: 14px,
        ),

    ));
    &:nth-last-child(1) {
        padding: 0px;
    }

}

.deliveryTimeIntervalsSectionTime {
    @include all-props((
        font-family: $gilroy,
        font-weight: (
            0: 600,
            1024: 500,
        ),
        font-size: (
            0: 16px,
            375: 17px,
            1024: 17px,
        ),
        line-height: (
            0: 17px,
            375: 18px,
            1024: 18px,
        ),
        letter-spacing: -0.2px,
        color: var(--color__pepper),
        padding-bottom: (
            375: 2px,
            1024: 2px,
        ),
    ));
}

.deliveryTimeIntervalsSectionBorders {
    @include all-props((
        font-family: $gilroy,
        font-weight: (
            0: 500,
            1024: 400,
        ),
        font-size: (
            0: 15px,
            375: 16px,
            1024: 16px,
        ),
        line-height: (
            0: 16px,
            375: 18px,
            1024: 18px,
        ),
        letter-spacing: -0.2px,
        color: var(--color__hering),
    ));
}
