@import '~styles/variables/index';

$vertical-margin: (
    0: 8px,
    375: 10px,
    1024: 10px,
);


.step-address__title-container {
    @include all-props((
        text-align: center,
        margin-bottom: $vertical-margin,
    ));
}

.step-address__inputs-row {
    @include all-props((
        position: relative,
        display: flex,
        justify-content: space-between,
    ));

    & + & {
        @include all-props((
            margin-top: $vertical-margin,
        ))
    }
}

.step-address__inputs-row-item {
    @include all-props((
        width: 33%,
    ));

    & + & {
        @include all-props((
            margin-left: (
                0: 8px,
                375: 10px,
                1024: 10px,
            )
        ));
    }
}

.step-address__suggestions-input {
    @include all-props((
        position: relative,
        z-index: 3,
        width: 100%,
    ));
}

.step-address__suggestions {
    @include all-props((
        position: absolute,
        top: calc(100% - var(--sizeBorderRadiusXS)),
        left: 0,
        z-index: 2,
        width: 100%,
        padding: 12px 0,
        box-sizing: border-box,
        border: 1.5px solid var(--color__grey-salt),
        border-bottom-left-radius: var(--sizeBorderRadiusXS),
        border-bottom-right-radius: var(--sizeBorderRadiusXS),
        overflow: hidden,
        background-color: var(--color__coconut),
    ));

    @media (-webkit-max-device-pixel-ratio: 1.25) {
        border-width: 1px;
    }
}

.step-address__checkboxes {
    @include all-props((
        margin-top: (
            0: 10px,
            375: 12px,
            1024: 12px,
        ),
        margin-bottom: (
            0: 10px,
            360: 12px,
            1024: 12px,
        ),
    ));
}

.step-address__checkbox-item {
    @include all-props((
        display: flex,
        align-items: center,
        padding-left: 2px,
    ));
}

.step-address__checkbox-item-text {
    @include all-props((
        margin-left: (
            0: 8px,
            375: 10px,
            1024: 10px,
        ),
        font-family: $gilroy,
        font-weight: var(--font-weight__medium),
        color: var(--color__pepper),
        letter-spacing: -0.2px,
        font-size: (
            0: 15px,
            375: 16px,
            1024: 16px,
        ),
    ));
}

.step-address-notifcations-container {
    @include all-props((
        padding-top: (
            0: 10px,
            360: 12px,
            1024: 12px,
        ),
        border-top: 1px solid var(--color__grey-salt),
    ));
}

.step-address-notification {
    @include all-props((
        border-radius: var(--sizeBorderRadiusM),
        background-color: #F9F9F9,
        border: 1px solid var(--color__grey-salt),
        padding: (
            0:   11px 12px,
            375: 11px 14px,
            1024: 11px 14px,
        ),
        font-family: $gilroy,
        font-weight: var(--font-weight__semibold),
        letter-spacing: -0.2px,
        color: var(--color__coal),
        font-size: (
            0: 13px,
            375: 14px,
            1024: 14px,
        ),
        line-height: (
            0:   13px,
            375: 15px,
            1024: 15px,
        ),
    ));

    svg {
        @include all-props((
            position: relative,
            top: 2px,
            margin-right: 4px,
        ));
    }
}
