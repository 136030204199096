@import '~styles/variables/index';

.basket-preview__top-area__first-row {
    @include mobile-props((
        display: flex,
        justify-content: space-between,
        align-items: center,
        width: 100%,

        padding-bottom: (
            0:   6px,
            375: 6px,
            414: 14px,
        ),
    ));
}

.basket-preview__title {
    @include mobile-props((
        flex: 4,
    ));
}

.basket-preview__set-button {
    @include mobile-props((
        flex: 1,
        margin-left: (
            0:   14px,
            375: 20px,
            414: 24px,
        ),
        height: (
            0:   40px,
            375: 46px,
            414: 48px,
        ),
        width: (
            0:   86px,
            375: 98px,
            414: 106px,
        ),
    ));

    :global(.num) {
        letter-spacing: 0;
    }
}

.basket-preview__crossed {
    @include mobile-props((
        font-weight: 600,
        text-decoration: line-through,
    ));
}
