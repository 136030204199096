// @import '~styles/variables/index';
@import "~global/styles/cool-media-queries/index.global.scss";
@import '~styles/variables/colors.scss';
@import '~styles/variables/ui-colors.scss';

// Local viables

@import './_desktopVariables.scss';
@import './_mobileVariables.scss';

// Resert deafault <a> localy
/*
TODO: Нужно применить глобально. ~styles/variables/index' из-за это импорта компонент
вподает в рекурсию.
*/

a, a:visited, a:active {
    text-decoration: none;
    color: inherit;
}

//  Scope

:global {
@import '~app/components/ui/Button/button.scss';
@import '~app/components/ui/Paragraph/paragraph.scss';
@import '~app/views/Header/components/MobileNavRegionButton/index.scss';


.global-header--desktop {
    @include mobile-props((
        display: none,
    ));
    @include desktop-props((
        display: block,
    ));
}
.global-header--mobile {
    @include mobile-props((
        display: block,
    ));
    @include desktop-props((
        display: none,
    ));

}

// External classes

// Header layout classes
.global-header-wrapper {
    @include all-props((
        display: flex,
        justify-content: space-between,
        align-items: center,
        user-select: none,
        width: 100%,
    ));

    @include mobile-props((
        max-width: 444px,
    ));

    @include mobile-props((
        padding: (
            0: 8px 0 12px,
            444: 8px 0 12px,
        ),
    ));

    @include desktop-props((
        padding: (
            1024: 12px 0,
            1366: 15px 0,
            1680: 16px 0,
        ),
    ));
    &.index {
        @include mobile-props((
            position: fixed,
            top: 0,
            transform: (
                320:  translate(-16px),
                375:  translate(-20px),
                500:  translate(-20px),
            ),
            padding: (
                0: 10px 16px 12px,
                375: 12px 20px 13px,
                414: 10px 20px,
            ),
            max-width: 500px,
            background-color: #FFF,
            z-index: 10,
            box-sizing: border-box,
            margin: 0 auto,
        ));
    }
    .global-header-logo {
        display: inline-block;
    }
    .global-header-navigation--wrapper__static,
    .global-header-navigation--wrapper {
        display: flex;
        align-items: baseline,
    }
    .global-header-navigation {
        position: relative;
        display: flex;
        align-items: baseline,
    }
}

.global-header-logo-wrapper {
    display: flex;
}

/* Haeder component classes */
.header-sighin-button {
    @include all-props((
        display: inline-block,
        font-family: $gilroy,
    ));
    @include mobile-props((
        padding-left: 17px,
        padding-right: 3px,
    ));
    @include desktop-props((
        background-color: var(--color__fish),
        border-color: var(--color__fish),
        border-radius: 8px,
        padding: (
            1024: 5.5px 18px,
            1366: 5.5px 18px,
            1680: 8px 19px,
        ),
    ));

    .header-sighin-button__item {
        @include all-props((
            font-family: inherit,
            letter-spacing: -0.3px,
        ));
        @include mobile-props((
            font-weight: 700,
            font-size: $mobileHeaderFontSizeRange,
            color: #9c9c9c,
            text-decoration: underline,
            line-height: $mobileHeaderLineHeigthRange,
        ));
        @include desktop-props((
            font-weight: 500,
            font-size: $desktopHeaderFontSizeRange,
            color: var(--color__cuttlefish),
        ));

        @include responsive-desktop-props(1680px, (
            font-size: 17px,
            line-height: 20px,
        ))
    }

    &:hover {
        @include desktop-props((
            transition: all 0.1s,
            background-color: var(--color__salt),
        ));
    }
}

.global-header-period {
    @include all-props((
        display: (
            0: none,
            1024: block,
        ),
        padding-right: (
            1024: 15px,
            1366: 21px,
            1680: 25px,
            1920: 31px,
        ),
    ))
}

.header-city-lever {
    &.spaMode {
        padding: 0;
    }

    @include desktop-props((
        line-height: $desktopHeaderLineHeigthRange,
        font-family: $gilroy,
        display: flex,
        align-items: center,
    ));

    @include responsive-desktop-props(1680px, (
        padding-right: 22px,
    ));
}

.header__burger-arrow_icon {
    @include desktop-props((
        padding-left: 1px,
    ))
}

.header-city-lever__item {
    @include desktop-props((
        font-family: $gilroy,
        cursor: pointer,
        display: inline-block,
        line-height: inherit,
        font-size: (
            1024: 15px,
            1366: 15px,
            1680: 17px,
        ),
        letter-spacing: -0.3px,
        color: var(--color__hering),
        font-weight: 500,
    ));

    & span {
        font-family: inherit;
    }

    /* FRNT-3379 */
    // @include responsive-desktop-props(1536px, (
    //     font-size: 17px,
    // ));

    &:hover {
        color: var(--color__cuttlefish);
    }

    &.disabled {
        text-decoration: none,
    }

    &.selected--city__item {
        font-weight: 400;
        text-decoration: none;
        color: var(--color__cuttlefish);
        cursor: default;
    }

    a {
        font-family: $gilroy;
        text-decoration: underline;
        letter-spacing: -0.3px;
        color: var(--color__hering);
        font-weight: 400,
    };
    a:hover {
        color: var(--color__cuttlefish);
    }

    span {
        font-weight: 500,
    };
}

.header-city-lever__divider {
    @include desktop-props((
        display: inline-block,
        padding: (
            1024: 0 2px,
            1680: 0 4px,
        ),
        color: var(--color__hering),
    ));

    /* FRNT-3379 */
    @include responsive-desktop-props(1536px, (
        font-size: 17px,
    ));
};


//  ╌╌╌╌╌╌╌╌

.header-with-location____logo-link {
    @include all-props((
        display: flex,
        align-items: center,
    ));
    @include mobile-props((
        height: $mobileLogoHeights,
    ));
    @include desktop-props((
        height: (
            1024: 38px,
            1366: 47px,
            1680: 46px,
        ),
        width: (
            1024: 200px,
            1366: 248px,
            1680: 250px,
        ),
    ));

    @include responsive-desktop-props(1680px, (
        height: 44px,
    ));
}

.header-with-location____logo {
    @include all-props((
        width: 100%,
    ));
}
.header-with-location____logo--black-bg {
    display: none;
}

/* BURGER */
$burger-transition-step: 300ms;

#mobile-section {
    transition: margin 500ms;

    &.shifted {
        margin-top: 108px;

        @media (min-width: 360px) {
            margin-top: 118px;
        }
        @media (min-width: 375px) {
            margin-top: 125px;
        }
        @media (min-width: 414px) {
            margin-top: 153px;
        }
        @media (min-width: 1024px) {
            margin-top: 0;
        }
    }
}

.header__column {
    @include mobile-props((
        display: flex,
        align-items: center,
    ));
}
.hidden {
    visibility: hidden;
}
.header__burger {
    @include mobile-props((
        padding-left: 0,
        display: flex,
        align-items: center,
        margin: 0,
        border: none,
        outline: none,
        background: transparent,
        overflow: visible,
        line-height: $mobileLogoHeights,
        padding-right: (
            0: 5px,
            375: 8px,
        ),
    ));
}
.header__burger-icon {
    @include mobile-props((
        display: inline-block,
        overflow: visible,
        position: relative,
        width: (
            0: 26px,
            360: 28px,
            375: 29px,
            414: 34px,
        ),
        height: (
            0: 26px,
            360: 28px,
            375: 29px,
            414: 34px,
        ),
        fill: #424242,
    ));

    &.is-open {
        fill: var(--color__grey-salt);
    }

    .header__burger-bun-top {
        &.is-close {
            transform: translateY(0);
            opacity: 1;
        }
        &.is-opening {
            transition: transform $burger-transition-step;
            transform: translateY(4.8px);
        }
        &.is-open {
            transform: translateY(4.8px);
            opacity: 0;
        }
        &.is-closing {
            transition: transform $burger-transition-step $burger-transition-step, opacity 1ms $burger-transition-step;
            transform: translateY(0px);
            opacity: 1;
        }
    }

    .header__burger-bun-bottom {
        &.is-close {
            transform: translateY(0);
            opacity: 1;
        }
        &.is-opening {
            transition: transform $burger-transition-step;
            transform: translateY(-4.8px);
        }
        &.is-open {
            transform: translateY(-4.8px);
            opacity: 0;
        }
        &.is-closing {
            transition: transform $burger-transition-step $burger-transition-step, opacity 1ms $burger-transition-step;
            transform: translateY(0px);
            opacity: 1;
        }
    }
    .header__burger-close-1 {
        &.is-close {
            transform: rotate(0) scaleX(1);
            transform-origin: center;
        }
        &.is-opening {
            transition: transform $burger-transition-step $burger-transition-step;
            transform: rotate(45deg) scaleX(0.87);
            transform-origin: center;
        }
        &.is-open {
            transform: rotate(45deg) scaleX(0.87);
            transform-origin: center;
        }
        &.is-closing {
            transition: transform $burger-transition-step;
            transform: rotate(0) scaleX(1);
            transform-origin: center;
        }
    }
    .header__burger-close-2 {
        &.is-close {
            transform: rotate(0) scaleX(1);
            transform-origin: center;
        }
        &.is-opening {
            transition: transform $burger-transition-step $burger-transition-step;
            transform: rotate(-45deg) scaleX(0.87);
            transform-origin: center;
        }
        &.is-open {
            transform: rotate(-45deg) scaleX(0.87);
            transform-origin: center;
        }
        &.is-closing {
            transition: transform $burger-transition-step;
            transform: rotate(0) scaleX(1);
            transform-origin: center;
        }
    }
    .header__burger-patty {
        &.is-open {
            opacity: 0;
        }
    }
}
.header__logo {
    @include mobile-props((
        line-height: $mobileLogoHeights,
        display: flex,
        align-items: center,
    ))
}
.header__logo-pic {
    @include all-props((
        transition-property: opacity,
        transition-duration: 450ms,
    ));
    @include mobile-props((
        display: block,
        object-fit: contain,
        height: (
            0: 17px,
            375: 19.5px,
        ),
    ));

    &--short {
        @include all-props((
            opacity: 0,
            position: absolute,
            pointer-events: none,
        ))
    }
}
.black-bg {
    .header-city-lever .header-city-lever__item.selected--city__item {
        color: #fff,
    }

    .header-with-location____logo--white-bg {
        display: none;
    }
    .header-with-location____logo--black-bg {
        display: block;
    }

    .header__burger-icon {
        rect {
            fill: #fff;
        }
    }
}

.header-with-location____logo--ny-2023 {
    display: none;
}

.header-with-location____logo--ny-2023-bg {
    display: none;
}

.black-bg.ny-2023 {
    .header__logo-pic {
        @include mobile-props((
            filter: none,
        ))
    }

    .header-city-lever__item, .header-city-lever__divider {
        color: #49705D;
        span {
            font-weight: 400;
        }
    }
    .header-city-lever .header-city-lever__item.selected--city__item {
        color: #49705D;
        span {
            font-weight: 500;
        }
    }

    .button-text {
        color: #257450;
    }

    #city-select-mobile-button-wrapper {
        span {
            color: #257450;
        }

    #city-select-mobile-tri-item {
            path {
                fill: #257450;
            }
        }
    }

    .header-with-location____logo--white-bg {
        display: none;
    }
    .header-with-location____logo--black-bg {
        display: none;
    }
    .header-with-location____logo--ny-2023-bg {
        display: block;
    }

    .header-sighin-button__item {
        @include mobile-props((
            color: #257450,
        ));

        @include desktop-props((
            color: #93B698,
        ));
    }
    .header__burger-icon {
        rect {
            // fill: #fff;
            fill: #043E25;
        }
    }

    .header-sighin-button {
        @include desktop-props((
            background-color: #1C5525,
        ));
        &:hover {
            @include desktop-props((
                background-color: #1C5525,
            ));
        }
    }
}

.black-bg.ny-2023.black-bg--ny-sweets {
    .header__logo-pic {
        @include mobile-props((
            filter: none,
        ))
    }

    .header-with-location____logo--white-bg {
        display: none;
    }
    .header-with-location____logo--black-bg {
        display: none;
    }
    .header-with-location____logo--ny-2023-bg {
        display: block;
    }

    .header-sighin-button__item {
        @include mobile-props((
            color: #271515,
        ));

        @include desktop-props((
            color: #8E8585,
        ));
    }
    .header__burger-icon {
        rect {
            fill: #271515;
        }
    }

    .header-sighin-button {
        @include desktop-props((
            background-color: #331E1E,
        ));
        &:hover {
            @include desktop-props((
                background-color: #331E1E,
            ));
        }
    }
}

}
