@import '~styles/variables/index';

.basket__dishes-type-list-container {
    @include all-props((
        position: relative,
        padding: (
            0: 0 16px 0 16px,
            375: 0 20px 0 20px,
            414: 0 20px 0 20px,
        ),
        background: var(--color__coconut),
    ));

    &.is-on-front {
        @include all-props((
            z-index: 3,
        ));
    }

    &.small {
        @include mobile-props((
            padding: (
                0:   0 $basket-horizontal-paddings_320,
                375: 0 $basket-horizontal-paddings_375,
            ),
            border-top: 1px solid #f5f5f5,
        ));
    }
    &.trial {
        @include mobile-props(());
    }
}

.basket__dishes-type-list {
    @include all-props((
        position: relative,
        z-index: 2,
        top: 0,

        list-style: none,
        margin: 0,
        background: none,
        border: none,

        padding: 0,
        padding-top: (
            0:   16px,
            375: 24px,
            1024: 24px,
        ),
    ));

    &.small-top-indent {
        @include all-props((
            padding-top: (
                0:   10px,
                375: 13px,
                1024: 13px,
            ),
        ));
    }

    &.has-add-more-button {
        @include all-props((
            border-bottom-left-radius: 0px,
            border-bottom-right-radius: 0px,
        ));

        .basket__dishes-type-item:last-child {
            @include all-props((
                border-bottom-left-radius: 0px,
                border-bottom-right-radius: 0px,
                border-bottom: none,
            ));
        }
    }
}

.basket__dishes-type-list_hidden-categories {
    @include all-props((
        padding-top: 0,
    ))
}

:global {
    @keyframes addToBasketAnimateIn360 {
        0% {
            height: 0px;
            opacity: 0;
            transform: scale(0.9);
        }
        60% {
            height: 96px;
        }
        70% {
            opacity: 0.6;
        }

        95% {
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    @keyframes addToBasketAnimate375 {
        0% {
            height: 0px;
            opacity: 0;
            transform: scale(0.9);
        }
        60% {
            height: 110px;
        }
        70% {
            opacity: 0.6;
        }

        95% {
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
    @keyframes addToBasketAnimate414 {
        0% {
            height: 0px;
            opacity: 0;
            transform: scale(0.9);
        }
        60% {
            height: 114px;
        }
        70% {
            opacity: 0.6;
        }

        95% {
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
}

//  transition: width 2s, height 2s, background-color 2s, transform 2s;
.basket-dish-item__wrap {
    background-color: #fff;
    // opacity: 0;
    // transform: scale(1);
    // transition:
    //     opacity 400ms cubic-bezier(.25, .1, .25, 1) 300ms,
    //     transform 400ms cubic-bezier(.25, .1, .25, 1) 300ms;

    // &--collapsed {
    //     transition:
    //         opacity 400ms cubic-bezier(.25, .1, .25, 1),
    //         transform 400ms cubic-bezier(.25, .1, .25, 1);
    //         height: 0;
    //     opacity: 0;
    //     transform: scale(0.9);
    // }

    @media (min-width: 1024px) {
        /*
            В десктопной версии из-за transform-а карточка диша начинает перекрывать
            раскрытый селект порций у предыдущего диша
        */
        transform: none;
    }
    // transform: translateY(114px);

    &.basket-dish-added {
        opacity: 1;
        // animation: animateIn 5ms forwards;
    }
    &.basket-dish-adding {
        // animation-timing-function: cubic-bezier(.25, .1, .25, 1);
        @include mobile-props((
            animation: (
                0: addToBasketAnimateIn360 700ms forwards,
                375: addToBasketAnimate375 700ms forwards,
                414: addToBasketAnimate414 700ms forwards,
            ),
        ));
    }
}
