@import '~styles/variables/index';

.root {
    *, *::after, *::before {
        box-sizing: border-box;
    }
}

.root {
    @include mobile-props((
        margin-bottom: (
            0: 20px,
            375: 34px,
        ),
    ));
    @include responsive-mobile-props-limited(320px,(
        padding-top: 14px,
        padding-bottom: 18px
    ), 375px, 320px, 375px);
    @include responsive-mobile-props-limited(375px,(
        padding-top: 16px,
        padding-bottom: 20px
    ), 428px, 375px);
}

.sliderContainer {
    @include mobile-props((
        box-sizing: border-box,
        overflow-y: scroll,
        scrollbar-width: none,
        position: relative,
        padding-left: (
            0: 16px,
            375: 20px,
        ),
        padding-right: (
            0: 16px,
            375: 20px,
        ),
    ));

    &::-webkit-scrollbar {
        @include mobile-props((
            display: none,
        ));
    }
}

.stubContainer {
    @include mobile-props((
        display: flex,
    ));
}

.safariStub {
    @include mobile-props((
        box-sizing: border-box,
        width: (
            0: 16px,
            375: 20px,
        ),
        height: 100%,
    ));
}

.headerContainer {
    @include mobile-props((
        width: 100%,
        display: flex,
        justify-content: space-between,
        align-items: center,
        padding: (
            0: 0 16px,
            375: 0 20px,
        ),
        margin-bottom: 12px,
    ));
    @include responsive-mobile-props-limited(320px,(
        margin-bottom: 12px,
    ), 375px, 320px, 375px);
    @include responsive-mobile-props-limited(375px,(
        margin-bottom: 14px,
    ), 428px, 375px);
}

.headerTitle {
    @include mobile-props((
        font-family: Gilroy,
        color: #9C9C9C,
        font-weight: 600,
        font-style: normal,
        letter-spacing: -0.4px,
    ));
    @include responsive-mobile-props-limited(320px,(
        font-size: 16px,
        line-height: 18px,
    ), 375px, 320px, 375px);
    @include responsive-mobile-props-limited(375px,(
        font-size: 18px,
        line-height: 20px,
    ), 428px, 375px);
}

.tooltopIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltopIcon svg {
    @include responsive-mobile-props-limited(320px,(
        width: 18px,
        height: 18px,
    ));
}

.gridList {
    @include mobile-props((
        list-style: none,
        display: grid,
        grid-template-columns: 1fr 1fr,
        gap: (
            0: 10px,
            375: 15px,
        ),
        width: (
            0: 100%,
            428: 382px,
        ),
        margin: 0 auto,
    ));
}

.sliderList {
    @include mobile-props((
        list-style: none,
        display: flex,
        align-items: center,
        width: fit-content,
    ));
}

.listContainerForTwoSets {
    @include responsive-mobile-props-limited(320px,(
        gap: 12px,
    ), 375px, 0px, 375px);
    @include responsive-mobile-props-limited(375px,(
        gap: 15px,
    ), 428px, 375px);
}

.listContainerForMoreThreeSets {
    @include responsive-mobile-props-limited(320px,(
        gap: 8px,
    ), 375px, 0px, 375px);
    @include responsive-mobile-props-limited(375px,(
        gap: 10px,
    ), 428px, 375px);
}

.sliderCard {
    @include all-props((
        position: relative,
        overflow: hidden,
        object-fit: cover,
        object-position: center,
        background-size: cover,
        display: flex,
        flex-direction: column,
        justify-content: flex-end,
        align-items: flex-start,
    ));

    @include responsive-mobile-props-limited(320px,(
        width: 138px,
        height: 207px,
        padding: 12px,
        border-radius: 16px,
    ), 375px, 0px, 375px);
    @include responsive-mobile-props-limited(375px,(
        width: 160px,
        height: 240px,
        padding: 14px,
        border-radius: 18px,
    ), 428px, 375px);
}

.cutoutText {
    font-family: $haas-dsstd;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: black;
    background-color: white;
    mix-blend-mode: screen;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsive-mobile-props-limited(320px,(
        width: 61.5px,
        height: 20px,
        // padding-top: 3px,
        // padding-bottom: 3px,
        padding-left: 8px,
        padding-right: 8px,
        border-radius: 6px,
        font-size: 14px,
        line-height: 14px,
    ), 375px, 0px, 375px);

    @include responsive-mobile-props-limited(375px,(
        width: 64px,
        height: 22px,
        // padding-top: 3.5px,
        // padding-bottom: 3.5px,
        padding-left: 8px,
        padding-right: 8px,
        border-radius: 8px,
        font-size: 15px,
        line-height: 15px,
    ), 428px, 375px);

    @include mobile-props((
        line-height: (
            418: normal,
        ),
    ));

}

.cutoutTextWrapper {
    @include mobile-props((
        // display: flex,
        // align-items: center,
    ))
}

.priceText {
    // @include mobile-props((
    //     padding-top: (
    //         0: 0,
    //         354: 1px,
    //         357: 0px,
    //         387: 1px,
    //         390: 0px,
    //         414: 1px,
    //         417: 0px,
    //     ),
    // ))

    &::after {
        content: "₽";
        padding-left: 1px;
        font-family: $gilroy;
        font-weight: 600;
        letter-spacing: -0.057px;
    }
}

// .rub {
//     padding-left: 1px;
//     font-family: $gilroy;
//     font-weight: 600;
//     letter-spacing: -0.057px;
// }

.infoContainer {
    @include all-props((
        position: relative,
        width: 100%,
        height: 100%,
        display: flex,
        flex-direction: column,
        text-align: start,
    ));
}

.infoTextContainer {
    @include all-props((
        position: relative,
        z-index: 1,
        font-family: Gilroy,
        margin-top: auto,
    ));
    @include responsive-mobile-props-limited(320px, (
        padding-right: 9px,
    ), 375px, 0px, 375px);
    @include responsive-mobile-props-limited(375px,(
        padding-right: 10px,
    ), 428px, 375px);
}

.infoTitle {
    font-weight: 700;
    color: #FFFFFF;
    text-align: start;
    @include mobile-props((
        letter-spacing: -0.2px,
    ));
    @include responsive-mobile-props-limited(320px, (
        font-size: 16px,
        line-height: 16px,
    ), 375px, 0px, 375px);
    @include responsive-mobile-props-limited(375px,(
        font-size: 18px,
        line-height: 18px,
    ), 428px, 375px);
}

.infoDescription {
    font-weight: 500;
    color: #FFFFFF;
    text-align: start;
    @include mobile-props((
        letter-spacing: -0.2px,
    ));
    @include responsive-mobile-props-limited(320px, (
        margin-bottom: 6px,
        font-size: 14px,
        line-height: 14px,
    ), 375px, 0px, 375px);
    @include responsive-mobile-props-limited(375px,(
        margin-bottom: 6px,
        font-size: 15px,
        line-height: 15px,
    ), 428px, 375px);
}


.linkCard {
    @include mobile-props((
        display: flex,
        flex-direction: column,
        justify-content: flex-start,
        align-items: center,
        overflow: hidden,
        background-color: #F6F6F6,
    ));

    @include responsive-mobile-props-limited(320px,(
        width: 138px,
        height: 207px,
        gap: 6px,
        border-radius: 16px,
    ), 375px, 0px, 375px);
    @include responsive-mobile-props-limited(375px,(
        width: 160px,
        height: 240px,
        gap: 8px,
        border-radius: 18px,
    ), 428px, 375px);
}

.linkTitle {
    @include mobile-props((
        width: auto,
        font-family: Gilroy,
        color: #9C9C9C,
        font-weight: 500,
        font-style: normal,
        letter-spacing: -0.4px,
        text-align: center,
    ));

    @include responsive-mobile-props-limited(320px,(
        margin-top: 62px,
        font-size: 14px,
        line-height: 14px,
    ), 375px, 0px, 375px);
    @include responsive-mobile-props-limited(375px,(
        margin-top: 86px,
        font-size: 15px,
        line-height: 15px,
    ), 428px, 375px);
}

.linkButton {
    @include mobile-props((
        font-family: Gilroy,
        color: #9C9C9C,
        font-weight: 600,
        font-style: normal,
        text-align: center,
        border: 1px solid #DBDBDB,
    ));

    @include responsive-mobile-props-limited(320px,(
        padding-top: 8px,
        padding-bottom: 8px,
        padding-left: 10px,
        padding-right: 10px,
        font-size: 11px,
        line-height: 12px,
        border-radius: 8px,
    ), 375px, 0px, 375px);
    @include responsive-mobile-props-limited(375px,(
        padding-top: 8px,
        padding-bottom: 8px,
        padding-left: 10px,
        padding-right: 10px,
        font-size: 13px,
        line-height: 14px,
        border-radius: 8px,
    ), 428px, 375px);
}

.green {
    background-image: url('./img/green.jpg');
};
.profitable {
    background-image: url('./img/profitable.jpg');
};
.top {
    background-image: url('./img/top.jpg');
};
.halloween {
    background-image: url('./img/halloween.jpg');
}

.cardShield {
    position: absolute;
    @include responsive-mobile-props-limited(320px, (
        width: 51px,
        height: 20px,
        top: 8px,
        left: 10px,
    ), 375px, 0px, 375px);
    @include responsive-mobile-props-limited(375px, (
        width: 64px,
        height: 22px,
        top: 10px,
        left: 12px,
    ), 428px, 375px);
    svg {
        width: 100%;
        height: 100%
    }
}

.cardShieldSmall {
    @include mobile-props((
        display: (
            0: block,
            375: none,
        )
    ))
}

.cardShieldMedium {
    @include mobile-props((
        display: (
            0: none,
            375: block,
        )
    ))
}
