@import '~styles/variables/index';

.category__title-container {
    @include all-props((
        display: flex,
    ));
    @include mobile-props((
        align-items: center,
        justify-content: center,

        height: auto,
        padding: (
            0:   0 16px,
            375: 0 20px,
        ),
        margin-bottom: (
            0:   10px,
            360: 10px,
            375: 13px,
            414: 16px,
        ),

        transition: opacity 300ms,
    ));
    @include desktop-props((
        flex-direction: column,
        justify-content: flex-end,
        align-items: flex-start,

        padding-bottom: 3px,
    ));

    &.title-updating {
        @include mobile-props((
            opacity: 0,
        ));
    }
}

.category__title {
    @include desktop_960 {
        margin-right: $v3-res960-gird-gap;

        font-family: $gilroy-semibold;
        color: var(--color__cuttlefish);
        text-align: left;
        font-size: 26px;
        letter-spacing: -1.11px;
    }
    @include desktop_1024 {
        margin-right: $v3-res1024-gird-gap;

        font-size: 28px;
        letter-spacing: -1.18px;
    }

    @include desktop_1280 {
        margin-right: $v3-res1280-gird-gap;

        font-size: 34px;
        letter-spacing: -1.44px;
    }

    @include desktop_1366 {
        margin-right: $v3-res1366-gird-gap;

        font-size: 35px;
        letter-spacing: -1.51px;
    }

    @include desktop_1600 {
        margin-right: $v3-res1600-gird-gap;

        font-size: 37px;
        letter-spacing: -1.58px;
    }

    @include desktop_1920 {
        margin-right: $v3-res1920-gird-gap;

        font-size: 41px;
        letter-spacing: -1.77px;
    }
}

.dishes-container {
    @include mobile_320 {
        position: relative;
    }
    @include desktop_960 {
        position: relative;
    }
}

.pixel-for-scroll {
    position: absolute;
    z-index: -1;
    height: 1px;
    width: 1px;

    @include desktop-props((
        top: (
            1024: -100px,
            1280: -120px,
            1366: -120px,
            1440: -100px,
            1920: -80px,
        )
    ))
}

// TODO: remove
.dishes-list {
    @include all-props((
        list-style: none,
        display: flex,
        flex-flow: row wrap,
    ));
    @include mobile-props((
        margin: 0 auto,
        max-width: (
            412: 424px,
        ),
        padding: 0,
    ));
    @include desktop-props((
        margin: 0 auto,
        margin-top: (
            1024:  20px,
            1280: 26px,
        ),
        padding: 0,
    ));
}

