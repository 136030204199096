@import "~global/styles/cool-media-queries/index.global.scss";
@import '~styles/variables/index';
@import "~global/styles/fonts/index.scss";

.subscription-info-popup {
    @include all-props((
        background-color: var(--color__coconut),
        position: fixed,
        z-index: 3000,
        right: 0,
        top: 0,
        bottom: 0,
        width: (
            0: 100%,
            1024: 452px,
        ),
        display: flex,
        flex-direction: column,
    ));

    @include desktop-props((
        will-change: right,
        box-shadow: 0 2px 30px 0 rgba(198, 198, 198, 0.29),
    ));
}

.subscription-info-popup {
    &.step-by-step-checkout {
        @include mobile-props((
            border-top-left-radius: var(--sizeBorderRadiusXXL),
            border-top-right-radius: var(--sizeBorderRadiusXXL),
            top: (
                0: 73px,
                375:   78px,
            ),
            box-shadow: 3px -4px 16px rgba(0, 0, 0, 0.05),
        ));
    }
}

.popup-header {
    @include all-props((
        display: flex,
        flex-direction: row,
        align-items: center,
        justify-content: flex-end,
        min-height: (
            0: 52px,
            375: 60px,
            1024: 60px,
        ),
        // box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.04),
    ));
}

.popup-header {
    &.step-by-step-checkout {
        border-bottom: 1px solid var(--color__salt);
    }
}

.popup-header__close {
    @include all-props((
        display: flex,
        flex-direction: row,
        align-items: center,
        height: 100%,
        outline: none,
        background-color: var(--color__coconut),
        width: 30%,
        padding-right: 22px,
        justify-content: flex-end,
    ));

    svg {
        @include all-props((
            width: 25px,
            height: 25px,
        ));
    }
}

.popup-content {
    // @include all-props((
    //     padding: (
    //         0: 30px 20px,
    //         1024: 30px 20px,
    //     ),
    // ));
    @include all-props((
        overflow-y: scroll,
    ));

    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
}

.popup-content::-webkit-scrollbar {
    @include all-props((
        display: none,
    ));
}

.popup-content__main-title {
    @include mobile-props((
        margin-bottom: (
            0: 20px,
            375: 23px,
            1024: 23px,
        ),
        color: var(--color__cuttlefish),
    ));
}

.popup-content-message {
    @include all-props((
        margin-bottom: (
            0: 20px,
            375: 23px,
            1024: 23px,
        )
    ));
}

.popup-content-message__title {
    @include all-props((
        color: #4F4F4F,
        padding-bottom: 3px,
        font-family: $gilroy,
        font-weight: 700,
        font-size: (
            0: 17px,
            375: 18px,
            1024: 18px,
        ),
        letter-spacing: -0.2px,
        line-height: (
            0: 18px,
            375: 19px,
            1024: 19px,
        ),
    ));
}

.popup-content-message__text {
    @include all-props((
        font-family: $gilroy,
        font-weight: var(--font-weight__regular) !important,
        color: $emperor,
        line-height: (
            0: 17px,
            375: 18px,
            1024: 18px,
        ),
    ));
}

.popup--num {
    font-family: $n-haas-disp;
    font-weight: 400;
}
