@import "~global/styles/cool-media-queries/index.global.scss";

.parter-shield-root {
    position: absolute;
    @include all-props((
        width: (
            0: 23.3%,
            1024: 16.6%,
        ),
        top: (
            0: 14px,
            1024: 21px,
        ),
        right: (
            0: 14px,
            1024: 21px,
        ),
    ));
    &.details {
        @include all-props((
            top: (
                0: 16px,
                1024: 26px,
            ),
            right: (
                0: 16px,
                1024: 26px,
            ),
        ));

    }
    &.basket {
        @include all-props((
            width: (
                0: 28%,
                1024: 33.3%,
            ),
            top: (
                0: 6px,
                1024: 6px,
            ),
            right: (
                0: 6px,
                1024: 6px,
            ),
        ))
    }
}

.parter-logo-root {
    width: 100%;
    height: 100%;
}

.parter-logo-img {
    width: inherit;
    height: inherit;
    object-fit: contain;
    @include all-props((
        border-radius: (
            0: 3px,
            1024: 6px,
        ),

    ));
    &.basket {
        @include all-props((
            border-radius: (
                0: 2px,
             1024: 3px,
            )
        ))
    }
}
