$font-path: '../../assets/fonts';
$font-name: 'HaasGrotDisp';


@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-15XXThin.woff") format('woff');
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-25XThin.woff") format('woff');
  font-display: swap;
  font-weight: 200;
}

@font-face {
  font-family: $font-name;
  src: url("#{$font-path}/#{$font-name}-35Thin.woff") format('woff');
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-45Light.woff") format('woff');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-55Roman.woff") format('woff');
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-65Medium.woff") format('woff');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-75Bold.woff") format('woff');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: $font-name;
  src:
    url("#{$font-path}/#{$font-name}-95Black.woff") format('woff');
  font-display: swap;
  font-weight: 900;
}
