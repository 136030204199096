@import '~styles/variables/index';


.filter-item-wrap {
    display: flex;
    flex-direction: column;

    transition: margin 500ms;

    @media (min-width: 1280px) {
        margin-bottom: 23px;

        &.is-expanded {
            margin-bottom: 15px;
        }
    }
}

.filter-item {
    position: relative;
    z-index: 2;
    display: flex;
    padding: 22px 20px 22px 0;
    text-align: left;
    cursor: pointer;
    border: none;
    background: transparent;

    &.is-expanded {
        .filter-item__text {
            @media (min-width: 1024px) {
                transform: translate(0, 4px);
            }
            @media (min-width: 1280px) {
                transform: translate(-$v3-res1280-gird-gap, 4px);
            }
            @media (min-width: 1366px) {
                transform: translate(-46px, 4px);
            }
            @media (min-width: 1440px) {
                // transform: translate(-$v3-res1440-gird-gap, 4px);
            }
            @media (min-width: 1600px) {
                // transform: translate(-$v3-res1600-gird-gap, 4px);
            }
            @media (min-width: 1920px) {
                transform: translate(-37px, 0px);
                // transform: translate(-$v3-res1920-gird-gap, 4px);
            }
        }
    }

    @media (min-width: 1024px) {
        padding-left: $v3-res960-gird-gap;
    }
    @media (min-width: 1024px) {
        padding-left: $v3-res1024-gird-gap;
    }
    @media (min-width: 1280px) {
        padding-left: $v3-res1280-gird-gap;
        padding-right: 20px;
        padding-bottom: 1px;
    }
    @media (min-width: 1366px) {
        margin-bottom: 7px;
        padding-left: $v3-res1366-gird-gap;
        &.active {
            padding-bottom: 25px;
        }
    }
    @media (min-width: 1440px) {
       padding-left: $v3-res1440-gird-gap;
    }
    @media (min-width: 1600px) {
        padding-left: $v3-res1600-gird-gap;
    }
    @media (min-width: 1920px) {
    //    padding-left: $v3-res1920-gird-gap;
       padding-left: $v3-res1600-gird-gap;
       margin-bottom: 19px;
    }
}

.filter-item__content-wrap {
    position: relative;
    z-index: 3;
    opacity: 1;

    transition: opacity 400ms ease-in-out, margin 400ms ease-in-out;

    &.is-title-hidden {
        opacity: 0;
        margin-top: -54px;
        margin-bottom: 54px;
    }

    @media (min-width: 1280px) {
        &.is-title-hidden {
            margin-top: -32px;
            margin-bottom: 32px;
        }
    }

    @media (min-width: 1366px) {
        &.is-title-hidden {
            margin-top: -40px;
            margin-bottom: 40px;
        }
    }

    @media (min-width: 1920px) {
        &.is-title-hidden {
            margin-top: -65px;
            margin-bottom: 65px;
        }
    }

    &.is-expanded {
        opacity: 1;
        margin-bottom: 0px;
    }
}

.filter__icon {
    display: none;

    opacity: 1;

    &.entering {
        opacity: 0;
        transition: opacity 200ms;
    }
    &.entered {
        opacity: 0;
    }
    &.exiting {
        opacity: 1;
        transition: opacity 200ms ease 200ms;
    }
    &.exited {
        opacity: 1;
    }

    @media (min-width: 1280px) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 18px;
    }

    @media (min-width: 1366px) {
        margin-right: 22px;
    }

    @media (min-width: 1920px) {
        svg {
            transform: scale(1.1);
        }
    }
}

.filter-item__arrow {
    position: absolute;
    right: 23px;
    top: 30px;
    width: 10px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    transform: rotate(0deg);
    transition: transform 250ms, right 250ms, top 250ms;

    &--expanded {
        transform: rotate(90deg);
        right: 23px;
        top: 28px;
    }

    @media (min-width: 1366px) {
        top: 34px;
        right: 32px;
        &--expanded {
            top: 28px;
        }
    }

    @media (min-width: 1600px) {
        // right: 55px;
    }

    @media (min-width: 1920px) {
        right: 42px;
        transform: scale(1.5);

        &--expanded {
            transform: scale(1.5) rotate(90deg);
        }
    }
}

.filter-item__text {
    align-items: center;
    width: 100%;
    transition: transform 400ms;
    transform: translate(0, 0);
}

.filter-item__name {
    margin-top: -3px;
    color: var(--color__cuttlefish);
    font-family: $gilroy-bold;
    font-size: 16px;
    letter-spacing: -0.12px;

    opacity: 1;
    transition: opacity 400ms;

    &.entering {
        opacity: 0;
        transition: opacity 200ms;
    }
    &.entered {
        opacity: 0;
    }
    &.exiting {
        opacity: 1;
        transition: opacity 200ms ease 200ms;
    }
    &.exited {
        opacity: 1;
    }

    @media (min-width: 1280px) {
        font-size: 17px;
        letter-spacing: -0.31px;
    }

    @media (min-width: 1600px) {
        font-size: 18px;
        letter-spacing: -0.33px;
    }

    @media (min-width: 1920px) {
        font-size: 20px;
        letter-spacing: -0.36px;
    }
}

.filter-item__subtitle {
    color: var(--color__hering);
    font-family: $gilroy-light;
    font-size: 14px;

    opacity: 1;
    transition: opacity 400ms;

    &.entering {
        opacity: 0;
        transition: opacity 200ms;
    }
    &.entered {
        opacity: 0;
    }
    &.exiting {
        opacity: 1;
        transition: opacity 200ms ease 200ms;
    }
    &.exited {
        opacity: 1;
    }

    @media (min-width: 1280px) {
        margin-top: -3px;
    }

    @media (min-width: 1600px) {
        font-size: 15px;
    }

    @media (min-width: 1920px) {
        margin-top: -1px;
        font-size: 17px;
    }
}
