$font-path: '../../assets/fonts';

// @font-face {
//   font-family: 'Gilroy';
//   src:
//     url("#{$font-path}/Gilroy-Thin.woff") format('woff'),
//     url("#{$font-path}/Gilroy-Thin.woff") format('woff');
//   font-display: swap;
//   font-weight: 100;
// }

// @font-face {
//   font-family: 'Gilroy';
//   src:
//     url("#{$font-path}/Gilroy-UltraLight.woff") format('woff');
//   font-display: swap;
//   font-weight: 200;
// }

// @font-face {
//   font-family: 'Gilroy';
//   src: url("#{$font-path}/gilroy-light.woff") format('woff');
//   font-display: swap;
//   font-weight: 300;
// }

// @font-face {
//   font-family: 'Gilroy';
//   src:
//     url("#{$font-path}/gilroy-regular.woff") format('woff');
//   font-display: swap;
//   font-weight: 400;
// }

// @font-face {
//   font-family: 'Gilroy';
//   src:
//     url("#{$font-path}/gilroy-medium.woff") format('woff');
//   font-display: swap;
//   font-weight: 500;
// }

// @font-face {
//   font-family: 'Gilroy';
//   src:
//     url("#{$font-path}/gilroy-semibold.woff") format('woff');
//   font-display: swap;
//   font-weight: 600;
// }

@font-face {
  font-family: 'ProximaNova';
  src:
    url("#{$font-path}/ProximaNova-Bold.woff") format('woff');
  font-display: swap;
  font-weight: 700;
}

// @font-face {
//   font-family: 'Gilroy';
//   src:
//     url("#{$font-path}/gilroy-extrabold.woff") format('woff');
//   font-display: swap;
//   font-weight: 800;
// }

// @font-face {
//   font-family: 'Gilroy';
//   src:
//     url("#{$font-path}/Gilroy-Black.woff") format('woff');
//   font-display: swap;
//   font-weight: 900;
// }
