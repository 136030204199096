@import '~styles/variables/index';

.checkmark {
    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    width: 100%;
    height: 100%;

    background: var(--color__coconut);
    border: 1px solid var(--color__salt);
    border-radius: 2px;

    &.checked {
        border: 1px solid $kournikova;
        border-radius: 3px;
        background: $kournikova;
    }
    &.checked.disabled {
        border: 1px solid $buttermilk;
        border-radius: 3px;
        background: $buttermilk;
    }

    svg {
        display: inline-block;
        width: 90%;
    }

    @media (min-width: 1600px) {
        width: 22px;
        height: 22px;
    }

    &.version-uikit,
    &.version-uikit-responsive {
        @include all-props((
            border: 2px solid var(--color__grey-salt),
            border-radius: var(--sizeBorderRadiusXXXS),
        ));

        &.disabled {
            @include all-props((
                border-color: var(--color__salt),
            ));
        }
        &.checked {
            @include all-props((
                border-color: var(--color__egg),
                background-color: var(--color__egg),
            ));
        }
        &.checked.disabled {
            @include all-props((
                border-color: var(--color__melon),
                background-color: var(--color__melon),
            ));
        }

        svg {
            position: relative;
            left: 1px;
        }
    }
}
