@import '~styles/variables/index';


$animation-time: 500ms;


.dialog__root {
    @include all-props((
        position: fixed,
        right: 0,
        left: 0,
        bottom: 0,
        z-index: 5000,

        margin: 0 auto,
        background-color: var(--color__coconut),
        box-shadow: 3px -4px 16px rgba(0, 0, 0, 0.05),
        text-align: center,

        transform: translateY(0%),
        border-radius: (
            0: var(--sizeBorderRadiusXL) var(--sizeBorderRadiusXL) 0 0,
            375: var(--sizeBorderRadiusXXL) var(--sizeBorderRadiusXXL) 0 0,
        ),
    ));
    @include mobile-props((
        padding-bottom: (
            0:   44px,
            375: 52px,
        ),
        max-width: 444px,
        margin: 0 auto,

    ));
    @include desktop-props((
        top: 30%,
        bottom: auto,
        max-width: 500px,

        padding-bottom: (
            1024: 30px,
            1366: 34px,
            1680: 40px,
            1920: 48px,
        ),
        min-width: (
            1024: 548px,
            1366: 560px,
            1680: 660px,
            1920: 716px,
        ),

        border-radius: 13px,
        box-shadow: none,
    ));
}

.custom_dialog__root {
    @include mobile-props((
        display: flex,
        flex-direction: column,
        height: 100dvh,
        border-radius: 0,
        padding-bottom: 0,
    ))
}

.dialog__header {
    @include all-props((
        display: flex,
        justify-content: flex-end,
    ));
    @include mobile-props((
        padding: (
            0:   14px 16px 14px,
            375: 14px 20px,
        ),

        background-color: var(--color__coconut),
        border-bottom: 1px solid var(--color__salt),
        border-radius: (
            0: var(--sizeBorderRadiusXL) var(--sizeBorderRadiusXL) 0 0,
            375: var(--sizeBorderRadiusXXL) var(--sizeBorderRadiusXXL) 0 0,
        ),
    ));
    @include desktop-props((
        position: absolute,
        bottom: 100%,
        left: 0,
        right: 0,
        padding-right: 0,
        padding-left: 0,

        background-color: transparent,
    ));
}

.custom_dialog__header {
    @include mobile-props((
        padding: (
            0:   14px 16px 14px,
            375: 14px 20px 13px,
        ),
        box-shadow: 0px 2px 28px 0px rgba(0, 0, 0, 0.04),
        border-radius: 0
    ))
}

.dialog__close-button {
    // reset default button style
    @include all-props((
        border: none,
        outline: none,
        margin: 0,
        padding: 0,
        width: auto,
        overflow: visible,
        background: transparent,
        text-align: unset,
        transform: (
            0: translate(-5px, -3px),
            375: translate(-1px),
        ),
    ));

    $icon-side-sizes: (
        0:    26px,
        375:  26px,
        414:  26px,
        1024: 26px,
    );
    @include all-props((
        cursor: pointer,
        height:  $icon-side-sizes,
        width: $icon-side-sizes,
    ));

    &:disabled {
        @include all-props((
            pointer-events: none,
            opacity: 0,
        ));
    }

    g {
        @include mobile-props((
            fill: #f1f1f1,
        ));
    }
}

.city_select_dialog__close-button {
    @include mobile-props((
        width: (
            0: 24px,
            375: 28px,
        ),
        height: (
            0: 24px,
            375: 28px,
        )
    ))
}

.dialog__title-container {
    @include all-props((
        background-color: var(--color__coconut),
    ));
    @include mobile-props((
        padding: (
            0:   22px 20px 8px,
            375: 24px 22px 9px,
            414: 24px 32px 14px,
        ),
    ));
    @include desktop-props((
        padding: (
            1024:  0 30px 24px,
            1280: 0 34px 28px,
            1680: 0 40px 28px,
            1920: 0 48px 30px,
        ),
        border-radius: (
            1024:  11px,
            1280: 16px,
        ),
    ));
}

.dialog__regular-text-container {
    @include all-props((
        display: flex,
        justify-content: center,
    ));
    @include mobile-props((
        flex-direction: column,
        transform: (
            0:   translateY(-9px),
            414: translateY(-10px),
        ),
    ));
    @include desktop-props((
        flex-direction: row,
    ));
}

.dialog__strong-text {
    @include mobile-props((
        margin: (
            0:   7px,
            375: 9px,
            414: 10px,
        ),
    ));
    @include desktop-props((
        padding-top: (
            1024:  30px,
            1366: 34px,
            1680: 40px,
            1920: 50px,
        ),
        padding-bottom: 2px,

        font-family: $gilroy,
        font-weight: 700,
        font-size: (
            1024: 25px,
            1280: 27px,
            1366: 29px,
            1440: 30px,
            1680: 33px,
            1920: 34px,
        ),
        letter-spacing: -0.7px,
    ))
}

// Усиление селектора, чтобы перебить стили параграфа
.dialog__root {
    .dialog__regular-text,
    .dialog__extra-text {
        @include mobile-props((
            font-weight: 400,
            font-size: (
                0: 17px,
                375: 18px,
            ),
        ));
        @include desktop-props((
            font-family: $gilroy,
            font-weight: 400,
            font-size: (
                1024: 18px,
                1366: 19px,
                1600: 21px,
            ),
            letter-spacing: -0.4px,
        ));
    }
}

.dialog__extra-text-container {
    @include all-props((
        color: var(--color__lichi),
    ));
    @include mobile-props((
        padding: (
            0:   16px 20px 0,
            375: 20px 22px 0,
        ),
    ));
    @include desktop-props((
        margin-bottom: (
            1024:  -12px,
            1366: -16px,
            1680: -20px,
        ),
        padding: (
            1024:  18px 30px 0 30px,
            1366: 20px 34px 0 34px,
            1680: 24px 40px 0 40px,
            1920: 26px 48px 0 48px,
        ),
    ));
}

.dialog__buttons-container {
    @include all-props((
        cursor: pointer,
        -webkit-tap-highlight-color: transparent,
    ));
    @include mobile-props((
        padding: (
            0:   0 20px 20px 20px,
            375: 0 22px 22px 22px,
            414: 0 32px 0,
        ),
    ));
    @include desktop-props((
        display: flex,
        padding: (
            1024:  0 30px 0px 30px,
            1366: 0 34px 0px 34px,
            1680: 0 40px 0px 40px,
            1920: 0 48px 0px 48px,
        ),
    ));

    & button {
        @include desktop-props((
            min-height: (
                1024: 48px,
                1280: 50px,
                1360: 52px,
                1680: 60px,
                1920: 66px,
            ),
        ));
    }
    & p {
        @include desktop-props((
            font-size: (
                1024: 19px,
                1680: 22px,
                1920: 23px,
            ),
        ));
    }

    &.one-row {
        @include mobile-props((
            display: flex,
            flex-direction: row,
            justify-content: center,
        ));

        & .dialog__button {
            @include mobile-props((
                max-width: (
                    0: calc(50% - 4px),
                    375: calc(50% - 5px),
                    414: calc(50% - 6px),
                ),
            ));
        }

        & .dialog__button:first-child {
            @include mobile-props((
                margin-right: (
                    0:   8px,
                    375: 11px,
                    414: 12px,
                ),
            ));
            @include desktop-props((
                margin-right: 16px,
            ));
        }
        & .dialog__button:last-child {
            @include mobile-props((
                margin-left: (
                    0:   8px,
                    375: 11px,
                    414: 12px,
                ),
            ));
            @include desktop-props((
                margin-left: 16px,
            ));
        }
    }
    &.two-rows {
        @include mobile-props((
            display: flex,
            flex-direction: column,
        ));

        & .dialog__button:first-child {
            @include mobile-props((
                margin-bottom: 12px,
            ));
            @include desktop-props((
                margin-right: 16px,
            ));
        }
    }
}

.custom_dialog_root__flex-wrapper {
    @include mobile-props((
        height: calc(100vh - 56px),
        display: flex,
        flex-direction: column,
    ));
}


/* PROFILE MODIFIACTION STYLES */

.profile_notifications {
    @include desktop-props((
        padding: (
            1024: (
                26px 28px,
            )
        ),
    ));

    .dialog__strong-text {
        @include desktop-props((
            padding-top: 0px,
        ))
    }

    .dialog__title-container {
        @include desktop-props((
            padding: (
                1024:  0 0 24px,
                1280: 0 0 28px,
                1680: 0 0 28px,
                1920: 0 0 30px,
            ),
        ));
    }

    .dialog__buttons-container {
        @include desktop-props((
            display: flex,
            padding: (
                1024:  0,
                1366: 0,
                1680: 0,
                1920: 0,
            ),
        ));
    }

    .dialog__header {
        @include all-props((
            display: flex,
            justify-content: flex-end,
        ));
        @include desktop-props((
            position: absolute,
            top: 0,
            left: 0,
            right: 0,
            padding-top: 26px,
            padding-right: 28px,
        ));
    }
}
