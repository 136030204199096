.spinner-container {
    width: 100%;
    height: 100%;
}

@keyframes rotate {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
}

.spinner {
    width: 100%;
    height: 100%;

    animation: rotate 1000ms infinite linear;
}

.success {
    width: 100%;
    height: 100%;
}

