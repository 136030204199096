@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";


// $padding: 24px;
// $video-aspect: calc(946 / 532);
// $header-height: 13.33vw;

// $w: calc(100vw - $padding * 2);
// $h: calc(calc($w * $video-aspect) + $header-height);

// $content-height: calc($h - $header-height);


.video-slider-modal {
    @include all-props((
        position: fixed,
        left: 0,
        top: 0,
        width: 100%,
        height: 100%,
        z-index: 100,
        background: rgba(15, 15, 15, 0.6),
        backdrop-filter: blur(3.5px),
        // padding: $padding,
        box-sizing: border-box,
        display: flex,
        align-items: center,
    ));

    @include responsive-mobile-props(390px, (
        padding: 24px,
    ));

    @include responsive-mobile-props(390px, (
        padding-top: 52px,
        padding-bottom: 52px,
    ));
}

.spa {
    z-index: 31001;
}

.video-slider-modal-container {
    // @include all-props((
    //     background: #1F1F1F,
    //     margin: 0 auto,
    //     overflow: hidden,
    // ));

    // @include mobile-props((
    //     height: $h,
    //     width: $w,
    // ));

    @include all-props((
        width: 100%,
        height: 100%,
        object-fit: contain,
        overflow: hidden,
        display: flex,
        flex-direction: column,
    ));

    @include responsive-mobile-props(390px, (
        border-radius: 16px,
    ));

    @include desktop-props((
        width: (
            1024: 736px,
            1366: 982px,
            1600: 1208px,
        ),
        margin: auto,
    ));
    @include responsive-desktop-props(1680px, (
        border-radius: 16px,
    ));
}

.video-slider-modal-header {
    @include all-props((
        // height: $header-height,
        display: flex,
        align-items: center,
        justify-content: flex-end,
        background: #1F1F1F,
    ));

    @include responsive-mobile-props(390px, (
        height: 52px,
    ));

    @include responsive-desktop-props(1680px, (
        height: 52px,
    ))
}

.video-slider-modal-close {
    @include all-props((
        // height: $header-height,
        // width: $header-height,
        display: flex,
        align-items: center,
        justify-content: center,
    ));

    @include responsive-mobile-props(390px, (
        height: 52px,
        width: 52px,
    ));

    @include responsive-desktop-props(1680px, (
        height: 52px,
        width: 52px,
    ));
}

.video-slider-modal-iframe {
    @include all-props((
        height: 100%,
        // height: $content-height,
    ));
}
