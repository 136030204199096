@import "~global/styles/cool-media-queries/index.global.scss";
@import "~global/styles/fonts/index.scss";


/* ANIMATION */
@keyframes dimming {
    0% {
        opacity: 1;
    }
    25% {
        opacity: 0.3
    }
    50% {
        opacity: 0.3;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.auth-confirm-pincode {
    @include all-props((
        display: flex,
        flex-direction: column,
        align-items: center,
        opacity: 0,
        transition: opacity 500ms,
    ));

    &.entered {
        opacity: 1;
    }

    &.loading {
        animation-duration: 1000ms;
        animation-name: dimming;
        animation-iteration-count: infinite;
    }
}

.auth-confirm-pincode-title {
    @include all-props((
        margin-bottom: (
            0: 8px,
            375: 10px,
            1024: 10px,
        ),
        text-align: center,
        max-width: 65% // ширина для подтверждения заказа на /thx
    ));
}
.auth-confirm-pincode-input {
    @include all-props((
        height: (
            0: 50px,
            375: 54px,
            1024: 54px,
        ),
        margin-bottom: (
            0: 20px,
            375: 20px,
            1024: 20px,
        ),
        width: (
            0: 170px,
            375: 186px,
            1024: 186px,
        ),
    ));
}

.auth-confirm-pincode-action__item-wrapper {
    @include all-props((
        width: 100%,
    ));
    & + & {
        @include all-props((
            margin-top: (
                0: 0px,
                1024: 10px,
            )
        ));
    }
}
.auth-confirm-pincode-action__item {
    @include all-props((
        text-decoration: underline,
        font-weight: var(--font-weight__regular),
        text-align: center,
        color: var(--color__hering),
        margin-bottom: (
            0: 8px,
            375: 10px,
        ),
    ));
}


// INPUT

.pincode-input-root {
    width: inherit;
    height: inherit;
    opacity: 1;
    &.loading {
        opacity: 0.3;
    }

}

:global {
    .pincode-input-mask-lib {
        width: 100%;
        height: 100%;
        & input {
            @include all-props((
                margin-right: 6px,
                text-align: center,
                color: var(--color__pepper),
                border: 1.5px solid var(--color__egg),
                font-family: $haas-disp,
                font-weight: 600,
                font-size: (
                    0: 18px,
                    375: 19px,
                    1024: 19px,
                ),
                width: (
                    0: 38px,
                    375: 42px,
                    1024: 42px,
                ),
            ));
        }

        & input:nth-last-child(1) {
            margin-right: 0px,
        }

        &.wrong {
            & input {
                color: var(--color__clockwork-orange);
                border: 1.5px solid var(--color__clockwork-orange);
            }
        }
    }
}
.pincode-input-mask {
    position: absolute;
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: space-between;
    z-index: 1;

    input {
        background-color: var(--color__coconut) !important;
    }
}

.pincode-input-focus-button {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}
